import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, Container, Label, Row } from 'reactstrap';
import { describeCostCenter, saveCostCenter, setOnChange, setOnCleanUp } from '../../../store/actions/Master/costCenter';
import { AvForm, AvInput, AvGroup } from 'availity-reactstrap-validation';
import { initialCostCenterState } from '../../../store/reducers/initialState';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _costcenter } from '../../../constants/componentConstants';
import { IS_NEW } from '../../../constants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import Dropdown from '../../CustomControls/Dropdown';
import CustomAvField from '../../CustomControls/CustomAvField';
import ErrorListing from '../../CustomControls/ErrorListing';

const ManageCostCenter = props => {
  let history = useHistory();
  const backUrl = '/master/cost-centers';
  const dispatch = useDispatch();
  const costCenter = useSelector(state => state.costCenter);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  let IsEdit = false;
  let { costcenterid } = useParams();
  const notificationType = costCenter.isError ? 'error' : 'success';

  const showSaveOption = hasPermissionToAction(permissions, _costcenter, _create);

  let currentCostCenter = costCenter.currentCostCenter;
  let localPath = '/master/cost-centers/cost-center';
  let heading = 'Add Cost Center';

  if (costcenterid) {
    IsEdit = true;
    heading = 'Edit Cost Center';
    localPath = localPath + `/${costcenterid}`;
  }

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Cost Center Listing', path: '/master/cost-centers' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  useEffect(() => {
    if (costcenterid) {
      dispatch(describeCostCenter(costcenterid));
    } else {
      dispatch(describeCostCenter(IS_NEW));
    }
  }, [dispatch, costcenterid]);

  //clean up
  useEffect(() => {
    return () => {
      setLocalCleanUpData({
        data: { ...costCenter.currentCostCenter, ...initialCostCenterState.currentCostCenter }
      });
    };
  }, []);

  const onPageChangeHandler = currentPage => {};

  const setLocalCleanUpData = cleanUpData => {
    dispatch(setOnCleanUp(cleanUpData));
  };

  const setLocalChange = currentState => {
    dispatch(setOnChange(currentState));
  };

  const handleInput = e => {
    setLocalChange({
      ...costCenter,
      data: { ...costCenter.currentCostCenter, [e.target.name]: e.target.value }
    });
  };

  const dropDownChange = (field, selectedOption) => {
    setLocalChange({
      ...costCenter,
      data: { ...costCenter.currentCostCenter, [field]: selectedOption.value }
    });
  };

  const handleCheckBoxInput = e => {
    let isChecked;
    if (e.target.value === 'false') {
      isChecked = false;
    } else {
      isChecked = true;
    }
    setLocalChange({
      ...costCenter,
      data: { ...costCenter.currentCostCenter, [e.target.name]: !isChecked }
    });
  };

  const handleSubmit = e => {
    if (costCenter.currentCostCenter) {
      dispatch(saveCostCenter(costCenter.currentCostCenter, !IsEdit, costcenterid));
    }
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  return (
    <React.Fragment>
      {costCenter.redirect ? <Redirect to={backUrl} /> : ''}
      {costCenter.isLoading && <LoadingSpinner />}
      {costCenter.showMessage && <Notification notificationType={notificationType} message={costCenter.message} />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardTitle>{heading}</CardTitle>
                <CardBody>
                  <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                    {costCenter.errorList && costCenter.errorList.length > 0 && <ErrorListing errorList={costCenter.errorList} />}
                    <Row>
                      <Col lg={12}>
                        <Row>
                          <Col sm="4">
                            <div className="mb-3">
                              <CustomAvField
                                name="costCenterCode"
                                label="Cost Center Code"
                                className="form-control"
                                placeholder="Cost Center Code"
                                value={currentCostCenter.costCenterCode}
                                onChange={handleInput}
                                type="text"
                                maxLength="20"
                                validate={{
                                  required: { value: true, errorMessage: 'Please enter a valid cost center code' }
                                }}
                              />
                            </div>
                            <div className="mb-3">
                              <CustomAvField
                                name="costCenterName"
                                label="Cost Center Name"
                                className="form-control"
                                placeholder="Cost Center Name"
                                value={currentCostCenter.costCenterName}
                                onChange={handleInput}
                                type="text"
                                maxLength="50"
                                validate={{
                                  required: { value: true, errorMessage: 'Please enter a valid cost center name' }
                                }}
                              />
                            </div>
                            <div className="mb-3">
                              <Dropdown
                                placeholder="Select Client"
                                label="Client"
                                name="clientProfileId"
                                options={currentCostCenter.clientProfiles}
                                isMulti={false}
                                value={currentCostCenter.clientProfiles.find(x => x.value === currentCostCenter.clientProfileId)}
                                onChange={val => dropDownChange('clientProfileId', val)}
                                validate={{
                                  required: { value: true, errorMessage: 'Please select a client' }
                                }}
                              />
                            </div>
                          </Col>
                          <Col sm="12">
                            <div className="mb-3">
                              <AvGroup check>
                                <AvInput
                                  type="checkbox"
                                  name="isActive"
                                  label="Is Active"
                                  onChange={handleCheckBoxInput}
                                  value={currentCostCenter.isActive}
                                />
                                <Label check for="checkbox">
                                  {' '}
                                  Is Active
                                </Label>
                              </AvGroup>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      {showSaveOption && (
                        <Button
                          type="submit"
                          color="primary"
                          className="btn "
                          disabled={costCenter.isLoading || (costCenter.showMessage && !costCenter.isError)}
                        >
                          SAVE
                        </Button>
                      )}
                      <Button onClick={cancelHandler} color="secondary" className=" ">
                        CANCEL
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageCostCenter;
