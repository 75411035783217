import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
// import { getReportRequisition } from '../../../store/actions/order';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { isEmpty, map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { setAllOrderDetails } from '../../../store/actions/order';
import moment from 'moment';

const OrderDetailReport = props => {
  let history = useHistory();
  const dispatch = useDispatch();
  let { requisitionid } = useParams();
  const backUrl = '/order/orderlist';
  const requisition = useSelector(state => state.requisition);
  const ordersDetails = useSelector(state => state.orderDetail?.ordersDetails);
  let localPath = `/order/orderlist/order-report/${requisitionid}`;
  const allData = useSelector(state => state);
  let heading = 'Order Report';
  let reportData = requisition.requisitionReport;

  const imageResult = window.localStorage.getItem('lmsCompanyLogo');

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Order Listing', path: '/order/orderlist' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  useEffect(() => {
    if (requisitionid) {
      dispatch(setAllOrderDetails(requisitionid));
    }
  }, [dispatch, requisitionid]);

  const printInvoice = () => {
    window.print();
  };

  const cancelHandler = () => {
    history.push('/customer/order-info');
  };
  let totalQty = 0;
  if (reportData && ordersDetails?.length > 0) {
    totalQty = ordersDetails.reduce(function(prev, cur) {
      return prev + parseInt(cur.productQuantity);
    }, 0);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          {!isEmpty(reportData) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="report-container">
                      <div id="content">
                        <div className="table-responsive">
                          <Table className="table-nowrap">
                            <thead>
                              <tr className="reportLine">
                                <th >Product Name</th>
                                <th >Client Name</th>
                                <th >Department</th>
                                <th>Order Ref</th>
                                <th >Ordered Date</th>
                                <th >Requested Delivery Date</th>
                                <th >Ordered By</th>
                                <th >Product Price</th>
                                <th  className="text-end">
                                  Product Quantity
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {ordersDetails?.map((item, key) => (
                                <tr key={key}>
                                  <td>{item.productName}</td>
                                  <td>{item?.clientName}</td>
                                  <td>{item?.departmentName}</td>
                                  <td>{item?.orderRef}</td>
                                  <td>{moment(new Date(item?.orderDate)).format('DD-MMM-YYYY')}</td>
                                  <td>{moment(new Date(item?.requestedDeliveryDate)).format('DD-MMM-YYYY')}</td>
                                  <td>{item?.createdBy}</td>
                                  <td>{item.productPrice}</td>
                                  <td className="text-end">{item.productQuantity}</td>
                                </tr>
                              ))}
                              <tr style={{ width: '100%' }}>
                                <td className="text-start">
                                  <b>{`Line ${ordersDetails?.length || 0} `}</b>
                                </td>
                                <td className="text-start">
                                </td>
                                <td className="text-start">
                                </td>
                                <td className="text-start">
                                </td>
                                <td className="text-start">
                                </td>
                                <td className="text-start">
                                </td>

                                <td colSpan="2" className="text-end">
                                  <b>{`Total    :  ${totalQty}`}</b>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div className="d-print-none">
                        <div className="float-start">
                          {/* <Link to="#" onClick={cancelHandler} className="btn btn-primary w-md button-space">
                            Submit
                          </Link> */}
                           <Link to="#" onClick={printInvoice} className="btn btn-outline-primary">
                            <FontAwesomeIcon className="standardIcon" icon={faPrint} /> Print
                          </Link>
                          <Link to="#" onClick={cancelHandler} className="btn btn-secondary w-md button-space">
                            Back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OrderDetailReport;
