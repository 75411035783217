import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Signup.scss';
import { useSelector, useDispatch } from 'react-redux';
import { checkAndRedirect } from '../../utils/';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { submitSignup } from '../../store/actions/auth';
import { getIsLoading, getUserLoginError, getUserLoginErrorMessage } from '../../selectors/index';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import naztec from '../../assets/images/naztec.svg';
import LoadingSpinner from '../CustomControls/Spinner';
// import { getByDisplayValue } from '@testing-library/react';

const Signup = ({ isAuthenticated }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    contactperson: '',
    username: '',
    contactphone: '',
    password: '',
    banknumber: '',
    debtorcode: '',
    streetaddress: '',
    state: '',
    suburb: '',
    postcode: '',
    DepartmentName: '',
    billingstreetaddress: '',
    billingstate: '',
    billingsuburb: '',
    billingpostcode: '',
    checkbox: false
  });
  function setbilling() {
    setForm({
      ...form,
      billingstreetaddress: form.streetaddress,
      billingstate: form.state,
      billingsuburb: form.suburb,
      billingpostcode: form.postcode
    });
  }
  function removebilling() {
    setForm({
      ...form,
      billingstreetaddress: '',
      billingstate: '',
      billingsuburb: '',
      billingpostcode: ''
    });
  }
  const isLoading = useSelector(state => getIsLoading(state));
  const loginError = useSelector(state => getUserLoginError(state));
  const loginErrorMessage = useSelector(state => getUserLoginErrorMessage(state));

  const redirect = () => push('/login');

  useEffect(() => {
    checkAndRedirect(!isAuthenticated, redirect);
  }, [isAuthenticated]);

  const handleInput = e => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSubmit = e => {
    //  e.persist();
    // const usernameError = !(form.username && form.username.length > 5);
    const passwordError = !(form.password && form.password.length > 1);

    if (!passwordError) {
      //Removing cookies
      if (form.username !== Cookies.get('username')) {
        Cookies.remove('name');
        Cookies.remove('username');
        Cookies.remove('userid');
        Cookies.remove('accessToken');
      }
    }
    dispatch(submitSignup(form));
  };

  // const getGreeting = () => {
  //   let greetingText = 'Hello!';
  //   const date = new Date();
  //   const hour = date.getHours();
  //   if (hour < 12) {
  //     greetingText = 'Good Morning!';
  //   } else if (hour < 18) {
  //     greetingText = 'Good Afternoon!';
  //   } else {
  //     greetingText = 'Good Evening!';
  //   }
  //   return greetingText;
  // };

  return (
    <div className="main-container">
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10">
              <div className="wrap d-md-flex">
                {/* <div className="text-wrap p-4 p-lg-5 text-center d-flex align-items-center order-md-last">
                  <div className="text w-100">
                    <div className="mb-4">
                      <h2>{getGreeting()}</h2>
                    </div>
                    <div className="mb-4">
                      {' '}
                      <h4>Welcome to the Naztec LMS</h4>
                    </div>
                  </div>
                </div> */}
                <div className="login-wrap p-4 p-lg-5">
                  <div className="d-flex">
                    <div className="w-100">
                      <h3 className="mb-4">Signup</h3>
                    </div>
                    <div className="mb-4 mb-md-5">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img src={naztec} alt="" height="50" />
                      </Link>
                    </div>
                  </div>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={() => {
                      handleSubmit();
                    }}
                  >
                    <div className="row1">
                      <div className="mb-2">
                        <AvField
                          name="firstname"
                          label="First Name *"
                          className="form-control"
                          placeholder="Enter first name"
                          value={form.firstname}
                          onChange={handleInput}
                          type="text"
                          validate={{
                            required: { value: true, errorMessage: 'Please enter a first name' }
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <AvField
                          name="lastname"
                          label="Last Name *"
                          className="form-control"
                          placeholder="Enter last name"
                          value={form.lastname}
                          onChange={handleInput}
                          type="text"
                          validate={{
                            required: { value: true, errorMessage: 'Please enter a last name' }
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <AvField
                          name="contactperson"
                          label="Primary Contact Person *"
                          className="form-control"
                          placeholder="Enter person"
                          value={form.contactperson}
                          onChange={handleInput}
                          type="text"
                          validate={{
                            required: { value: true, errorMessage: 'Please enter a contact person' }
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <AvField
                          name="username"
                          label="Primary Contact Email *"
                          className="form-control"
                          placeholder="Enter email"
                          value={form.username}
                          onChange={handleInput}
                          type="email"
                          validate={{
                            required: { value: true, errorMessage: 'Please enter an email' },
                            pattern: {
                              value: '/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/',
                              errorMessage: 'Please enter a valid email address'
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="row1">
                      <div className="mb-2">
                        <AvField
                          name="contactphone"
                          label="Primary Contact’s Phone *"
                          className="form-control"
                          placeholder="Enter contact's phone"
                          value={form.contactphone}
                          onChange={handleInput}
                          type="number"
                          validate={{
                            required: { value: true, errorMessage: 'Please enter a contact phone' }
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <AvField
                          name="password"
                          label="Password"
                          className="form-control"
                          placeholder="Enter password"
                          type="password"
                          value={form.password}
                          onChange={handleInput}
                          validate={{
                            required: { value: true, errorMessage: 'Please enter a password' }
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <AvField
                          name="banknumber"
                          label="ABN *"
                          className="form-control"
                          placeholder="Enter ABN"
                          value={form.banknumber}
                          onChange={handleInput}
                          type="number"
                          validate={{
                            required: { value: true, errorMessage: 'Please enter an ABN' }
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <AvField
                          name="debtorcode"
                          label="Debtor Code"
                          className="form-control"
                          placeholder="Enter code"
                          value={form.debtorcode}
                          onChange={handleInput}
                          type="number"
                          //   validate={{
                          //     required: { value: true, errorMessage: 'Please enter an email' },
                          //     pattern: {
                          //       value: '/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/',
                          //       errorMessage: 'Please enter a valid email address'
                          //     }
                          //   }}
                        />
                      </div>
                    </div>
                    <div className="row1">
                      <div className="mb-2">
                        <AvField
                          name="streetaddress"
                          label="Delivery Street Address *"
                          className="form-control"
                          placeholder="Enter address"
                          value={form.streetaddress}
                          onChange={handleInput}
                          type="text"
                          validate={{
                            required: { value: true, errorMessage: 'Please enter an address' }
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <AvField
                          name="state"
                          label="Deliver State *"
                          className="form-control"
                          placeholder="Enter state"
                          type="text"
                          value={form.state}
                          onChange={handleInput}
                          validate={{
                            required: { value: true, errorMessage: 'Please enter a state' }
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <AvField
                          name="suburb"
                          label="Delivery Suburb"
                          className="form-control"
                          placeholder="Enter suburb"
                          value={form.suburb}
                          onChange={handleInput}
                          type="text"
                          //   validate={{
                          //     required: { value: true, errorMessage: 'Please enter an email' },
                          //     pattern: {
                          //       value: '/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/',
                          //       errorMessage: 'Please enter a valid email address'
                          //     }
                          //   }}
                        />
                      </div>
                      <div className="mb-2">
                        <AvField
                          name="postcode"
                          label="Delivery Postcode"
                          className="form-control"
                          placeholder="Enter postcode"
                          value={form.postcode}
                          onChange={handleInput}
                          type="number"
                          //   validate={{
                          //     required: { value: true, errorMessage: 'Please enter an email' },
                          //     pattern: {
                          //       value: '/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/',
                          //       errorMessage: 'Please enter a valid email address'
                          //     }
                          //   }}
                        />
                      </div>
                    </div>
                    <div className="row1">
                      <div className="mb-2">
                        <AvField
                          name="DepartmentName"
                          label="Department Name *"
                          className="form-control"
                          placeholder="Enter Department Name"
                          value={form.DepartmentName}
                          onChange={handleInput}
                          type="text"
                          validate={{
                            required: { value: true, errorMessage: 'Please enter a Department Name' }
                          }}
                        />
                      </div>
                    </div>
                    <div className="mb-2 my-auto">
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          name="checkbox"
                          value={form.checkbox}
                          onClick={e => {
                            if (e.target.checked) {
                              setForm({
                                ...form,
                                checkbox: true
                              });
                              setbilling();
                            } else {
                              setForm({
                                ...form,
                                checkbox: false
                              });
                              removebilling();
                            }
                          }}
                        />{' '}
                        <Label check>Use Same Address as the Delivery Address</Label>
                      </FormGroup>
                    </div>
                    <div className="row1">
                      <div className="mb-2">
                        <AvField
                          name="billingstreetaddress"
                          label="Billing Street Address"
                          className="form-control"
                          placeholder="Enter address"
                          value={form.billingstreetaddress}
                          onChange={handleInput}
                          type="text"
                          //   validate={{
                          //     required: { value: true, errorMessage: 'Please enter an email' },
                          //     pattern: {
                          //       value: '/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/',
                          //       errorMessage: 'Please enter a valid email address'
                          //     }
                          //   }}
                        />
                      </div>
                      <div className="mb-2">
                        <AvField
                          name="billingstate"
                          label="Billing State"
                          className="form-control"
                          placeholder="Enter state"
                          value={form.billingstate}
                          onChange={handleInput}
                          type="text"
                          //   validate={{
                          //     required: { value: true, errorMessage: 'Please enter an email' },
                          //     pattern: {
                          //       value: '/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/',
                          //       errorMessage: 'Please enter a valid email address'
                          //     }
                          //   }}
                        />
                      </div>
                      <div className="mb-2">
                        <AvField
                          name="billingsuburb"
                          label="Billing Suburb"
                          className="form-control"
                          placeholder="Enter suburb"
                          value={form.billingsuburb}
                          onChange={handleInput}
                          type="text"
                          //   validate={{
                          //     required: { value: true, errorMessage: 'Please enter an email' },
                          //     pattern: {
                          //       value: '/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/',
                          //       errorMessage: 'Please enter a valid email address'
                          //     }
                          //   }}
                        />
                      </div>
                      <div className="mb-2">
                        <AvField
                          name="billingpostcode"
                          label="Billing Postcode"
                          className="form-control"
                          placeholder="Enter postcode"
                          type="number"
                          value={form.billingpostcode}
                          onChange={handleInput}
                          //   validate={{
                          //     required: { value: true, errorMessage: 'Please enter a postcode' }
                          //   }}
                        />
                      </div>
                    </div>
                    {loginError && (
                      <div>
                        <p className="show-custom-error">{loginErrorMessage}</p>{' '}
                      </div>
                    )}
                    <div className="mt-3 btn-block">
                      {isLoading ? (
                        <LoadingSpinner centeredLoading={false} />
                      ) : (
                        <Button disabled={isLoading} className="btn btn-primary">
                          Signup
                        </Button>
                      )}
                    </div>
                  </AvForm>
                  <div className="mt-2 text-center">
                    <p className="mb-0">
                      Already have an account?{' '}
                      <Link to="/login" className="text-[#3A37A6]">
                        Login
                      </Link>
                    </p>
                  </div>
                  <div className="mt-2 text-center">
                    <p className="mb-0">© {new Date().getFullYear()} Softlogic Australia</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

Signup.defaultProps = {
  isAuthenticated: false
};

Signup.propTypes = {
  isAuthenticated: PropTypes.bool
};

export default Signup;
