import { GROUP_GET_ALL_DATA, GROUP_GET_DATA, GROUP_ERROR, GROUP_IS_LOADING, GROUP_SAVE_DATA, GROUP_ON_CHANGE_DATA, GROUP_CLEAN_UP, GROUP_USERS_CHANGE, GROUP_PERMISSIONS_CHANGE, GROUP_USERS_SAVE, GROUP_PERMISSIONS_SAVE } from '../../constants/index';
import * as constant from "../../constants/componentConstants";
import { initialGroupState } from './initialState';

const groupReducer = (state = initialGroupState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GROUP_ERROR: {
      const { showMessage, errorMessage } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage
      };
    }
    case GROUP_GET_ALL_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        groupList: resultData
      };
    }
    case GROUP_GET_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        currentGroup: {
          ...state.currentGroup,
          ...resultData
        }
      };
    }
    case GROUP_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }
    case GROUP_SAVE_DATA: {
      const { showMessage, message, redirect } = payload;
      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message
      };
    }

    case GROUP_ON_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        currentGroup: {
          ...state.currentGroup,
          ...data
        }
      };
    }
    case GROUP_CLEAN_UP: {
      let { data } = payload;

      return {
        ...state,
        message: '',
        isError: false,
        showMessage: false,
        redirect: false,
        currentGroup: {
          ...state.currentGroup,
          ...data
        }
      };
    }


    case GROUP_USERS_CHANGE: {
      let { data } = payload;
      let currentList = state.currentGroup.users;
      const objIndex = currentList.findIndex((obj => obj.id == data.id))
      currentList[objIndex].selected = data.value;

      return {
        ...state,
        currentGroup: {
          ...state.currentGroup,
          users: currentList
        }
      };
    }
    case GROUP_USERS_SAVE: {
      const { showMessage, message, redirect } = payload;
      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message
      };
    }

    case GROUP_PERMISSIONS_CHANGE: {
      let { data } = payload;
      let currentList = state.currentGroup.permissions;
      if (data.type == constant._view) {
        const objIndex = currentList.findIndex((obj => obj.viewId === data.id))
        currentList[objIndex].isView = data.value;
      }
      else if (data.type == constant._create) {
        const objIndex = currentList.findIndex((obj => obj.createId === data.id))
        currentList[objIndex].isCreate = data.value;
      }
      else if (data.type == constant._delete) {
        const objIndex = currentList.findIndex((obj => obj.deleteId === data.id))
        currentList[objIndex].isDelete = data.value;
      }
      else if (data.type == constant._reportprint) {
        const objIndex = currentList.findIndex((obj => obj.printViewId === data.id))
        currentList[objIndex].isPrintView = data.value;
      }

      return {
        ...state,
        currentGroup: {
          ...state.currentGroup,
          users: currentList
        }
      };
    }

    case GROUP_PERMISSIONS_SAVE: {
      const { showMessage, message, redirect } = payload;
      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message
      };
    }

    default:
      return state;
  }
};

export default groupReducer;
