import {
    RPT_COMPANY_LISTING_SET_DATA,
    RPT_COMPANY_LISTING_ERROR,
    RPT_COMPANY_LISTING_IS_LOADING,
    RPT_COMPANY_LISTING_ON_SEARCH_CHANGE_DATA,
    RPT_COMPANY_LISTING_CLEAN_UP
  } from '../../../constants/index';
  import { initialRptCompanyListingState } from '../initialReportState';
  
  const rptCompanyListingReducer = (state = initialRptCompanyListingState, action) => {
    const { payload, type } = action;
  
    switch (type) {
      case RPT_COMPANY_LISTING_ERROR: {
        const { showMessage, errorMessage } = payload;
        return {
          ...state,
          isError: showMessage ? true : false,
          showMessage: showMessage,
          message: errorMessage
        };
      }
      case RPT_COMPANY_LISTING_SET_DATA: {
        const { resultData } = payload;
  
        let filterDataResult = state.filterData;
  
        if (resultData.filterData) {
          filterDataResult = resultData.filterData;
        }
  
        return {
          ...state,
          rptList: resultData.reportData,
          filterData: filterDataResult
        };
      }
      case RPT_COMPANY_LISTING_IS_LOADING: {
        const { isLoading } = payload;
        return {
          ...state,
          isLoading: isLoading
        };
      }
  
      case RPT_COMPANY_LISTING_CLEAN_UP: {
        let { data } = payload;
  
        return {
          ...state,
          ...data
        };
      }
  
      case RPT_COMPANY_LISTING_ON_SEARCH_CHANGE_DATA: {
        let { data } = payload;
  
        return {
          ...state,
          filterOptions: {
            ...state.filterOptions,
            ...data
          }
        };
      }
      default:
        return state;
    }
  };
  
  export default rptCompanyListingReducer;
  