import React from 'react';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper, faRedo, faShareSquare, faPrint, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
// import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import './style.scss';

const CustomerTable = ({ paginatedData, editHandler, deleteHandler, showDelete }) => {
  return (
    <React.Fragment>
      <div>
        <Table className="table" responsive>
          <thead>
            <tr>
              {/* <th>Product Code</th> */}
              <th>Customer Code</th>
              <th>Customer Name</th>
              <th>Customer ABN</th>
              <th>Customer Email</th>
              {/* <th>Product Type Name</th> */}
              {/* <th>Status</th> */}
              {/* <th>Actions</th> */}
              <th>View</th>
              {showDelete && <th className="col-sm-1">Delete</th>}
            </tr>
          </thead>
          <tbody>
            {paginatedData.map(n => {
              return (
                <tr key={n.clientProfileId}>
                  {/* <td>{n.productCode || ''}</td> */}
                  <td>{n.clientCode || ''}</td>
                  <td>{n.clientName || ''}</td>
                  <td>{n.abn || ''}</td>
                  <td>{n.primaryContactEmail || ''}</td>
                  {/* <td>{n.productTypeName || ''}</td> */}
                  {/* <td>{n.isActive ? 'Active' : 'Inactive'}</td> */}
                  {
                    // <td>
                    //   <button className="btn tableIcons" onClick={() => editHandler(n.clientProfileId)}>
                    //     {' '}
                    //     <FontAwesomeIcon icon={faPencilAlt} />
                    //   </button>
                    // </td>
                  }
                  {
                    <td>
                      <button className="btn tableIcons" onClick={() => editHandler(n.clientProfileId)}>
                        {' '}
                        <FontAwesomeIcon icon={faNewspaper} />
                      </button>
                    </td>
                  }
                  {showDelete && (
                    <td>
                      <button className="btn tableIcons" onClick={() => deleteHandler(n.clientProfileId)}>
                        {' '}
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default CustomerTable;
