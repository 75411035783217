import React, { useEffect } from 'react';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import './style.scss';

const LocationTable = ({ paginatedData, editHandler, deleteHandler, showDelete }) => {
  return (
    <React.Fragment>
      <div>
        <Table className="table" responsive>
          <thead>
            <tr>
              <th>Location Name</th>
              <th>Contact Person</th>
              <th>Contact Email</th>
              <th>Contact Phone</th>
              <th>Status</th>
              <th>Action</th>
              {showDelete && <th className="col-sm-1">Delete</th>}
            </tr>
          </thead>
          <tbody>
            {paginatedData.map(n => {
              return (
                <tr key={n.locationId}>
                  <td>{n.locationName || ''}</td>
                  <td>{n.contactPerson || ''}</td>
                  <td>{n.contactEmail || ''}</td>
                  <td>{n.contactPhone || ''}</td>
                  <td>{n.isActive ? 'Active' : 'Inactive'}</td>
                  {
                    <td>
                      <button className="btn tableIcons" onClick={() => editHandler(n.locationId)}>
                        {' '}
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </button>
                    </td>
                  }
                  {showDelete && (
                    <td>
                      <button className="btn tableIcons" onClick={() => deleteHandler(n.locationId)}>
                        {' '}
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default LocationTable;
