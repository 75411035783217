import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Col, Row } from 'reactstrap';
import Pagination from '../../CustomControls/Pagination';
import SearchBar from '../../CustomControls/SearchBar';
import { _create, _groups } from '../../../constants/componentConstants';
import { getAllDistributionCenterClients } from '../../../store/actions/Management/distributionCenter';
import { useHistory } from 'react-router-dom';

const ManageClientDistribution = props => {
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const [results, setResults] = useState([]);
  const [urlId, setUrlId] = useState('');
  let history = useHistory();
  const ui = useSelector(state => state.ui);

  const distribution = useSelector(state => state.distribution);
  const pageSize = ui.layout.pageSize;
  let currentUser = props.currentUser;
  let clients = distribution.distributionDetails.clients;

  useEffect(() => {
    if (searchText.length > 0) {
      setResults(
        clients.filter(
          x =>
            x.clientName.toLowerCase().includes(searchText.toLowerCase()) || x.clientCode.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setResults(clients);
    }
  }, [searchText, setResults, clients]);

  useEffect(() => {
    const parts = history.location.pathname.split('/')[4] ?? '';
    setUrlId(parts);
    if (parts !== '') {
      dispatch(getAllDistributionCenterClients(parts));
    }
  }, [dispatch]);

  const onPageChangeHandler = currentPage => {};

  const onCheckboxHandler = (e, id) => {
    props.onClientSelectionHandler(id, e.target.checked);
  };

  const handleInput = e => {
    setSearchText(e.target.value);
  };

  // const heading = currentUser.firstname
  //   ? ` Clients assigned to ${currentUser.firstname || ''} ${currentUser.lastname || ''}`
  //   : 'Clients assigned to the new user';
  const heading = 'Assign Clients to the Remote Linen Room ';
  return (
    <React.Fragment>
      <Row>
        <Col sm="8">
          <h5> {heading}</h5>
        </Col>
      </Row>
      <Row className="mb-2 buttonrow">
        <Col sm="4">
          <SearchBar onChange={handleInput} placeholder="Search" value={searchText} />
        </Col>
        <Col sm="8"></Col>
      </Row>

      <Pagination data={results} pageSize={pageSize} onPageChange={onPageChangeHandler} startingPage={1}>
        <UserTable onCheckboxHandler={onCheckboxHandler} />
      </Pagination>
    </React.Fragment>
  );
};

export default ManageClientDistribution;

const UserTable = ({ paginatedData, onCheckboxHandler }) => {
  return (
    <React.Fragment>
      <div>
        <Table className="table" responsive>
          <thead>
            <tr>
              <th>Select</th>
              <th>Client Code</th>
              <th>Client Name</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData?.map(n => {
              return (
                <tr key={n.clientProfileId}>
                  <td>
                    <input
                      type="checkbox"
                      value={n.isActive}
                      checked={n.isActive}
                      onChange={e => onCheckboxHandler(e, n.clientProfileId)}
                    />
                  </td>
                  <td>{`${n.clientCode}`}</td>
                  <td>{`${n.clientName}`}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};
