import React from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../CustomControls/Breadcrumb';
import features from '../../assets/images/dashboard.png';
import { get as getCookie } from 'js-cookie';

const Dashboard = props => {
    const name = getCookie('name');
    const breadCrumbPaths = [{ id: 1, title: 'Home', path: '/' }];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Home " paths={breadCrumbPaths} />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <div>
                                    <Row>
                                        <Col lg="9" sm="8">
                                            <div className="p-4">
                                                <h5 className="text-primary">Welcome back {name}! </h5>
                                                <br />
                                                <h6>Naztec - Release Information</h6>
                                                <div className="text-muted">
                                                    <p>Your latest update as at 12-Oct-22</p>
                                                    <ul>
                                                        <li>Big fixes on bulk dispatch date not picking up the default requisition delivery date</li>
                                                    </ul>
                                                </div>
                                                <div className="text-muted">
                                                    <p>Update as at 07-Oct-22</p>
                                                    <ul>
                                                        <li>Big fixes on bulk dispatch</li>
                                                        <li>Big fixes on dipatch auto numbering</li>
                                                    </ul>
                                                </div>
                                                <div className="text-muted">
                                                    <p>Update as at 05-Sep-22</p>
                                                    <ul>
                                                        <li>Fixed the GMT date filter bug on the department summary</li>
                                                    </ul>
                                                </div>
                                                <div className="text-muted">
                                                    <p>Update as at 27-Aug-22</p>
                                                    <ul>
                                                        <li>Reset Password functionality</li>
                                                        <li>Department summary report space decreased in the header</li>
                                                        <li>Bug fix on the sorting order of the requisition and dispatch listing</li>
                                                    </ul>
                                                </div>
                                                <div className="text-muted">
                                                    <p>Update as at 19-Aug-22</p>
                                                    <ul>
                                                        <li>New bulk dispatches feature</li>
                                                        <li>Requisition and dispatch listing sort order by date and client</li>
                                                        <li>Dispatch by default will have the delivery date set from the requisition </li>
                                                        <li>Department summary report's date will be set to the previous month's start and end date </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg="3" sm="4" className="align-self-center">
                                            <div>
                                                <img src={features} alt="" className="img-fluid d-block" />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

Dashboard.defaultProps = {
    devices: {}
};

export default Dashboard;
