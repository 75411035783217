import {
  CUSTOMER_DEPARMENTS_SET_DATA,
  CUSTOMER_IS_LOADING,
  CUSTOMER_ON_SEARCH_CHANGE_DATA,
  CUSTOMER_ORDER_PLACED,
  CUSTOMER_PRODUCTS_ERROR,
  CUSTOMER_PRODUCTS_SET_DATA,
  CUSTOMER_PRODUCTS_SET_DATA_DETAILS,
  DISTRIBUTION_PRODUCTS,
  ORDER_ON_CHANGE_DATA
} from '../../constants';
import { initialCustomerState } from './initialState';

const customerReducer = (state = initialCustomerState, action) => {
  const { payload, type } = action;
  switch (type) {
    case CUSTOMER_PRODUCTS_ERROR: {
      const { showMessage, errorMessage, errorList } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage,
        errorList: errorList
      };
    }
    case CUSTOMER_PRODUCTS_SET_DATA: {
      const { resultData } = payload;
      // debugger
      return {
        ...state,
        products: resultData?.data?.map(p => {
          return {
            ...p,
            label: p.productName,
            value: p.productId
          };
        }),
        totalRecords: resultData.totalRecords
      };
    }
    case CUSTOMER_DEPARMENTS_SET_DATA: {
      const { resultData } = payload;

      return {
        ...state,
        department: resultData?.map(p => {
          return {
            ...p,
            label: p.departmentName,
            value: p.departmentId
          };
        }),
        totalRecords: resultData.totalRecords
      };
    }
    case CUSTOMER_PRODUCTS_SET_DATA_DETAILS: {
      const { resultData } = payload;

      return {
        ...state,
        productsList: resultData?.data?.map(p => {
          return {
            ...p,
            label: p.productName,
            value: p.productId
          };
        })
      };
    }
    case DISTRIBUTION_PRODUCTS: {
      const { resultData } = payload;
      return {
        ...state,
        distributionProducts: resultData
      };
    }
    case CUSTOMER_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }
    case CUSTOMER_ON_SEARCH_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        filterOptions: {
          ...state.data,
          ...data
        }
      };
    }

    case CUSTOMER_ORDER_PLACED: {
      //As per require we do not redirect in steam preserve some of the data
      let { showMessage, message, redirect } = payload;

      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message
      };
    }
    default:
      return state;
  }
};

export default customerReducer;
