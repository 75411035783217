import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Dropdown from '../../CustomControls/Dropdown';
import Table from './ProductTable';
import Pagination from '../../CustomControls/Pagination';
// import { getAllProducts } from '../../../store/actions/Master/product';
//  setOnConfirmationModal, deleteProduct
import { getAllCustomerDeparments, getAllCustomerProducts } from '../../../store/actions/customer';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _product, _delete } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import SearchBar from '../../CustomControls/SearchBar';
import CustomConfirmation from '../../CustomControls/ConfirmationModal';

import './style.scss';
import { setOnSearchChange } from '../../../store/actions/customer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Product = props => {
  const [searchText, setSearchText] = useState('');
  const [department, setDepartment ] = useState();

  const history = useHistory();
  const dispatch = useDispatch();
  const product = useSelector(state => state.customer);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  const pageSize = ui.layout.pageSize;
  const [selectedDep, setSelectedDep] = useState();
  // const currentPageNumber = product?.filterOptions?.pageNumber;

  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const showDeleteOption = hasPermissionToAction(permissions, _product, _delete);
  const products = useSelector(state => state.customer?.productsList || []);
  const searchInput = useRef(null);

  const selectedProductHandler = val => {
    if(val !== undefined){
      setDepartment(val)
      setSelectedDep(val.value);
      dispatch(
        getAllCustomerProducts(currentPageNumber, pageSize, product?.filterOptions?.filterBy, product?.filterOptions?.sortBy, '', val.value)
      );

    }
  };
  useEffect(()=>{
    selectedProductHandler(product.department[0])
  },[dispatch,product.department])
  useEffect(() => {
    initialCall();
  }, [dispatch]);

  function initialCall() {
    if (currentPageNumber !== 0) {
      dispatch(getAllCustomerDeparments());
    }
  }

  const onPageChange = currentPage => {
    setCurrentPageNumber(currentPage);
  };

  const onAddNewHandler = () => {
    history.push('/product-view/product-quantity');
  };

  const onEditHandler = id => {
    history.push(`/master/products/product/${id}`);
  };

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Product Listing', path: '/master/products', isCurrent: true }
  ];

  const handleInput = e => {
    setCurrentPageNumber(1);
    dispatch(
      getAllCustomerProducts(1, pageSize, product?.filterOptions?.filterBy, product?.filterOptions?.sortBy, searchText, selectedDep)
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {/* {product.showMessage && <Notification notificationType={notificationType} message={product.message} />} */}
        <Container fluid>
          <Breadcrumbs title="Product Listing " paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="4" className="d-flex">
                      <SearchBar
                        onChange={e => {
                          setSearchText(e?.target?.value);
                        }}
                        placeholder="Search By Order No"
                        value={searchText}
                      />
                    </Col>
                    <Col sm="4" className="d-flex align-items-end">
                      <div className="text-sm-start">
                        <Button type="button" className="btn btn-outline-primary" onClick={handleInput}>
                          <FontAwesomeIcon className="standardIcon" icon={faSearch} /> Search
                        </Button>
                      </div>
                    </Col>
                    <Col sm="4">
                      <div className="text-sm-end">
                        <Button type="button" className="btn btn-primary btn-block  app-add-button" onClick={onAddNewHandler}>
                          ADD ORDER
                        </Button>
                      </div>
                    </Col>
                    <Col sm="4">
                      <div className="my-3">
                        <Dropdown
                          placeholder="Select Department"
                          options={product.department}
                          isMulti={false}
                          label="Department"
                          // value={ProductSelectionOptions(). find(x => x.value === selectedProductId)}
                          value={department}
                          onChange={selectedProductHandler}
                          isOptionDisabled={option => option.isdisabled}
                          ref={searchInput}
                          triggerValidation={false}
                          validate={{
                            required: { value: true, errorMessage: 'Please select a department' }
                          }}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="tablerow">
                    <Col xl="12">
                      <div>
                        {product.isLoading && <LoadingSpinner />}
                        <Pagination
                          data={product.products}
                          pageSize={pageSize}
                          onPageChange={onPageChange}
                          startingPage={!currentPageNumber ? 1 : currentPageNumber}
                          apiPaging={true}
                          apiDataLength={product.totalRecords}
                        >
                          <Table editHandler={onEditHandler} showDelete={showDeleteOption} />
                          {/* deleteHandler={onDeleteHandler} */}
                        </Pagination>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Product;
