import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Label,
    Row,
} from 'reactstrap';
import { describeUser, resetPasswordHandler, setOnChange,setOnCleanUp } from '../../../store/actions/user';
import { initialUserState } from '../../../store/reducers/initialState';
import { AvForm } from 'availity-reactstrap-validation';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _resetpassword } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import CustomAvField from '../../CustomControls/CustomAvField';
import ErrorListing from '../../CustomControls/ErrorListing';


const ResetPassword = props => {
    let history = useHistory();
    const backUrl = '/management/users';
    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);
    const permissions = ui.layout.permissions;
    let { userid } = useParams();
    const user = useSelector(state => state.user);
    const notificationType = user.isError ? 'error' : 'success';
    const showSaveOption = hasPermissionToAction(permissions, _resetpassword, _create);

    let currentUser = user.currentUser;
    let localPath = '/management/users/reset-password';
    let heading = 'Reset Password';

    const breadCrumbPaths = [
        { id: 1, title: 'Home', path: '/' },
        { id: 2, title: 'User Listing', path: '/management/users' },
        { id: 3, title: heading, path: localPath, isCurrent: true }
    ];



    useEffect(() => {
        if (userid) {
            dispatch(describeUser(userid));
        }
    }, [dispatch, userid]);

    const [resetPassword, setResetPassword] = useState('');

      //clean up
      useEffect(() => {
        return () => {
          setLocalCleanUpData({
            data: { ...user.currentUser, ...initialUserState.currentUser }
          });
        };
      }, []);

    const setLocalCleanUpData = cleanUpData => {
        dispatch(setOnCleanUp(cleanUpData));
      };

    const handleSubmit = e => {
        if (userid) {
            if (resetPassword.length > 0) {
                dispatch(resetPasswordHandler(userid, resetPassword));
            }
            else {
                setErrorMessage("please enter a valid password")
            }
        }
    };

    const onChangeHandler = e => {
        setResetPassword(e.target.value);
    };

    const setLocalChange = currentState => {
        dispatch(setOnChange(currentState));
    };

    const setErrorMessage = err => {
        let errorlist = user.currentUser.errorList || [];
        if (err) {
            errorlist.push(err);
        }
        else {
            errorlist = [];
        }
        setLocalChange({
            ...user,
            data: { ...user.currentUser, errorList: errorlist }
        });
    };

    const cancelHandler = () => {
        history.push(backUrl);
    };

    return (

        <React.Fragment>
            {user.redirect ? <Redirect to={backUrl} /> : ''}
            {user.isLoading && <LoadingSpinner />}
            {user.showMessage && <Notification notificationType={notificationType} message={user.message} />}
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={heading} paths={breadCrumbPaths} />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardTitle>
                                    <Row className="buttonrow">
                                        <Col md="8">
                                            RESET PASSWORD
                                        </Col>
                                        <Col md="4">
                                        </Col>
                                    </Row>

                                </CardTitle>
                                <CardBody>
                                    {currentUser.errorList && currentUser.errorList.length > 0 && <ErrorListing errorList={currentUser.errorList} />}

                                    <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                                        <Row>
                                            <Col sm="12">
                                                <div className="mb-3">
                                                    <Label> {currentUser.firstName + " " +  currentUser.lastName}</Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="3">
                                                <CustomAvField
                                                    name="password"
                                                    label="Reset Password"
                                                    className="form-control"
                                                    placeholder="Reset password"
                                                    type="password"
                                                    value={resetPassword}
                                                    onChange={onChangeHandler}
                                                    validate={{
                                                        required: { value: true, errorMessage: 'Please enter a password' },
                                                        pattern: {
                                                            value: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
                                                            errorMessage: 'Password is not complex enough.'
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="12">
                                                <div className="mb-3">
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="d-flex flex-wrap gap-2 mb-3">
                                                    {showSaveOption && (
                                                        <Button type="submit" color="primary" className="btn " disabled={user.isLoading}>
                                                            RESET PASSWORD
                                                        </Button>
                                                    )}
                                                    <Button onClick={cancelHandler} color="secondary" className=" ">
                                                        CANCEL
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};


export default ResetPassword;
