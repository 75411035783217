import { addDaysToDate } from '../../utils';

export const initialAuthState = {
  loginError: false,
  loginErrorMessage: ''
};

export const initialUiState = {
  isLoading: false,
  isError: false,
  showMessage: false,
  message: '',
  layout: {
    permissions: [],
    pageSize: 20,
    maxQty: 1000,
    dataFetched: false
  }
};
export const internalOrderState = {
  isLoading: false,
  isError: false,
  showMessage: false,
  redirect: false,
  message: '',
  internalOrder: [],
 
};
export const initialCustomerState = {
  isLoading: false,
  isError: false,
  showMessage: false,
  redirect: false,
  message: '',
  products: [],
  department: [],
  tableData:[]
};

export const initialUserState = {
  isLoading: false,
  message: '',
  isError: false,
  showMessage: false,
  redirect: false,
  userList: [],

  currentUser: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    groupId: '',
    groups: [],
    clientUsers: [],
    clientIds: [],
    isActive: true,
    errorList: []
  }
};

export const initialGroupState = {
  isLoading: false,
  message: '',
  isError: false,
  showMessage: false,
  redirect: false,
  groupList: [],
  currentGroup: {
    id: '',
    groupName: '',
    isActive: true,
    users: [],
    permissions: []
  },
  userList: [],
  permissionList: []
};

export const initialCompanyState = {
  isLoading: false,
  message: '',
  isError: false,
  errorList: [],
  showMessage: false,
  redirect: false,
  companyList: [],
  currentCompany: {
    id: '',
    companyLogo: '',
    companyName: '',
    dispatchContactEmail: '',
    abn: '',
    dispatchContactPerson: '',
    dispatchContactPhone: '',
    invoiceContactEmail: '',
    invoiceContactPerson: '',
    invoiceContactPhone: '',
    invoiceDetails: '',
    postCode: '',
    state: '',
    streetAddress: '',
    primaryContactPerson: '',
    primaryContactEmail: '',
    primaryContactPhone: '',
    suburb: '',
    tax: 10,
    isActive: true,
    licensed: true,
    taxName: '',
    companyPageSize: 20,
    maxReqQty: 2000,
    timezone: '',
    timezoneList: [],
    groups: [],
    userFirstName: '',
    userLastName: '',
    userEmail: '',
    userPassword: '',
    userGroupId: '',
    userPhoneNumber: ''
  }
};

export const initialDistributionState = {
  isLoading: false,
  message: '',
  isError: false,
  errorList: [],
  showMessage: false,
  redirect: false,
  distributionList: [],
  distributionDetails: [],
  distributionId: '',
  currentActiveTab: 'general',
  currentDistribution: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    groupId: '',
    groups: [],
    clientUsers: [],
    clientIds: [],
    isActive: true,
    errorList: [],
    dcCode: '',
    name: ''
  }
};
export const initialClientState = {
  isLoading: false,
  message: '',
  isError: false,
  errorList: [],
  showMessage: false,
  redirect: false,
  clientList: [],
  currentClient: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    groupId: '',
    groups: [],
    clientUsers: [],
    clientIds: [],
    isActive: true,
    errorList: [],
    dcCode: '',
    name: ''
  }
};
export const initialClientPriceState = {
  isLoading: false,
  message: '',
  isError: false,
  errorList: [],
  showMessage: false,
  redirect: false,
  clientList: [],
  selectedIds: [],
  editClient: {
    priceType: '',
    price: 0
  }
};
export const initialClientProfileState = {
  isLoading: false,
  message: '',
  errorList: [],
  isError: false,
  showMessage: false,
  redirect: false,
  showConfirmationModal: false,
  confirmationId: '',
  clientProfileList: [],
  currentClientProfile: {
    clientProfileId: '',
    clientCode: '',
    clientName: '',
    abn: '',
    debtorCode: '',
    deliveryStreetAddress: '',
    deliverySuburb: '',
    deliveryState: '',
    deliveryPostCode: '',
    billingStreetAddress: '',
    billingSuburb: '',
    billingState: '',
    billingPostCode: '',
    primaryContactPerson: '',
    primaryContactEmail: '',
    primaryContactPhone: '',
    deliveryCharge: 0,
    password: '',
    confirmPassword: '',
    invoiceInstruction: '',
    clientTypeId: '',
    isActive: true,
    isOnline: false,
    clientTypes: [],
    weekdays: [],
    deliveryDays: [],
    clientUsers: [],
    userIds: [],
    errorList: []
  }
};

export const initialCostCenterState = {
  isLoading: false,
  message: '',
  isError: false,
  errorList: [],
  showMessage: false,
  redirect: false,
  showConfirmationModal: false,
  confirmationId: '',
  costCenterList: [],
  currentCostCenter: {
    costCenterId: '',
    costCenterCode: '',
    costCenterName: '',
    clientProfileName: '',
    clientProfileId: '',
    isActive: true,
    clientProfiles: []
  }
};

export const initialDepartmentState = {
  isLoading: false,
  message: '',
  isError: false,
  showMessage: false,
  redirect: false,
  errorList: [],
  showConfirmationModal: false,
  confirmationId: '',
  departmentList: [],
  currentDepartment: {
    departmentId: '',
    departmentCode: '',
    departmentName: '',
    debtorCode: '',
    streetAddress: '',
    suburb: '',
    state: '',
    postCode: '',
    primaryContactPerson: '',
    primaryContactEmail: '',
    primaryContactPhone: '',
    additionalCharge: 0,
    noOfDeliveryPerWeek: '',
    costCenterId: '',
    costCenterCode: '',
    costCenterName: '',
    clientProfileName: '',
    clientProfileId: '',
    isActive: true,
    clientProfiles: [],
    costCenters: []
  }
};

export const initialProductTypeState = {
  isLoading: false,
  message: '',
  errorList: [],
  isError: false,
  showMessage: false,
  redirect: false,
  showConfirmationModal: false,
  confirmationId: '',
  productTypeList: [],
  currentProductType: {
    productTypeId: '',
    productTypeCode: '',
    productTypeName: '',
    isActive: true
  }
};

export const initialAuditLogState = {
  isLoading: false,
  message: '',
  isError: false,
  showMessage: false,
  redirect: false,
  auditLogList: [],
  totalRecords: 0,
  filterOptions: {
    pageNumber: 1,
    pageSize: 10,
    filterBy: [],
    sortBy: {
      name: 'ChangedDate',
      order: 'desc'
    }
  }
};

export const initialAuditPriceLogState = {
  isLoading: false,
  message: '',
  isError: false,
  showMessage: false,
  redirect: false,
  auditPricingLogList: [],
  totalRecords: 0,
  filterOptions: {
    pageNumber: 1,
    pageSize: 10,
    filterBy: [],
    sortBy: {
      name: 'ChangedDate',
      order: 'desc'
    }
  }
};

export const initialLocationState = {
  isLoading: false,
  message: '',
  isError: false,
  errorList: [],
  showMessage: false,
  redirect: false,
  showConfirmationModal: false,
  confirmationId: '',
  locationList: [],
  currentLocation: {
    locationId: '',
    locationName: '',
    contactPerson: '',
    contactEmail: '',
    contactPhone: '',
    isActive: true
  }
};

export const initialProductState = {
  isLoading: false,
  message: '',
  isError: false,
  errorList: [],
  showMessage: false,
  createdId: '',
  redirect: false,
  showConfirmationModal: false,
  confirmationId: '',
  productList: [],
  currentProduct: {
    productId: '',
    productTypeId: '',
    productTypeCode: '',
    productTypeName: '',
    productCode: '',
    productName: '',
    perUnitCost: '0',
    weightOfOneUnit: '0',
    packageSize: '0',
    lastPurchaseDate: new Date(),
    lastPurchasePrice: '0',
    standardCharge: '0',
    isActive: true,
    productTypes: [],
    locations: [],
    departments: [],
    suppliers: [],
    departmentSelectList: [],
    locationSelectList: [],
    supplierSelectList: [],
    clientProfileSelectList: [],
    currentDepartment: {
      clientProfileId: '',
      departmentId: '',
      productId: '',
      departmentName: '',
      price: '0',
      orderInPack: false
    },
    currentLocation: {
      locationId: '',
      productId: '',
      locationName: '',
      currentStock: '0',
      storageLocation: ''
    },
    currentSupplier: {
      supplierId: '',
      productId: '',
      supplierName: '',
      packPrice: '0',
      priorityOrder: '0'
    }
  }
};

export const initialRequisitionState = {
  isLoading: false,
  message: '',
  errorList: [],
  isError: false,
  showMessage: false,
  redirect: false,
  showConfirmationModal: false,
  confirmationId: '',
  requisitionList: [],
  totalRecords: 0,
  filterOptions: {
    pageNumber: 1,
    pageSize: 10,
    filterBy: [
      {
        name: 'fromdate',
        value: new Date(addDaysToDate(new Date(), -30)).toDateString()
      },
      {
        name: 'todate',
        value: new Date().toDateString()
      }
    ],
    sortBy: {
      name: 'requisitionref',
      order: 'asc'
    }
  },
  currentRequisition: {
    requisitionId: '',
    requisitionRef: '',
    requisitionDate: '',
    deliveryDate: '',
    remarks: '',
    isDispatched: false,
    clientProfileId: '',
    clientProfileName: '',
    departmentId: '',
    departmentName: '',
    deliveryTypeId: '',
    deliveryTypeName: '',
    refAutoGenerated: true,
    clientProfileSelectList: [],
    departmentSelectList: [],
    deliveryTypeSelectList: [],
    productSelectList: [],
    requisitionDetails: []
  },
  requisitionReport: {
    requisitionId: '',
    requisitionRef: '',
    requisitionDate: '',
    deliveryDate: '',
    remarks: '',
    isDispatched: false,
    clientProfileId: '',
    clientProfileName: '',
    clientProfileCode: '',

    clientBillingPostCode: '',
    clientBillingState: '',
    clientBillingStreetAddress: '',
    clientBillingSuburb: '',
    clientPrimaryContactEmail: '',
    clientPrimaryContactPerson: '',
    clientPrimaryContactPhone: '',

    departmentId: '',
    deliveryTypeId: '',
    deliveryTypeName: '',
    companyName: '',
    companyStreetAddress: '',
    companySuburb: '',
    companyState: '',
    companyPostCode: '',
    departmentName: '',
    departmentCode: '',
    departmentStreetAddress: '',
    departmentSuburb: '',
    departmentState: '',
    departmentPostCode: '',
    requisitionDetails: []
  }
};

export const initialDispatchState = {
  isLoading: false,
  message: '',
  errorList: [],
  isError: false,
  showMessage: false,
  redirect: false,
  showConfirmationModal: false,
  confirmationId: '',
  dispatchList: [],
  totalRecords: 0,
  filterOptions: {
    pageNumber: 1,
    pageSize: 10,
    filterBy: [
      {
        name: 'fromdate',
        value: new Date(addDaysToDate(new Date(), -30)).toDateString()
      },
      {
        name: 'todate',
        value: new Date().toDateString()
      }
    ],
    sortBy: {
      name: 'dispatchref',
      order: 'asc'
    }
  },
  currentDispatch: {
    dispatchId: '',
    dispatchRef: '',
    requisitionRef: '',
    dispatchedDate: '',
    deliveryDate: '',
    requisitionDate: '',
    requisitionRemarks: '',
    expectedDeliveryDate: '',
    remarks: '',
    deliveryTypeId: '',
    departmentId: '',
    isDispatched: false,
    refAutoGenerated: true,
    clientProfileId: '',
    clientProfileName: '',
    departmentName: '',
    deliveryTypeName: '',
    locationId: '',
    requisitionSelectList: [],
    locationSelectList: [],
    dispatchDetails: []
  },
  currentBulkDispatch: {
    clientId: '',
    overrideDispatchDate: false,
    dispatchedBulkDate: '',
    locationId: '',
    clientSelectList: [],
    locationSelectList: [],
    dispatchBulkDetails: []
  },
  dispatchReport: {
    dispatchId: '',
    dispatchRef: '',
    requisitionRef: '',
    dispatchedDate: '',
    deliveryDate: '',
    requisitionDate: '',
    requisitionRemarks: '',
    remarks: '',
    departmentId: '',
    isDispatched: '',
    clientProfileId: '',
    clientProfileName: '',
    clientProfileCode: '',
    clientBillingPostCode: '',
    clientBillingState: '',
    clientBillingStreetAddress: '',
    clientBillingSuburb: '',
    clientPrimaryContactEmail: '',
    clientPrimaryContactPerson: '',
    clientPrimaryContactPhone: '',
    deliveryTypeId: '',
    deliveryTypeName: '',
    locationId: '',
    locationName: '',
    companyName: '',
    companyStreetAddress: '',
    companySuburb: '',
    companyState: '',
    companyPostCode: '',
    departmentName: '',
    departmentCode: '',
    departmentStreetAddress: '',
    departmentSuburb: '',
    departmentState: '',
    departmentPostCode: '',
    dispatchDetails: []
  }
};

export const initialCreditNoteState = {
  isLoading: false,
  message: '',
  isError: false,
  showMessage: false,
  redirect: false,
  showConfirmationModal: false,
  confirmationId: '',
  creditNoteList: [],
  totalRecords: 0,
  filterOptions: {
    pageNumber: 1,
    pageSize: 10,
    filterBy: [
      {
        name: 'fromdate',
        value: new Date(addDaysToDate(new Date(), -30)).toDateString()
      },
      {
        name: 'todate',
        value: new Date().toDateString()
      }
    ],
    sortBy: {
      name: 'creditnoteno',
      order: 'asc'
    }
  },
  currentCreditNote: {
    creditNoteId: '',
    creditNoteNo: '',
    amount: '0.00',
    creditNoteDate: '',
    remarks: '',
    clientProfileId: '',
    clientProfileName: '',
    departmentId: '',
    departmentName: '',
    clientProfileSelectList: [],
    departmentSelectList: []
  },
  creditNoteReport: {
    creditNoteId: '',
    creditNoteNo: '',
    creditNoteDate: '',
    refAutoGenerated: true,
    remarks: '',
    amount: '',
    clientProfileId: '',
    clientProfileName: '',
    clientProfileCode: '',
    clientBillingPostCode: '',
    clientBillingState: '',
    clientBillingStreetAddress: '',
    clientBillingSuburb: '',
    clientPrimaryContactEmail: '',
    clientPrimaryContactPerson: '',
    clientPrimaryContactPhone: '',
    departmentId: '',
    deliveryTypeId: '',
    deliveryTypeName: '',
    companyName: '',
    companyStreetAddress: '',
    companySuburb: '',
    companyState: '',
    companyPostCode: '',
    departmentName: '',
    departmentCode: '',
    departmentStreetAddress: '',
    departmentSuburb: '',
    departmentState: '',
    departmentPostCode: ''
  }
};
