import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Label } from "reactstrap";
import "./style.scss"
import {
    Col,
    Row
} from "reactstrap";
const SearchBar = (props) => {
    const label = props.label ? props.label : "Search";
    return (
        <div>
            <Label for={label} className={`label`} >{label}</Label>
            <div className="inputContainer">
                <FontAwesomeIcon className="icon" icon={faSearch} />
                <input type="text" className="form-control input-text field" value={props.value} placeholder={props.placeholder} onChange={props.onChange} aria-label="search bar" aria-describedby="basic-addon2" />
            </div>
        </div>
    )


}

export default SearchBar;

