import React from "react"
import { Table, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faUsers, faEllipsisH, faUsersCog } from '@fortawesome/free-solid-svg-icons';
import "./style.scss";

const GroupTable = ({ paginatedData, editHandler, userHandler,permissionHandler }) => {


    return (
        <React.Fragment>
            <div>
                <Table className="table" responsive>
                    <thead>
                        <tr>
                            <th>Group Name</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map(n => {
                            return (
                                <tr key={n.id}>
                                    <td>{n.groupName || ''}</td>
                                    <td>{n.isActive ? 'Active' : 'Inactive'}</td>
                                    {/* <td><button className="btn tableIcons" onClick={() => editHandler(n.id)} > <FontAwesomeIcon icon={faPencilAlt} /></button></td> */}
                                    <td>
                                        <UncontrolledDropdown direction="up">
                                            <DropdownToggle href="#" className="card-drop" tag="i">
                                                <FontAwesomeIcon icon={faEllipsisH} /> 
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem href="#" onClick={() => editHandler(n.id)}>
                                                    <FontAwesomeIcon icon={faPencilAlt} /> <span>Edit</span>
                                                </DropdownItem>
                                                <DropdownItem href="#" onClick={() => userHandler(n.id)}>
                                                    <FontAwesomeIcon icon={faUsers} /> <span>Manage Users</span> 
                                                </DropdownItem>
                                                <DropdownItem href="#" onClick={() => permissionHandler(n.id)}>
                                                    <FontAwesomeIcon icon={faUsersCog} /> <span>Permissions</span> 
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

            </div>
        </React.Fragment>
    )
}


export default GroupTable;