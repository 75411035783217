import React from "react"
import PropTypes from 'prop-types'
import { Col, Modal, ModalBody, Row,Input,Label } from "reactstrap"

const RejectionModal = ({ show, onRejectClick, onCloseClick, message, header, id = null,setReason }) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} className="rejectionModal">
      <ModalBody className="model-space">
        <Row>
          <Col lg={12}>
            <div >
            
              <h5 className="text-center" >{header}</h5>
              <p>{message}</p>
   
    <Label for="exampleText" className="mt-2">
     Rejection reason:
    </Label>
    <Input
      id="exampleText"
      name="text"
      type="textarea"
      onChange={(e)=>{
        setReason(e.target.value)
      }}
    />

            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-danger btn-md me-2"
                onClick={onRejectClick}
              >
                Reject
              </button>
              <button
                type="button"
                className="btn btn-secondary btn-md me-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

RejectionModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  message: PropTypes.any,
  header: PropTypes.any
}

export default RejectionModal
