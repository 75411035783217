import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import Papa from 'papaparse';
import {
  getAllRptDeptSummaryData,
  getAllRptProdSummaryCSVData,
  getAllRptProdSummaryData,
  setOnCleanUp,
  setOnSearchChange
} from '../../../store/actions/ReportOperation/rptDepartmentSummary';
import Dropdown from '../../CustomControls/Dropdown';
import DatePicker from '../../CustomControls/CustomDatePicker';
import moment from 'moment';
import { initialRptDeptSummaryState } from '../../../store/reducers/initialReportState';
import { getProductsByDept } from '../../../store/actions/Operation/dispatch';
import { useHistory } from 'react-router-dom';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import { _create, _reportprint, _requisition, _delete } from '../../../constants/componentConstants';
import ReportViewer from '../ReportViewer';
import ReportHeader from './ReportHeader';
import ReportFooter from '../Utils/ReportFooter';
import { generalStyles, noBorderTableStyle } from './ReportStyling';
import { getCustomersName } from '../../../store/actions/customerDetail';
import { addDaysToDate, getLastDateOfLastMonth, getFirstDateOfLastMonth } from '../../../utils';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import {
  getAllOrdersDetails,
  setOnConfirmationModal,
  setAllOrderDetails,
  setApproveOrder,
  setOrderDelete,
  getAllOrdersDetailsCSV,
  getAllOrdersDetailsPDF,
  rejectOrdersDetails,
  getAllCustomerDeparmentsWithId
} from '../../../store/actions/orderDetail';

import LoadingSpinner from '../../CustomControls/Spinner';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';

const styles = StyleSheet.create({
  productName: {
    width: '50%'
  },
  productCode: {
    width: '20%'
  },
  dispatches: {
    width: '15%'
  },
  issuedQty: {
    width: '12%',
    textAlign: 'right'
  },
  itemPrice: {
    width: '15%',
    textAlign: 'right'
  },
  subTotal: {
    width: '15%',
    textAlign: 'right'
  },
  filterView: {
    paddingTop: 10
  },
  filterText: {
    fontSize: 9,
    fontWeight: 'bold'
  }
});

const headerStyle = StyleSheet.create({
  leftCol: {
    width: '70%',
    textAlign: 'left'
  },
  rightLabelCol: {
    width: '15%',
    textAlign: 'left'
  },
  rightCol: {
    width: '15%',
    textAlign: 'left'
  },
  fullCol: {
    width: '100%',
    textAlign: 'left'
  },
  highlight: {
    fontWeight: 'bold'
  }
});

const summaryStyles = StyleSheet.create({
  leftCol: {
    width: '58%',
    textAlign: 'left'
  },
  rightLabelCol: {
    width: '27%',
    textAlign: 'left'
  },
  rightCol: {
    width: '15%',
    textAlign: 'right',
    borderBottom: 0.5,
    borderStyle: 'solid',
    borderColor: '#bfbfbf'
  }
});

const ProductSummaryCSVRpt = props => {
  const csvData = useSelector(state => state?.rptDeptSummary?.csvData);

  // const [csvData, setCsvData] = useState();
  // useEffect(() => {
  //   setCsvData(csvRptData);
  // }, [csvRptData]);

  const dispatch = useDispatch();
  const customer = useSelector(state => state.customerDetail?.customerDetailsByName);

  const [filterFromDate, setFilterFromDate] = useState({ field: 'fromdate', value: getFirstDateOfLastMonth(), label: '' });
  const [filterToDate, setFilterToDate] = useState({ field: 'todate', value: getLastDateOfLastMonth(), label: '' });
  // const [filterFromDate, setFilterFromDate] = useState(addDaysToDate(new Date(), -30));
  // const [filterToDate, setFilterToDate] = useState(new Date());
  const [filterClient, setFilterClient] = useState([{ name: 'productid', value: 'all', label: 'All' }]);
  const rptData = useSelector(state => state.rptDeptSummary);
  const state = useSelector(state => state);
  const orders = useSelector(state => state.orderDetail?.orders || []);
  const order = useSelector(state => state.orderDetail || []);
  const ui = useSelector(state => state.ui);
  const allData = useSelector(state => state);
  const permissions = ui.layout.permissions;
  const pageSize = ui.layout.pageSize;
  const currentPageNumber = order?.filterOptions?.pageNumber;
  const isLoading = rptData.isLoading || false;
  const data = rptData.rptList;
  const filterData = rptData.filterData;
  const [isBilled, setisBilled] = useState(false);
  const [isOrdered, setisOrdered] = useState(false);
  const reportHeading = 'Product Summary CSV';
  const [toggle, settoggle] = useState(false);
  const [billed, setbilled] = useState(false);
  const setLocalCleanUpData = cleanUpData => {
    dispatch(setOnCleanUp(cleanUpData));
  };

  //clean up
  useEffect(() => {
    // console.log("CSV",csvData)
    return () => {
      setLocalCleanUpData({
        data: { ...initialRptDeptSummaryState }
      });

    };
    
  }, []);
  useEffect(() => {
    dispatch(getAllOrdersDetails(currentPageNumber, pageSize, order?.filterOptions?.filterBy, order?.filterOptions?.sortBy));
  }, [dispatch, currentPageNumber, pageSize]);
  useEffect(() => {
    dispatch(getAllRptProdSummaryData(rptData.filterOptions.filterByProd, rptData.filterOptions.sortBy, true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCustomersName());
  }, [dispatch]);

  const [departmentData, setDepartmentData] = useState();
  const [customerData, setCustomerData] = useState();
  const productsDispatch = useSelector(state => state.dispatch.currentDispatch.productSelectList);
  const [selectedUser, setSelectedUser] = useState();

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: `${reportHeading} Report`, path: '/operation-reports/product-summary-csv', isCurrent: true }
  ];

  const handleDateInput = (field, dateVal, callSetMethod) => {
    const saveObj = {};
    const setDate = dateVal ? new Date(dateVal) : null;
    saveObj.value = setDate;
    saveObj.field = field;
    saveObj.label = '';
    callSetMethod(saveObj);
  };

  const dropDownChange = data => {
    setCustomerData({
      valueName: data.label,
      value: data.value,
      name: 'clientprofileid',
      displayName: 'ClientProfile'
    });
    setSelectedUser(data.value);
    dispatch(getAllCustomerDeparmentsWithId(data.value));
    dispatch(getAllOrdersDetails(currentPageNumber, pageSize, order?.filterOptions?.filterBy, order?.filterOptions?.sortBy, data.value));
    // setLocalChange({
    //   ...orders,
    //   data: { ...orders.currentClientProfile, [field]: selectedOption.value }
    // });
  };
  const dropDownChangeDepartment = data => {
    setDepartmentData({
      valueName: data.label,
      value: data.value,
      name: 'departmentid',
      displayName: 'Department'
    });
    dispatch(getProductsByDept(data.value));
    // setProducts(productSelectList);
  };

  const dropdownChange = (field, selectedOption, callSetMethod) => {
    const selectedList = [];

    if (selectedOption[selectedOption.length - 1]?.value !== '') {
      selectedOption.forEach(element => {
        selectedList.push({
          value: element.value || 'all',
          displayName: 'Product',
          valueName: element.label,
          name: 'productid'
        });
      });
    } else {
      selectedList.length = 0;
      selectedList.push({
        value: 'all',
        displayName: 'Product',
        valueName: 'All',
        name: 'productid'
      });
    }

    // selectedList.field = field;
    callSetMethod(selectedList);
  };

  const showOrdered = () => {
    setisOrdered(!isOrdered);
    settoggle(!toggle);
  };

  const showBilled = () => {
    setisBilled(!isBilled);
    setbilled(!billed);
  };

  const setLocalChange = currentState => {
    dispatch(setOnSearchChange(currentState));
  };

  const onGenerateHandler = () => {
    let toFilter = rptData.filterOptions.filterByProd || [];
    let filterSearchList = toFilter.slice(-2);
    filterSearchList = getFilterData(filterSearchList, filterFromDate);
    filterSearchList = getFilterData(filterSearchList, filterToDate);

    const a = [...filterClient, customerData, departmentData, ...filterSearchList];
    setLocalChange({
      ...rptData,
      data: { ...rptData.filterOptions, filterByProd: a }
    });
    dispatch(getAllRptProdSummaryCSVData(a, rptData.filterOptions.sortBy, isBilled, isOrdered));
  };

  const getFilterData = (filterSearchList, filterObj) => {
    const objIndex = filterSearchList.findIndex(x => x?.name?.toLowerCase() === filterObj?.field);
    if (objIndex !== -1) {
      filterSearchList[objIndex].value = filterObj[0]?.value ?? filterObj?.value;
      filterSearchList[objIndex].valueName = filterObj[0]?.label ?? filterObj?.label;
    } else {
      filterSearchList.push({
        name: filterObj[0]?.field ?? filterObj?.field,
        value: filterObj[0]?.value ?? filterObj?.value,
        valueName: filterObj[0]?.label ?? filterObj?.label
      });
    }
    return filterSearchList;
  };

  const currentFromDate = rptData.filterOptions?.filterByProd?.find(x => x?.name === 'fromdate')?.value;
  const currentToDate = rptData.filterOptions?.filterByProd?.find(x => x?.name === 'todate')?.value;

  const filterText = `Invoice analysis by department for the period : ${moment(new Date(currentFromDate)).format('DD-MMM-YYYY') ||
    ''} - ${moment(new Date(currentToDate)).format('DD-MMM-YYYY') || ''}`;
  const downloadCsv = () => {
    const csvContent = Papa.unparse(csvData);
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    a.click();
    URL.revokeObjectURL(url);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={`${reportHeading} Report`} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col md="4">
                      <div className="mb-3">
                        <Dropdown
                          placeholder="Select Customer"
                          label="Customers"
                          name="customerName"
                          options={customer}
                          isMulti={false}
                          onChange={val => dropDownChange(val)}
                          validate={{
                            required: { value: true, errorMessage: 'Please select a Customer' }
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <Dropdown
                          placeholder="Select Department"
                          label="Departments"
                          name="departmentName"
                          options={order.departmentDetails}
                          isMulti={false}
                          onChange={val => dropDownChangeDepartment(val)}
                          validate={{
                            required: { value: true, errorMessage: 'Please select a Department' }
                          }}
                        />
                      </div>
                    </Col>
                    <Col sm="3">
                      <div className="mb-3">
                        <Dropdown
                          placeholder="All"
                          label="Products"
                          name="productid"
                          options={productsDispatch}
                          isMulti={true}
                          value={filterData?.products?.find(x => x.value === filterClient.value)}
                          onChange={val => dropdownChange('productid', val, setFilterClient)}
                          validate={{
                            required: { value: false }
                          }}
                        />
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="mb-4">
                        <DatePicker
                          label="From Date"
                          selected={filterFromDate.value}
                          onChange={date => handleDateInput('fromdate', date, setFilterFromDate)}
                          dateFormat="dd-MMM-yyyy"
                        />
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="mb-4">
                        <DatePicker
                          label="To Date"
                          selected={filterToDate.value}
                          onChange={date => handleDateInput('todate', date, setFilterToDate)}
                          dateFormat="dd-MMM-yyyy"
                          minDate={filterFromDate.value}
                        />
                      </div>
                    </Col>

                    <Col sm="5">
                      <div className="text-sm-end">
                      <Button type="button" disabled={!customerData || !departmentData} className="btn btn-primary btn-block  app-add-button" onClick={onGenerateHandler}>
                          GENERATE
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="2">
                      <div className="mb-4">
                        <b>Ordered Quantity</b>
                        <input style={{ marginLeft: '5px' }} type="checkbox" value={toggle} onChange={showOrdered} />
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="mb-4">
                        <b>Billed Amount</b>
                        <input style={{ marginLeft: '5px' }} type="checkbox" value={billed} onChange={showBilled} />
                      </div>
                    </Col>
                  </Row>
                  <Row>

                    <Col xl="12">
                      {isLoading ? (
                        <LoadingSpinner />
                      ) : csvData?.length === 0 || !csvData ? (
                        <div className="no-report-data">
                          {' '}
                          <div className="no-report-data-text">No Data Available </div>{' '}
                        </div>
                      ) : (
                        <div>
                          <h4>CSV Preview</h4>
                          <Table>
                            <thead>
                              <tr>
                                {/* {csvData.length > 0 &&
                                  Object.keys(csvData[0]).map((header, index) => {
                                    if (index !== 1 && (index !== 6 || toggle) && (index !== 4 || billed) && index !== 5) {
                                      return <th key={index}>{header}</th>;
                                    }
                                    return null;
                                  })} */}
                                    <th>Client Name</th>
                                    <th>Department Name</th>
                                    <th>Product Name</th>
                                    {csvData[0]?.Ordered_Quantity !== null && <th>Ordered Quantity</th>}
                                    {csvData[0]?.Billed_Amount !== null &&  <th>Billed Amount</th>}
                                    <th>Delivered Quantity</th>
                              </tr>

                              {/* <tr>
                              {csvData.length > 0 &&
                               Object.keys(csvData[0]).map((header, index) => (
                                  <th key={index}>{header}</th>
                                ))}
                            </tr> */}
                            </thead>
                            <tbody>
                              {csvData.map(item => (
                                <>
                                  <tr>
                                    <td>{item.Client_Name}</td>
                                    <td>{item.Department_Name}</td>
                                    <td>{item.Product_Name}</td>
                                    {item.Ordered_Quantity !== null && <td>{item.Ordered_Quantity}</td>}
                                    {item.Billed_Amount !== null && <td>{item.Billed_Amount}</td>}
                                    <td>{item.Delivered_Quantity}</td>
                                  </tr>
                                </>
                              ))}
                              {/* {csvData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                  {Object.values(row).map((cell, cellIndex) => (
                                    <td key={cellIndex}>{cell}</td>
                                  ))}
                                </tr>
                              ))} */}
                            </tbody>
                          </Table>
                          <Button onClick={downloadCsv} type="button" className="btn btn-primary btn-block  app-add-button">
                            Download CSV
                          </Button>
                        </div>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProductSummaryCSVRpt;
