import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Table } from 'reactstrap';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, Container, Row, Label } from 'reactstrap';
import { describeBulkDispatch, saveBulkDispatch, getRequisitionByClient, setOnBulkChange, setOnBulkCleanUp } from '../../../store/actions/Operation/dispatch';
import { AvForm, AvInput, AvGroup } from 'availity-reactstrap-validation';
import { initialDispatchState } from '../../../store/reducers/initialState';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _dispatchbulk, _allowbackdate } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import Dropdown from '../../CustomControls/Dropdown';
import CustomAvField from '../../CustomControls/CustomAvField';
import DatePicker from '../../CustomControls/CustomDatePicker';
import ErrorListing from '../../CustomControls/ErrorListing';
import moment from 'moment';

const BulkDispatch = props => {
    let history = useHistory();
    const backUrl = '/operation/dispatches';
    const dispatchAction = useDispatch();
    const dispatch = useSelector(state => state.dispatch);
    const ui = useSelector(state => state.ui);
    const permissions = ui.layout.permissions;
    let IsEdit = false;
    let { clientid } = useParams();
    const notificationType = dispatch.isError ? 'error' : 'success';
    const [triggerValidation, setTriggerValidation] = useState(false);



    let showSaveOption = hasPermissionToAction(permissions, _dispatchbulk, _create);
    let allowBackdate = hasPermissionToAction(permissions, _allowbackdate, _create);

    let currentBulkDispatch = dispatch.currentBulkDispatch;


    let localPath = '/operation/dispatches/bulk-dispatch';
    let heading = 'Bulk Dispatch';



    const breadCrumbPaths = [
        { id: 1, title: 'Home', path: '/' },
        { id: 2, title: 'Dispatch Listing', path: '/operation/dispatches' },
        { id: 3, title: heading, path: localPath, isCurrent: true }
    ];

    useEffect(() => {
        dispatchAction(describeBulkDispatch());
    }, [dispatchAction]);

    useEffect(() => {
        if (currentBulkDispatch.clientId) {
            dispatchAction(getRequisitionByClient(currentBulkDispatch.clientId));
        }
    }, [dispatchAction, currentBulkDispatch.clientId]);

    //clean up
    useEffect(() => {
        return () => {
            setLocalCleanUpData({
                data: { ...dispatch.currentBulkDispatch, ...initialDispatchState.currentBulkDispatch }
            });
        };
    }, []);

    const setLocalCleanUpData = cleanUpData => {
        dispatchAction(setOnBulkCleanUp(cleanUpData));
    };

    const setLocalChange = currentState => {
        dispatchAction(setOnBulkChange(currentState));
    };


    const handleCheckBoxInput = (e) => {
        let isChecked;
        if (e.target.value === "false") {
            isChecked = false;
        } else {
            isChecked = true;
        }
        let currentDisItems = dispatch.currentBulkDispatch.dispatchBulkDetails || [];
        
        if(e.target.name === 'overrideDispatchDate') {
            currentDisItems.forEach(element => {
                element.dispatchedDate = !isChecked ? currentBulkDispatch.dispatchedBulkDate  : element.deliveryDate
            });
        }

        setLocalChange({
            ...dispatch,
            data: { ...dispatch.currentBulkDispatch, [e.target.name]: !isChecked, dispatchBulkDetails: currentDisItems  }
        });
    }

    const handleDateInput = (field, dateVal) => {
        const setDate = dateVal ? new Date(dateVal) : null;
        var dispatchData =  { ...dispatch.currentBulkDispatch, [field]: setDate };
  
        if(field === 'dispatchedBulkDate') {
            let currentDisItems = dispatch.currentBulkDispatch.dispatchBulkDetails || [];
            currentDisItems.forEach(element => {
                element.dispatchedDate = currentBulkDispatch.overrideDispatchDate ? setDate  : element.deliveryDate
            });

            dispatchData = {...dispatchData, dispatchBulkDetails: currentDisItems}
        }

        setLocalChange({
            ...dispatch,
            data: dispatchData
        });
    };

    const dropDownChange = (field, selectedOption) => {
        let changeObj = { [field]: selectedOption.value };
        var dispatchData = { ...dispatch.currentBulkDispatch, ...changeObj };
        
        if (field === 'clientId') {
            dispatchData = { ...dispatchData, overrideDispatchDate: false };
        }

        setLocalChange({
            ...dispatch,
            data: dispatchData
        });

        if (field === 'clientId') {
            dispatchAction(getRequisitionByClient(selectedOption.value));
        }

        
     
    };

    const handleSubmit = e => {
        if (!isValid()) {
            setTriggerValidation(true);
            return;
        }

        if (dispatch.currentBulkDispatch) {
            dispatchAction(saveBulkDispatch(dispatch.currentBulkDispatch));
            setTriggerValidation(false);
        }
    };
    const isValid = () => {
        let status = true;
        if (!dispatch.currentBulkDispatch.clientId) {
            status = false;
        }

        if (!dispatch.currentBulkDispatch.locationId) {
            status = false;
        }
        let currentDisItems = dispatch.currentBulkDispatch.dispatchBulkDetails || [];
        if(currentDisItems.length == 0)
        {
            status = false;
        }

        return status;
    };
    const handleInvalidSubmit = e => {
        setTriggerValidation(true);
    };

    const cancelHandler = () => {
        history.push(backUrl);
    };

    const onUpdateItemHandler = addObj => {
        let currentDisItems = dispatch.currentBulkDispatch.dispatchBulkDetails || [];

        const objIndex = currentDisItems.findIndex(x => x.requisitionId === addObj.requisitionId);

        if (objIndex !== -1) {
            currentDisItems[objIndex].remarks = addObj.remarks;
        }

        setLocalChange({
            ...dispatch,
            data: { ...dispatch.currentBulkDispatch, dispatchBulkDetails: currentDisItems }
        });
    };

    const contentHeading = IsEdit ? `${heading} - ${currentBulkDispatch.dispatchRef}` : heading;
    const colSpanSplit = !currentBulkDispatch.refAutoGenerated ? 4 : 8;

    return (
        <React.Fragment>
            {dispatch.redirect ? <Redirect to={backUrl} /> : ''}
            {dispatch.isLoading && <LoadingSpinner />}
            {dispatch.showMessage && <Notification notificationType={notificationType} message={dispatch.message} />}
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={heading} paths={breadCrumbPaths} />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardTitle>{contentHeading} </CardTitle>
                                <CardBody>
                                    <AvForm className="form-horizontal" onValidSubmit={handleSubmit} onInvalidSubmit={handleInvalidSubmit}>
                                        {dispatch.errorList && dispatch.errorList.length > 0 && <ErrorListing errorList={dispatch.errorList} />}
                                        <Row>
                                            <Col lg={12}>
                                                <CardSubtitle>Client Information</CardSubtitle>
                                                <Card>
                                                    <CardBody>
                                                        <Row>
                                                            <Col sm="3">
                                                                <div className="mb-3">
                                                                    <Dropdown
                                                                        placeholder="Select Client"
                                                                        label="Client"
                                                                        name="clientId"
                                                                        options={currentBulkDispatch.clientSelectList}
                                                                        isMulti={false}
                                                                        value={currentBulkDispatch.clientSelectList.find(x => x.value === currentBulkDispatch.clientId)}
                                                                        onChange={val => dropDownChange('clientId', val)}
                                                                        isDisabled={false}
                                                                        triggerValidation={triggerValidation}
                                                                        validate={{
                                                                            required: { value: true, errorMessage: 'Please select a client' }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col sm="3">
                                                                <div className="mb-3">
                                                                    <Dropdown
                                                                        placeholder="Select Dispatch Location"
                                                                        label="Dispatch Location"
                                                                        name="locationId"
                                                                        options={currentBulkDispatch.locationSelectList}
                                                                        isMulti={false}
                                                                        value={currentBulkDispatch.locationSelectList.find(x => x.value === currentBulkDispatch.locationId)}
                                                                        onChange={val => dropDownChange('locationId', val)}
                                                                        triggerValidation={triggerValidation}
                                                                        validate={{
                                                                            required: { value: true, errorMessage: 'Please select a dispatch location' }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md="3">
                                                                {allowBackdate &&
                                                                    <AvGroup check className="padding-35">
                                                                        <AvInput type="checkbox" name="overrideDispatchDate" label="Override Dispatch Date" onChange={handleCheckBoxInput} value={currentBulkDispatch.overrideDispatchDate} checked={currentBulkDispatch.overrideDispatchDate}/>
                                                                        <Label check for="checkbox"> Override Dispatch Date</Label>
                                                                    </AvGroup>
                                                                }
                                                            </Col>
                                                            <Col md="2">
                                                                {currentBulkDispatch.overrideDispatchDate &&
                                                                    <div className="mb-2">
                                                                        <DatePicker
                                                                            label="Dispatch Date"
                                                                            triggerValidation={triggerValidation}
                                                                            selected={currentBulkDispatch.dispatchedBulkDate ? new Date(currentBulkDispatch.dispatchedBulkDate) : null}
                                                                            onChange={date => handleDateInput('dispatchedBulkDate', date)}
                                                                            dateFormat="dd-MMM-yyyy"
                                                                            minDate={allowBackdate ? new Date('January 1, 1990 00:00:00') : new Date()}
                                                                            validate={{
                                                                                required: { value: true, errorMessage: 'Please select a valid dispatch date' }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <CardSubtitle>Requisition Details</CardSubtitle>
                                                <Card>
                                                    <CardBody>
                                                        <RequisitionDetailTable data={dispatch.currentBulkDispatch.dispatchBulkDetails} editItemHandler={onUpdateItemHandler} />
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <div className="d-flex flex-wrap gap-2">
                                            {showSaveOption && (
                                                <Button type="submit" color="primary" className="btn " disabled={dispatch.isLoading}>
                                                    SAVE
                                                </Button>
                                            )}
                                            <Button onClick={cancelHandler} color="secondary" className=" ">
                                                CANCEL
                                            </Button>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default BulkDispatch;

const RequisitionDetailTable = ({ data, editItemHandler }) => {
    return (
        <div>
            <Table className="tablelight bulkdispatch" responsive>
                <thead>
                    <tr>
                        <th className="col-sm-1">Select</th>
                        <th className="col-sm-2">Requisition Ref</th>
                        <th className="col-sm-2">Dispatch Date</th>
                        <th className="col-sm-7 alignLeft">Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    <React.Fragment>
                        {data.map(n => {
                            return <UpdateTBRow key={n.requisitionId} item={n} editItemHandler={editItemHandler} />;
                        })}
                    </React.Fragment>
                </tbody>
            </Table>
        </div>
    );
};

const UpdateTBRow = ({ item, editItemHandler }) => {
    const [remarks, setRemarks] = useState(item.remarks);
    const [isSelected, setIsSelected] = useState(item.isSelected);
    const onChangeHandler = e => {
        setRemarks(e.target.value);
        item.remarks = e.target.value;
        editItemHandler(item);
    };

    const handleItemCheckBoxInput = (e) => {
        let isChecked;
        if (e.target.value === "false") {
            isChecked = false;
        } else {
            isChecked = true;
        }
        setIsSelected(isChecked);
        item.isSelected = isChecked;
        editItemHandler(item);
    }

    return (
        <tr key={item.requisitionId}>
            <td>
                <AvInput type="checkbox" name={`isselected_${item.requisitionId}`} onChange={handleItemCheckBoxInput} value={isSelected} />
            </td>
            <td>{item.requisitionRef || ''}</td>
            <td>{moment(new Date(item.dispatchedDate)).format("DD-MMM-YYYY") || ''}</td>
            <td>
                <CustomAvField
                    name={`remark_${item.requisitionId}`}
                    label=""
                    className="form-control"
                    placeholder="Remarks"
                    value={remarks}
                    onChange={onChangeHandler}
                    type="text"
                    maxLength="200"
                />
            </td>
        </tr>
    );
};
