import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
// import Table from './RequisitionTable';
import Table from './InternelTable';

import Pagination from '../../CustomControls/Pagination';
import {
  getAllRequisitions,
  setOnSearchChange,
  setOnConfirmationModal,
  deleteRequisition
} from '../../../store/actions/Operation/requisition';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _reportprint, _requisition, _delete } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import { addDaysToDate } from '../../../utils';
import SearchBar from '../../CustomControls/SearchBar';
import DatePicker from '../../CustomControls/CustomDatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import CustomConfirmation from '../../CustomControls/ConfirmationModal';
import { getAllInternalOrder } from '../../../store/actions/Operation/requisition';
import { getOneInternalOrder } from '../../../store/actions/Operation/requisition';
// import './style.scss';
import '../../Customer/OrderDetail/style.scss';
import { deleteAllInternalOrder } from '../../../store/actions/Operation/requisition';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
const InternalOrder = props => {
  const [searchText, setSearchText] = useState('');
  const [searchFromDate, setSearchFromDate] = useState(addDaysToDate(new Date(), -30));
  const [searchToDate, setSearchToDate] = useState(new Date());
  const [check, setCheck] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const requisition = useSelector(state => state.requisition);
  const order = useSelector(state => state.order);
  const internalOrder = useSelector(state => state.order.internalOrder);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  const pageSize = ui.layout.pageSize;
  const currentPageNumber = requisition.filterOptions.pageNumber;

  const notificationType = requisition.isError ? 'error' : 'success';

  const showSaveOption = hasPermissionToAction(permissions, _requisition, _create);
  const showPrintOption = hasPermissionToAction(permissions, _requisition, _reportprint);
  const showDeleteOption = hasPermissionToAction(permissions, _requisition, _delete);
  useEffect(() => {
    const view = window.location.href.split('/');
    setCheck(view[view.length - 1]);
  }, []);
  // useEffect(() => {
  //   dispatch(getAllRequisitions(currentPageNumber, pageSize, requisition.filterOptions.filterBy, requisition.filterOptions.sortBy));
  // }, [dispatch, currentPageNumber, pageSize]);
  useEffect(() => {
    dispatch(getAllInternalOrder(currentPageNumber, pageSize, requisition.filterOptions.filterBy, requisition.filterOptions.sortBy));
  }, [dispatch, currentPageNumber, pageSize]);
  const onPageChangeHandler = currentPage => {
    setLocalChange({
      ...order,
      data: { ...order.internalOrder, pageNumber: currentPage }
    });
    dispatch(getAllInternalOrder(currentPageNumber, pageSize, requisition.filterOptions.filterBy, requisition.filterOptions.sortBy));
  };

  const onAddNewHandler = () => {
    history.push('/operation/requisitions/requisition');
  };

  const onEditHandler = id => {
    dispatch(getOneInternalOrder(id));
    history.push(`/customer/internal-order-view`);
  };

  const onRepeatHandler = id => {
    history.push(`/operation/requisitions/requisition/1/${id}`);
  };

  const onDispatchHandler = id => {
    dispatch(getOneInternalOrder(id));
    history.push(`/customer/internal-order/dispatch-internal-order/`);
  };
  const onPrintHandler = id => {
    history.push(`/operation/requisitions/requisition-report/${id}`);
  };

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Internal Order', path: '/customer/internal-order', isCurrent: true }
    // { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  useEffect(() => {
    return () => {
      let filterListCurrent = requisition.filterOptions.filterBy || [];
      if (filterListCurrent.length > 0) {
        filterListCurrent = filterListCurrent.filter(x => x.name.toLowerCase() !== 'requisitionref');
      }
      setLocalChange({
        ...requisition,
        data: { ...requisition.filterOptions, filterBy: filterListCurrent }
      });
    };
  }, []);

  const handleInput = e => {
    const searchVal = e.target.value ?? '';

    setSearchText(searchVal);
    let filterSearchList = requisition.filterOptions.filterBy || [];

    const objIndex = filterSearchList.findIndex(x => x.name.toLowerCase() === 'requisitionref');

    if (objIndex !== -1) {
      filterSearchList[objIndex].value = searchVal.toLowerCase();
    } else {
      filterSearchList.push({ name: 'requisitionref', value: searchVal.toLowerCase() });
    }
  };
  const handleDateInput = (field, dateVal) => {
    const setDate = dateVal ? new Date(dateVal) : null;
    if (field === 'fromdate') {
      setSearchFromDate(setDate);
    }

    if (field === 'todate') {
      setSearchToDate(setDate);
    }

    let filterSearchList = requisition.filterOptions.filterBy || [];

    const objIndex = filterSearchList.findIndex(x => x.name.toLowerCase() === field);

    if (objIndex !== -1) {
      filterSearchList[objIndex].value = setDate.toDateString();
    } else {
      filterSearchList.push({ name: field, value: setDate.toDateString() });
    }

    setLocalChange({
      ...requisition,
      data: { ...requisition.filterOptions, filterBy: filterSearchList }
    });
  };

  const setLocalChange = currentState => {
    dispatch(setOnSearchChange(currentState));
  };

  const onSearchHandler = e => {
    dispatch(
      getAllInternalOrder(currentPageNumber, pageSize, requisition.filterOptions.filterBy, requisition.filterOptions.sortBy, searchText)
    );

    // dispatch(getAllRequisitions(currentPageNumber, pageSize, requisition.filterOptions.filterBy, requisition.filterOptions.sortBy));
  };

  const onDeleteHandler = id => {
    dispatch(
      setOnConfirmationModal({
        data: {
          showConfirmationModal: true,
          confirmationId: id
        }
      })
    );
  };

  const onDeleteConfirmation = () => {
    if (requisition.confirmationId) {
      dispatch(deleteAllInternalOrder(requisition.confirmationId));
      setTimeout(() => {
      dispatch(getAllInternalOrder(currentPageNumber, pageSize, requisition.filterOptions.filterBy, requisition.filterOptions.sortBy));
      }, 500);
      dispatch(
        setOnConfirmationModal({
          data: {
            showConfirmationModal: false
          }
        })
      );
    }
  };

  const onDeleteCancel = () => {
    dispatch(
      setOnConfirmationModal({
        data: {
          showConfirmationModal: false,
          confirmationId: ''
        }
      })
    );
  };

  return (
    <React.Fragment>
      <CustomConfirmation
        show={requisition.showConfirmationModal}
        onDeleteClick={onDeleteConfirmation}
        onCloseClick={onDeleteCancel}
        message="Are you sure you want to delete this record?"
        header="Deleting record"
      />
      <div className="page-content">
        {requisition.showMessage && <Notification notificationType={notificationType} message={requisition.message} />}
        <Container fluid>
          <Breadcrumbs title="Internal Orders " paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="3">
                      <SearchBar onChange={handleInput} placeholder="Search By Order Ref" value={searchText} />
                    </Col>
                    <Col sm="2">
                      <div className="mb-4">
                        <DatePicker
                          label="From Date"
                          selected={searchFromDate}
                          onChange={date => handleDateInput('fromdate', date)}
                          dateFormat="dd-MMM-yyyy"
                        />
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="mb-4">
                        <DatePicker
                          label="To Date"
                          selected={searchToDate}
                          onChange={date => handleDateInput('todate', date)}
                          dateFormat="dd-MMM-yyyy"
                          minDate={searchFromDate}
                        />
                      </div>
                    </Col>
                    <Col sm="2" className="m-auto">
                      <div className="text-sm-start">
                        <Button type="button" className="btn btn-outline-primary" onClick={onSearchHandler}>
                          <FontAwesomeIcon className="standardIcon" icon={faSearch} /> Search
                        </Button>
                      </div>
                    </Col>
                    <Col sm="3">
                      {/* <div className="text-sm-end">
                        {showSaveOption && (
                          <Button type="button" className="btn btn-primary btn-block  app-add-button" onClick={onAddNewHandler}>
                            NEW REQUISITION
                          </Button>
                        )}
                      </div> */}
                    </Col>
                  </Row>
                  <Row className="tablerow">
                    <Col xl="12">
                      <div>
                        {requisition.isLoading && <LoadingSpinner />}
                        <Pagination
                          data={internalOrder?.data ?? []}
                          pageSize={pageSize}
                          onPageChange={onPageChangeHandler}
                          startingPage={currentPageNumber}
                          apiPaging={true}
                          apiDataLength={internalOrder?.totalRecords ?? 0}
                        >
                          <Table
                            editHandler={onEditHandler}
                            // repeatHandler={onRepeatHandler}
                            dispatchHandler={onDispatchHandler}
                            printHandler={onPrintHandler}
                            check={check}
                            // showPrint={showPrintOption}
                            deleteHandler={onDeleteHandler}
                            showDelete={showDeleteOption}
                          />
                        </Pagination>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InternalOrder;
