import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Label, Table } from 'reactstrap';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
// import { getReportRequisition } from '../../../store/actions/order';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { isEmpty, map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { setAllOrderDetails } from '../../../store/actions/order';
import moment from 'moment';
import ReportHeader from '../../Reporting/OperationReport/ReportHeader';

const ProductWiseCustomerPrint = props => {
  let history = useHistory();
  const dispatch = useDispatch();
  let { requisitionid } = useParams();
  //   const backUrl = '/order/orderlist';
  const requisition = useSelector(state => state.requisition);
  const ordersDetails = useSelector(state => state.orderDetail?.ordersDetails);
  const tableData = useSelector(state => state.orderDetail?.tableData || []);
  const productsData = useSelector(state => state.orderDetail?.productsData);
  let localPath = `/order/orderlist/order-report/${requisitionid}`;
  const allData = useSelector(state => state);
  let heading = 'Product Wise Report';
  let reportData = requisition.requisitionReport;

  const imageResult = window.localStorage.getItem('lmsCompanyLogo');

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Product Wise Listing', path: '/customer/product-wise-orders/customer-wise-orders' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  useEffect(() => {
    if (requisitionid) {
      dispatch(setAllOrderDetails(requisitionid));
    }
  }, [dispatch, requisitionid]);

  const printInvoice = () => {
    window.print();
  };

  const cancelHandler = () => {
    history.push('/customer/product-wise-orders');
  };
  let totalQty = 0;
  if (tableData?.reportData?.length > 0) {
    totalQty = tableData?.reportData?.reduce(function(prev, cur) {
      return prev + parseInt(cur.totalAmount);
    }, 0);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          {!isEmpty(reportData) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <ReportHeader reportTitle={"Product Summary"} /> */}
                    <Row>
                      <Col lg={12} className="text-end my-2">
                        <h3>Product Summary</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        {/* {
                          <Row>
                            <Col sm="2">
                              <Label className="label">Client :</Label>
                            </Col>
                            <Col sm="4">
                              <Label>{productsData?.clientName}</Label>
                            </Col>
                          </Row>
                        }
                        <Row>
                          <Col sm="2">
                            <Label className="label">Email :</Label>
                          </Col>
                          <Col sm="4">
                            <Label>{productsData?.customerEmail}</Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="2">
                            <Label className="label">Phone # :</Label>
                          </Col>
                          <Col sm="4">
                            <Label>{productsData?.customerPhone}</Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="2">
                            <Label className="label">Address :</Label>
                          </Col>
                          <Col sm="4">
                            <Label>{productsData?.customerAddress}</Label>
                          </Col>
                        </Row> */}
                        <Row>
                          <Col sm="12">
                            <Label className="label">
                              Product Report for the period : {moment(new Date(tableData?.fromDate)).format('DD-MMM-YYYY') || ''} -{' '}
                              {moment(new Date(tableData?.toDate)).format('DD-MMM-YYYY') || ''}
                            </Label>
                          </Col>
                          <Col sm="4">{/* <Label>{currentRequisition.isDispatched ? 'Dispatched' : 'New'}</Label> */}</Col>
                        </Row>
                      </Col>
                      <Col lg="6">
                        <Row className="flex justify-content-end">
                          <Col sm="2" className="text-end">
                            <Label className="label">Date :</Label>
                          </Col>
                          <Col sm="4" className="text-end">
                            <Label>{moment(new Date()).format('DD-MMM-YYYY') || ''}</Label>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className="report-container">
                      <div id="content">
                        <div className="table-responsive">
                          <Table className="table-nowrap">
                            <thead>
                              <tr className="reportLine">
                                <th>Client Name</th>
                                <th>Product Name</th>
                                <th>Ordered Quantity</th>
                                <th>Delivered Quantity</th>
                                <th>Amount</th>
                                {/* <th>View</th> */}
                              </tr>
                            </thead>
                            {/* <td>{n.clientName || ''}</td>
                    <td>{n.productName || ''}</td>
                    <td>{n.orderedQuantity}</td>
                    <td>{n.deliveredQuantity}</td>
                    <td>{n.totalAmount}</td> */}
                            <tbody>
                              {tableData?.reportData?.map((item, key) => (
                                <tr key={key}>
                                  <td>{item.clientName}</td>
                                  <td>{item.productName}</td>
                                  <td>{item?.orderedQuantity}</td>
                                  <td>{item?.deliveredQuantity}</td>
                                  {/* <td>{item?.price}</td> */}
                                  <td>{item?.totalAmount}</td>
                                </tr>
                              ))}
                              <tr style={{ width: '100%' }}>
                                <td className="text-start">
                                  <b>{`Line ${productsData?.response?.length || 0} `}</b>
                                </td>
                                <td className="text-start"></td>
                                <td className="text-start"></td>
                                <td className="text-start"></td>
                                <td>
                                  <b>{`Total    :  ${totalQty}`}</b>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div className="d-print-none">
                        <div className="float-start">
                          {/* <Link to="#" onClick={cancelHandler} className="btn btn-primary w-md button-space">
                            Submit
                          </Link> */}
                          <Link to="#" onClick={printInvoice} className="btn btn-outline-primary">
                            <FontAwesomeIcon className="standardIcon" icon={faPrint} /> Print
                          </Link>
                          <Link to="#" onClick={cancelHandler} className="btn btn-secondary w-md button-space">
                            Back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProductWiseCustomerPrint;
