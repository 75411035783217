import Cookies from 'js-cookie';
import { apiHelper } from '../../utils';
import { setErrorThunk, setGetResults, setLoadingStatus, setOnChangeData, setOnConfirmationData, setOnSearchChangeData, setSaveData } from './actionHelper';
import {
  CUSTOMER_DEPARMENTS_SET_DATA,
  CUSTOMER_IS_LOADING,
  CUSTOMER_ORDER_PLACED,
  CUSTOMER_PRODUCTS_ERROR,
  CUSTOMER_PRODUCTS_SET_DATA,
  CUSTOMER_PRODUCTS_SET_DATA_DETAILS,
  DISTRIBUTION_PRODUCTS,
  ORDER_ON_CHANGE_DATA,
  ORDER_ON_SEARCH_CHANGE_DATA,
  ORDER_UPDATE_CONFIRMATION
} from '../../constants';

// const API_URL = '/product/all';

export const getAllCustomerProducts = (currentPageNumber, pageSize, filterBy, sortBy, query, departmentId) => dispatch => {
  dispatch(_getAllCustomerProducts(currentPageNumber, pageSize, filterBy, sortBy, query, departmentId));
};

const _getAllCustomerProducts = (currentPageNumber, pageSize, filterBy, sortBy, query, departmentId) => {

  return apiHelper({
    url: `/product/all?pageNumber=${currentPageNumber ?? 1}&pageSize=${pageSize ?? 20}&searchQuery=${query ??
      ''}&departmentId=${departmentId}`,
    method: 'GET',
    onLoad: status => setLoadingStatus(CUSTOMER_IS_LOADING, status),
    onSuccess: result => setGetResults(CUSTOMER_PRODUCTS_SET_DATA, result),
    onFailure: err => setErrorThunk(CUSTOMER_PRODUCTS_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};
export const getAllCustomerDeparments = () => dispatch => {
  dispatch(_getAllCustomerDeparments());
};

const _getAllCustomerDeparments = () => {
  return apiHelper({
    url: `/customer/departments`,
    method: 'GET',
    onLoad: status => setLoadingStatus(CUSTOMER_IS_LOADING, status),
    onSuccess: result => setGetResults(CUSTOMER_DEPARMENTS_SET_DATA, result),
    onFailure: err => setErrorThunk(CUSTOMER_PRODUCTS_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};
export const getAllProductsPlaceOrder = () => dispatch => {
  dispatch(_getAllProductsPlaceOrder());
};

const _getAllProductsPlaceOrder = () => {
  return apiHelper({
    url: `/internalOrder/PopulateInternalOrder`,
    method: 'GET',
    onLoad: status => setLoadingStatus(CUSTOMER_IS_LOADING, status),
    onSuccess: result => setGetResults(DISTRIBUTION_PRODUCTS, result),
    onFailure: err => setErrorThunk(CUSTOMER_PRODUCTS_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};


export const getAllCustomerProductsDetails = (currentPageNumber, pageSize, filterBy, sortBy, departmentId) => dispatch => {
  dispatch(_getAllCustomerProductsDetails(currentPageNumber, pageSize, filterBy, sortBy, departmentId));
};

const _getAllCustomerProductsDetails = (currentPageNumber, pageSize, filterBy, sortBy, departmentId) =>
  apiHelper({
    url: `/product/all?pageNumber=${currentPageNumber ?? 1}&pageSize=${pageSize}&departmentId=${departmentId}`,
    method: 'GET',
    onLoad: status => setLoadingStatus(CUSTOMER_IS_LOADING, status),
    onSuccess: result => setGetResults(CUSTOMER_PRODUCTS_SET_DATA_DETAILS, result),
    onFailure: err => setErrorThunk(CUSTOMER_PRODUCTS_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
export const setAllProducts = data => dispatch => {
  dispatch(_setAllProducts(data));
};

const _setAllProducts = data =>
  apiHelper({
    url: `/customer/order`,
    method: 'POST',
    data: data,
    onLoad: status => setLoadingStatus(CUSTOMER_IS_LOADING, status),
    onSuccess: result => setSaveData(CUSTOMER_ORDER_PLACED, true, result),
    onFailure: err => setErrorThunk(CUSTOMER_PRODUCTS_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
export const setOrderDcManager = data => dispatch => {
  dispatch(_setOrderDcManager(data));
};

const _setOrderDcManager = data =>
  apiHelper({
    url: `/internalorder`,
    method: 'POST',
    data: data,
    onLoad: status => setLoadingStatus(CUSTOMER_IS_LOADING, status),
    onSuccess: result => setSaveData(CUSTOMER_ORDER_PLACED, true, result),
    onFailure: err => setErrorThunk(CUSTOMER_PRODUCTS_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
export const setOnSearchChange = retData => dispatch => {
  dispatch(setOnSearchChangeData(ORDER_ON_SEARCH_CHANGE_DATA, retData));
};

export const setOnConfirmationModal = retData => dispatch => {
  dispatch(setOnConfirmationData(ORDER_UPDATE_CONFIRMATION, retData));
};
