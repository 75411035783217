import { CLIENTPROFILE_GET_ALL_DATA, CLIENTPROFILE_GET_DATA, CLIENTPROFILE_ERROR, CLIENTPROFILE_IS_LOADING, CLIENTPROFILE_SAVE_DATA,CLIENTPROFILE_DELETE_DATA, CLIENTPROFILE_ON_CHANGE_DATA, CLIENTPROFILE_CLEAN_UP,CLIENTPROFILE_UPDATE_CONFIRMATION } from '../../../constants/index';
import { apiHelper } from '../../../utils/index';
import Cookies from 'js-cookie';
import { setErrorThunk, setLoadingStatus, setGetResults, setDescribeData, setSaveData,setDeleteData, setOnChangeData, setOnCleanUpData,setOnConfirmationData } from '../actionHelper';

const API_URL = '/clientprofile'

export const getAllClientProfiles = () => dispatch => {
    dispatch(_getAllClientProfiles());
};

const _getAllClientProfiles = () =>
    apiHelper({
        url: `${API_URL}`,
        method: 'GET',
        onLoad: status => setLoadingStatus(CLIENTPROFILE_IS_LOADING, status),
        onSuccess: result => setGetResults(CLIENTPROFILE_GET_ALL_DATA, result),
        onFailure: err => setErrorThunk(CLIENTPROFILE_ERROR, true, err),
        accessToken: Cookies.get('accessToken'),
    });

export const describeClientProfile = (id) => dispatch => {
    dispatch(_getClientProfile(id));
};

const _getClientProfile = (id) =>
    apiHelper({
        url: `${API_URL}/${id}`,
        method: 'GET',
        data: {
            id: id
        },
        onLoad: status => setLoadingStatus(CLIENTPROFILE_IS_LOADING, status),
        onSuccess: result => setDescribeData(CLIENTPROFILE_GET_DATA, result),
        onFailure: err => setErrorThunk(CLIENTPROFILE_ERROR, true, err),
        accessToken: Cookies.get('accessToken'),
    });

export const saveClientProfile = (saveData, isNew, id = null) => dispatch => {
    let MethodType = 'POST';
    if (!isNew) {
        MethodType = 'PUT'
    }

    let apiUrl = id ? `${API_URL}/${id}` : API_URL;

    dispatch(_saveClientProfile(saveData, MethodType, apiUrl));
}

const _saveClientProfile = (data, MethodType, apiUrl) =>
    apiHelper({
        url: apiUrl,
        method: MethodType,
        onSuccess: result => setSaveData(CLIENTPROFILE_SAVE_DATA, true, result),
        onFailure: err => setErrorThunk(CLIENTPROFILE_ERROR, true, err),
        onLoad: status => setLoadingStatus(CLIENTPROFILE_IS_LOADING, status),
        data,
        accessToken: Cookies.get('accessToken'),
    });


export const setOnChange = retData => dispatch => {
    dispatch(setOnChangeData(CLIENTPROFILE_ON_CHANGE_DATA, retData));
};

export const setOnCleanUp = retData => dispatch => {
    dispatch(setOnCleanUpData(CLIENTPROFILE_CLEAN_UP, retData));
};



export const deleteClientProfile = (id) => dispatch => {
    let MethodType = 'DELETE';
    let apiUrl = id ? `${API_URL}/${id}` : API_URL;

    dispatch(_deleteClientProfile(MethodType, apiUrl));
    
}

export const setOnConfirmationModal = retData => dispatch => {
    dispatch(setOnConfirmationData(CLIENTPROFILE_UPDATE_CONFIRMATION, retData));
};


export const onDeleteSuccess = (result) => dispatch => {
    dispatch(setDeleteData(CLIENTPROFILE_DELETE_DATA, true, result));
    dispatch(_getAllClientProfiles());
};



const _deleteClientProfile = (MethodType, apiUrl) =>
    apiHelper({
        url: apiUrl,
        method: MethodType,
        onSuccess: result => onDeleteSuccess(result),
        onFailure: err => setErrorThunk(CLIENTPROFILE_ERROR, true, err),
        onLoad: status => setLoadingStatus(CLIENTPROFILE_IS_LOADING, status),
        accessToken: Cookies.get('accessToken'),
    });
