import React from 'react';
import {
  Card,
  CardBody,
  CardSubtitle,
  Col,
  Row,
} from "reactstrap"
import "./style.scss"

const ErrorListing = ({ errorList, heading }) => {
  const errorHeading = heading ? heading : 'Error Summary'
  return (
    <Row>
      <Col lg={12}>
        <CardSubtitle className="errorHeading">{errorHeading}</CardSubtitle>
        <Card>
          <CardBody>
            <ul className="errorList">
              {errorList.map((value, index) => {
                return (
                  <li key={index} > {value} </li>
                );
              })}
            </ul>
          </CardBody>
        </Card>
      </Col>
    </Row >
  );
}

export default ErrorListing;



