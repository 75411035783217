import {
  ORDER_DETAIL_IS_LOADING,
  ORDER_DETAIL_ERROR,
  ORDER_DETAIL_SET_DATA,
  ORDER_DETAIL_DETAILS,
  ORDER_DETAIL_ON_SEARCH_CHANGE_DATA,
  ORDER_DETAIL_UPDATE_CONFIRMATION,
  ORDER_DETAIL_SET_DATA_CSV,
  ORDER_DETAIL_SET_DATA_PDF,
  ORDER_DETAIL_DEPARTMENT_SET_DATA,
  ORDER_DETAIL_ON_CHANGE_DATA,
  PRODUCT_ORDER_DETAIL_SET_DATA,
  PRODUCT_ORDER_DETAIL_ALL_SET_DATA
} from '../../constants';
import { generateCSVCustomer, generatePDFCustomer } from '../actions/actionHelper';
import { initialCustomerState } from './initialState';

const orderReducer = (state = initialCustomerState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ORDER_DETAIL_ERROR: {
      const { showMessage, errorMessage, errorList } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage,
        errorList: errorList
      };
    }
    case ORDER_DETAIL_SET_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        orders: resultData,
        totalRecords: resultData.totalRecords,
        filterOptions: {
          ...state.filterOptions,
          pageNumber: resultData.pageNumber,
          pageSize: resultData.pageSize
        }
      };
    }
    case PRODUCT_ORDER_DETAIL_SET_DATA: {
      
      const { resultData } = payload;
      if (resultData?.filterData !== null) {

        return {
          ...state,
          allData: resultData,
          tableData: resultData
        };
        
      }
      else {

        return {
          ...state,
          tableData: resultData
        };
      }
     
    }
    case PRODUCT_ORDER_DETAIL_ALL_SET_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        productsData: resultData
      };


    }
    case ORDER_DETAIL_DEPARTMENT_SET_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        departmentDetails: resultData.map((item) => ({
          label: item.departmentName,
          value: item.departmentId,
        })),
      };
    }

    case ORDER_DETAIL_SET_DATA_CSV: {
      const { resultData } = payload;

      generateCSVCustomer(resultData);
      return {
        ...state,
        csvData: resultData
      };
    }
    case ORDER_DETAIL_SET_DATA_PDF: {
      const { resultData } = payload;

      generatePDFCustomer(resultData);

      return {
        ...state,
        csvData: resultData
      };
    }
    case ORDER_DETAIL_ON_SEARCH_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        filterOptions: {
          ...state.data,
          ...data
        }
      };
    }
    case ORDER_DETAIL_ON_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        departmentDetails: data
      };
    }
    case ORDER_DETAIL_UPDATE_CONFIRMATION: {
      let { data } = payload;

      return {
        ...state,
        ...data
      };
    }
    case ORDER_DETAIL_DETAILS: {
      let { resultData } = payload;

      return {
        ...state,
        ordersDetails: resultData
      };
    }
    case ORDER_DETAIL_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }

    default:
      return state;
  }
};

export default orderReducer;
