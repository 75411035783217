import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    filterContainer: {
        flexDirection: 'row',
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 0.5,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        fontSize: 10,
        paddingTop: 10,
        paddingBottom: 5,
    },
    footerInfo: {
        color: 'grey',
    },
    paging: {
        marginLeft: 'auto',
    },
    pageNumberContainer: {
        borderStyle: "solid",
        borderTopColor: '#bfbfbf',
        borderWidth: 0.5,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        position: 'absolute',
        fontSize: 10,
        bottom: 20,
        left: 20,
        right: 20,
        paddingVertical: 5,
        marginVertical: 5,
    },

    table: {
        display: "table",
        width: "auto",

        borderWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        flexDirection: "row"
    },
    tableColHeader: {
        width: "25%",

    },
    tableCol: {
        width: "25%",

    },
    tableHeaderCell: {
        margin: 5,
        fontSize: 10,
        fontWeight: 500
    },
    tableCell: {
        margin: 5,
        fontSize: 10
    }
});


const ReportFilterInfo = ({ filterList = [] }) => {

    return (
        filterList.length > 0 ? <View style={styles.filterContainer}>
            <View style={styles.table}>
                {filterList.map(item => {
                    return (
                        <View style={styles.tableRow} key={item.name} >
                            <View style={styles.tableCol}>
                                <Text style={styles.tableHeaderCell}> {item.displayName} :</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item.valueName}</Text>
                            </View>
                        </View>
                    );
                })}
            </View>

        </View> : <View></View>
    )
};

export default ReportFilterInfo
