import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Table from './GroupTable';
import Pagination from '../../CustomControls/Pagination';
import { getAllGroups } from '../../../store/actions/group';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _groups } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';

import './style.scss';

const Groups = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const group = useSelector(state => state.group);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  const pageSize = ui.layout.pageSize;
  const GroupData = group.groupList || [];

  const notificationType = group.isError ? 'error' : 'success';

  const showSaveOption = hasPermissionToAction(permissions, _groups, _create);

  useEffect(() => {
    dispatch(getAllGroups());
  }, [dispatch]);

  const onPageChangeHandler = currentPage => {};

  const onAddNewHandler = () => {
    history.push('/management/groups/group');
  };

  const onEditHandler = id => {
    history.push(`/management/groups/group/${id}`);
  };

  const onUserHandler = id => {
    history.push(`/management/groups/group-user/${id}`);
  };
  const onPermissionHandler = id => {
    history.push(`/management/groups/group-permission/${id}`);
  };

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Group Listing', path: '/management/groups', isCurrent: true }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        {group.showMessage && <Notification notificationType={notificationType} message={group.message} />}
        <Container fluid>
          <Breadcrumbs title="Group Listing" paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        {showSaveOption && (
                          <Button type="button" className="btn btn-primary btn-block " onClick={onAddNewHandler}>
                            ADD GROUP
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="tablerow">
                    <Col xl="12">
                      <div>
                        {group.isLoading && <LoadingSpinner />}
                        <Pagination data={GroupData} pageSize={pageSize} onPageChange={onPageChangeHandler} startingPage={1}>
                          <Table editHandler={onEditHandler} userHandler={onUserHandler} permissionHandler={onPermissionHandler} />
                        </Pagination>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Groups;
