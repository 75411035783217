import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, Container, Label, Row } from 'reactstrap';
import { describeGroup, saveGroup, setOnChange, setOnCleanUp } from '../../../store/actions/group';
import { AvForm, AvField, AvInput, AvGroup } from 'availity-reactstrap-validation';
import { initialGroupState } from '../../../store/reducers/initialState';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _groups } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import CustomAvField from '../../CustomControls/CustomAvField';

const ManageGroup = props => {
  let history = useHistory();
  const backUrl = '/management/groups';
  const dispatch = useDispatch();
  const group = useSelector(state => state.group);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  let IsEdit = false;
  let { groupid } = useParams();
  const notificationType = group.isError ? 'error' : 'success';

  const showSaveOption = hasPermissionToAction(permissions, _groups, _create);

  let currentGroup = group.currentGroup;
  let localPath = '/management/groups/group';
  let heading = 'Add Group';

  if (groupid) {
    IsEdit = true;
    heading = 'Edit Group';
    localPath = localPath + `/${groupid}`;
  }

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Group Listing', path: '/management/groups' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  useEffect(() => {
    if (groupid) {
      dispatch(describeGroup(groupid));
    }
  }, [dispatch, groupid]);

  //clean up
  useEffect(() => {
    return () => {
      setLocalCleanUpData({
        data: { ...group.currentGroup, ...initialGroupState.currentGroup }
      });
    };
  }, []);

  const onPageChangeHandler = currentPage => {};

  const setLocalCleanUpData = cleanUpData => {
    dispatch(setOnCleanUp(cleanUpData));
  };

  const setLocalChange = currentState => {
    dispatch(setOnChange(currentState));
  };

  const handleInput = e => {
    setLocalChange({
      ...group,
      data: { ...group.currentGroup, [e.target.name]: e.target.value }
    });
  };

  const handleCheckBoxInput = e => {
    let isChecked;
    if (e.target.value === 'false') {
      isChecked = false;
    } else {
      isChecked = true;
    }
    setLocalChange({
      ...group,
      data: { ...group.currentGroup, [e.target.name]: !isChecked }
    });
  };

  const handleSubmit = e => {
    if (group.currentGroup) {
      dispatch(saveGroup(group.currentGroup, !IsEdit, groupid));
    }
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  return (
    <React.Fragment>
      {group.redirect ? <Redirect to={backUrl} /> : ''}
      {group.isLoading && <LoadingSpinner />}
      {group.showMessage && <Notification notificationType={notificationType} message={group.message} />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardTitle>{heading}</CardTitle>
                <CardBody>
                  <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                    <Row>
                      <Col md="4">
                        <div className="mb-3">
                          <CustomAvField
                            name="groupName"
                            label="Group Name"
                            className="form-control"
                            placeholder="Group Name"
                            value={currentGroup.groupName}
                            onChange={handleInput}
                            type="text"
                            validate={{
                              required: { value: true, errorMessage: 'Please enter a valid group name' }
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <AvGroup check>
                            <AvInput
                              type="checkbox"
                              name="isActive"
                              label="Is Active"
                              onChange={handleCheckBoxInput}
                              value={currentGroup.isActive}
                            />
                            <Label check for="checkbox">
                              {' '}
                              Is Active
                            </Label>
                          </AvGroup>
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      {showSaveOption && (
                        <Button type="submit" color="primary" className="btn " disabled={group.isLoading}>
                          SAVE
                        </Button>
                      )}
                      <Button onClick={cancelHandler} color="secondary" className=" ">
                        CANCEL
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageGroup;
