import React from 'react';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fasort, faSortDown } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

const AuditLogTable = ({ paginatedData }) => {
  return (
    <React.Fragment>
      <div>
        <Table className="table" responsive>
          <thead>
            <tr>
              <th>Screen</th>
              <th>Operation Type</th>
              <th>Client Name</th>
              <th>Changed By</th>
              <th>Price Details</th>
              <th>Amount</th>
              <th>Type</th>
              <th>
                {' '}
                <span>
                  {' '}
                  Changed Date <FontAwesomeIcon icon={faSortDown} width={20} />
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedData?.map(n => {
              return (
                <tr key={n.id}>
                  <td>{n.screenName || ''}</td>
                  <td>{n.operationType || ''}</td>
                  <td>{n.clientName || ''}</td>
                  <td>{n.changedBy || ''}</td>
                  <td>{n.amount < 0 ? "Decrease" : "Increase"}</td>
                  <td>{n.amount < 0 ? n.amount * -1 : n.amount }</td>
                  <td>{`${n.type === 'Fixed' ? '$' : '%'}`}</td>
                  <td>{moment(new Date(n.changedDate)).format('DD-MMM-YYYY h:mm:ss a') || ''}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default AuditLogTable;
