import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
// import { getReportRequisition } from '../../../store/actions/order';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { isEmpty, map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { setAllOrderDetails } from '../../../store/actions/order';
import LoadingSpinner from '../../CustomControls/Spinner';

const OrderReport = props => {
  let history = useHistory();
  const dispatch = useDispatch();
  let { requisitionid } = useParams();
  const backUrl = '/order/orderlist';
  const requisition = useSelector(state => state.requisition);
  const ordersDetails = useSelector(state => state.order?.ordersDetails);
  const loading = useSelector(state => state.order);
  let localPath = `/order/orderlist/order-report/${requisitionid}`;
  const allData = useSelector(state => state);
  let heading = 'Order Report';
  let reportData = requisition.requisitionReport;

  const imageResult = window.localStorage.getItem('lmsCompanyLogo');

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Order Listing', path: '/order/orderlist' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  useEffect(() => {
    if (requisitionid) {
      dispatch(setAllOrderDetails(requisitionid));
    }
  }, [dispatch, requisitionid]);

  const printInvoice = () => {
    window.print();
  };

  const cancelHandler = () => {
    history.push('/order');
  };
  let totalQty = 0;
  if (reportData && ordersDetails?.length > 0) {
    totalQty = ordersDetails.reduce(function(prev, cur) {
      return prev + parseInt(cur.productQuantity);
    }, 0);
  }

  return (
    <React.Fragment>
      {loading.isLoading && <LoadingSpinner />}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          {!isEmpty(reportData) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="report-container">
                      <div id="content">
                        <div className="table-responsive">
                          <Table className="table-nowrap">
                            <thead>
                              <tr className="reportLine">
                                <th style={{ width: '55%' }}>Product Name</th>
                                <th style={{ width: '15%' }}>Product Price</th>
                                <th style={{ width: '15%' }}>Delivered Quantity</th>
                                <th style={{ width: '20%' }} className="text-end">
                                  Ordered Quantity
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {ordersDetails?.map((item, key) => (
                                <tr key={key}>
                                  <td>{item.productName}</td>
                                  <td>{item.productPrice}</td>
                                  <td>{item.deliveredQuantity}</td>
                                  <td className="text-end">{item.productQuantity}</td>
                                </tr>
                              ))}
                              <tr>
                                <td className="text-start">
                                  <b>{`Line ${ordersDetails?.length || 0} `}</b>
                                </td>
                                <td className="text-start">
                                </td>

                                <td colSpan="2" className="text-end">
                                  <b>{`Total    :  ${totalQty}`}</b>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div className="d-print-none">
                        <div className="float-start">
                          {/* <Link to="#" onClick={cancelHandler} className="btn btn-primary w-md button-space">
                            Submit
                          </Link> */}
                          <Link to="#" onClick={cancelHandler} className="btn btn-secondary w-md button-space">
                            Back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OrderReport;
