import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
import { describeUser, saveUser, setOnChange, setOnCleanUp } from '../../../store/actions/user';
import { AvForm, AvInput, AvGroup } from 'availity-reactstrap-validation';
import { initialUserState } from '../../../store/reducers/initialState';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import Dropdown from '../../CustomControls/Dropdown';
import { IS_NEW, STATES } from '../../../constants';
import classnames from 'classnames';
import { _create, _users } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import CustomAvField from '../../CustomControls/CustomAvField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSitemap } from '@fortawesome/free-solid-svg-icons';
import ManageClientUsers from './ManageClientUsers';
import ErrorListing from '../../CustomControls/ErrorListing';

const ManageUser = props => {
  let history = useHistory();
  const backUrl = '/management/users';
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  let IsEdit = false;
  let { userid } = useParams();
  const notificationType = user.isError ? 'error' : 'success';
  const [currentActiveTab, setCurrentActiveTab] = useState('general');
  const showSaveOption = hasPermissionToAction(permissions, _users, _create);
  const [selectedUser, setSelectedUser] = useState('');
  let currentUser = user.currentUser;
  let localPath = '/management/users/user';
  let heading = 'Add User';

  if (userid) {
    IsEdit = true;
    heading = 'Edit User';
    localPath = localPath + `/${userid}`;
  }

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'User Listing', path: '/management/users' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  useEffect(() => {
    if (userid) {
      dispatch(describeUser(userid));
    } else {
      dispatch(describeUser(IS_NEW));
    }
  }, [dispatch, userid]);

  //clean up
  useEffect(() => {
    return () => {
      setLocalCleanUpData({
        data: { ...user.currentUser, ...initialUserState.currentUser }
      });
    };
  }, []);

  const setLocalCleanUpData = cleanUpData => {
    dispatch(setOnCleanUp(cleanUpData));
  };

  const setLocalChange = currentState => {
    dispatch(setOnChange(currentState));
  };

  const handleInput = e => {
    setLocalChange({
      ...user,
      data: { ...user.currentUser, [e.target.name]: e.target.value }
    });
  };

  const handleCheckBoxInput = e => {
    let isChecked;
    if (e.target.value === 'false') {
      isChecked = false;
    } else {
      isChecked = true;
    }
    setLocalChange({
      ...user,
      data: { ...user.currentUser, [e.target.name]: !isChecked }
    });
  };

  const setErrorMessage = err => {
    let errorlist = user.currentUser.errorList || [];
    if (err) {
      errorlist.push(err);
    } else {
      errorlist = [];
    }
    setLocalChange({
      ...user,
      data: { ...user.currentUser, errorList: errorlist }
    });
  };

  const handleSubmit = e => {
    if (user.currentUser) {
      const selectedClients = (user.currentUser.clientUsers && user.currentUser.clientUsers.filter(x => x.isActive === true)) || [];
      const clientIds = [];
      if (
        selectedUser === 'Remote Linen Room' ||
        selectedUser === 'SLS - Dispatch' ||
        selectedUser === 'Accountant' ||
        selectedClients.length > 0
      ) {
        selectedClients.forEach(element => {
          clientIds.push(element.clientProfileId);
        });
        user.currentUser.clientIds = clientIds;
        dispatch(saveUser(user.currentUser, !IsEdit, userid));
      } else {
        setErrorMessage('You need to select atleast one client');
      }
    }
  };

  const dropDownChange = (field, selectedOption) => {
    setSelectedUser(selectedOption.label);
    setLocalChange({
      ...user,
      data: { ...user.currentUser, [field]: selectedOption.value }
    });
  };

  const onClientSelectionHandler = (clientId, value) => {
    let currentClients = currentUser.clientUsers || [];
    let errorList = user.currentUser.errorList || [];
    const objIndex = currentClients.findIndex(obj => obj.clientProfileId == clientId);
    currentClients[objIndex].isActive = value;

    if (currentClients.length > 0) {
      errorList = [];
    }

    setLocalChange({
      ...user,
      data: { ...user.currentUser, clientUsers: currentClients, errorList: errorList }
    });
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  return (
    <React.Fragment>
      {user.redirect ? <Redirect to={backUrl} /> : ''}
      {user.isLoading && <LoadingSpinner />}
      {user.showMessage && <Notification notificationType={notificationType} message={user.message} />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardTitle>
                  <Row className="buttonrow">
                    <Col md="8">ADD USER</Col>
                    <Col md="4">
                      <Nav tabs className="custom-tab nav-justified customTab">
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: currentActiveTab === 'general'
                            })}
                            onClick={() => {
                              setCurrentActiveTab('general');
                            }}
                          >
                            <span className="d-none d-sm-block">
                              <FontAwesomeIcon icon={faHome} /> <span className="pd-left">Basic</span>
                            </span>
                            <span className="d-block d-sm-none">
                              <FontAwesomeIcon icon={faHome} />
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: currentActiveTab === 'clients'
                            })}
                            onClick={() => {
                              setCurrentActiveTab('clients');
                            }}
                            disabled={
                              selectedUser === 'Remote Linen Room' || selectedUser === 'SLS - Dispatch' || selectedUser === 'Accountant'
                            }
                          >
                            <span className="d-none d-sm-block">
                              <FontAwesomeIcon icon={faSitemap} />
                              <span className="pd-left">Clients</span>
                            </span>
                            <span className="d-block d-sm-none">
                              <FontAwesomeIcon icon={faSitemap} />
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                  </Row>
                </CardTitle>
                <CardBody>
                  {currentUser.errorList && currentUser.errorList.length > 0 && <ErrorListing errorList={currentUser.errorList} />}

                  <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                    <TabContent activeTab={currentActiveTab} className="p-3 text-muted">
                      <TabPane tabId="general">
                        <Row>
                          <Col lg="12">
                            <Row>
                              <Col sm="6">
                                <div className="mb-3">
                                  <CustomAvField
                                    name="firstName"
                                    label="First Name"
                                    className="form-control"
                                    placeholder="First Name"
                                    value={currentUser.firstName}
                                    onChange={handleInput}
                                    type="text"
                                    validate={{
                                      required: { value: true, errorMessage: 'Please enter a valid first name' }
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="mb-3">
                                  <CustomAvField
                                    name="lastName"
                                    label="Last Name"
                                    className="form-control"
                                    placeholder="Last Name"
                                    value={currentUser.lastName}
                                    onChange={handleInput}
                                    type="text"
                                    validate={{
                                      required: { value: true, errorMessage: 'Please enter a valid last name' }
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="3">
                                <div className="mb-3">
                                  <CustomAvField
                                    name="email"
                                    label="Email"
                                    className="form-control"
                                    placeholder="Enter email"
                                    value={currentUser.username}
                                    onChange={handleInput}
                                    disabled={IsEdit}
                                    type="email"
                                    validate={{
                                      required: { value: true, errorMessage: 'Please enter an email' },
                                      pattern: {
                                        value: '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$',
                                        errorMessage: 'Please enter a valid email address'
                                      }
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md="3">
                                <div className="mb-3">
                                  <CustomAvField
                                    name="phoneNumber"
                                    label="Phone"
                                    className="form-control"
                                    placeholder="Phone"
                                    value={currentUser.phoneNumber}
                                    onChange={handleInput}
                                    type="text"
                                    maxLength="20"
                                    validate={{
                                      required: { value: false, errorMessage: '' }
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="mb-3">
                                  <Dropdown
                                    placeholder="Select Group"
                                    label="User Group"
                                    name="groupId"
                                    options={currentUser.groups}
                                    isMulti={false}
                                    value={currentUser.groups.find(x => x.value === currentUser.groupId)}
                                    onChange={val => dropDownChange('groupId', val)}
                                    validate={{
                                      required: { value: true, errorMessage: 'Please select a user group' }
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            {!IsEdit && (
                              <Row>
                                <Col md="3">
                                  <div className="mb-3">
                                    <CustomAvField
                                      name="password"
                                      label="Password"
                                      className="form-control"
                                      placeholder="Enter password"
                                      type="password"
                                      value={currentUser.password}
                                      onChange={handleInput}
                                      validate={{
                                        required: { value: true, errorMessage: 'Please enter a password' },
                                        pattern: {
                                          value: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
                                          errorMessage: 'Password is not complex enough.'
                                        }
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="mb-3">
                                    <CustomAvField
                                      name="confirmPassword"
                                      label="Confirm password"
                                      className="form-control"
                                      placeholder="Confirm password"
                                      type="password"
                                      value={currentUser.confirmPassword}
                                      onChange={handleInput}
                                      validate={{
                                        required: { value: true, errorMessage: 'Please enter a password' },
                                        match: { value: 'password', errorMessage: 'Passwords do not match' }
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            )}
                            <Row>
                              <Col md="6">
                                <div className="mb-3">
                                  <AvGroup check>
                                    <AvInput
                                      type="checkbox"
                                      name="isActive"
                                      label="Is Active"
                                      onChange={handleCheckBoxInput}
                                      value={currentUser.isActive}
                                    />
                                    <Label check for="checkbox">
                                      {' '}
                                      Is Active
                                    </Label>
                                  </AvGroup>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="clients">
                        <Row>
                          <Col sm="12">
                            <ManageClientUsers currentUser={currentUser} onClientSelectionHandler={onClientSelectionHandler} />
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                    <div className="d-flex flex-wrap gap-2">
                      {showSaveOption && (
                        <Button type="submit" color="primary" className="btn " disabled={user.isLoading}>
                          SAVE
                        </Button>
                      )}
                      <Button onClick={cancelHandler} color="secondary" className=" ">
                        CANCEL
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageUser;
