import { apiHelper } from '../../../utils/index';
import Cookies from 'js-cookie';
import { setErrorThunk, setLoadingStatus, setGetResults,  } from '../actionHelper';
import {

  CLIENT_ERROR,
  CLIENT_GET_ALL_DATA,
  CLIENT_IS_LOADING,
} from '../../../constants';

const API_URL = `/distributionCenter/GetClients?id=`;

export const getAllClients = (param) => dispatch => {
  dispatch(_getAllClients(param));
};

const _getAllClients = (param) =>
  apiHelper({
    url: `${API_URL}${param}`,
    method: 'GET',
    onLoad: status => setLoadingStatus(CLIENT_IS_LOADING, status),
    onSuccess: result => setGetResults(CLIENT_GET_ALL_DATA, result),
    onFailure: err => setErrorThunk(CLIENT_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
// export const createDistributionCenter = payload => dispatch => {
//   dispatch(_createDistributionCenter(payload));
// };

// const _createDistributionCenter = payload =>
//   apiHelper({
//     url: `${API_URL}`,
//     method: 'POST',
//     data: payload,
//     onLoad: status => setLoadingStatus(DISTRIBUTION_IS_LOADING, status),
//     onSuccess: result => setGetResults(result),
//     onFailure: err => setErrorThunk(DISTRIBUTION_ERROR, true, err),
//     accessToken: Cookies.get('accessToken')
//   });



// export const setOnChange = retData => dispatch => {
//   dispatch(setOnChangeData(DISTRIBUTION_ON_CHANGE_DATA, retData));
// };

// export const setOnCleanUp = retData => dispatch => {
//   dispatch(setOnCleanUpData(DISTRIBUTION_CLEAN_UP, retData));
// };
