import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, Container, Label, Row } from 'reactstrap';
import { describeLocation, saveLocation, setOnChange, setOnCleanUp } from '../../../store/actions/Master/location';
import { AvForm, AvInput, AvGroup } from 'availity-reactstrap-validation';
import { initialLocationState } from '../../../store/reducers/initialState';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _location } from '../../../constants/componentConstants';
import { IS_NEW } from '../../../constants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import CustomAvField from '../../CustomControls/CustomAvField';
import ErrorListing from '../../CustomControls/ErrorListing';

const ManageLocation = props => {
  let history = useHistory();
  const backUrl = '/master/locations';
  const dispatch = useDispatch();
  const location = useSelector(state => state.location);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  let IsEdit = false;
  let { locationid } = useParams();
  const notificationType = location.isError ? 'error' : 'success';

  const showSaveOption = hasPermissionToAction(permissions, _location, _create);

  let currentLocation = location.currentLocation;
  let localPath = '/master/locations/location';
  let heading = 'Add Location';

  if (locationid) {
    IsEdit = true;
    heading = 'Edit Location';
    localPath = localPath + `/${locationid}`;
  }

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Location Listing', path: '/master/locations' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  useEffect(() => {
    if (locationid) {
      dispatch(describeLocation(locationid));
    } else {
      dispatch(describeLocation(IS_NEW));
    }
  }, [dispatch, locationid]);

  //clean up
  useEffect(() => {
    return () => {
      setLocalCleanUpData({
        data: { ...location.currentLocation, ...initialLocationState.currentLocation }
      });
    };
  }, []);

  const onPageChangeHandler = currentPage => {};

  const setLocalCleanUpData = cleanUpData => {
    dispatch(setOnCleanUp(cleanUpData));
  };

  const setLocalChange = currentState => {
    dispatch(setOnChange(currentState));
  };

  const handleInput = e => {
    setLocalChange({
      ...location,
      data: { ...location.currentLocation, [e.target.name]: e.target.value }
    });
  };

  const dropDownChange = (field, selectedOption, cascadeEffectDropDowns = []) => {
    let changeObj = { [field]: selectedOption.value };

    if (cascadeEffectDropDowns.length > 0) {
      cascadeEffectDropDowns.forEach(element => {
        changeObj = { ...changeObj, [element]: '' };
      });
    }

    setLocalChange({
      ...location,
      data: { ...location.currentLocation, ...changeObj }
    });
  };

  const CostCenterOptions = () => {
    const options = [
      {
        label: 'Select Cost Center',
        value: ''
      }
    ];

    const filterCostCenter = currentLocation.costCenters.filter(x => x.filterId === currentLocation.clientProfileId);

    filterCostCenter.forEach(element => {
      options.push(element);
    });

    return options;
  };

  const handleCheckBoxInput = e => {
    let isChecked;
    if (e.target.value === 'false') {
      isChecked = false;
    } else {
      isChecked = true;
    }
    setLocalChange({
      ...location,
      data: { ...location.currentLocation, [e.target.name]: !isChecked }
    });
  };

  const handleSubmit = e => {
    if (location.currentLocation) {
      dispatch(saveLocation(location.currentLocation, !IsEdit, locationid));
    }
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  return (
    <React.Fragment>
      {location.redirect ? <Redirect to={backUrl} /> : ''}
      {location.isLoading && <LoadingSpinner />}
      {location.showMessage && <Notification notificationType={notificationType} message={location.message} />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardTitle>{heading}</CardTitle>
                <CardBody>
                  <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                    {location.errorList && location.errorList.length > 0 && <ErrorListing errorList={location.errorList} />}
                    <Row>
                      <Col lg={12}>
                        <Row>
                          <Col sm="4">
                            <div className="mb-3">
                              <CustomAvField
                                name="locationName"
                                label="Location Name"
                                className="form-control"
                                placeholder="Location Name"
                                value={currentLocation.locationName}
                                onChange={handleInput}
                                type="text"
                                maxLength="50"
                                validate={{
                                  required: { value: true, errorMessage: 'Please enter a valid cost center name' }
                                }}
                              />
                            </div>
                          </Col>
                          <Col sm="4">
                            <div className="mb-3">
                              <CustomAvField
                                name="contactPerson"
                                label="Contact Person"
                                className="form-control"
                                placeholder="Contact Person"
                                value={currentLocation.contactPerson}
                                onChange={handleInput}
                                type="text"
                                maxLength="50"
                                validate={{
                                  required: { value: false, errorMessage: '' }
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="4">
                            <div className="mb-3">
                              <CustomAvField
                                name="contactEmail"
                                label="Contact's Email"
                                className="form-control"
                                placeholder="Contact Email"
                                value={currentLocation.contactEmail}
                                onChange={handleInput}
                                maxLength="50"
                                type="email"
                                validate={{
                                  required: { value: false, errorMessage: 'Please enter an email' },
                                  pattern: {
                                    value: '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$',
                                    errorMessage: 'Please enter a valid email address'
                                  }
                                }}
                              />
                            </div>
                          </Col>
                          <Col sm="4">
                            <div className="mb-3">
                              <CustomAvField
                                name="contactPhone"
                                label="Contact's Phone"
                                className="form-control"
                                placeholder="Contact Phone"
                                value={currentLocation.contactPhone}
                                onChange={handleInput}
                                type="text"
                                maxLength="20"
                                validate={{
                                  required: { value: false, errorMessage: '' }
                                }}
                              />
                            </div>
                          </Col>
                          <Col md="12">
                            <AvGroup check>
                              <AvInput
                                type="checkbox"
                                name="isActive"
                                label="Is Active"
                                onChange={handleCheckBoxInput}
                                value={currentLocation.isActive}
                              />
                              <Label check for="checkbox">
                                {' '}
                                Is Active
                              </Label>
                            </AvGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      {showSaveOption && (
                        <Button
                          type="submit"
                          color="primary"
                          className="btn "
                          disabled={location.isLoading || (location.showMessage && !location.isError)}
                        >
                          SAVE
                        </Button>
                      )}
                      <Button onClick={cancelHandler} color="secondary" className=" ">
                        CANCEL
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageLocation;
