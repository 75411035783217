import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  CardText,
  TabContent,
  TabPane
} from 'reactstrap';
import { describeProduct, saveProduct, setOnChange, setOnCleanUp } from '../../../store/actions/Master/product';
import { AvForm, AvInput, AvGroup } from 'availity-reactstrap-validation';
import { initialProductState } from '../../../store/reducers/initialState';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _product } from '../../../constants/componentConstants';
import { IS_NEW } from '../../../constants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import Dropdown from '../../CustomControls/Dropdown';
import CustomAvField from '../../CustomControls/CustomAvField';
import CustomInput from '../../CustomControls/CustomInput';
import classnames from 'classnames';
import ProductDepartment from './ProductDepartment';
import ProductSupplier from './ProductSupplier';
import ProductLocation from './ProductLocation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCompass, faSitemap, faPeopleCarry } from '@fortawesome/free-solid-svg-icons';
import './style.scss';
import DatePicker from '../../CustomControls/CustomDatePicker';
import ErrorListing from '../../CustomControls/ErrorListing';

const ManageProduct = props => {
  let history = useHistory();
  const backUrl = '/master/products';
  const dispatch = useDispatch();
  const product = useSelector(state => state.product);
  const [triggerValidation, setTriggerValidation] = useState(false);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  let IsEdit = false;
  let { productid } = useParams();
  const notificationType = product.isError ? 'error' : 'success';
  const [currentActiveTab, setCurrentActiveTab] = useState('general');

  let editRedirect = false;
  let redirectUrl = backUrl;

  const showSaveOption = hasPermissionToAction(permissions, _product, _create);

  let currentProduct = product.currentProduct;

  let localPath = '/master/products/product';
  let heading = 'Add Product';

  if (productid) {
    IsEdit = true;
    heading = 'Edit Product';
    localPath = localPath + `/${productid}`;
  }

  if (!productid && product.createdId) {
    redirectUrl = localPath + '/' + product.createdId;
    editRedirect = true;
  }

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Product Listing', path: '/master/products' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  useEffect(() => {
    if (productid) {
      dispatch(describeProduct(productid));
    } else {
      dispatch(describeProduct(IS_NEW));
    }
  }, [dispatch, productid]);

  //clean up
  useEffect(() => {
    return () => {
      setLocalCleanUpData({
        data: { ...product.currentProduct, ...initialProductState.currentProduct }
      });
    };
  }, []);

  const onPageChangeHandler = currentPage => {};

  const setLocalCleanUpData = cleanUpData => {
    dispatch(setOnCleanUp(cleanUpData));
  };

  const setLocalChange = currentState => {
    dispatch(setOnChange(currentState));
  };

  const handleInput = e => {
    let changeObj = { [e.target.name]: e.target.value };

    if (e.target.name === 'standardCharge') {
      let curDpt = product.currentProduct.currentDepartment;
      curDpt.price = e.target.value;

      changeObj.currentDepartment = curDpt;
    }

    setLocalChange({
      ...product,
      data: { ...product.currentProduct, ...changeObj }
    });
  };

  const handleDateInput = (field, dateVal) => {
    const setDate = dateVal ? new Date(dateVal) : null;
    setLocalChange({
      ...product,
      data: { ...product.currentProduct, [field]: setDate }
    });
  };

  const dropDownChange = (field, selectedOption) => {
    setLocalChange({
      ...product,
      data: { ...product.currentProduct, [field]: selectedOption.value }
    });
  };

  const handleCheckBoxInput = e => {
    let isChecked;
    if (e.target.value === 'false') {
      isChecked = false;
    } else {
      isChecked = true;
    }
    setLocalChange({
      ...product,
      data: { ...product.currentProduct, [e.target.name]: !isChecked }
    });
  };

  const isValid = () => {
    let status = true;
    if (!product.currentProduct.productTypeId) {
      status = false;
    }

    if (!product.currentProduct.lastPurchaseDate || product.currentProduct.lastPurchaseDate === '') {
      status = false;
    }

    return status;
  };
  const handleInvalidSubmit = e => {
    setTriggerValidation(true);
  };

  const handleSubmit = e => {
    if (!isValid()) {
      setTriggerValidation(true);
    } else {
      if (product.currentProduct) {
        dispatch(saveProduct(product.currentProduct, !IsEdit, productid));
      }
    }
  };

  const handleTabDataChangeHandler = (field, value) => {
    setLocalChange({
      ...product,
      data: { ...product.currentProduct, [field]: value }
    });
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  return (
    <React.Fragment>
      {product.redirect || editRedirect ? <Redirect to={redirectUrl} /> : ''}
      {product.isLoading && <LoadingSpinner />}
      {product.showMessage && <Notification notificationType={notificationType} message={product.message} />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardTitle>
                  <Row className="buttonrow">
                    <Col md="4">{heading}</Col>
                    <Col md="8">
                      <Nav tabs className="custom-tab nav-justified customTab">
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: currentActiveTab === 'general'
                            })}
                            onClick={() => {
                              setCurrentActiveTab('general');
                            }}
                          >
                            <span className="d-none d-sm-block">
                              <FontAwesomeIcon icon={faHome} /> <span className="pd-left">Basic</span>
                            </span>
                            <span className="d-block d-sm-none">
                              <FontAwesomeIcon icon={faHome} />
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: currentActiveTab === 'department'
                            })}
                            onClick={() => {
                              setCurrentActiveTab('department');
                            }}
                            disabled={!IsEdit}
                          >
                            <span className="d-none d-sm-block">
                              <FontAwesomeIcon icon={faSitemap} />
                              <span className="pd-left">Department</span>
                            </span>
                            <span className="d-block d-sm-none">
                              <FontAwesomeIcon icon={faSitemap} />
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: currentActiveTab === 'location'
                            })}
                            onClick={() => {
                              setCurrentActiveTab('location');
                            }}
                            disabled={!IsEdit}
                          >
                            <span className="d-none d-sm-block">
                              <FontAwesomeIcon icon={faCompass} />
                              <span className="pd-left">Location</span>
                            </span>
                            <span className="d-block d-sm-none">
                              <FontAwesomeIcon icon={faCompass} />
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: currentActiveTab === 'supplier'
                            })}
                            onClick={() => {
                              setCurrentActiveTab('supplier');
                            }}
                            disabled={!IsEdit}
                          >
                            <span className="d-none d-sm-block">
                              <FontAwesomeIcon icon={faPeopleCarry} /> <span className="pd-left">Supplier</span>
                            </span>
                            <span className="d-block d-sm-none">
                              <FontAwesomeIcon icon={faPeopleCarry} />
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                  </Row>
                </CardTitle>
                <CardBody>
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col md="6"></Col>
                      </Row>

                      <Row>
                        <Col sm="12">
                          <TabContent activeTab={currentActiveTab} className="p-3 text-muted">
                            <TabPane tabId="general">
                              <AvForm className="form-horizontal" onValidSubmit={handleSubmit} onInvalidSubmit={handleInvalidSubmit}>
                                {product.errorList && product.errorList.length > 0 && <ErrorListing errorList={product.errorList} />}
                                <Row>
                                  <Col sm="12">
                                    <h6>Basic product info</h6>
                                    <Card>
                                      <CardBody>
                                        <Row>
                                          <Col md="4">
                                            <div className="mb-3">
                                              <Dropdown
                                                placeholder="Select Product Type"
                                                label="Product Type"
                                                name="productTypeId"
                                                options={currentProduct.productTypes}
                                                isMulti={false}
                                                triggerValidation={triggerValidation}
                                                value={currentProduct.productTypes.find(x => x.value === currentProduct.productTypeId)}
                                                onChange={val => dropDownChange('productTypeId', val)}
                                                validate={{
                                                  required: { value: true, errorMessage: 'Please select a product type' }
                                                }}
                                              />
                                            </div>
                                          </Col>
                                          <Col md="4">
                                            <div className="mb-3">
                                              <CustomAvField
                                                name="productCode"
                                                label="Product Code"
                                                className="form-control"
                                                placeholder="Product Code"
                                                value={currentProduct.productCode}
                                                onChange={handleInput}
                                                type="text"
                                                maxLength="20"
                                                validate={{
                                                  required: { value: true, errorMessage: 'Please enter a valid product code' }
                                                }}
                                              />
                                            </div>
                                          </Col>
                                          <Col md="4">
                                            <div className="mb-3">
                                              <CustomAvField
                                                name="productName"
                                                label="Product Name"
                                                className="form-control"
                                                placeholder="Product Name"
                                                value={currentProduct.productName}
                                                onChange={handleInput}
                                                type="text"
                                                maxLength="50"
                                                validate={{
                                                  required: { value: true, errorMessage: 'Please enter a valid product name' }
                                                }}
                                              />
                                            </div>
                                          </Col>
                                          <Col md="4">
                                            <div className="mb-3">
                                              <CustomAvField
                                                name="packageSize"
                                                label="Package Size"
                                                className="form-control"
                                                placeholder="Package Size"
                                                value={currentProduct.packageSize}
                                                onChange={handleInput}
                                                step="1"
                                                min="0"
                                                type="number"
                                                maxLength="50"
                                                validate={{
                                                  required: { value: true, errorMessage: 'Please enter a valid package size' }
                                                }}
                                              />
                                            </div>
                                          </Col>
                                          <Col md="4">
                                            <div className="mb-3">
                                              <CustomInput
                                                name="weightOfOneUnit"
                                                label="Weight Of One Unit"
                                                className="form-control"
                                                placeholder="Weight Of One Unit"
                                                value={currentProduct.weightOfOneUnit}
                                                onChange={handleInput}
                                                step=".01"
                                                min="0"
                                                iconValue="kg"
                                                type="number"
                                                validate={{
                                                  required: { value: true, errorMessage: 'Please enter a valid unit weight' }
                                                }}
                                              />
                                            </div>
                                          </Col>
                                          <Col md="4">
                                            <div className="mb-3">
                                              <CustomInput
                                                name="perUnitCost"
                                                label="Per Unit Cost"
                                                className="form-control"
                                                placeholder="Per Unit Cost"
                                                value={currentProduct.perUnitCost}
                                                onChange={handleInput}
                                                step=".001"
                                                min="0"
                                                iconValue="$"
                                                type="number"
                                                validate={{
                                                  required: { value: true, errorMessage: 'Please enter a valid per unit cost' }
                                                }}
                                              />
                                            </div>
                                          </Col>
                                          <Col md="4">
                                            <div className="mb-3">
                                              <CustomInput
                                                name="lastPurchasePrice"
                                                label="Last Purchase Price"
                                                className="form-control"
                                                placeholder="Last Purchase Price"
                                                value={currentProduct.lastPurchasePrice}
                                                onChange={handleInput}
                                                step=".001"
                                                min="0"
                                                iconValue="$"
                                                type="number"
                                                validate={{
                                                  required: { value: true, errorMessage: 'Please enter a valid per last purchase price' }
                                                }}
                                              />
                                            </div>
                                          </Col>
                                          <Col md="4">
                                            <div className="mb-3">
                                              <CustomInput
                                                name="standardCharge"
                                                label="Per Unit Charge (from clients)"
                                                className="form-control"
                                                placeholder="Per Unit Charge"
                                                value={currentProduct.standardCharge}
                                                onChange={handleInput}
                                                step=".001"
                                                min="0"
                                                iconValue="$"
                                                type="number"
                                                validate={{
                                                  required: { value: true, errorMessage: 'Please enter a valid per standardCharge' }
                                                }}
                                              />
                                            </div>
                                          </Col>
                                          <Col md="2">
                                            <div className="mb-4">
                                              <DatePicker
                                                label="Last purchase date"
                                                triggerValidation={triggerValidation}
                                                selected={
                                                  currentProduct.lastPurchaseDate ? new Date(currentProduct.lastPurchaseDate) : null
                                                }
                                                onChange={date => handleDateInput('lastPurchaseDate', date)}
                                                dateFormat="dd-MMM-yyyy"
                                                validate={{
                                                  required: { value: true, errorMessage: 'Please select a valid purchase date' }
                                                }}
                                              />
                                            </div>
                                          </Col>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <AvGroup check>
                                                <AvInput
                                                  type="checkbox"
                                                  name="isActive"
                                                  label="Is Active"
                                                  onChange={handleCheckBoxInput}
                                                  value={currentProduct.isActive}
                                                />
                                                <Label check for="checkbox">
                                                  {' '}
                                                  Is Active
                                                </Label>
                                              </AvGroup>
                                            </div>
                                          </Col>
                                        </Row>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                </Row>
                                <div className="d-flex flex-wrap gap-2">
                                  {showSaveOption && (
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="btn "
                                      disabled={product.isLoading || (product.showMessage && !product.isError)}
                                    >
                                      SAVE
                                    </Button>
                                  )}
                                  <Button onClick={cancelHandler} color="secondary" className=" ">
                                    CANCEL
                                  </Button>
                                </div>
                              </AvForm>
                            </TabPane>
                            <TabPane tabId="department">
                              <Row>
                                <Col sm="12">
                                  <ProductDepartment product={product} handleTabDataChangeHandler={handleTabDataChangeHandler} />
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="location">
                              <Row>
                                <Col sm="12">
                                  <ProductLocation product={product} handleTabDataChangeHandler={handleTabDataChangeHandler} />
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="supplier">
                              <Row>
                                <Col sm="12">
                                  <ProductSupplier product={product} handleTabDataChangeHandler={handleTabDataChangeHandler} />
                                </Col>
                              </Row>
                            </TabPane>
                          </TabContent>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageProduct;
