import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import uiReducer from './ui';
import userReducer from './user';
import groupReducer from './group';
import authReducer from './auth';
import companyReducer from './Management/company';
import clientProfileReducer from './Master/clientProfile';
import costCenterReducer from './Master/costCenter';
import departmentReducer from './Master/department';
import productTypeReducer from './Master/productType';
import productReducer from './Master/product';
import locationReducer from './Master/location';
import requisitionReducer from './Operation/requisition';
import dispatchReducer from './Operation/dispatch';
import creditNoteReducer from './Operation/creditNote';
import auditLogReducer from './Audit/auditLog';
import rptLocationListingReducer from './ReportListing/rptLocationListing';
import rptClientProfileListingReducer from './ReportListing/rptClientProfileListing';
import rptCostCenterListingReducer from './ReportListing/rptCostCenterListing';
import rptDepartmentListingReducer from './ReportListing/rptDepartmentListing';
import rptProductTypeListingReducer from './ReportListing/rptProductTypeListing';
import rptProductListingReducer from './ReportListing/rptProductListing';
import rptDeptSummaryReducer from './ReportOperation/rptDepartmentSummary';
import rptCompanyListingReducer from './ReportAdmin/rptCompany';
import { LOGOUT } from '../../constants/index';
import customerReducer from './customer';
import orderReducer from './order';
import orderDetailReducer from './orderDetail';
import customerDetailReducer from './customerDetail';
import emailReducer from './email';
import distributionCenterReducer from './Management/distributionCenter';
import clientCenterReducer from './Management/client';
import pricingReducer from './Master/pricing';
import auditPricingLogReducer from './Audit/auditPricingLog';
const appReducer = history =>
  combineReducers({
    auth: authReducer,
    auditLog: auditLogReducer,
    company: companyReducer,
    clientProfile: clientProfileReducer,
    costCenter: costCenterReducer,
    department: departmentReducer,
    group: groupReducer,
    location: locationReducer,
    productType: productTypeReducer,
    product: productReducer,
    ui: uiReducer,
    email: emailReducer,
    user: userReducer,
    requisition: requisitionReducer,
    dispatch: dispatchReducer,
    creditNote: creditNoteReducer,
    rptCompanyListing: rptCompanyListingReducer,
    rptLocationListing: rptLocationListingReducer,
    rptClientProfileListing: rptClientProfileListingReducer,
    rptCostCenterListing: rptCostCenterListingReducer,
    rptDepartmentListing: rptDepartmentListingReducer,
    rptProductTypeListing: rptProductTypeListingReducer,
    rptProductListing: rptProductListingReducer,
    rptDeptSummary: rptDeptSummaryReducer,
    customer: customerReducer,
    order: orderReducer,
    orderDetail: orderDetailReducer,
    customerDetail: customerDetailReducer,
    router: connectRouter(history),
    distribution: distributionCenterReducer,
    client: clientCenterReducer,
    pricing: pricingReducer,
    auditPricingLog: auditPricingLogReducer
  });

const rootReducer = history => (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return appReducer(history)(state, action);
};

export default rootReducer;
