import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';

import Pagination from '../CustomControls/Pagination';

import LoadingSpinner from '../CustomControls/Spinner';
import Notification from '../CustomControls/Notification';
import Breadcrumbs from '../CustomControls/Breadcrumb';
import SearchBar from '../CustomControls/SearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import AuditLogTable from './AuditPricingTable';
import { getAllAuditPricingLogs, setOnChange } from '../../store/actions/Audit/auditPricingLog';

const AuditPricingLog = props => {
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const auditPricingLog = useSelector(state => state.auditPricingLog);
  const ui = useSelector(state => state.ui);
  const pageSize = ui.layout.pageSize;

  const currentPageNumber = auditPricingLog?.filterOptions?.pageNumber;

  const notificationType = auditPricingLog?.isError ? 'error' : 'success';

  useEffect(() => {
    dispatch(
      getAllAuditPricingLogs(currentPageNumber, pageSize, auditPricingLog?.filterOptions.filterBy, auditPricingLog?.filterOptions?.sortBy)
    );
  }, [dispatch, currentPageNumber, pageSize, auditPricingLog?.filterOptions?.filterBy, auditPricingLog?.filterOptions?.sortBy]);

  const onPageChangeHandler = currentPage => {
    setLocalChange({
      ...auditPricingLog,
      data: { ...auditPricingLog?.filterOptions, pageNumber: currentPage }
    });
  };

  const setLocalChange = currentState => {
    dispatch(setOnChange(currentState));
  };

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Audit Pricing Logs', path: '/audit/audit-pricing-logs', isCurrent: true }
  ];

  const handleInput = e => {
    setSearchText(e.target.value);
  };

  const onClickHandler = e => {
    let filterSearchList = auditPricingLog.filterOptions.filterBy || [];

    const objIndex = filterSearchList.findIndex(x => x.name.toLowerCase() === 'operationid');

    if (objIndex !== -1) {
      filterSearchList[objIndex].value = searchText.toLowerCase();
    } else {
      filterSearchList.push({ name: 'operationid', value: searchText.toLowerCase() });
    }

    setLocalChange({
      ...auditPricingLog,
      data: { ...auditPricingLog?.filterOptions, filterBy: filterSearchList }
    });

    dispatch(getAllAuditPricingLogs(currentPageNumber, pageSize, filterSearchList, auditPricingLog?.filterOptions?.sortBy));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {auditPricingLog?.showMessage && <Notification notificationType={notificationType} message={auditPricingLog?.message} />}
        <Container fluid>
          <Breadcrumbs title="Audit Pricing Logs " paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="4">
                      <SearchBar onChange={handleInput} placeholder="Search By Client Name" value={searchText} />
                    </Col>
                    <Col sm="2" className="m-auto p-10-25">
                      <div className="text-sm-start">
                        <Button type="button" className="btn btn-outline-primary" onClick={onClickHandler}>
                          <FontAwesomeIcon className="standardIcon" icon={faSearch} /> Search
                        </Button>
                      </div>
                    </Col>
                    <Col sm="6"></Col>
                  </Row>
                  <Row className="tablerow">
                    <Col xl="12">
                      <div>
                        {auditPricingLog?.isLoading && <LoadingSpinner />}
                        <Pagination
                          data={auditPricingLog?.auditPricingLogList}
                          pageSize={pageSize}
                          onPageChange={onPageChangeHandler}
                          startingPage={currentPageNumber}
                          apiPaging={true}
                          apiDataLength={auditPricingLog?.totalRecords}
                        >
                          <AuditLogTable />
                        </Pagination>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AuditPricingLog;
