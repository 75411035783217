import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';

const Layout = ({ children, logout, location }) => {

  return (
    <React.Fragment>

      <div id="layout-wrapper">
        <Header logout={logout} />
        <Sidebar location={location} />
        <div className="main-content">{children}</div>
        <Footer />
      </div>
    </React.Fragment>
  );
};


Layout.propTypes = {
  logout: PropTypes.func,
  handlerNavSidebar: PropTypes.func,
  currentSidebarOpen: PropTypes.bool,
};

export default Layout;

