import React from 'react';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper, faRedo, faShareSquare, faPrint, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import '../../../components/Operation/Requisition/style.scss'
import moment from 'moment';

const InternalTable = ({
  paginatedData,
  editHandler,
  repeatHandler,
  dispatchHandler,
  printHandler,
  showPrint,
  deleteHandler,
  showDelete,
  check
}) => {
  return (
    <React.Fragment>
      <div>
        <Table className="table" responsive>
          <thead>
            <tr>
              <th>Order Ref</th>
              <th>Date</th>
              {/* <th>Client</th>
              <th>Department</th> */}
              <th>Expected Delivery Date</th>
              <th>Order By</th>
              <th>Status</th>
              <th>View</th>
              {showPrint && <th>Repeat</th>}
              {check !== 'view-order' && <th>Dispatch</th>}
              {showDelete && <th>Delete</th>}
              {showPrint && <th>Print</th>}
            </tr>
          </thead>
          <tbody>
            {paginatedData?.map(n => {
              return (
                <tr key={n.requisitionId}>
                  <td>{n.orderRef || ''}</td>
                  <td>{moment(new Date(n.orderDate)).format('DD-MMM-YYYY') || ''}</td>
                  {/* <td>{n.clientProfileName || ''}</td>
                  <td>{n.departmentName || ''}</td> */}
                  <td>{moment(new Date(n.expectedDeliveryDate)).format('DD-MMM-YYYY') || ''}</td>
                  <td>{n.createdBy || ''}</td>
                  <td>{n.status }</td>
                  {
                    <td>
                      <button className="btn tableIcons" onClick={() => editHandler(n.orderId)}>
                        {' '}
                        <FontAwesomeIcon icon={faNewspaper} />
                      </button>
                    </td>
                  }
                  { showPrint && (
                    <td>
                      <button className="btn tableIcons" onClick={() => repeatHandler(n.orderId)}>
                        {' '}
                        <FontAwesomeIcon icon={faRedo} />
                      </button>
                    </td>
            ) }
                 {check !== 'view-order' && <td>
                    { n.status === "Delivered"  ? (
                      ''
                    ) : (
                      <button className="btn tableIcons" onClick={() => dispatchHandler(n.orderId)}>
                        {' '}
                        
                        <FontAwesomeIcon icon={faShareSquare} />
                      </button>
                    )}
                  </td>}
                  {showDelete && (
                    <td>
                      <button className="btn tableIcons" onClick={() => deleteHandler(n.orderId)}>
                        {' '}
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </td>
                  )}
                  {showPrint && (
                    <td>
                      <button className="btn tableIcons" onClick={() => printHandler(n.orderId)}>
                        {' '}
                        <FontAwesomeIcon icon={faPrint} />
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default InternalTable;
