import { DEPARTMENT_GET_ALL_DATA, DEPARTMENT_GET_DATA, DEPARTMENT_ERROR, DEPARTMENT_IS_LOADING, DEPARTMENT_SAVE_DATA, DEPARTMENT_ON_CHANGE_DATA, DEPARTMENT_CLEAN_UP,DEPARTMENT_DELETE_DATA, DEPARTMENT_UPDATE_CONFIRMATION }  from '../../../constants/index';
import { initialDepartmentState } from '../initialState';

const departmentReducer = (state = initialDepartmentState, action) => {
  const { payload, type } = action;
  switch (type) {
    case DEPARTMENT_ERROR: {
      const { showMessage, errorMessage, errorList } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage,
        errorList: errorList
      };
    }
    case DEPARTMENT_GET_ALL_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        departmentList: resultData
      };
    }
    case DEPARTMENT_GET_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        currentDepartment: {
          ...state.currentDepartment,
          ...resultData
        }
      };
    }
    case DEPARTMENT_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }
    case DEPARTMENT_SAVE_DATA: {
      const { showMessage, message, redirect } = payload;
      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message
      };
    }

    case DEPARTMENT_ON_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        currentDepartment: {
          ...state.currentDepartment,
          ...data
        }
      };
    }
    case DEPARTMENT_CLEAN_UP: {
      let { data } = payload;

      return {
        ...state,
        message: '',
        errorList: [],
        isError: false,
        showMessage: false,
        redirect: false,
        showConfirmationModal: false,
        confirmationId : '',
        currentDepartment: {
          ...state.currentDepartment,
          ...data
        }
      };
    }
    case DEPARTMENT_DELETE_DATA: {
      const { showMessage, message, redirect } = payload;
      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message,
        showConfirmationModal: false,
        confirmationId : ''
      };
    }

    case DEPARTMENT_UPDATE_CONFIRMATION: {
      let { data } = payload;

      return {
        ...state,
        ...data
      };
    }


    default:
      return state;
  }
};

export default departmentReducer;




