import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faSignOutAlt, faCog, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { get as getCookie } from 'js-cookie';
import { hasPermissionToAction } from '../../utils/accessUtil';
import * as permissionconst from '../../constants/componentConstants';
import { useSelector } from 'react-redux';
const UserInfo = ({ logout }) => {
  const [menu, setMenu] = useState(false);
  const name = getCookie('name');
  const username = getCookie('username');
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
          <span className="d-none d-xl-inline-block ms-3 me-1">Hello! {name}</span>
          <FontAwesomeIcon icon={faChevronDown} size="1x" className="d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-userinfo">
          <DropdownItem tag="a" href="#">
            <FontAwesomeIcon icon={faUserCircle} className="align-middle me-3" />
            <span>{username}</span>
          </DropdownItem>
          {hasPermissionToAction(permissions, permissionconst._requisition, permissionconst._view) && (
            <Link to="/change-password" className="dropdown-item">
              <FontAwesomeIcon icon={faCog} className="align-middle me-3" />
              <span>Change Password</span>
            </Link>
          )}
          {hasPermissionToAction(permissions, permissionconst._customerproduct, permissionconst._view) && (
            <Link to="/edit-profile" className="dropdown-item">
              <FontAwesomeIcon icon={faCog} className="align-middle me-3" />
              <span>Edit Profile</span>
            </Link>
          )}

          <div className="dropdown-divider" />
          <Link to="#" onClick={logout} className="dropdown-item">
            <FontAwesomeIcon icon={faSignOutAlt} className="align-middle me-3" />
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default UserInfo;
