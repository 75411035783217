import FileSaver from 'file-saver';
export const setErrorThunk = (RTYPE, showMessage, { errorMessage, errorList = [] }) => dispatch => {
  dispatch(_setError(RTYPE, errorMessage, showMessage, errorList));
  setTimeout(() => {
    dispatch(_setError(RTYPE, errorMessage, showMessage, errorList));
  }, 2500);
};

const _setError = (RTYPE, errorMessage, showMessage, errorList) => ({
  type: RTYPE,
  payload: { showMessage, errorMessage, errorList }
});

export const setLoadingStatus = (RTYPE, isLoading) => ({
  type: RTYPE,
  payload: { isLoading: isLoading }
});

export const setGetResults = (RTYPE, result) => ({
  type: RTYPE,
  payload: { resultData: result }
});

export const setDescribeData = (RTYPE, result) => ({
  type: RTYPE,
  payload: { resultData: result }
});
export const setInternalOrderData = (RTYPE, result) => ({
  type: RTYPE,
  payload: { resultData: result }
});

export const setSaveData = (RTYPE, showMessage, { message, createdId = '' }, misc = null) => dispatch => {
  dispatch(_setSuccess(RTYPE, message, showMessage, false, createdId, misc));
  setTimeout(() => {
    dispatch(_setSuccess(RTYPE, message, showMessage, true, createdId, misc));
  }, 2500);
};

const _setSuccess = (RTYPE, message, showMessage, redirect, createdId, misc = null) => ({
  type: RTYPE,
  payload: { showMessage, message, redirect, createdId, misc }
});

export const setOnChangeData = (RTYPE, retData) => ({
  type: RTYPE,
  payload: retData
});

export const setOnCleanUpData = (RTYPE, retData) => ({
  type: RTYPE,
  payload: retData
});

export const setOnSearchChangeData = (RTYPE, retData) => ({
  type: RTYPE,
  payload: retData
});

export const setOnConfirmationData = (RTYPE, retData) => ({
  type: RTYPE,
  payload: retData
});

export const setDeleteData = (RTYPE, showMessage, { message }) => dispatch => {
  dispatch(_setSuccess(RTYPE, message, showMessage, false, ''));
  setTimeout(() => {
    dispatch(_setSuccess(RTYPE, message, showMessage, false, ''));
  }, 2500);
};

// export const generateCSV = data => {
//   const now = new Date();
//   const fileName = `Report_${now
//     .toLocaleString('en-US', { hour12: true, hour: 'numeric', minute: 'numeric', day: 'numeric', month: 'short', year: 'numeric' })
//     .replace(/ /g, '_')}.csv`;
//   const blob = new Blob([data], { type: 'application/vnd.ms-excel;charset=utf-8' });
//   FileSaver.saveAs(blob, fileName);
// };
export const generateCSVCustomer = data => {
  const now = new Date();
  const fileName = `Customer_Order_Report_${now
    .toLocaleString('en-US', { hour12: true, hour: 'numeric', minute: 'numeric', day: 'numeric', month: 'short', year: 'numeric' })
    .replace(/ /g, '_')}.csv`;
  const blob = new Blob([data], { type: 'application/vnd.ms-excel;charset=utf-8' });
  FileSaver.saveAs(blob, fileName);
};
// export const generatePDFCustomer = data => {
//   const now = new Date();
//   const fileName = `Customer_Order_PDF_Report_${now
//     .toLocaleString('en-US', { hour12: true, hour: 'numeric', minute: 'numeric', day: 'numeric', month: 'short', year: 'numeric' })
//     .replace(/ /g, '_')}.pdf`;
//   const blob = new Blob([data], { type: 'application/pdf' });
//   FileSaver.saveAs(blob, fileName);
// };
// export const generatePDFCustomer = data => {

//   const now = new Date();
//   const fileName = `Customer_Order_PDF_Report_${now
//     .toLocaleString('en-US', { hour12: true, hour: 'numeric', minute: 'numeric', day: 'numeric', month: 'short', year: 'numeric' })
//     .replace(/ /g, '_')}.pdf`;

//   const blob = new Blob([data], { type: 'application/pdf' });

//   if (navigator.msSaveBlob) {
//     // For IE/Edge browsers
//     navigator.msSaveBlob(blob, fileName);
//   } else {
//     // For other browsers
//     const link = document.createElement('a');
//     if (link.download !== undefined) {
//       // Set the download attribute if it's supported
//       const url = URL.createObjectURL(blob);
//       link.href = url;
//       link.download = fileName;
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//       URL.revokeObjectURL(url);
//     } else {
//       // Provide alternative method for unsupported browsers
//       window.alert('Your browser does not support downloading files. Please try using a different browser.');
//     }
//   }
// };
export function generatePDFCustomer(response) {
  // debugger;
  window.open(response, '_blank');
  // Convert the response string to a Uint8Array
  // const data = new Uint8Array(response.length);
  // for (let i = 0; i < response.length; i++) {
  //   data[i] = response.charCodeAt(i);
  // }

  // // Create a Blob from the Uint8Array
  // const blob = new Blob([data], { type: 'application/pdf' });

  // // Create a download link for the Blob
  // const link = document.createElement('a');
  // link.href = URL.createObjectURL(blob);
  // link.download = 'converted.pdf';

  // // Add the link to the document and simulate a click event
  // document.body.appendChild(link);
  // link.click();

  // // Clean up by removing the link element
  // document.body.removeChild(link);
}
