import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
import { describeClientProfile, saveClientProfile, setOnChange, setOnCleanUp } from '../../../store/actions/Master/clientProfile';
import { AvForm, AvInput, AvGroup } from 'availity-reactstrap-validation';
import { initialClientProfileState } from '../../../store/reducers/initialState';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import CustomInput from '../../CustomControls/CustomInput';
import { _create, _clientprofile, _clientadmin, _view, _distributionmanager } from '../../../constants/componentConstants';
import { IS_NEW, STATES } from '../../../constants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import Dropdown from '../../CustomControls/Dropdown';
import CustomAvField from '../../CustomControls/CustomAvField';
import ErrorListing from '../../CustomControls/ErrorListing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSitemap } from '@fortawesome/free-solid-svg-icons';
import ManageClientUsers from './ManageClientUsers';
import classnames from 'classnames';

const ManageClientProfile = props => {
  let history = useHistory();
  const [useDeliveryAdd, setUseDeliveryAdd] = useState(false);
  const [currentActiveTab, setCurrentActiveTab] = useState('general');
  const backUrl = '/master/client-profiles';
  const dispatch = useDispatch();
  const clientProfile = useSelector(state => state.clientProfile);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  const [selectedUserType, setSelectedUserType] = useState([]);

  let IsEdit = false;
  let { clientprofileid } = useParams();
  const notificationType = clientProfile.isError ? 'error' : 'success';

  const showSaveOption = hasPermissionToAction(permissions, _clientprofile, _create);
  const usersPermission = hasPermissionToAction(permissions, _clientadmin, _view);
  const dcPermission = hasPermissionToAction(permissions, _distributionmanager, _view);

  let currentClientProfile = clientProfile.currentClientProfile;
  let localPath = '/master/client-profiles/client-profile';
  let heading = 'Add Client';

  if (clientprofileid) {
    IsEdit = true;
    heading = 'Edit Client';
    localPath = localPath + `/${clientprofileid}`;
  }

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Client Listing', path: '/master/client-profiles' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  useEffect(() => {
    if (clientprofileid) {
      dispatch(describeClientProfile(clientprofileid));
    } else {
      dispatch(describeClientProfile(IS_NEW));
    }
  }, [dispatch, clientprofileid]);

  //clean up
  useEffect(() => {
    return () => {
      setLocalCleanUpData({
        data: { ...clientProfile.currentClientProfile, ...initialClientProfileState.currentClientProfile }
      });
    };
  }, []);

  const setLocalCleanUpData = cleanUpData => {
    dispatch(setOnCleanUp(cleanUpData));
  };

  const setLocalChange = currentState => {
    dispatch(setOnChange(currentState));
  };

  const handleInput = e => {
    setLocalChange({
      ...clientProfile,
      data: { ...clientProfile.currentClientProfile, [e.target.name]: e.target.value }
    });
  };

  const dropDownChange = (field, selectedOption) => {
    setLocalChange({
      ...clientProfile,
      data: { ...clientProfile.currentClientProfile, [field]: selectedOption.value }
    });
  };

  const dropDownMultiChange = (field, selectedOption) => {
    const selectedList = [];
    if (selectedOption) {
      selectedOption.forEach(element => {
        selectedList.push(element.value);
      });
    }
    setLocalChange({
      ...clientProfile,
      data: { ...clientProfile.currentClientProfile, [field]: selectedList }
    });
  };

  const deliveryAddressHandler = () => {
    const currentVal = !useDeliveryAdd;
    let deliveryAddress = {
      billingStreetAddress: '',
      billingSuburb: '',
      billingState: '',
      billingPostCode: ''
    };
    if (currentVal) {
      deliveryAddress = {
        billingStreetAddress: clientProfile.currentClientProfile.deliveryStreetAddress,
        billingSuburb: clientProfile.currentClientProfile.deliverySuburb,
        billingState: clientProfile.currentClientProfile.deliveryState,
        billingPostCode: clientProfile.currentClientProfile.deliveryPostCode
      };
    }
    setLocalChange({
      ...clientProfile,
      data: { ...clientProfile.currentClientProfile, ...deliveryAddress }
    });
    setUseDeliveryAdd(currentVal);
  };

  const onClientSelectionHandler = (userId, value) => {
    let currentUsers = currentClientProfile.clientUsers || [];
    let errorList = clientProfile.currentClientProfile.errorList || [];
    const objIndex = currentUsers.findIndex(obj => obj.userId == userId);
    currentUsers[objIndex].isActive = value;

    if (currentUsers.length > 0) {
      errorList = [];
    }

    setLocalChange({
      ...clientProfile,
      data: { ...clientProfile.currentClientProfile, clientUsers: currentUsers, errorList: errorList }
    });
  };

  // const clientTypeOptions = () => {
  //     const options = [];

  //     currentClientProfile.clientTypes.forEach(element => {
  //         options.push()
  //     });

  // }

  const handleCheckBoxInput = e => {
    let isChecked;
    if (e.target.value === 'false') {
      isChecked = false;
    } else {
      isChecked = true;
    }
    setLocalChange({
      ...clientProfile,
      data: { ...clientProfile.currentClientProfile, [e.target.name]: !isChecked }
    });
  };

  const setErrorMessage = err => {
    let errorlist = clientProfile.currentClientProfile.errorList || [];
    if (err) {
      errorlist.push(err);
    } else {
      errorlist = [];
    }
    setLocalChange({
      ...clientProfile,
      data: { ...clientProfile.currentClientProfile, errorList: errorlist }
    });
  };

  const handleSubmit = e => {
    if (usersPermission || dcPermission) {
      dispatch(saveClientProfile(clientProfile.currentClientProfile, !IsEdit, clientprofileid));
    } else {
      if (clientProfile.currentClientProfile) {
        const selectedUsers =
          (clientProfile.currentClientProfile.clientUsers &&
            clientProfile.currentClientProfile.clientUsers.filter(x => x.isActive === true)) ||
          [];
        const userIds = [];
        if (selectedUsers.length > 0) {
          selectedUsers.forEach(element => {
            userIds.push(element.userId);
          });
          clientProfile.currentClientProfile.userIds = userIds;
          dispatch(saveClientProfile(clientProfile.currentClientProfile, !IsEdit, clientprofileid));
        } else {
          setErrorMessage('You need to select atleast one user for this client');
        }
      }
    }
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  let displayErrorList = clientProfile.errorList && clientProfile.errorList.length > 0 ? clientProfile.errorList : [];

  if (clientProfile.currentClientProfile.errorList && clientProfile.currentClientProfile.errorList.length > 0) {
    displayErrorList = clientProfile.currentClientProfile.errorList;
  }

  useEffect(() => {
    setSelectedUserType(
      permissions.filter(item => {
        return item === 'distributionmanager_view';
      })
    );
  }, [permissions]);

  return (
    <React.Fragment>
      {clientProfile.redirect ? <Redirect to={backUrl} /> : ''}
      {clientProfile.isLoading && <LoadingSpinner />}
      {clientProfile.showMessage && <Notification notificationType={notificationType} message={clientProfile.message} />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardTitle>
                  <Row className="buttonrow">
                    <Col md="8">{heading}</Col>
                    <Col md="4">
                      <Nav tabs className="custom-tab nav-justified customTab">
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: currentActiveTab === 'general'
                            })}
                            onClick={() => {
                              setCurrentActiveTab('general');
                            }}
                          >
                            <span className="d-none d-sm-block">
                              <FontAwesomeIcon icon={faHome} /> <span className="pd-left">Basic</span>
                            </span>
                            <span className="d-block d-sm-none">
                              <FontAwesomeIcon icon={faHome} />
                            </span>
                          </NavLink>
                        </NavItem>
                        {usersPermission || dcPermission ? (
                          <div></div>
                        ) : (
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: currentActiveTab === 'users'
                              })}
                              onClick={() => {
                                setCurrentActiveTab('users');
                              }}
                              disabled={selectedUserType?.length > 0 ? true : false}
                            >
                              <span className="d-none d-sm-block">
                                <FontAwesomeIcon icon={faSitemap} />
                                <span className="pd-left">Users</span>
                              </span>
                              <span className="d-block d-sm-none">
                                <FontAwesomeIcon icon={faSitemap} />
                              </span>
                            </NavLink>
                          </NavItem>
                        )}
                      </Nav>
                    </Col>
                  </Row>
                </CardTitle>
                <CardBody>
                  <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                    {displayErrorList.length > 0 && <ErrorListing errorList={displayErrorList} />}
                    <TabContent activeTab={currentActiveTab} className="p-3 text-muted">
                      <TabPane tabId="general">
                        <Row>
                          <Col lg={12}>
                            <CardSubtitle>Client basic info</CardSubtitle>

                            <CardBody>
                              <Row>
                                <Col md="4">
                                  <div className="mb-3">
                                    <CustomAvField
                                      name="clientCode"
                                      label="Client Code"
                                      className="form-control"
                                      placeholder="Client Code"
                                      value={currentClientProfile.clientCode}
                                      onChange={handleInput}
                                      disabled={true}
                                      type="text"
                                      maxLength="20"
                                      validate={{
                                        required: { value: true, errorMessage: 'Please enter a valid client code' }
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div className="mb-3">
                                    <CustomAvField
                                      name="clientName"
                                      label="Client Name"
                                      className="form-control"
                                      placeholder="Client Name"
                                      value={currentClientProfile.clientName}
                                      onChange={handleInput}
                                      type="text"
                                      maxLength="50"
                                      validate={{
                                        required: { value: true, errorMessage: 'Please enter a valid client name' }
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div className="mb-3">
                                    <Dropdown
                                      placeholder="Select Client Type"
                                      label="Client Type"
                                      name="clientTypeId"
                                      options={currentClientProfile.clientTypes}
                                      isMulti={false}
                                      value={currentClientProfile.clientTypes.find(x => x.value === currentClientProfile.clientTypeId)}
                                      onChange={val => dropDownChange('clientTypeId', val)}
                                      validate={{
                                        required: { value: true, errorMessage: 'Please select a client type' }
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md="2">
                                  <div className="mb-3">
                                    <CustomAvField
                                      name="abn"
                                      label="ABN"
                                      className="form-control"
                                      placeholder="ABN"
                                      value={currentClientProfile.abn}
                                      onChange={handleInput}
                                      type="text"
                                      maxLength="20"
                                      validate={{
                                        required: { value: true, errorMessage: 'Please enter a valid ABN' }
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md="2">
                                  <div className="mb-3">
                                    <CustomAvField
                                      name="debtorCode"
                                      label="Debtor Code"
                                      className="form-control"
                                      placeholder="Debtor Code"
                                      value={currentClientProfile.debtorCode}
                                      onChange={handleInput}
                                      type="text"
                                      maxLength="20"
                                      validate={{
                                        required: { value: false, errorMessage: '' }
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div className="mb-3">
                                    <CustomAvField
                                      name="invoiceInstruction"
                                      label="Invoice Instruction"
                                      className="form-control"
                                      placeholder="Invoice Instruction"
                                      value={currentClientProfile.invoiceInstruction}
                                      onChange={handleInput}
                                      type="textarea"
                                      maxLength="300"
                                      validate={{
                                        required: { value: false, errorMessage: '' }
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col sm="4" className="m-auto">
                                  <div className="mb-3">
                                    <AvGroup check>
                                      <AvInput
                                        type="checkbox"
                                        name="isActive"
                                        label="Is Active"
                                        onChange={handleCheckBoxInput}
                                        checked={currentClientProfile.isActive}
                                        value={currentClientProfile.isActive}
                                      />
                                      <Label check for="checkbox">
                                        {' '}
                                        Is Active
                                      </Label>
                                    </AvGroup>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>

                            <CardSubtitle>Contact Info</CardSubtitle>

                            <CardBody>
                              <Row>
                                <Col md="4">
                                  <div className="mb-3">
                                    <CustomAvField
                                      name="primaryContactPerson"
                                      label="Primary Contact Person"
                                      className="form-control"
                                      placeholder="Primary Contact Person"
                                      value={currentClientProfile.primaryContactPerson}
                                      onChange={handleInput}
                                      type="text"
                                      maxLength="50"
                                      validate={{
                                        required: { value: false, errorMessage: '' }
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="mb-3">
                                    <CustomAvField
                                      name="primaryContactEmail"
                                      label="Primary Contact's Email"
                                      className="form-control"
                                      placeholder="Primary Contact Email"
                                      value={currentClientProfile.primaryContactEmail}
                                      onChange={handleInput}
                                      maxLength="50"
                                      type="email"
                                      validate={{
                                        required: { value: currentClientProfile.isOnline, errorMessage: 'Please enter an email' },
                                        pattern: {
                                          value: '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$',
                                          errorMessage: 'Please enter a valid email address'
                                        }
                                      }}
                                    />
                                  </div>
                                </Col>

                                <Col md="2" className="m-auto">
                                  <AvInput
                                    type="checkbox"
                                    name="isOnline"
                                    label="Is Online"
                                    onChange={handleCheckBoxInput}
                                    checked={currentClientProfile.isOnline}
                                    value={currentClientProfile.isOnline}
                                  />
                                  <Label check for="checkbox">
                                    {' '}
                                    Is Online
                                  </Label>
                                </Col>
                                <Col md="4">
                                  <div className="mb-3">
                                    <CustomAvField
                                      name="primaryContactPhone"
                                      label="Primary Contact's Phone"
                                      className="form-control"
                                      placeholder="Primary Contact Phone"
                                      value={currentClientProfile.primaryContactPhone}
                                      onChange={handleInput}
                                      type="text"
                                      maxLength="20"
                                      validate={{
                                        required: { value: false, errorMessage: '' }
                                      }}
                                    />
                                  </div>
                                </Col>

                                {currentClientProfile.isOnline && (
                                  <>
                                    {currentClientProfile.isFirstOnline && (
                                      <>
                                        <Col md="3">
                                          <div className="mb-3">
                                            <CustomAvField
                                              name="password"
                                              label="Password"
                                              className="form-control"
                                              placeholder="Enter password"
                                              type="password"
                                              value={currentClientProfile.password}
                                              onChange={handleInput}
                                              validate={{
                                                required: { value: true, errorMessage: 'Please enter a password' },
                                                pattern: {
                                                  value: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
                                                  errorMessage: 'Password is not complex enough.'
                                                }
                                              }}
                                            />
                                          </div>
                                        </Col>
                                      </>
                                    )}
                                  </>
                                )}
                              </Row>
                            </CardBody>

                            <CardSubtitle>Delivery info</CardSubtitle>

                            <CardBody>
                              <Row>
                                <Col md="4">
                                  <div className="mb-3">
                                    <CustomAvField
                                      name="deliveryStreetAddress"
                                      label="Delivery Street Address"
                                      className="form-control"
                                      placeholder="Delivery Street Address"
                                      value={currentClientProfile.deliveryStreetAddress}
                                      onChange={handleInput}
                                      type="text"
                                      maxLength="100"
                                      validate={{
                                        required: { value: false, errorMessage: '' }
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md="2">
                                  <div className="mb-3">
                                    <CustomAvField
                                      name="deliverySuburb"
                                      label="Delivery Suburb"
                                      className="form-control"
                                      placeholder="Delivery Suburb"
                                      value={currentClientProfile.deliverySuburb}
                                      onChange={handleInput}
                                      type="text"
                                      maxLength="50"
                                      validate={{
                                        required: { value: false, errorMessage: '' }
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="mb-3">
                                    <Dropdown
                                      placeholder="Select delivery state"
                                      label="Delivery State"
                                      name="deliveryState"
                                      options={STATES}
                                      isMulti={false}
                                      value={STATES.find(x => x.value === currentClientProfile.deliveryState)}
                                      onChange={val => dropDownChange('deliveryState', val)}
                                      validate={{
                                        required: { value: true, errorMessage: 'Please select a delivery state' }
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md="2">
                                  <div className="mb-3">
                                    <CustomAvField
                                      name="deliveryPostCode"
                                      label="Delivery Postcode"
                                      className="form-control"
                                      placeholder="Delivery Postcode"
                                      value={currentClientProfile.deliveryPostCode}
                                      onChange={handleInput}
                                      type="text"
                                      maxLength="20"
                                      validate={{
                                        required: { value: false, errorMessage: '' }
                                      }}
                                    />
                                  </div>
                                </Col>

                                <Col md="4">
                                  <div className="mb-3">
                                    <CustomInput
                                      name="deliveryCharge"
                                      label="Delivery Charge"
                                      className="form-control"
                                      placeholder="Delivery Charge"
                                      value={currentClientProfile.deliveryCharge}
                                      onChange={handleInput}
                                      step=".01"
                                      iconValue="$"
                                      min="0"
                                      type="number"
                                      validate={{
                                        required: { value: true, errorMessage: 'Please enter a valid delivery charge' }
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md="5">
                                  <div className="mb-3">
                                    <Dropdown
                                      placeholder="Select Delivery Days"
                                      label="Delivery Days"
                                      name="deliveryDays"
                                      options={currentClientProfile.weekdays}
                                      isMulti={true}
                                      value={currentClientProfile.weekdays.filter(x => currentClientProfile.deliveryDays.includes(x.value))}
                                      onChange={val => dropDownMultiChange('deliveryDays', val)}
                                      validate={{
                                        required: { value: false, errorMessage: 'Please select delivery day(s)' }
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>

                            <CardSubtitle>Billing info</CardSubtitle>

                            <CardBody>
                              <Row>
                                <Col md="12">
                                  <AvGroup check>
                                    <div className="mb-3">
                                      <AvInput
                                        type="checkbox"
                                        name="useDeliveryAdd"
                                        label="Use the same address as the delivery address"
                                        onChange={deliveryAddressHandler}
                                        value={useDeliveryAdd}
                                      />
                                      <Label check for="checkbox">
                                        {' '}
                                        Use the same address as the delivery address
                                      </Label>
                                    </div>
                                  </AvGroup>
                                </Col>
                                <Col md="4">
                                  <div className="mb-3">
                                    <CustomAvField
                                      name="billingStreetAddress"
                                      label="Billing Street Address"
                                      className="form-control"
                                      placeholder="Billing Street Address"
                                      value={currentClientProfile.billingStreetAddress}
                                      onChange={handleInput}
                                      type="text"
                                      maxLength="100"
                                      validate={{
                                        required: { value: false, errorMessage: '' }
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md="2">
                                  <div className="mb-3">
                                    <CustomAvField
                                      name="billingSuburb"
                                      label="Billing Suburb"
                                      className="form-control"
                                      placeholder="Billing Suburb"
                                      value={currentClientProfile.billingSuburb}
                                      onChange={handleInput}
                                      type="text"
                                      maxLength="50"
                                      validate={{
                                        required: { value: false, errorMessage: '' }
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="mb-3">
                                    <Dropdown
                                      placeholder="Select billing state"
                                      label="Billing State"
                                      name="billingState"
                                      options={STATES}
                                      isMulti={false}
                                      value={STATES.find(x => x.value === currentClientProfile.billingState)}
                                      onChange={val => dropDownChange('billingState', val)}
                                      validate={{
                                        required: { value: false, errorMessage: 'Please select a billing state' }
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md="2">
                                  <div className="mb-3">
                                    <CustomAvField
                                      name="billingPostCode"
                                      label="Billing Postcode"
                                      className="form-control"
                                      placeholder="Billing Postcode"
                                      value={currentClientProfile.billingPostCode}
                                      onChange={handleInput}
                                      type="text"
                                      maxLength="20"
                                      validate={{
                                        required: { value: false, errorMessage: '' }
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Col>
                        </Row>
                      </TabPane>

                      {
                        <TabPane tabId="users">
                          <Row>
                            <Col sm="12">
                              <ManageClientUsers currentClient={currentClientProfile} onClientSelectionHandler={onClientSelectionHandler} />
                            </Col>
                          </Row>
                        </TabPane>
                      }
                    </TabContent>

                    <div className="d-flex flex-wrap gap-2">
                      {showSaveOption && (
                        <Button
                          type="submit"
                          color="primary"
                          className="btn mb-3"
                          disabled={clientProfile.isLoading || (clientProfile.showMessage && !clientProfile.isError)}
                        >
                          SAVE
                        </Button>
                      )}
                      <Button onClick={cancelHandler} color="secondary" className="mb-3 ">
                        CANCEL
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageClientProfile;
