

export const hasPermission = (permissionList, pageAccess, pageActions) => {
    let hasAccess = false;
    if (pageAccess === "general") {
      return true;
    }
    if (permissionList && pageAccess && pageActions) {
      pageActions.forEach(action => {
        if (permissionList.includes(`${pageAccess}_${action}`)) {
          hasAccess = true
        }
      });
    }
  
    return hasAccess;
  }

  export const hasPermissionToAction = (permissionList, pageAccess, pageAction) => {
    let hasAccess = false;
    hasAccess = permissionList.includes(`${pageAccess}_${pageAction}`);
    return hasAccess;
  }


