import React from 'react';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper, faRedo, faShareSquare, faPrint, faTrashAlt, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import './style.scss';
import moment from 'moment';

const RequisitionTable = ({
  paginatedData,
  editHandler,
  repeatHandler,
  dispatchHandler,
  printHandler,
  showPrint,
  deleteHandler,
  showDelete,
  sortConfig,
  onSort
}) => {
  const getSortIcon = key => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? faSortUp : faSortDown;
    }
    return faSort;
  };

  return (
    <React.Fragment>
      <div>
        <Table className="table" responsive>
          <thead>
            <tr>
              <th onClick={() => onSort('requisitionref')}>
                Requisition No <FontAwesomeIcon icon={getSortIcon('requisitionref')} />
              </th>
              <th onClick={() => onSort('changeddate')}>
                Date <FontAwesomeIcon icon={getSortIcon('changeddate')} />
              </th>
              <th>Client</th>
              <th>Department</th>
              <th onClick={() => onSort('expectedDeliveryDate')}>
                Expected Delivery Date <FontAwesomeIcon icon={getSortIcon('expectedDeliveryDate')} />
              </th>
              <th>Order By</th>
              <th>Status</th>
              <th>View</th>
              {showPrint && <th>Repeat</th>}
              <th>Dispatch</th>
              {showDelete && <th>Delete</th>}
              {showPrint && <th>Print</th>}
            </tr>
          </thead>
          <tbody>
            {paginatedData.map(n => {
              return (
                <tr key={n.requisitionId}>
                  <td>{n.requisitionRef || ''}</td>
                  <td>{moment(new Date(n.requisitionDate)).format('DD-MMM-YYYY') || ''}</td>
                  <td>{n.clientProfileName || ''}</td>
                  <td>{n.departmentName || ''}</td>
                  <td>{moment(new Date(n.expectedDeliveryDate)).format('DD-MMM-YYYY') || ''}</td>
                  <td>{n.createdBy || ''}</td>
                  <td>{n.isDispatched ? 'Dispatched' : 'New'}</td>
                  {
                    <td>
                      <button className="btn tableIcons" onClick={() => editHandler(n.requisitionId)}>
                        {' '}
                        <FontAwesomeIcon icon={faNewspaper} />
                      </button>
                    </td>
                  }
                  {showPrint && (
                    <td>
                      <button className="btn tableIcons" onClick={() => repeatHandler(n.requisitionId)}>
                        {' '}
                        <FontAwesomeIcon icon={faRedo} />
                      </button>
                    </td>
                  )}
                  <td>
                    {n.isDispatched ? (
                      ''
                    ) : (
                      <button className="btn tableIcons" onClick={() => dispatchHandler(n.requisitionId)}>
                        {' '}
                        <FontAwesomeIcon icon={faShareSquare} />
                      </button>
                    )}
                  </td>
                  {showDelete && (
                    <td>
                      <button className="btn tableIcons" onClick={() => deleteHandler(n.requisitionId)}>
                        {' '}
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </td>
                  )}
                  {showPrint && (
                    <td>
                      <button className="btn tableIcons" onClick={() => printHandler(n.requisitionId)}>
                        {' '}
                        <FontAwesomeIcon icon={faPrint} />
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default RequisitionTable;
