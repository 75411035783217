import { addDaysToDate } from '../../utils';

export const initialRptCompanyListingState = {
  isLoading: false,
  message: '',
  isError: false,
  showMessage: false,
  redirect: false,
  rptList: [],
  filterData: {
  },
  filterOptions: {
    filterBy: [
      {
        displayName: 'Status',
        name: 'isactive',
        value: 'all',
        valueName: 'All'
      }
    ],
    sortBy: {
      name: 'companyname',
      order: 'asc'
    }
  }
};

export const initialRptLocationListingState = {
  isLoading: false,
  message: '',
  isError: false,
  showMessage: false,
  redirect: false,
  rptList: [],
  filterOptions: {
    filterBy: [
      {
        displayName: 'Status',
        name: 'isactive',
        value: 'all',
        valueName: 'All'
      }
    ],
    sortBy: {
      name: 'locationname',
      order: 'asc'
    }
  }
};

export const initialRptClientProfileListingState = {
  isLoading: false,
  message: '',
  isError: false,
  showMessage: false,
  redirect: false,
  rptList: [],
  filterData: {
    clientTypes: []
  },
  filterOptions: {
    filterBy: [
      {
        displayName: 'Status',
        name: 'isactive',
        value: 'all',
        valueName: 'All'
      },
      {
        displayName: 'Client Type',
        name: 'clienttypeid',
        value: 'all',
        valueName: 'All'
      }
    ],
    sortBy: {
      name: 'clientcode',
      order: 'asc'
    }
  }
};

export const initialRptCostCenterListingState = {
  isLoading: false,
  message: '',
  isError: false,
  showMessage: false,
  redirect: false,
  rptList: [],
  filterData: {
    clientProfiles: []
  },
  filterOptions: {
    filterBy: [
      {
        displayName: 'Status',
        name: 'isactive',
        value: 'all',
        valueName: 'All'
      },
      {
        displayName: 'Client Profile',
        name: 'clientprofileid',
        value: 'all',
        valueName: 'All'
      }
    ],
    sortBy: {
      name: 'costcentercode',
      order: 'asc'
    }
  }
};

export const initialRptDepartmentListingState = {
  isLoading: false,
  message: '',
  isError: false,
  showMessage: false,
  redirect: false,
  rptList: [],
  filterData: {
    clientProfiles: [],
    costCenters: []
  },
  
  filterOptions: {
    filterBy: [
      {
        displayName: 'Status',
        name: 'isactive',
        value: 'all',
        valueName: 'All'
      },
      {
        displayName: 'Client Profile',
        name: 'clientprofileid',
        value: 'all',
        valueName: 'All'
      },
      {
        displayName: 'Cost Center',
        name: 'costcenterid',
        value: 'all',
        valueName: 'All'
      }
    ],
 
    sortBy: {
      name: 'departmentcode',
      order: 'asc'
    }
  }
};

export const initialRptProductListingState = {
  isLoading: false,
  message: '',
  isError: false,
  showMessage: false,
  redirect: false,
  rptList: [],
  filterData: {
    productTypes: []
  },
  filterOptions: {
    filterBy: [
      {
        displayName: 'Status',
        name: 'isactive',
        value: 'all',
        valueName: 'All'
      },
      {
        displayName: 'Product Type',
        name: 'producttypeid',
        value: 'all',
        valueName: 'All'
      }
    ],
    sortBy: {
      name: 'productcode',
      order: 'asc'
    }
  }
};

export const initialRptProductTypeListingState = {
  isLoading: false,
  message: '',
  isError: false,
  showMessage: false,
  redirect: false,
  rptList: [],
  filterOptions: {
    filterBy: [
      {
        displayName: 'Status',
        name: 'isactive',
        value: 'all',
        valueName: 'All'
      }
    ],
    sortBy: {
      name: 'producttypecode',
      order: 'asc'
    }
  }
};

export const initialRptDeptSummaryState = {
  isLoading: false,
  message: '',
  isError: false,
  showMessage: false,
  redirect: false,
  rptList: [],
  rptListInternal: [],
  filterData: {
    clientProfiles: []
  },
  csvData: [],
  filterOptions: {
    filterBy: [
      {
        displayName: 'Client Profile',
        name: 'clientprofileid',
        value: 'all',
        valueName: 'All'
      },
      {
        name: 'fromdate',
        value: new Date(addDaysToDate(new Date(), -30)).toDateString()
      },
      {
        name: 'todate',
        value: new Date().toDateString()
      }
    ],
    filterByProd: [

      {
        displayName: 'Product',
        name: 'productid',
        value: 'all',
        valueName: 'All'
      },
      {
        name: 'fromdate',
        value: new Date(addDaysToDate(new Date(), -30)).toDateString()
      },
      {
        name: 'todate',
        value: new Date().toDateString()
      }
    ],
    sortBy: {
      name: 'clientname',
      order: 'asc'
    }
  }
};

