import { GROUP_GET_ALL_DATA, GROUP_GET_DATA, GROUP_ERROR, GROUP_IS_LOADING, GROUP_SAVE_DATA, GROUP_ON_CHANGE_DATA, GROUP_CLEAN_UP, GROUP_USERS_CHANGE, GROUP_PERMISSIONS_CHANGE, GROUP_USERS_SAVE, GROUP_PERMISSIONS_SAVE } from '../../constants/index';
import { apiHelper } from '../../utils/index';
import Cookies from 'js-cookie';
import { setErrorThunk, setLoadingStatus, setGetResults, setDescribeData, setSaveData, setOnChangeData, setOnCleanUpData } from './actionHelper';

const API_URL = '/group'

export const getAllGroups = () => dispatch => {
    dispatch(_getAllGroups());
};

const _getAllGroups = () =>
    apiHelper({
        url: `${API_URL}`,
        method: 'GET',
        onLoad: status => setLoadingStatus(GROUP_IS_LOADING, status),
        onSuccess: result => setGetResults(GROUP_GET_ALL_DATA, result),
        onFailure: err => setErrorThunk(GROUP_ERROR, true, err),
        accessToken: Cookies.get('accessToken'),
    });

export const describeGroup = (id) => dispatch => {
    dispatch(_getGroup(id));
};

const _getGroup = (id) =>
    apiHelper({
        url: `${API_URL}/${id}`,
        method: 'GET',
        data: {
            id: id
        },
        onLoad: status => setLoadingStatus(GROUP_IS_LOADING, status),
        onSuccess: result => setDescribeData(GROUP_GET_DATA, result),
        onFailure: err => setErrorThunk(GROUP_ERROR, true, err),
        accessToken: Cookies.get('accessToken'),
    });

export const saveGroup = (saveData, isNew, id = null) => dispatch => {
    let MethodType = 'POST';
    if (!isNew) {
        MethodType = 'PUT'
    }

    let apiUrl = id ? `${API_URL}/${id}` : API_URL;

    dispatch(_saveGroup(saveData, MethodType, apiUrl));
}

const _saveGroup = (data, MethodType, apiUrl) =>
    apiHelper({
        url: apiUrl,
        method: MethodType,
        onSuccess: result => setSaveData(GROUP_SAVE_DATA, true, result),
        onFailure: err => setErrorThunk(GROUP_ERROR, true, err),
        onLoad: status => setLoadingStatus(GROUP_IS_LOADING, status),
        data,
        accessToken: Cookies.get('accessToken'),
    });


export const setOnChange = retData => dispatch => {
    dispatch(setOnChangeData(GROUP_ON_CHANGE_DATA, retData));
};

export const setOnCleanUp = retData => dispatch => {
    dispatch(setOnCleanUpData(GROUP_CLEAN_UP, retData));
};



// User groups

export const getGroupUsers = (id) => dispatch => {
    dispatch(_getGroupUsers(id));
};

const _getGroupUsers = (id) =>
    apiHelper({
        url: `${API_URL}/users/${id}`,
        method: 'GET',
        data: {
            id: id
        },
        onLoad: status => setLoadingStatus(GROUP_IS_LOADING, status),
        onSuccess: result => setDescribeData(GROUP_GET_DATA, result),
        onFailure: err => setErrorThunk(GROUP_ERROR, true, err),
        accessToken: Cookies.get('accessToken'),
    });

export const setOnGroupUserChange = retData => dispatch => {
    dispatch(setOnGroupUserChangeData(GROUP_USERS_CHANGE, retData));
};

export const setOnGroupUserChangeData = (RTYPE, retData) => ({
    type: RTYPE,
    payload: retData
});

export const saveGroupUsers = (saveData, id = null) => dispatch => {

    let apiUrl = `${API_URL}/users/${id}`;

    dispatch(_saveGroupUsers(saveData, apiUrl));
}

const _saveGroupUsers = (data, apiUrl) =>
    apiHelper({
        url: apiUrl,
        method: 'PUT',
        onSuccess: result => setSaveData(GROUP_USERS_SAVE, true, result),
        onFailure: err => setErrorThunk(GROUP_ERROR, true, err),
        onLoad: status => setLoadingStatus(GROUP_IS_LOADING, status),
        data,
        accessToken: Cookies.get('accessToken'),
    });


// Permission groups
export const getGroupPermissions = (id) => dispatch => {
    dispatch(_getGroupPermissions(id));
};

const _getGroupPermissions = (id) =>
    apiHelper({
        url: `${API_URL}/permissions/${id}`,
        method: 'GET',
        data: {
            id: id
        },
        onLoad: status => setLoadingStatus(GROUP_IS_LOADING, status),
        onSuccess: result => setDescribeData(GROUP_GET_DATA, result),
        onFailure: err => setErrorThunk(GROUP_ERROR, true, err),
        accessToken: Cookies.get('accessToken'),
    });

export const setOnGroupPermissionChange = retData => dispatch => {
    dispatch(setOnGroupPermissionChangeData(GROUP_PERMISSIONS_CHANGE, retData));
};

export const setOnGroupPermissionChangeData = (RTYPE, retData) => ({
    type: RTYPE,
    payload: retData
});

export const saveGroupPermissions = (saveData, id = null) => dispatch => {

    let apiUrl = `${API_URL}/permissions/${id}`;

    dispatch(_saveGroupPermissions(saveData, apiUrl));
}

const _saveGroupPermissions = (data, apiUrl) =>
    apiHelper({
        url: apiUrl,
        method: 'PUT',
        onSuccess: result => setSaveData(GROUP_PERMISSIONS_SAVE, true, result),
        onFailure: err => setErrorThunk(GROUP_ERROR, true, err),
        onLoad: status => setLoadingStatus(GROUP_IS_LOADING, status),
        data,
        accessToken: Cookies.get('accessToken'),
    });