import React, { useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { changePassword, setOnCleanUp, updateProfileDetails, userDetails } from '../../../store/actions/user';
import { AvForm } from 'availity-reactstrap-validation';
import { initialUserState } from '../../../store/reducers/initialState';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import CustomAvField from '../../CustomControls/CustomAvField';
import { useState } from 'react';

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_VALUE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const EditProfile = props => {
  let history = useHistory();
  const backUrl = '/';
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  const initialState = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  };

  const [passwordState, localDispatch] = useReducer(reducer, initialState);

  const notificationType = user.isError ? 'error' : 'success';

  let heading = 'Edit Profile';

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Edit Profile', path: '/edit-profile' }
  ];

  const setLocalCleanUpData = cleanUpData => {
    dispatch(setOnCleanUp(cleanUpData));
  };

  //clean up
  useEffect(() => {
    return () => {
      setLocalCleanUpData({
        data: { ...user.currentUser, ...initialUserState.currentUser }
      });
    };
  }, []);
  useEffect(() => {
    dispatch(userDetails());
  }, [dispatch]);
  const [updatedData, setUpdatedData] = useState({
    phoneNumber: '',
    password: '',
    deliveryAddress: ''
  });
  const handleInput = (fieldName, value) => {
    setUpdatedData(prevState => ({
      ...prevState,
      [fieldName]: value
    }));

    // localDispatch({ type: 'CHANGE_VALUE', payload: { [fieldName]: value } });
  };
  const handleSubmit = e => {
    // if (passwordState.currentPassword && passwordState.newPassword && passwordState.confirmPassword) {
    //   dispatch(changePassword(passwordState.currentPassword, passwordState.newPassword, passwordState.confirmPassword));
    // }
    dispatch(updateProfileDetails(updatedData));
    setUpdatedData({
      phoneNumber: '',
      password: '',
      deliveryAddress: ''
    });
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  return (
    <React.Fragment>
      {/* {user.redirect ? <Redirect to={backUrl} /> : ''} */}
      {user.isLoading && <LoadingSpinner />}
      {user.showMessage && <Notification notificationType={notificationType} message={user.message} />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardTitle>Edit Profile</CardTitle>
                <CardBody>
                  <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                    <Row>
                      <Col md="5">
                        <div className="mb-1">
                          <CustomAvField
                            name="email"
                            label="Email"
                            readOnly="true"
                            className="form-control"
                            placeholder="Enter email"
                            value={user?.userDetails?.primaryContactEmail}
                            disabled={true}
                            type="email"
                          />
                        </div>
                        <div className="mb-1">
                          <CustomAvField
                            name="phoneNumber"
                            label="Phone"
                            className="form-control"
                            placeholder="Phone"
                            value={user?.userDetails?.primaryContactPhone}
                            onChange={e => {
                              handleInput('phoneNumber', e.target.value);
                            }}
                            type="text"
                            maxLength="20"
                            validate={{
                              required: { value: false, errorMessage: 'Please enter a phone number' }
                            }}
                          />
                        </div>
                        <div className="mb-1">
                          <CustomAvField
                            name="deliveryStreetAddress"
                            label="Delivery Address"
                            className="form-control"
                            placeholder="Delivery Address"
                            value={user?.userDetails?.deliveryStreetAddress}
                            onChange={e => {
                              handleInput('deliveryAddress', e.target.value);
                            }}
                            type="text"
                            maxLength="100"
                            validate={{
                              required: { value: false, errorMessage: 'Please enter a delivery address' }
                            }}
                          />
                        </div>
                      </Col>
                      <Col sm="5">
                        <div className="mb-1">
                          <CustomAvField
                            name="newPassword"
                            label="New password"
                            className="form-control"
                            placeholder="New password"
                            type="password"
                            value={passwordState.newPassword}
                            onChange={e => {
                              handleInput('password', e.target.value);
                            }}
                            validate={{
                              required: { value: false, errorMessage: 'Please enter a new password' },
                              pattern: {
                                value: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
                                errorMessage: 'Password is not complex enough.'
                              }
                            }}
                          />
                        </div>
                        {/* <div className="mb-1">
                          <CustomAvField
                            name="confirmPassword"
                            label="Confirm password"
                            className="form-control"
                            placeholder="Confirm password"
                            type="password"
                            value={passwordState.confirmPassword}
                            onChange={handleInput}
                            validate={{
                              required: { value: false, errorMessage: 'Please enter a confirm password' },
                              match: { value: 'newPassword', errorMessage: 'Passowords do not match' }
                            }}
                          />
                        </div> */}
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2 mt-3">
                      <Button
                        type="submit"
                        color="primary"
                        className="btn "
                        disabled={updatedData.deliveryAddress === '' && updatedData.password === '' && updatedData.phoneNumber === ''}
                      >
                        UPDATE
                      </Button>
                      <Button onClick={cancelHandler} color="secondary" className=" ">
                        CANCEL
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditProfile;
