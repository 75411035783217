import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faExpand } from '@fortawesome/free-solid-svg-icons';
import UserInfo from './UserInfo';
import { useDispatch, useSelector } from 'react-redux';
import { getPendingOrders, getSignalRUrl } from '../../store/actions/order';
import { useEffect } from 'react';
import { hasPermissionToAction } from '../../utils/accessUtil';
import * as permissionconst from '../../constants/componentConstants';
import { HubConnectionBuilder } from '@microsoft/signalr';
import Cookies from 'js-cookie';
import axios from 'axios';

const SignalUrl = `${window.location.origin}/customer/SignalRUrl`;

const Header = ({ logout }) => {
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  const toggleSideBar = () => {
    var body = document.body;
    body.classList.toggle('side-menu-collpsed');
    body.classList.toggle('sidebar-enable');
  };
  // useEffect(()=>{
  // fetch('../../../../appsettings.json').then((res)=>console.log(res.json()));

  // })
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const [url, seturl] = useState();
  const pendingOrder = useSelector(state => state.order.count);
  const URL = useSelector(state => state.order.url);
  const toggleFullscreen = () => {
    if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };
  const accessToken = Cookies.get('accessToken');
  const headers = {
    Authorization: `Bearer ${accessToken}`
    // Add other headers if needed
  };
  useEffect(() => {
    if (hasPermissionToAction(permissions, permissionconst._pendingorder, permissionconst._view)) {
      dispatch(getPendingOrders());
    }

    axios
      .get(SignalUrl, {
        headers: headers
      })
      .then(res => {
        const URL = res.data;
        // console.log(URL.chatHubUrl);
        const url = URL.chatHubUrl;

        const socketUrl = `${url}/chatHub`;
        const connection = new HubConnectionBuilder().withUrl(socketUrl).build();

        connection.start().catch(error => console.error(error));
        return () => {
          connection.stop();
        };
      })
      .catch(error => {
        // Handle any errors that occurred during the fetch
        console.error('Error:', error);
      });

    // const hubUrl = `${url}/chatHub`;
    // Create a connection to the SignalR hub

    // Start the connection

    // Handle the "EventReceived" event

    // Cleanup the connection when the component unmounts
  }, []);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              onClick={() => {
                toggleSideBar();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="side-menu-btn"
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
          <div className="d-flex"></div>
          <div className="d-flex">
            {hasPermissionToAction(permissions, permissionconst._allcustomers, permissionconst._view) && (
              <div className="dropdown d-none d-lg-inline-block ms-1 ">
                <button type="button" className="position-relative btn header-item noti-icon " data-toggle="fullscreen">
                  <FontAwesomeIcon icon={faBell} />
                  {pendingOrder > 0 && (
                    <div
                      className="bg-danger position-absolute rounded-circle "
                      style={{ width: '8px', height: '8px', top: '38%', right: '25%' }}
                    ></div>
                  )}
                </button>
              </div>
            )}
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <FontAwesomeIcon icon={faExpand} />
              </button>
            </div>
            <UserInfo logout={logout} />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
