import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useParams, useHistory, Link } from 'react-router-dom';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
// import {useNavigate} from 'react-router-dom'
// import { useHistory } from 'react-router-dom';
import { _clientadmin, _view } from '../../../constants/componentConstants';

import Table from './ProductWiseReport';
import Pagination from '../../CustomControls/Pagination';
import Dropdown from '../../CustomControls/Dropdown';
import {
  getAllOrdersDetails,
  setOnSearchChange,
  setOnConfirmationModal,
  setAllOrderDetails,
  setApproveOrder,
  setOrderDelete,
  getAllOrdersDetailsCSV,
  getAllOrdersDetailsPDF,
  rejectOrdersDetails,
  getAllCustomerDeparmentsWithId,
  setOnChange,
  getAllProductDetails,
  getAllProductsOrdered
} from '../../../store/actions/orderDetail';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _reportprint, _requisition, _delete } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import { addDaysToDate } from '../../../utils';
import SearchBar from '../../CustomControls/SearchBar';
import DatePicker from '../../CustomControls/CustomDatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import CustomConfirmation from '../../CustomControls/ConfirmationModal';
import RejectionModal from '../../CustomControls/RejectionModal';
import './style.scss';
import { getCustomersName } from '../../../store/actions/customerDetail';
import { useRef } from 'react';

const ProductWiseReport = props => {
  const [filterClient, setFilterClient] = useState([{ name: 'productid', value: 'all', label: 'All' }]);
  const status = [
    {
      value: 'all',
      label: 'All'
    },
    {
      value: 'Pending',
      label: 'Pending'
    },
    {
      value: 'Accepted',
      label: 'Accepted'
    },
    {
      value: 'Delivered',
      label: 'Delivered'
    },
    {
      value: 'Rejected',
      label: 'Rejected'
    }
  ];
  const [searchText, setSearchText] = useState('');
  const [searchFromDate, setSearchFromDate] = useState(addDaysToDate(new Date(), -30));
  const [searchToDate, setSearchToDate] = useState(new Date());
  const history = useHistory();
  const dispatch = useDispatch();
  const orders = useSelector(state => state.orderDetail?.allData || []);
  const tableData = useSelector(state => state.orderDetail?.tableData || []);
  const allData = useSelector(state => state.orderDetail?.allData || []);
  const state = useSelector(state => state);

  const order = useSelector(state => state.orderDetail || []);
  // const csvData = useSelector(state => state.orderDetail?.csvData || []);
  const customer = useSelector(state => state.customerDetail?.customerDetailsByName);
  const ui = useSelector(state => state.ui);
  // const allData = useSelector(state => state);
  const permissions = ui.layout.permissions;
  const pageSize = ui.layout.pageSize;
  const currentPageNumber = order?.filterOptions?.pageNumber;
  const[table,settable]=useState()
  const notificationType = orders?.isError ? 'error' : 'success';
  const showPrintOption = hasPermissionToAction(permissions, _requisition, _reportprint);
  const showDeleteOption = hasPermissionToAction(permissions, _requisition, _delete);
  const [showModal, setShowModal] = useState(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [orderID, setOrderID] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [departmentData, setDepartmentData] = useState('all');
  const [statusData, setStatusData] = useState();
  const [stateHandler, setStateHandler] = useState(false);
  // const dropRef = useRef(null);
  const dropdownChange = (field, selectedOption, callSetMethod) => {
    let selectedList = [];
    if (selectedOption.length > 0) {
      selectedList = selectedOption?.map(item => {
        return {
          value: item.value || 'all',
          displayName: 'Product',
          valueName: item.label,
          name: 'productid'
        };
      });
    } else {
      selectedList.push({
        value: 'all',
        displayName: 'Product',
        valueName: 'All',
        name: 'productid'
      });
    }
    callSetMethod(selectedList);
  };
  // selectedUser
  // const [customerData,setCustomerData ] = useState();
  const [reason, setReason] = useState('');
  useEffect(() => {
    const payload = {
      filterBy: [
        { name: 'fromdate', value: new Date(searchFromDate) },
        { name: 'todate', value: searchToDate }
      ],
      includeFilters: true
    };
    dispatch(getAllProductDetails(payload));
  }, [dispatch]);
  // useEffect(()=>{
  //   setLocalChangeOrderDetails()
  // },[order])
  async function approveHandler(id, status) {
    await dispatch(setApproveOrder(id, status));
    setTimeout(() => {
      dispatch(getAllOrdersDetails(currentPageNumber, pageSize, order?.filterOptions?.filterBy, order?.filterOptions?.sortBy));
    }, 2000);
  }

  const onPageChangeHandler = currentPage => {
    setLocalChange({
      ...order,
      data: { ...order?.filterOptions, pageNumber: currentPage }
    });
  };

  const onAddNewHandler = () => {
    history.push('/operation/requisitions/requisition');
  };

  const onEditHandler = (id, name, productId, productName) => {
    // dispatch(setAllOrderDetails(id));
    const payload = {
      filterBy: [
        {
          displayName: 'ClientProfile',
          name: 'clientprofileid',
          value: id,
          valueName: name
        },
        {
          displayName: 'Product',
          name: 'productid',
          value: productId,
          valueName: productName
        },
        { name: 'fromdate', value: new Date(searchFromDate) },
        { name: 'todate', value: searchToDate }
      ]
    };
    dispatch(getAllProductsOrdered(payload));
    history.push(`/customer/product-wise-orders/product-wise-order-info`);
  };

  const onRepeatHandler = id => {
    history.push(`/operation/requisitions/requisition/1/${id}`);
  };

  const onDispatchHandler = id => {
    history.push(`/operation/dispatches/dispatch/1/${id}`);
  };

  const onPrintHandler = id => {
    history.push(`/operation/requisitions/requisition-report/${id}`);
  };
  const dropDownChange = data => {
    setStateHandler(true);
    setDepartmentData('all');
    setLocalChangeOrderDetails({
      ...order,
      data: []
    });
    setSelectedUser(data.value);
    dispatch(getAllCustomerDeparmentsWithId(data.value));
    dispatch(
      getAllOrdersDetails(
        currentPageNumber,
        pageSize,
        order?.filterOptions?.filterBy,
        order?.filterOptions?.sortBy,
        data.value,
        'all',
        statusData
      )
    );
    // setLocalChange({
    //   ...orders,
    //   data: { ...orders.currentClientProfile, [field]: selectedOption.value }
    // });
  };

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Product Wise Listing', path: '/order', isCurrent: true }
  ];

  useEffect(() => {
    return () => {
      let filterListCurrent = order?.filterOptions?.filterBy || [];
      if (filterListCurrent.length > 0) {
        filterListCurrent = filterListCurrent.filter(x => x.name.toLowerCase() !== 'requisitionref');
      }
      setLocalChange({
        ...order,
        data: { ...order?.filterOptions, filterBy: filterListCurrent }
      });
    };
  }, []);

  const handleInput = e => {
    const searchVal = e.target.value ?? '';

    setSearchText(searchVal);
    let filterSearchList = order?.filterOptions?.filterBy || [];

    const objIndex = filterSearchList.findIndex(x => x.name.toLowerCase() === 'requisitionref');

    if (objIndex !== -1) {
      filterSearchList[objIndex].value = searchVal.toLowerCase();
    } else {
      filterSearchList.push({ name: 'requisitionref', value: searchVal.toLowerCase() });
    }
  };
  const handleDateInput = (field, dateVal) => {
    const setDate = dateVal ? new Date(dateVal) : null;
    if (field === 'fromdate') {
      setSearchFromDate(setDate);
    }

    if (field === 'todate') {
      setSearchToDate(setDate);
    }

    let filterSearchList = order?.filterOptions?.filterBy || [];

    const objIndex = filterSearchList.findIndex(x => x.name.toLowerCase() === field);

    if (objIndex !== -1) {
      filterSearchList[objIndex].value = setDate.toDateString();
    } else {
      filterSearchList.push({ name: field, value: setDate.toDateString() });
    }

    setLocalChange({
      ...order,
      data: { ...order?.filterOptions, filterBy: filterSearchList }
    });
  };
  // const navigate=useNavigate()

  const printInvoice = () => {
      history.push('/customer/customer-wise-orders')
  };

  const cancelHandler = () => {
    history.push('/customer/product-wise-orders');
  };

  const setLocalChange = currentState => {
    dispatch(setOnSearchChange(currentState));
  };
  const setLocalChangeOrderDetails = currentState => {
    dispatch(setOnChange(currentState));
  };

  const onSearchHandler = e => {
    const payload = {
      filterBy: [...filterClient, { name: 'fromdate', value: new Date(searchFromDate) }, { name: 'todate', value: searchToDate }],
      includeFilters: false
    };
    dispatch(getAllProductDetails(payload));
    // console.log(state);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {/* {orders?.showMessage && <Notification notificationType={notificationType} message={orders?.message} />} */}
        <Container fluid>
          <Breadcrumbs title="Product Wise Listing " paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="4">
                      {/* <SearchBar onChange={handleInput} placeholder="Search By Order No" value={searchText} /> */}
                      <div className="mb-3">
                        <Dropdown
                          placeholder="All"
                          label="Products"
                          name="products"
                          options={orders?.filterData?.products}
                          isMulti={true}
                          onChange={val => dropdownChange('productid', val, setFilterClient)}
                          value={orders?.filterData?.products?.find(x => x?.value === filterClient?.value)}
                          validate={{
                            required: { value: false, errorMessage: 'Please select a Product' }
                          }}
                        />
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="mb-4">
                        <DatePicker
                          label="From Date"
                          selected={searchFromDate}
                          onChange={date => handleDateInput('fromdate', date)}
                          dateFormat="dd-MMM-yyyy"
                        />
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="mb-4">
                        <DatePicker
                          label="To Date"
                          selected={searchToDate}
                          onChange={date => handleDateInput('todate', date)}
                          dateFormat="dd-MMM-yyyy"
                          minDate={searchToDate}
                        />
                      </div>
                    </Col>
                    <Col sm="4" className="m-auto">
                      <div className="text-sm-start">
                        <Button type="button" className="btn btn-outline-primary" onClick={onSearchHandler}>
                          <FontAwesomeIcon className="standardIcon" icon={faSearch} /> Search
                        </Button>
                      </div>
                    </Col>
                  </Row>
                 
                  <Row className="tablerow">
                    <Col xl="12">
                      <div>
                        {order.isLoading && <LoadingSpinner />}
                        <Pagination
                          data={tableData?.reportData ?? []}
                          pageSize={pageSize}
                          onPageChange={onPageChangeHandler}
                          startingPage={currentPageNumber}
                          apiPaging={true}
                          apiDataLength={orders?.totalRecords ?? 0}
                        >
                          <Table
                            editHandler={onEditHandler}
                            repeatHandler={onRepeatHandler}
                            dispatchHandler={onDispatchHandler}
                            printHandler={onPrintHandler}
                            showPrint={showPrintOption}
                            showDelete={showDeleteOption}
                            approveHandler={approveHandler}
                          />
                        </Pagination>
                      </div>
                    </Col>
                  </Row>
                  <div className="d-print-none">
                    <div className="float-start">
                      {/* <Link to="#" onClick={cancelHandler} className="btn btn-primary w-md button-space">
                            Submit
                          </Link> */}
                      <Link to="#" onClick={printInvoice} className="btn btn-outline-primary">
                        <FontAwesomeIcon className="standardIcon" icon={faPrint} /> Print
                      </Link>
                      <Link to="#" onClick={cancelHandler} className="btn btn-secondary w-md button-space">
                        Back
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProductWiseReport;
