import Cookies from 'js-cookie';
import { apiHelper } from '../../utils';
import { setErrorThunk, setGetResults, setLoadingStatus, setOnConfirmationData, setOnSearchChangeData, setSaveData } from './actionHelper';
import {
  CUSTOMER_DETAIL_IS_LOADING,
  CUSTOMER_DETAIL_ORDER_PLACED,
  CUSTOMER_DETAIL_PRODUCTS_ERROR,
  CUSTOMER_DETAIL_PRODUCTS_SET_DATA,
  CUSTOMER_DETAIL_ON_SEARCH_CHANGE_DATA,
  CUSTOMER_DETAIL_UPDATE_CONFIRMATION,
  CUSTOMER_DETAILS_BY_ID,
  CUSTOMER_DETAIL_BY_NAME
} from '../../constants';

// const API_URL = '/product/all';

export const getAllCustomerProducts =  (currentPageNumber, pageSize, filterBy, sortBy,searchText) => dispatch => {
  dispatch(_getAllCustomerProducts(currentPageNumber, pageSize, filterBy, sortBy,searchText));
};

const _getAllCustomerProducts = (currentPageNumber, pageSize, filterBy, sortBy,searchText) => {
  // const requisitionRefFilter = filterBy?.find(item => item.name === 'requisitionref');
  // const filterByRequisitionRef = requisitionRefFilter?.value ?? '';

  return apiHelper({
    url: `/customer`,
    method: 'POST',
    data: {
      pageNumber: currentPageNumber,
      pageSize: pageSize,
      filterBy: filterBy,
      sortBy: sortBy,
      searchQuery: searchText
    },
    onLoad: status => setLoadingStatus(CUSTOMER_DETAIL_IS_LOADING, status),
    onSuccess: result => setGetResults(CUSTOMER_DETAIL_PRODUCTS_SET_DATA, result),
    onFailure: err => setErrorThunk(CUSTOMER_DETAIL_PRODUCTS_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};
export const setAllProducts = data => dispatch => {
  dispatch(_setAllProducts(data));
};

const _setAllProducts = data =>
  apiHelper({
    url: `/customer/order`,
    method: 'POST',
    data: data,
    onLoad: status => setLoadingStatus(CUSTOMER_DETAIL_IS_LOADING, status),
    onSuccess: result => setSaveData(CUSTOMER_DETAIL_ORDER_PLACED, true, result),
    onFailure: err => setErrorThunk(CUSTOMER_DETAIL_PRODUCTS_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
export const setDeleteCustomer = data => dispatch => {
  dispatch(_setDeleteCustomer(data));
};

const _setDeleteCustomer = data =>
  apiHelper({
    url: `/customer/delete?customerId=${data}`,
    method: 'DELETE',
    onLoad: status => setLoadingStatus(CUSTOMER_DETAIL_IS_LOADING, status),

    onSuccess: result => setSaveData(CUSTOMER_DETAIL_ORDER_PLACED, true, result),
    onFailure: err => setErrorThunk(CUSTOMER_DETAIL_PRODUCTS_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
export const setCustomerDetailsByID = data => dispatch => {
  dispatch(_setCustomerDetailsByID(data));
};

const _setCustomerDetailsByID = data =>
  apiHelper({
    url: `/customer/details/${data}`,
    method: 'GET',
    onLoad: status => setLoadingStatus(CUSTOMER_DETAIL_IS_LOADING, status),
    onSuccess: result => setSaveData(CUSTOMER_DETAILS_BY_ID, result, true),
    onFailure: err => setErrorThunk(CUSTOMER_DETAIL_PRODUCTS_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
export const getCustomersName = () => dispatch => {
  dispatch(_getCustomersName());
};

const _getCustomersName = () =>
  apiHelper({
    url: `/customer/get-customers`,
    method: 'GET',
    onLoad: status => setLoadingStatus(CUSTOMER_DETAIL_IS_LOADING, status),
    onSuccess: result => setSaveData(CUSTOMER_DETAIL_BY_NAME, result, true),
    onFailure: err => setErrorThunk(CUSTOMER_DETAIL_PRODUCTS_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
export const setOnSearchChange = retData => dispatch => {
  dispatch(setOnSearchChangeData(CUSTOMER_DETAIL_ON_SEARCH_CHANGE_DATA, retData));
};
export const setOnConfirmationModal = retData => dispatch => {
  dispatch(setOnConfirmationData(CUSTOMER_DETAIL_UPDATE_CONFIRMATION, retData));
};
