import { EMAIL_IS_LOADING, CUSTOMER_ON_SEARCH_CHANGE_DATA, CUSTOMER_ORDER_PLACED, EMAIL_ERROR, EMAIL_SET_DATA } from '../../constants';
import { initialCustomerState } from './initialState';

const emailReducer = (state = initialCustomerState, action) => {
  const { payload, type } = action;
  switch (type) {
    case EMAIL_ERROR: {
      const { showMessage, errorMessage, errorList } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage,
        errorList: errorList
      };
    }
    case EMAIL_SET_DATA: {
      const { resultData } = payload;

      return {
        ...state,
        emailConfigration: resultData
      };
    }
    case EMAIL_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }
    case CUSTOMER_ON_SEARCH_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        filterOptions: {
          ...state.data,
          ...data
        }
      };
    }
    case CUSTOMER_ORDER_PLACED: {
      //As per require we do not redirect in steam preserve some of the data
      let { showMessage, message, redirect } = payload;

      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message
      };
    }
    default:
      return state;
  }
};

export default emailReducer;
