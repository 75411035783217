

export const STATUSES = [
    {
        label: 'All',
        value: 'all'
    },
    {
        label: 'Active',
        value: 'true'
    },
    {
        label: 'Inactive',
        value: 'false'
    }
];