import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, Col, Row } from 'reactstrap';
import Pagination from '../../CustomControls/Pagination';
import SearchBar from '../../CustomControls/SearchBar';
import { _create, _groups } from '../../../constants/componentConstants';

const ManageClientUsers = props => {
  const [searchText, setSearchText] = useState('');
  const [results, setResults] = useState([]);
  const ui = useSelector(state => state.ui);
  const pageSize = ui.layout.pageSize;
  let currentClient = props.currentClient;

  useEffect(() => {
    if (searchText.length > 0) {
      setResults(
        currentClient.clientUsers.filter(
          x =>
            x.userFullName.toLowerCase().includes(searchText.toLowerCase()) || x.username.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setResults(currentClient.clientUsers);
    }
  }, [searchText, setResults, currentClient.clientUsers]);

  const onPageChangeHandler = currentPage => {};

  const onCheckboxHandler = (e, id) => {
    props.onClientSelectionHandler(id, e.target.checked);
  };

  const handleInput = e => {
    setSearchText(e.target.value);
  };

  const heading = currentClient.clientName ? ` Users assigned to ${currentClient.clientName}` : 'Users assigned to the new Client';

  return (
    <React.Fragment>
      <Row>
        <Col sm="8">
          <h5> {heading}</h5>
        </Col>
      </Row>
      <Row className="mb-2 buttonrow">
        <Col sm="4">
          <SearchBar onChange={handleInput} placeholder="Search" value={searchText} />
        </Col>
        <Col sm="8"></Col>
      </Row>

      <Pagination data={results} pageSize={pageSize} onPageChange={onPageChangeHandler} startingPage={1}>
        <UserTable onCheckboxHandler={onCheckboxHandler} />
      </Pagination>
    </React.Fragment>
  );
};

export default ManageClientUsers;

const UserTable = ({ paginatedData, onCheckboxHandler }) => {
  return (
    <React.Fragment>
      <div>
        <Table className="table" responsive>
          <thead>
            <tr>
              <th>Select</th>
              <th>Name</th>
              <th>Username</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData &&
              paginatedData.map(n => {
                return (
                  <tr key={n.userId}>
                    <td>
                      <input type="checkbox" value={n.isActive} checked={n.isActive} onChange={e => onCheckboxHandler(e, n.userId)} />
                    </td>
                    <td>{`${n.userFullName}`}</td>
                    <td>{`${n.username}`}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};
