import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import ReportViewer from '../ReportViewer';
import ReportHeader from '../Utils/ReportHeader';
import ReportFooter from '../Utils/ReportFooter';
import ReportFilterInfo from '../Utils/ReportFilterInfo';
import { generalStyles } from '../Utils/ReportStyling';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { getAllRptCostCenterData, setOnSearchChange } from '../../../store/actions/ReportListing/rptCostCenterListing';
import Dropdown from '../../CustomControls/Dropdown';
import { STATUSES } from '../../../constants/filterConstants';
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../../CustomControls/Spinner';

const styles = StyleSheet.create({
  costCenterName: {
    width: '35%'
  },
  costCenterCode: {
    width: '20%'
  },
  clientProfileName: {
    width: '35%'
  },
  status: {
    width: '10%'
  }
});

const exportHeader = [
  { label: "Code", key: "costCenterCode" },
  { label: "Cost Center Name", key: "costCenterName" },
  { label: "Client Name", key: "clientProfileName" },
  { label: "Status", key: "isActive" },
];

const CostCenterListingRpt = props => {
  const dispatch = useDispatch();
  const [filterStatus, setFilterStatus] = useState({ field: 'isactive', value: 'all', label: 'All' });
  const [filterClient, setFilterClient] = useState({ field: 'clientprofileid', value: 'all', label: 'All' });
  const rptData = useSelector(state => state.rptCostCenterListing);

  const isLoading = rptData.isLoading || false;
  const data = rptData.rptList;
  const filterData = rptData.filterData;
  const reportHeading = 'Cost Center Listing';

  useEffect(() => {
    dispatch(getAllRptCostCenterData(rptData.filterOptions.filterBy, rptData.filterOptions.sortBy, true));
  }, []);

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: `${reportHeading} Report`, path: '/reports/cost-center-listing', isCurrent: true }
  ];

  const dropdownChange = (field, selectionOption, callSetMethod) => {
    selectionOption.field = field;
    callSetMethod(selectionOption);
  };

  const setLocalChange = currentState => {
    dispatch(setOnSearchChange(currentState));
  };

  const onGenerateHandler = () => {
    let filterSearchList = rptData.filterOptions.filterBy || [];

    filterSearchList = getFilterData(filterSearchList, filterStatus);

    filterSearchList = getFilterData(filterSearchList, filterClient);

    setLocalChange({
      ...rptData,
      data: { ...rptData.filterOptions, filterBy: filterSearchList }
    });

    dispatch(getAllRptCostCenterData(rptData.filterOptions.filterBy, rptData.filterOptions.sortBy));
  };

  const getFilterData = (filterSearchList, filterObj) => {
    const objIndex = filterSearchList.findIndex(x => x.name.toLowerCase() === filterObj.field);
    if (objIndex !== -1) {
      filterSearchList[objIndex].value = filterObj.value;
      filterSearchList[objIndex].valueName = filterObj.label;
    } else {
      filterSearchList.push({ name: filterObj.field, value: filterObj.value, valueName: filterObj.label });
    }
    return filterSearchList;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={`${reportHeading} Report`} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="3">
                      <div className="mb-3">
                        <Dropdown
                          placeholder="All"
                          label="Status"
                          name="isactive"
                          options={STATUSES}
                          isMulti={false}
                          value={STATUSES.find(x => x.value === filterStatus.value)}
                          onChange={val => dropdownChange('isactive', val, setFilterStatus)}
                          validate={{
                            required: { value: false }
                          }}
                        />
                      </div>
                    </Col>
                    <Col sm="3">
                      <div className="mb-3">
                        <Dropdown
                          placeholder="All"
                          label="Client"
                          name="clientprofileid"
                          options={filterData.clientProfiles}
                          isMulti={false}
                          value={filterData.clientProfiles.find(x => x.value === filterClient.value)}
                          onChange={val => dropdownChange('clientprofileid', val, setFilterClient)}
                          validate={{
                            required: { value: false }
                          }}
                        />
                      </div>
                    </Col>
                    <Col sm="4">
                      <div className="text-sm-start">
                        <Button type="button" className="btn btn-primary btn-block  app-add-button" onClick={onGenerateHandler}>
                          GENERATE
                        </Button>
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="text-sm-end">
                        <CSVLink
                          data={data}
                          headers={exportHeader}
                          filename={"costcenterlisting.csv"}
                          className="btn btn-primary download-button"
                        >
                          <FontAwesomeIcon icon={faFileExcel} /> EXPORT
                        </CSVLink>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      {isLoading ? (
                        <LoadingSpinner />
                      ) : (
                        <ReportViewer>
                          <Document title={reportHeading}>
                            <Page style={generalStyles.body}>
                              <ReportHeader reportTitle={reportHeading} />
                              <ReportFilterInfo filterList={rptData.filterOptions.filterBy} />
                              <View style={generalStyles.table}>
                                <View style={generalStyles.tableRow}>
                                  <View style={[generalStyles.tableColHeader, styles.costCenterCode]}>
                                    <Text style={generalStyles.tableCellHeader}>Code</Text>
                                  </View>
                                  <View style={[generalStyles.tableColHeader, styles.costCenterName]}>
                                    <Text style={generalStyles.tableCellHeader}>Cost Center Name</Text>
                                  </View>
                                  <View style={[generalStyles.tableColHeader, styles.clientProfileName]}>
                                    <Text style={generalStyles.tableCellHeader}>Client Name</Text>
                                  </View>
                                  <View style={[generalStyles.tableColHeader, styles.status]}>
                                    <Text style={generalStyles.tableCellHeader}>Status</Text>
                                  </View>
                                </View>
                                {data.length > 0 && <ReportDataRow results={data} />}
                              </View>
                              <ReportFooter footerInfo={reportHeading}>
                                <Text
                                  style={generalStyles.pageNumber}
                                  render={({ pageNumber, totalPages }) => `Page ${pageNumber} of  ${totalPages}`}
                                />
                              </ReportFooter>
                            </Page>
                          </Document>
                        </ReportViewer>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const ReportDataRow = ({ results }) => {
  return (
    <View>
      {results.map(item => {
        return (
          // <reportDataRow item={n} key={n.costCenterId}/>
          <View style={generalStyles.tableRow} key={item.costCenterId}>
            <View style={[generalStyles.tableCol, styles.costCenterCode]}>
              <Text style={generalStyles.tableCell}>{item.costCenterCode}</Text>
            </View>
            <View style={[generalStyles.tableCol, styles.costCenterName]}>
              <Text style={generalStyles.tableCell}> {item.costCenterName} </Text>
            </View>
            <View style={[generalStyles.tableCol, styles.clientProfileName]}>
              <Text style={generalStyles.tableCell}>{item.clientProfileName}</Text>
            </View>
            <View style={[generalStyles.tableCol, styles.status]}>
              <Text style={generalStyles.tableCell}>{item.isActive}</Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default CostCenterListingRpt;
