import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Table from './CustomerTable';
import Pagination from '../../CustomControls/Pagination';
import {
  getAllProducts,
  setOnConfirmationModal,
  getAllCustomerProducts,
  setDeleteCustomer,
  setCustomerDetailsByID
} from '../../../store/actions/customerDetail';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _product, _delete } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import SearchBar from '../../CustomControls/SearchBar';
import CustomConfirmation from '../../CustomControls/ConfirmationModal';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Customer = props => {
  const [searchText, setSearchText] = useState('');
  const [results, setResults] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const product = useSelector(state => state.customerDetail);
  const allDAta = useSelector(state => state);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  const pageSize = ui.layout.pageSize;
  const currentPageNumber = product?.filterOptions?.pageNumber;
  const notificationType = product?.isError ? 'error' : 'success';
  const [showModal, setShowModal] = useState(false);
  const [customerID, setCustomerID] = useState();
  const showSaveOption = hasPermissionToAction(permissions, _product, _create);
  const showDeleteOption = hasPermissionToAction(permissions, _product, _delete);

  useEffect(() => {
    dispatch(getAllCustomerProducts(currentPageNumber, pageSize, product?.filterOptions?.filterBy, product?.filterOptions?.sortBy));
  }, [dispatch, currentPageNumber, pageSize]);

  useEffect(() => {
    if (searchText.length > 0) {
      // dispatch(getAllCustomerProducts(currentPageNumber, pageSize, product?.filterOptions?.filterBy, product?.filterOptions?.sortBy))
      // setResults(
      //   product?.productList?.filter(
      //     x =>
      //       x?.clientCode?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      //       x?.clientName?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      //       x?.abn?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      //       x?.primaryContactEmail?.toLowerCase()?.includes(searchText?.toLowerCase())
      //   )
      // );
    } else {
      setResults(product?.productList);
    }
  }, [searchText, setResults, product?.productList]);

  const onPageChangeHandler = currentPage => {
    setLocalChange({
      ...product,
      data: { ...product?.filterOptions, pageNumber: currentPage }
    });
  };
  const setLocalChange = currentState => {
    dispatch(
      getAllCustomerProducts(
        currentState.data.pageNumber,
        currentState.data.pageSize,
        product?.filterOptions?.filterBy,
        product?.filterOptions?.sortBy
      )
    );
  };
  const onAddNewHandler = () => {
    history.push('/product-view/product-quantity');
  };

  const onEditHandler = id => {
    dispatch(setCustomerDetailsByID(id));
    history.push(`/customer/customer-info/customer-details`);
  };

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Customers', path: '/master/products', isCurrent: true }
  ];

  const handleInput = e => {
    setSearchText(e.target.value);
  };
  function onSearchHandler() {
    dispatch(getAllCustomerProducts(1, pageSize, product?.filterOptions?.filterBy, product?.filterOptions?.sortBy, searchText));
  }
  const onDeleteHandler = id => {
    setShowModal(true);
    setCustomerID(id);
    // dispatch(
    //   setOnConfirmationModal({
    //     data: {
    //       showConfirmationModal: true,
    //       confirmationId: id
    //     }
    //   })
    // );
  };

  const onDeleteConfirmation = async () => {
    setShowModal(false);
    dispatch(setDeleteCustomer(customerID));
    setTimeout(() => {
      dispatch(getAllCustomerProducts(1, pageSize, product?.filterOptions?.filterBy, product?.filterOptions?.sortBy));
    }, 2000);
  };

  const onDeleteCancel = () => {
    setShowModal(false);
    setCustomerID(null);
  };

  return (
    <React.Fragment>
      <CustomConfirmation
        show={showModal}
        onDeleteClick={onDeleteConfirmation}
        onCloseClick={onDeleteCancel}
        message="Are you sure you want to delete this record?"
        header="Deleting record"
      />
      <div className="page-content">
        {/* {product.showMessage && <Notification notificationType={notificationType} message={product.message} />} */}
        <Container fluid>
          <Breadcrumbs title="Customers " paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="4">
                      <SearchBar onChange={handleInput} placeholder="Search" value={searchText} />
                    </Col>
                    <Col sm="4" className="m-auto">
                      <div className="text-sm-start">
                        <Button type="button" className="btn btn-outline-primary mt-4" onClick={onSearchHandler}>
                          <FontAwesomeIcon className="standardIcon" icon={faSearch} /> Search
                        </Button>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        {/* <Button type="button" className="btn btn-primary btn-block  app-add-button" onClick={onAddNewHandler}>
                          ADD ORDER
                        </Button> */}
                      </div>
                    </Col>
                  </Row>
                  <Row className="tablerow">
                    <Col xl="12">
                      <div>
                        {product?.isLoading && <LoadingSpinner />}
                        <Pagination
                          data={product.productList}
                          pageSize={pageSize}
                          apiPaging={true}
                          apiDataLength={product.totalRecords}
                          onPageChange={onPageChangeHandler}
                          startingPage={currentPageNumber}
                        >
                          <Table editHandler={onEditHandler} deleteHandler={onDeleteHandler} showDelete={showDeleteOption} />
                        </Pagination>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Customer;
