import React from "react";
import { useDispatch } from 'react-redux';
import { Table } from "reactstrap"
import {
    Col,
    Row,
    Card,
    CardBody,
    Button
} from "reactstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Pagination from "../../CustomControls/Pagination";
import { PAGINATION_SUB_SIZE } from "../../../constants"
import LoadingSpinner from "../../CustomControls/Spinner";
import CustomInput from "../../CustomControls/CustomInput";
import Dropdown from "../../CustomControls/Dropdown";
import { AvForm } from "availity-reactstrap-validation";
import CustomAvField from "../../CustomControls/CustomAvField";
import { saveSupplier, deleteSupplier } from '../../../store/actions/Master/product';
import "./style.scss";

const ProductSupplier = ({ product, handleTabDataChangeHandler }) => {
    const dispatch = useDispatch();
    const currentProduct = product.currentProduct;
    const currentProductSupplier = product.currentProduct.currentSupplier;

    const onEditHandler = id => {

        const editObj = currentProduct.suppliers.find((obj => obj.supplierId === id))

        onLocalChange({ ...currentProductSupplier, ...editObj });
    };

    const onDeleteHandler = (productId, supplierId) => {

        if (productId && supplierId) {
            dispatch(deleteSupplier(productId, supplierId))
        }

    }

    const onPageChangeHandler = id => {

    };

    const onLocalChange = (value) => {

        handleTabDataChangeHandler('currentSupplier', value)
    }

    const handleCheckBoxInput = (e) => {
        let isChecked;

        if (e.target.value === "false") {
            isChecked = false;
        } else {
            isChecked = true;
        }
        onLocalChange({ ...currentProductSupplier, [e.target.name]: !isChecked });
    }

    const dropDownChange = (selectedOption) => {

        onLocalChange({ ...currentProductSupplier, supplierId: selectedOption.value, supplierName: selectedOption.label });

    };

    const handleInput = (e) => {

        onLocalChange({ ...currentProductSupplier, [e.target.name]: e.target.value });
    }

    const handleSubmit = (e) => {

        if (currentProductSupplier) {
            let saveObj = currentProductSupplier;
            saveObj.productId = currentProduct.productId;
            if (saveObj.supplierId) {
                dispatch(saveSupplier(saveObj))
            }
        }
    }

    const supplierList = () => {
        let retList = currentProduct.supplierSelectList;
        const supplierList = currentProduct.suppliers;
        retList.forEach(element => {
            if (supplierList.some(x => x.supplierId === element.value)) {
                element.isdisabled = true;
            } else {
                element.isdisabled = false;
            }
        });

        return retList
    }

    return (
        <div>
            <Row>
                <Col md="4">
                    <h6>Add/Update Suppliers </h6>
                    <Card>
                        <CardBody>
                            <AvForm className="form-horizontal" onValidSubmit={handleSubmit} >

                                <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Dropdown
                                                placeholder="Select Supplier"
                                                label="Supplier"
                                                name="supplierId"
                                                options={supplierList()}
                                                isMulti={false}
                                                value={currentProduct.supplierSelectList.find(x => x.value === currentProductSupplier.supplierId)}
                                                onChange={(val) => dropDownChange(val)}
                                                isOptionDisabled={(option) => option.isdisabled}
                                                validate={{
                                                    required: { value: true, errorMessage: 'Please select a product type' },
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3">
                                            <CustomInput
                                                name="packPrice"
                                                label="Pack Price"
                                                className="form-control"
                                                placeholder="Pack Price"
                                                value={currentProductSupplier.packPrice}
                                                onChange={handleInput}
                                                step=".001"
                                                min="0"
                                                iconValue="$"
                                                type="number"
                                                validate={{
                                                    required: { value: false, errorMessage: 'Please enter a valid pack price' },
                                                }}

                                            />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3">
                                            <CustomAvField
                                                name="priorityOrder"
                                                label="Priority Order"
                                                className="form-control"
                                                placeholder="Priority Order"
                                                value={currentProductSupplier.priorityOrder}
                                                onChange={handleInput}
                                                type="number"
                                                step="1"
                                                min="0"
                                                maxLength="20"
                                                validate={{
                                                    required: { value: false, errorMessage: 'Please enter a valid priority order' },
                                                }}

                                            />
                                        </div>
                                    </Col>
                                    <Col md="14" className="m-auto">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn "
                                            disabled={product.isLoading}
                                        >
                                            SAVE
                                        </Button>
                                    </Col>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="8">
                    <h6>Manage Product Supplier</h6>
                    <Card>
                        <CardBody>
                            <Row className="tablerow">
                                <Col xl="12">
                                    <div >
                                        {product.isLoading && <LoadingSpinner />}
                                        <Pagination
                                            data={currentProduct.suppliers}
                                            pageSize={PAGINATION_SUB_SIZE}
                                            onPageChange={onPageChangeHandler}
                                            startingPage={1}
                                        >
                                            <ProductSupplierTable editHandler={onEditHandler} deleteHandler={onDeleteHandler} currentProduct={currentProduct} />
                                        </Pagination>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div >

    )
}
export default ProductSupplier;

const ProductSupplierTable = ({ paginatedData, editHandler, deleteHandler }) => {


    return (
        <React.Fragment>
            <div>
                <Table className="tablelight" responsive>
                    <thead>
                        <tr>
                            <th>Supplier Name</th>
                            <th>PackPrice</th>
                            <th>Priority Order</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map(n => {
                            return (
                                <tr key={n.supplierId}>
                                    <td>{n.supplierName || ''}</td>
                                    <td>{n.packPrice || ''}</td>
                                    <td>{n.priorityOrder || ''}</td>
                                    {<td>

                                        <div className="flex-container flex-end">
                                            <div className="flex-item"> <button className="btn tableIcons" onClick={() => editHandler(n.supplierId)} > <FontAwesomeIcon icon={faPencilAlt} /></button></div>
                                            <div className="flex-item"><button className="btn tableIcons" onClick={() => deleteHandler(n.productId, n.supplierId)} > <FontAwesomeIcon icon={faTrashAlt} /></button></div>
                                        </div>


                                    </td>}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    )
}

