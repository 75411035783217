import React from 'react';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faKey, faNewspaper, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
// import './style.scss';

const UserTable = ({ paginatedData, deleteHandler, editHandler, clientViewHandler, showResetPasswordOption }) => {
  return (
    <React.Fragment>
      <div>
        <Table className="table" responsive>
          <thead>
            <tr>
              <th>Remote Linen Room Code</th>
              <th>Remote Linen Room Name</th>
              <th>Remote Linen Room Admin</th>
              <th>Clients</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData?.map(n => {
              return (
                <tr key={n.dcid}>
                  <td>{n.dcCode || ''}</td>
                  <td>{n.name || ''}</td>
                  <td>{n.dcManager || ''}</td>
                  {/* <td>{n.phoneNumber || ''}</td> */}
                  {showResetPasswordOption && (
                    <td>
                      <button className="btn tableIcons" onClick={() => clientViewHandler(n.dcid)}>
                        {' '}
                        <FontAwesomeIcon icon={faNewspaper} />
                      </button>
                    </td>
                  )}
                  <td style={{ display: 'flex', gap: '1rem' }}>
                    <button className="btn tableIcons" onClick={() => editHandler(n.dcid)}>
                      {' '}
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </button>
                    <button className="btn tableIcons" onClick={() => deleteHandler(n.dcid)}>
                      {' '}
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default UserTable;
