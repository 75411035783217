import React from 'react';
import { useDispatch } from 'react-redux';
import { Table } from 'reactstrap';
import { Col, Label, Row, Card, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../CustomControls/Pagination';
import { PAGINATION_SUB_SIZE } from '../../../constants';
import LoadingSpinner from '../../CustomControls/Spinner';
import CustomInput from '../../CustomControls/CustomInput';
import Dropdown from '../../CustomControls/Dropdown';
import { AvForm, AvInput, AvGroup } from 'availity-reactstrap-validation';
import { saveDepartment, deleteDepartment } from '../../../store/actions/Master/product';
import './style.scss';

const ProductDepartment = ({ product, handleTabDataChangeHandler }) => {
  const dispatch = useDispatch();
  const currentProduct = product.currentProduct;
  const currentProductDepartment = product.currentProduct.currentDepartment;

  const onEditHandler = id => {
    const editObj = currentProduct.departments.find(obj => obj.departmentId === id);
    editObj.clientProfileId = '';
    onLocalChange({ ...currentProductDepartment, ...editObj });
  };

  const onDeleteHandler = (productId, departmentId) => {
    if (productId && departmentId) {
      dispatch(deleteDepartment(productId, departmentId));
    }
  };

  const onPageChangeHandler = id => {};

  const onLocalChange = value => {
    handleTabDataChangeHandler('currentDepartment', value);
  };

  const onMainContentChange = (field, value) => {
    handleTabDataChangeHandler(field, value);
  };

  const handleCheckBoxInput = e => {
    let isChecked;

    if (e.target.value === 'false') {
      isChecked = false;
    } else {
      isChecked = true;
    }
    onLocalChange({ ...currentProductDepartment, [e.target.name]: !isChecked });
  };

  const dropDownChange = (field, selectedOption, cascadeEffectDropDowns = []) => {
    if (field === 'clientProfileId') {
      onLocalChange({ ...currentProductDepartment, [field]: selectedOption.value, clientProfileName: selectedOption.label, departmentId: '', departmentName: 'Select Department' });
    } else {
      let changeObj = { [field]: selectedOption.value };

      if (cascadeEffectDropDowns.length > 0) {
        cascadeEffectDropDowns.forEach(element => {
          changeObj = { ...changeObj, [element]: '' };
        });
      }
      onLocalChange({ ...currentProductDepartment,clientProfileId: selectedOption.filterId, departmentId: selectedOption.value, departmentName: selectedOption.label });
    }
  };

  const handleInput = e => {
    onLocalChange({ ...currentProductDepartment, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    if (currentProductDepartment) {
      let saveObj = currentProductDepartment;
      saveObj.productId = currentProduct.productId;
      var currentClientProfile = currentProduct.clientProfileSelectList.find(x => x.value === currentProductDepartment.clientProfileId);
      if(currentClientProfile)
      {
        saveObj.clientProfileName = currentClientProfile.label;
      }
      if (saveObj.departmentId) {
        dispatch(saveDepartment(saveObj));
      }
    }
  };

  // const departmentList = () => {
  //   let retList = currentProduct.departmentSelectList;
  //   const departmentList = currentProduct.departments;
  //   retList.forEach(element => {
  //     if (departmentList.some(x => x.departmentId === element.value)) {
  //       element.isdisabled = true;
  //     } else {
  //       element.isdisabled = false;
  //     }
  //   });

  //   return retList;
  // };

  const DepartmentOptions = () => {
    const options = [];

    let filterData = currentProduct.departmentSelectList || [];

    const departmentList = currentProduct.departments;
    filterData.forEach(element => {
      if (departmentList.some(x => x.departmentId === element.value)) {
        element.isdisabled = true;
      } else {
        element.isdisabled = false;
      }
    });

    if (currentProductDepartment.clientProfileId !== '') {
      filterData = currentProduct.departmentSelectList.filter(x => x.filterId === currentProductDepartment.clientProfileId);
    }

    if (filterData.filter(f => f.value === '').length === 0) {
      filterData.push({
        label: 'Select Department',
        value: ''
      });
    }

    filterData.forEach(element => {
      options.push(element);
    });

    return options;
  };
  return (
    <div>
      <Row>
        <Col md="4">
          <h6>Add/Update Departments </h6>
          <Card>
            <CardBody>
              <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <Dropdown
                        placeholder="Select Client"
                        label="Client"
                        name="clientProfileId"
                        options={currentProduct.clientProfileSelectList}
                        isMulti={false}
                        value={currentProduct.clientProfileSelectList.find(x => x.value === currentProductDepartment.clientProfileId)}
                        onChange={val => dropDownChange('clientProfileId', val, ['departmentId'])}
                        validate={{
                          required: { value: false, errorMessage: 'Please select a client' }
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="mb-3">
                      <Dropdown
                        placeholder="Select Department"
                        label="Department"
                        name="departmentId"
                        options={DepartmentOptions()}
                        isMulti={false}
                        value={DepartmentOptions().find(x => x.value === currentProductDepartment.departmentId)}
                        isOptionDisabled={option => option.isdisabled}
                        onChange={val => dropDownChange('departmentId', val)}
                        validate={{
                          required: { value: true, errorMessage: 'Please select a department' }
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <CustomInput
                        name="price"
                        label="Price"
                        className="form-control"
                        placeholder="Price"
                        value={currentProductDepartment.price}
                        onChange={handleInput}
                        step=".001"
                        min="0"
                        iconValue="$"
                        type="number"
                        validate={{
                          required: { value: true, errorMessage: 'Please enter a valid price' }
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="6" className="m-auto p-top-10">
                    <AvGroup check>
                      <AvInput
                        type="checkbox"
                        name="orderInPack"
                        label="Order In Pack"
                        onChange={handleCheckBoxInput}
                        checked={currentProductDepartment.orderInPack}
                        value={currentProductDepartment.orderInPack}
                      />
                      <Label check for="checkbox">
                        Order In Pack
                      </Label>
                    </AvGroup>
                  </Col>
                  <Col md="14" className="m-auto">
                    <Button type="submit" color="primary" className="btn " disabled={product.isLoading}>
                      SAVE
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
        <Col md="8">
          <h6>Manage Product Department</h6>
          <Card>
            <CardBody>
              <Row className="tablerow">
                <Col xl="12">
                  <div>
                    {product.isLoading && <LoadingSpinner />}
                    <Pagination
                      data={currentProduct.departments}
                      pageSize={PAGINATION_SUB_SIZE}
                      onPageChange={onPageChangeHandler}
                      startingPage={1}
                    >
                      <ProductDepartmentTable editHandler={onEditHandler} deleteHandler={onDeleteHandler} currentProduct={currentProduct} />
                    </Pagination>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default ProductDepartment;

const ProductDepartmentTable = ({ paginatedData, editHandler, deleteHandler }) => {
  return (
    <React.Fragment>
      <div>
        <Table className="tablelight" responsive>
          <thead>
          <tr>
              <th>Client Name</th>
              <th>Department Name</th>
              <th>Price</th>
              <th>Order In Pack</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map(n => {
              return (
                <tr key={n.departmentId}>
                  <td>{n.clientProfileName || ''}</td>
                  <td>{n.departmentName || ''}</td>
                  <td>{n.price || ''}</td>
                  <td>{n.orderInPack ? 'Yes' : 'No'}</td>
                  {
                    <td>
                      <div className="flex-container flex-end">
                        <div className="flex-item">
                          {' '}
                          <button className="btn tableIcons" onClick={() => editHandler(n.departmentId)}>
                            {' '}
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </button>
                        </div>
                        <div className="flex-item">
                          <button className="btn tableIcons" onClick={() => deleteHandler(n.productId, n.departmentId)}>
                            {' '}
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        </div>
                      </div>
                    </td>
                  }
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};
