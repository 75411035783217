import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Table from './CompanyTable';
import Pagination from '../../CustomControls/Pagination';
import { getAllCompany } from '../../../store/actions/Management/company';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _company, _adminops } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import SearchBar from '../../CustomControls/SearchBar';

import './style.scss';

const Company = props => {
  const [searchText, setSearchText] = useState('');
  const [results, setResults] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const company = useSelector(state => state.company);
  const ui = useSelector(state => state.ui);
  const pageSize = ui.layout.pageSize;
  const permissions = ui.layout.permissions;

  const notificationType = company.isError ? 'error' : 'success';

  const showSaveOption = hasPermissionToAction(permissions, _company, _create);

  const hasAddAccess = hasPermissionToAction(permissions, _company, _adminops);

  useEffect(() => {
    dispatch(getAllCompany());
  }, [dispatch]);

  useEffect(() => {
    if (searchText.length > 0) {
      setResults(
        company.companyList.filter(
          x =>
            x.clientCode.toLowerCase().includes(searchText.toLowerCase()) ||
            x.clientName.toLowerCase().includes(searchText.toLowerCase()) ||
            x.abn.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setResults(company.companyList);
    }
  }, [searchText, setResults, company.companyList]);

  const onPageChangeHandler = currentPage => {};

  const onAddNewHandler = () => {
    if (hasAddAccess) {
      history.push('/management/companies/company');
    }
  };

  const onEditHandler = id => {
    history.push(`/management/companies/company/${id}`);
  };

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Company Listing', path: '/management/companies', isCurrent: true }
  ];

  const handleInput = e => {
    setSearchText(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {company.showMessage && <Notification notificationType={notificationType} message={company.message} />}
        <Container fluid>
          <Breadcrumbs title="Company Listing " paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="4">
                      <SearchBar onChange={handleInput} placeholder="Search" value={searchText} />
                    </Col>
                    <Col sm="8">
                      {hasAddAccess && (
                        <div className="text-sm-end">
                          {showSaveOption && (
                            <Button type="button" className="btn btn-primary btn-block  app-add-button" onClick={onAddNewHandler}>
                              ADD COMPANY
                            </Button>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className="tablerow">
                    <Col xl="12">
                      <div>
                        {company.isLoading && <LoadingSpinner />}
                        <Pagination data={results} pageSize={pageSize} onPageChange={onPageChangeHandler} startingPage={1}>
                          <Table editHandler={onEditHandler} />
                        </Pagination>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Company;
