import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { getIsUserAuthenticated } from '../selectors/index';
import Layout from '../components/Layout/Layout';
import { logout } from '../store/actions/auth';

import { checkAndRedirect } from '../utils/';

export default Component => {
  class Unauthorized extends React.Component {
    componentDidMount() {
      const { isAuthenticated, redirect } = this.props;
      checkAndRedirect(isAuthenticated, redirect);
    }

    componentDidUpdate() {
      const { isAuthenticated, redirect } = this.props;
      checkAndRedirect(isAuthenticated, redirect);
    }

    render() {
      const { isAuthenticated, logout, location } = this.props;
      return isAuthenticated ? (
        <Layout isAuthenticated={isAuthenticated} logout={logout} location={location}>
          <Component {...this.props} />
        </Layout>
      ) : null;
    }
  }

  const mapStateToProps = state => {
    return {
      isAuthenticated: getIsUserAuthenticated()
    };
  };

  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        redirect: () => push('/login'),
        logout: () => logout()
      },
      dispatch
    );

  Unauthorized.defaultProps = {
    history: {
      action: ''
    }
  };

  Unauthorized.propTypes = {
    isAuthenticated: PropTypes.bool,
    redirect: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    history: PropTypes.object
  };

  return connect(mapStateToProps, mapDispatchToProps)(Unauthorized);
};
