import { CLIENTPROFILE_GET_ALL_DATA, CLIENTPROFILE_GET_DATA, CLIENTPROFILE_ERROR, CLIENTPROFILE_IS_LOADING, CLIENTPROFILE_SAVE_DATA, CLIENTPROFILE_ON_CHANGE_DATA, CLIENTPROFILE_CLEAN_UP,CLIENTPROFILE_DELETE_DATA,CLIENTPROFILE_UPDATE_CONFIRMATION }  from '../../../constants/index';
import { initialClientProfileState } from '../initialState';

const clientProfileReducer = (state = initialClientProfileState, action) => {
  const { payload, type } = action;
  switch (type) {
    case CLIENTPROFILE_ERROR: {
      const { showMessage, errorMessage, errorList } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage,
        errorList: errorList
      };
    }
    case CLIENTPROFILE_GET_ALL_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        clientProfileList: resultData
      };
    }
    case CLIENTPROFILE_GET_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        errorList: [],
        currentClientProfile: {
          ...state.currentClientProfile,
          ...resultData,
          errorList: []
        }
      };
    }
    case CLIENTPROFILE_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }
    case CLIENTPROFILE_SAVE_DATA: {
      const { showMessage, message, redirect } = payload;
      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message,
      };
    }

    case CLIENTPROFILE_ON_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        currentClientProfile: {
          ...state.currentClientProfile,
          ...data
        }
      };
    }
    case CLIENTPROFILE_CLEAN_UP: {
      let { data } = payload;

      return {
        ...state,
        message: '',
        errorList: [],
        isError: false,
        showMessage: false,
        redirect: false,
        showConfirmationModal: false,
        confirmationId : '',
        currentClientProfile: {
          ...state.currentClientProfile,
          ...data
        }
      };
    }

    case CLIENTPROFILE_DELETE_DATA: {
      const { showMessage, message, redirect } = payload;
      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message,
        showConfirmationModal: false,
        confirmationId : ''
      };
    }

    case CLIENTPROFILE_UPDATE_CONFIRMATION: {
      let { data } = payload;

      return {
        ...state,
        ...data
      };
    }

    default:
      return state;
  }
};

export default clientProfileReducer;




