import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import './ReportViewer.scss';

const ReportViewer = ({ children }) => {
  return (
    <div className="App">
      <PDFViewer width="100%" height="600px" className="cusaapp">
        {children}
      </PDFViewer>
    </div>
  );
};

export default ReportViewer;
