import {
  PRODUCT_GET_ALL_DATA,
  PRODUCT_GET_DATA,
  PRODUCT_ERROR,
  PRODUCT_IS_LOADING,
  PRODUCT_SAVE_DATA,
  PRODUCT_ON_CHANGE_DATA,
  PRODUCT_CLEAN_UP,
  PRODUCT_DEPARTMENT_SAVE,
  PRODUCT_DEPARTMENT_DELETE,
  PRODUCT_LOCATION_SAVE,
  PRODUCT_LOCATION_DELETE,
  PRODUCT_SUPPLIER_SAVE,
  PRODUCT_SUPPLIER_DELETE,
  PRODUCT_DELETE_DATA,
  PRODUCT_UPDATE_CONFIRMATION
} from '../../../constants/index';
import { apiHelper } from '../../../utils/index';
import Cookies from 'js-cookie';
import {
  setErrorThunk,
  setLoadingStatus,
  setGetResults,
  setDescribeData,
  setSaveData,
  setOnChangeData,
  setOnCleanUpData,
  setDeleteData,
  setOnConfirmationData
} from '../actionHelper';

const API_URL = '/product';

export const getAllProducts = () => dispatch => {
  dispatch(_getAllProducts());
};

const _getAllProducts = () =>
  apiHelper({
    url: `${API_URL}`,
    method: 'GET',
    onLoad: status => setLoadingStatus(PRODUCT_IS_LOADING, status),
    onSuccess: result => {
      return setGetResults(PRODUCT_GET_ALL_DATA, result);
    },

    onFailure: err => setErrorThunk(PRODUCT_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

export const describeProduct = id => dispatch => {
  dispatch(_getProduct(id));
};

const _getProduct = id =>
  apiHelper({
    url: `${API_URL}/${id}`,
    method: 'GET',
    data: {
      id: id
    },
    onLoad: status => setLoadingStatus(PRODUCT_IS_LOADING, status),
    onSuccess: result => setDescribeData(PRODUCT_GET_DATA, result),
    onFailure: err => setErrorThunk(PRODUCT_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

export const saveProduct = (saveData, isNew, id = null) => dispatch => {
  let MethodType = 'POST';
  if (!isNew) {
    MethodType = 'PUT';
  }

  let apiUrl = id ? `${API_URL}/${id}` : API_URL;

  dispatch(_saveProduct(saveData, MethodType, apiUrl));
};

const _saveProduct = (data, MethodType, apiUrl) =>
  apiHelper({
    url: apiUrl,
    method: MethodType,
    onSuccess: result => setSaveData(PRODUCT_SAVE_DATA, true, result),
    onFailure: err => setErrorThunk(PRODUCT_ERROR, true, err),
    onLoad: status => setLoadingStatus(PRODUCT_IS_LOADING, status),
    data,
    accessToken: Cookies.get('accessToken')
  });

export const setOnChange = retData => dispatch => {
  dispatch(setOnChangeData(PRODUCT_ON_CHANGE_DATA, retData));
};

export const setOnCleanUp = retData => dispatch => {
  dispatch(setOnCleanUpData(PRODUCT_CLEAN_UP, retData));
};

/* DEPARTMENT */

export const saveDepartment = saveData => dispatch => {
  let apiUrl = `${API_URL}/department`;

  dispatch(_saveDepartment(saveData, apiUrl));
};

const _saveDepartment = (data, apiUrl) =>
  apiHelper({
    url: apiUrl,
    method: 'POST',
    onSuccess: result => setSaveDepartmentData(false, result, data),
    onFailure: err => setErrorThunk(PRODUCT_ERROR, true, err),
    onLoad: status => setLoadingStatus(PRODUCT_IS_LOADING, status),
    data,
    accessToken: Cookies.get('accessToken')
  });

const setSaveDepartmentData = (showMessage, { message }, data) => ({
  type: PRODUCT_DEPARTMENT_SAVE,
  payload: { showMessage, message, data }
});

export const deleteDepartment = (productId, departmentId) => dispatch => {
  let apiUrl = `${API_URL}/department/${productId}/${departmentId}`;
  dispatch(_deleteDepartment({ productId, departmentId }, apiUrl));
};

const _deleteDepartment = (data, apiUrl) =>
  apiHelper({
    url: apiUrl,
    method: 'DELETE',
    onSuccess: result => setDeleteDepartmentData(false, result, data),
    onFailure: err => setErrorThunk(PRODUCT_ERROR, true, err),
    onLoad: status => setLoadingStatus(PRODUCT_IS_LOADING, status),
    data,
    accessToken: Cookies.get('accessToken')
  });

const setDeleteDepartmentData = (showMessage, { message }, data) => ({
  type: PRODUCT_DEPARTMENT_DELETE,
  payload: { showMessage, message, data }
});

/* LOCATION */

export const saveLocation = saveData => dispatch => {
  let apiUrl = `${API_URL}/location`;

  dispatch(_saveLocation(saveData, apiUrl));
};

const _saveLocation = (data, apiUrl) =>
  apiHelper({
    url: apiUrl,
    method: 'POST',
    onSuccess: result => setSaveLocationData(false, result, data),
    onFailure: err => setErrorThunk(PRODUCT_ERROR, true, err),
    onLoad: status => setLoadingStatus(PRODUCT_IS_LOADING, status),
    data,
    accessToken: Cookies.get('accessToken')
  });

const setSaveLocationData = (showMessage, { message }, data) => ({
  type: PRODUCT_LOCATION_SAVE,
  payload: { showMessage, message, data }
});

export const deleteLocation = (productId, locationId) => dispatch => {
  let apiUrl = `${API_URL}/location/${productId}/${locationId}`;
  dispatch(_deleteLocation({ productId, locationId }, apiUrl));
};

const _deleteLocation = (data, apiUrl) =>
  apiHelper({
    url: apiUrl,
    method: 'DELETE',
    onSuccess: result => setDeleteLocationData(false, result, data),
    onFailure: err => setErrorThunk(PRODUCT_ERROR, true, err),
    onLoad: status => setLoadingStatus(PRODUCT_IS_LOADING, status),
    data,
    accessToken: Cookies.get('accessToken')
  });

const setDeleteLocationData = (showMessage, { message }, data) => ({
  type: PRODUCT_LOCATION_DELETE,
  payload: { showMessage, message, data }
});

/* SUPPLIER */

export const saveSupplier = saveData => dispatch => {
  let apiUrl = `${API_URL}/supplier`;

  dispatch(_saveSupplier(saveData, apiUrl));
};

const _saveSupplier = (data, apiUrl) =>
  apiHelper({
    url: apiUrl,
    method: 'POST',
    onSuccess: result => setSaveSupplierData(false, result, data),
    onFailure: err => setErrorThunk(PRODUCT_ERROR, true, err),
    onLoad: status => setLoadingStatus(PRODUCT_IS_LOADING, status),
    data,
    accessToken: Cookies.get('accessToken')
  });

const setSaveSupplierData = (showMessage, { message }, data) => ({
  type: PRODUCT_SUPPLIER_SAVE,
  payload: { showMessage, message, data }
});

export const deleteSupplier = (productId, supplierId) => dispatch => {
  let apiUrl = `${API_URL}/supplier/${productId}/${supplierId}`;
  dispatch(_deleteSupplier({ productId, supplierId }, apiUrl));
};

const _deleteSupplier = (data, apiUrl) =>
  apiHelper({
    url: apiUrl,
    method: 'DELETE',
    onSuccess: result => setDeleteSupplierData(false, result, data),
    onFailure: err => setErrorThunk(PRODUCT_ERROR, true, err),
    onLoad: status => setLoadingStatus(PRODUCT_IS_LOADING, status),
    data,
    accessToken: Cookies.get('accessToken')
  });

const setDeleteSupplierData = (showMessage, { message }, data) => ({
  type: PRODUCT_SUPPLIER_DELETE,
  payload: { showMessage, message, data }
});

// DELETE OPERATION
export const deleteProduct = id => dispatch => {
  let MethodType = 'DELETE';
  let apiUrl = id ? `${API_URL}/${id}` : API_URL;

  dispatch(_deleteProduct(MethodType, apiUrl));
};

const _deleteProduct = (MethodType, apiUrl) =>
  apiHelper({
    url: apiUrl,
    method: MethodType,
    onSuccess: result => onDeleteSuccess(result),
    onFailure: err => setErrorThunk(PRODUCT_ERROR, true, err),
    onLoad: status => setLoadingStatus(PRODUCT_IS_LOADING, status),
    accessToken: Cookies.get('accessToken')
  });

export const setOnConfirmationModal = retData => dispatch => {
  dispatch(setOnConfirmationData(PRODUCT_UPDATE_CONFIRMATION, retData));
};

export const onDeleteSuccess = result => dispatch => {
  dispatch(setDeleteData(PRODUCT_DELETE_DATA, true, result));
  dispatch(_getAllProducts());
};
