import React, { useEffect } from "react"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import "./style.scss";

const Notification = ({ notificationType, message, showMessageTime = 5000, progressBar = false }) => {


    useEffect(() => {
        if (message && message.length > 0) {
            if (notificationType === "info") {
                toast.info(<div><FontAwesomeIcon icon={faInfoCircle} /><span className="message">{message}</span></div>, { className: "info" })
            }
            else if (notificationType === "warning") {
                toast.warning(<div><FontAwesomeIcon icon={faExclamationCircle} /><span className="message">{message}</span></div>, { className: "warning" });
            }
            else if (notificationType === "error") {
                toast.error(<div><FontAwesomeIcon icon={faTimesCircle} /><span className="message">{message}</span></div>, { className: "error" });
            }
            else {
                toast.success(<div><FontAwesomeIcon icon={faCheckCircle} /><span className="message">{message}</span></div>, { className: "success" })
            }
        }
    }, [notificationType, message]);



    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {/* Same as */}
            <ToastContainer />
        </div>
    )
}

export default Notification;