import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import Table from './AuditLogTable';
import Pagination from '../CustomControls/Pagination';
import { getAllAuditLogs, setOnChange } from '../../store/actions/Audit/auditLog';
import LoadingSpinner from '../CustomControls/Spinner';
import Notification from '../CustomControls/Notification';
import Breadcrumbs from '../CustomControls/Breadcrumb';
import SearchBar from '../CustomControls/SearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

const AuditLog = props => {
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const auditLog = useSelector(state => state.auditLog);
  const ui = useSelector(state => state.ui);
  const pageSize = ui.layout.pageSize;

  const currentPageNumber = auditLog.filterOptions.pageNumber;

  const notificationType = auditLog.isError ? 'error' : 'success';

  useEffect(() => {
    dispatch(getAllAuditLogs(currentPageNumber, pageSize, auditLog.filterOptions.filterBy, auditLog.filterOptions.sortBy));
  }, [dispatch, currentPageNumber, pageSize, auditLog.filterOptions.filterBy, auditLog.filterOptions.sortBy]);

  const onPageChangeHandler = currentPage => {
    setLocalChange({
      ...auditLog,
      data: { ...auditLog.filterOptions, pageNumber: currentPage }
    });
  };

  const setLocalChange = currentState => {
    dispatch(setOnChange(currentState));
  };

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Audit Logs', path: '/audit/audit-logs', isCurrent: true }
  ];

  const handleInput = e => {
    setSearchText(e.target.value);
  };

  const onClickHandler = e => {
    let filterSearchList = auditLog.filterOptions.filterBy || [];

    const objIndex = filterSearchList.findIndex(x => x.name.toLowerCase() === 'operationid');

    if (objIndex !== -1) {
      filterSearchList[objIndex].value = searchText.toLowerCase();
    } else {
      filterSearchList.push({ name: 'operationid', value: searchText.toLowerCase() });
    }

    setLocalChange({
      ...auditLog,
      data: { ...auditLog.filterOptions, filterBy: filterSearchList }
    });

    dispatch(getAllAuditLogs(currentPageNumber, pageSize, filterSearchList, auditLog.filterOptions.sortBy));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {auditLog.showMessage && <Notification notificationType={notificationType} message={auditLog.message} />}
        <Container fluid>
          <Breadcrumbs title="Audit Logs " paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="4">
                      <SearchBar onChange={handleInput} placeholder="Search By Operation Id" value={searchText} />
                    </Col>
                    <Col sm="2" className="m-auto p-10-25">
                      <div className="text-sm-start">
                        <Button type="button" className="btn btn-outline-primary" onClick={onClickHandler}>
                          <FontAwesomeIcon className="standardIcon" icon={faSearch} /> Search
                        </Button>
                      </div>
                    </Col>
                    <Col sm="6"></Col>
                  </Row>
                  <Row className="tablerow">
                    <Col xl="12">
                      <div>
                        {auditLog.isLoading && <LoadingSpinner />}
                        <Pagination
                          data={auditLog.auditLogList}
                          pageSize={pageSize}
                          onPageChange={onPageChangeHandler}
                          startingPage={currentPageNumber}
                          apiPaging={true}
                          apiDataLength={auditLog.totalRecords}
                        >
                          <Table />
                        </Pagination>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AuditLog;
