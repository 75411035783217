import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import ReportViewer from '../ReportViewer';
import ReportHeader from './ReportHeader';
import ReportFooter from '../Utils/ReportFooter';
import { generalStyles, noBorderTableStyle } from './ReportStyling';
import { addDaysToDate, getLastDateOfLastMonth, getFirstDateOfLastMonth } from '../../../utils';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { getAllRptDeptSummaryData, setOnSearchChange } from '../../../store/actions/ReportOperation/rptDepartmentSummary';
import Dropdown from '../../CustomControls/Dropdown';
import DatePicker from '../../CustomControls/CustomDatePicker';
import moment from 'moment';

import LoadingSpinner from '../../CustomControls/Spinner';

const styles = StyleSheet.create({
  productName: {
    width: '43%'
  },
  productCode: {
    width: '15%'
  },
  itemNo: {
    width: '5%'
  },
  issuedQty: {
    width: '12%',
    textAlign: 'right'
  },
  itemPrice: {
    width: '12%',
    textAlign: 'right'
  },
  subTotal: {
    width: '13%',
    textAlign: 'right'
  },
  filterView: {
    paddingTop: 10
  },
  filterText: {
    fontSize: 9,
    fontWeight: 'bold'
  }
});

const headerStyle = StyleSheet.create({
  leftCol: {
    width: '70%',
    textAlign: 'left'
  },
  rightLabelCol: {
    width: '15%',
    textAlign: 'left'
  },
  rightCol: {
    width: '15%',
    textAlign: 'left'
  },
  fullCol: {
    width: '100%',
    textAlign: 'left'
  },
  highlight: {
    fontWeight: 'bold'
  }
});

const summaryStyles = StyleSheet.create({
  leftCol: {
    width: '58%',
    textAlign: 'left'
  },
  rightLabelCol: {
    width: '27%',
    textAlign: 'left'
  },
  rightCol: {
    width: '15%',
    textAlign: 'right',
    borderBottom: 0.5,
    borderStyle: 'solid',
    borderColor: '#bfbfbf'
  }
});

const DepartmentSummaryRpt = props => {
  const dispatch = useDispatch();
  const [filterFromDate, setFilterFromDate] = useState({
    field: 'fromdate',
    value: getFirstDateOfLastMonth(),
    label: ''
  });
  const [filterToDate, setFilterToDate] = useState({ field: 'todate', value: getLastDateOfLastMonth(), label: '' });
  // const [filterFromDate, setFilterFromDate] = useState(addDaysToDate(new Date(), -30));
  // const [filterToDate, setFilterToDate] = useState(new Date());
  const [filterClient, setFilterClient] = useState({ field: 'clientprofileid', value: 'all', label: 'All' });
  const rptData = useSelector(state => state.rptDeptSummary);

  const isLoading = rptData.isLoading || false;
  const data = rptData.rptList;
  const filterData = rptData.filterData;
  const reportHeading = 'Department Summary';
  let i = 1;

  useEffect(() => {
    dispatch(getAllRptDeptSummaryData(rptData.filterOptions.filterBy, rptData.filterOptions.sortBy, true));
  }, []);

  useEffect(() => {
    if (filterClient.value === 'all') {
      return;
    }
    dispatch(getAllRptDeptSummaryData(rptData.filterOptions.filterBy, rptData.filterOptions.sortBy, true));
    onGenerateHandler();
  }, [filterClient]);

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: `${reportHeading} Report`, path: '/operation-reports/department-summary', isCurrent: true }
  ];

  const handleDateInput = (field, dateVal, callSetMethod) => {
    const saveObj = {};
    const setDate = dateVal ? new Date(dateVal) : null;
    saveObj.value = setDate;
    saveObj.field = field;
    saveObj.label = '';
    callSetMethod(saveObj);
  };

  const dropdownChange = (field, selectionOption, callSetMethod) => {
    selectionOption.field = field;
    callSetMethod(selectionOption);
  };

  const setLocalChange = currentState => {
    dispatch(setOnSearchChange(currentState));
  };

  const onGenerateHandler = () => {
    let filterSearchList = rptData.filterOptions.filterBy || [];
    filterSearchList = getFilterData(filterSearchList, filterClient);
    filterSearchList = getFilterData(filterSearchList, filterFromDate);
    filterSearchList = getFilterData(filterSearchList, filterToDate);
    setLocalChange({
      ...rptData,
      data: { ...rptData.filterOptions, filterBy: filterSearchList }
    });

    dispatch(getAllRptDeptSummaryData(rptData.filterOptions.filterBy, rptData.filterOptions.sortBy));
  };

  // const getFilterDataDate = (filterSearchList, fieldName, fieldValue) => {
  //   const objIndex = filterSearchList.findIndex(x => x.name.toLowerCase() === fieldName);
  //   if (objIndex !== -1) {
  //     filterSearchList[objIndex].value = fieldValue;
  //   } else {
  //     filterSearchList.push({ name: fieldName, value: fieldValue });
  //   }
  //   return filterSearchList;
  // };

  const getFilterData = (filterSearchList, filterObj) => {
    const objIndex = filterSearchList.findIndex(x => x.name.toLowerCase() === filterObj.field);
    if (objIndex !== -1) {
      filterSearchList[objIndex].value = filterObj.value;
      filterSearchList[objIndex].valueName = filterObj.label ?? '';
    } else {
      filterSearchList.push({ name: filterObj.field, value: filterObj.value, valueName: filterObj.label });
    }
    return filterSearchList;
  };

  const currentFromDate = rptData.filterOptions.filterBy.find(x => x.name === 'fromdate').value;
  const currentToDate = rptData.filterOptions.filterBy.find(x => x.name === 'todate').value;

  const filterText = `Invoice analysis by department for the period : ${moment(new Date(currentFromDate)).format('DD-MMM-YYYY') ||
    ''} - ${moment(new Date(currentToDate)).format('DD-MMM-YYYY') || ''}`;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={`${reportHeading} Report`} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="3">
                      <div className="mb-3">
                        <Dropdown
                          placeholder="All"
                          label="Client"
                          name="clientprofileid"
                          options={filterData.clientProfiles}
                          isMulti={false}
                          value={filterData?.clientProfiles?.find(x => x.value === filterClient?.value)}
                          onChange={val => dropdownChange('clientprofileid', val, setFilterClient)}
                          validate={{
                            required: { value: false }
                          }}
                        />
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="mb-4">
                        <DatePicker
                          label="From Date"
                          selected={filterFromDate.value}
                          onChange={date => handleDateInput('fromdate', date, setFilterFromDate)}
                          dateFormat="dd-MMM-yyyy"
                        />
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="mb-4">
                        <DatePicker
                          label="To Date"
                          selected={filterToDate.value}
                          onChange={date => handleDateInput('todate', date, setFilterToDate)}
                          dateFormat="dd-MMM-yyyy"
                          minDate={filterFromDate.value}
                        />
                      </div>
                    </Col>

                    <Col sm="5">
                      <div className="text-sm-end">
                        <Button type="button" className="btn btn-primary btn-block  app-add-button" onClick={onGenerateHandler}>
                          GENERATE
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      {isLoading ? (
                        <LoadingSpinner />
                      ) : data.length === 0 ? (
                        <div className="no-report-data">
                          {' '}
                          <div className="no-report-data-text">No Data Available</div>{' '}
                        </div>
                      ) : (
                        <ReportViewer>
                          <Document title={reportHeading}>
                            {data.map(departmentItem => {
                              return (
                                <Page style={generalStyles.body} key={departmentItem.departmentCode}>
                                  <ReportHeader reportTitle={reportHeading} />

                                  <View style={noBorderTableStyle.table}>
                                    <View style={noBorderTableStyle.tableRow}>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.leftCol]}>
                                        <Text style={[noBorderTableStyle.tableCell, headerStyle.highlight]}>
                                          {' '}
                                          {departmentItem.clientName}{' '}
                                        </Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightLabelCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> Reference :</Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> {departmentItem.departmentCode} </Text>
                                      </View>
                                    </View>
                                    <View style={noBorderTableStyle.tableRow}>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.leftCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> {departmentItem.clientAddress1} </Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightLabelCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> Date :</Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> {departmentItem.reportDate}</Text>
                                      </View>
                                    </View>
                                    <View style={noBorderTableStyle.tableRow}>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.leftCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> {departmentItem.clientAddress2} </Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightLabelCol]}>
                                        <Text style={noBorderTableStyle.tableCell}></Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightCol]}>
                                        <Text style={noBorderTableStyle.tableCell}></Text>
                                      </View>
                                    </View>
                                    <View style={noBorderTableStyle.tableRow}>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.leftCol]}>
                                        <Text style={noBorderTableStyle.tableCell}>
                                          Contact : {departmentItem.clientContactNo} for inquires{' '}
                                        </Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightLabelCol]}>
                                        <Text style={noBorderTableStyle.tableCell}></Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightCol]}>
                                        <Text style={noBorderTableStyle.tableCell}></Text>
                                      </View>
                                    </View>
                                    <View style={noBorderTableStyle.tableRow}>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.leftCol]}>
                                        <Text style={[noBorderTableStyle.tableCell, headerStyle.highlight]}>
                                          ABN : {departmentItem.clientProfileABN}{' '}
                                        </Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightLabelCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> </Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> </Text>
                                      </View>
                                    </View>
                                    <View style={noBorderTableStyle.tableRow}>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.leftCol]}>
                                        <Text style={[noBorderTableStyle.tableCell, headerStyle.highlight]}> </Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightLabelCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> </Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> </Text>
                                      </View>
                                    </View>
                                    <View style={noBorderTableStyle.tableRow}>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.leftCol]}>
                                        <Text style={[noBorderTableStyle.tableCell, headerStyle.highlight]}>
                                          {departmentItem.departmentName}{' '}
                                        </Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightLabelCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> </Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> </Text>
                                      </View>
                                    </View>
                                  </View>
                                  <View style={styles.filterView}>
                                    <Text style={styles.filterText}>{filterText}</Text>
                                  </View>
                                  <View style={generalStyles.table}>
                                    <View style={generalStyles.tableRow}>
                                      <View style={[generalStyles.tableColHeader, styles.itemNo]}>
                                        <Text style={generalStyles.tableCellHeader}>Item No</Text>
                                      </View>
                                      <View style={[generalStyles.tableColHeader, styles.productCode]}>
                                        <Text style={generalStyles.tableCellHeader}>Stock Code</Text>
                                      </View>
                                      <View style={[generalStyles.tableColHeader, styles.productName]}>
                                        <Text style={generalStyles.tableCellHeader}>Description</Text>
                                      </View>
                                      <View style={[generalStyles.tableColHeader, styles.issuedQty]}>
                                        <Text style={generalStyles.tableCellHeader}>Issued Qty</Text>
                                      </View>
                                      <View style={[generalStyles.tableColHeader, styles.itemPrice]}>
                                        <Text style={generalStyles.tableCellHeader}>Item Price($)</Text>
                                      </View>
                                      <View style={[generalStyles.tableColHeader, styles.subTotal]}>
                                        <Text style={generalStyles.tableCellHeader}>Charge($)</Text>
                                      </View>
                                    </View>
                                    {departmentItem.summaryDetails && departmentItem.summaryDetails.length > 0 && (
                                      <ReportDataRow results={departmentItem.summaryDetails} />
                                    )}
                                  </View>
                                  <View style={styles.filterView}>
                                    <Text style={styles.filterText}>Credit Notes</Text>
                                  </View>
                                  <View style={generalStyles.table}>
                                    {departmentItem.creditNotes && departmentItem.creditNotes.length > 0 && (
                                      <ReportDataRowCreditNote results={departmentItem.creditNotes} />
                                    )}
                                  </View>
                                  <View style={noBorderTableStyle.table}>
                                    <View style={noBorderTableStyle.tableRow}>
                                      <View style={[noBorderTableStyle.tableCol, summaryStyles.leftCol]}>
                                        <Text style={[noBorderTableStyle.tableCell]}></Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, summaryStyles.rightLabelCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> Total Item(s) :</Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, summaryStyles.rightCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> {departmentItem?.summaryDetails?.length} </Text>
                                      </View>
                                    </View>
                                    <View style={noBorderTableStyle.tableRow}>
                                      <View style={[noBorderTableStyle.tableCol, summaryStyles.leftCol]}>
                                        <Text style={[noBorderTableStyle.tableCell]}></Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, summaryStyles.rightLabelCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> Department Total($) :</Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, summaryStyles.rightCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> {departmentItem?.departmentTotal?.toFixed(2)} </Text>
                                      </View>
                                    </View>
                                    <View style={noBorderTableStyle.tableRow}>
                                      <View style={[noBorderTableStyle.tableCol, summaryStyles.leftCol]}>
                                        <Text style={[noBorderTableStyle.tableCell]}></Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, summaryStyles.rightLabelCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> GST($) :</Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, summaryStyles.rightCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> {departmentItem?.gst?.toFixed(2)} </Text>
                                      </View>
                                    </View>
                                    <View style={noBorderTableStyle.tableRow}>
                                      <View style={[noBorderTableStyle.tableCol, summaryStyles.leftCol]}>
                                        <Text style={[noBorderTableStyle.tableCell]}></Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, summaryStyles.rightLabelCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> Dept. Total Including GST($) :</Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, summaryStyles.rightCol]}>
                                        <Text style={noBorderTableStyle.tableCell}>
                                          {' '}
                                          {departmentItem?.departmentTotalWithGst?.toFixed(2)}{' '}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                  <ReportFooter footerInfo={reportHeading}>
                                    <Text
                                      style={generalStyles.pageNumber}
                                      render={({ pageNumber, totalPages }) => `Page ${pageNumber} of  ${totalPages}`}
                                    />
                                  </ReportFooter>
                                </Page>
                              );
                            })}
                          </Document>
                        </ReportViewer>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const ReportDataRow = ({ results }) => {
  return (
    <View>
      {results.map((item, index) => {
        return (
          // <reportDataRow item={n} key={n.costCenterId}/>
          <View style={generalStyles.tableRow} key={item.productCode}>
            <View style={[generalStyles.tableCol, styles.itemNo]}>
              <Text style={generalStyles.tableCell}>{index + 1}</Text>
            </View>
            <View style={[generalStyles.tableCol, styles.productCode]}>
              <Text style={generalStyles.tableCell}>{item.productCode}</Text>
            </View>
            <View style={[generalStyles.tableCol, styles.productName]}>
              <Text style={generalStyles.tableCell}> {item.productName} </Text>
            </View>
            <View style={[generalStyles.tableCol, styles.issuedQty]}>
              <Text style={generalStyles.tableCell}>{item.issuedQty}</Text>
            </View>
            <View style={[generalStyles.tableCol, styles.itemPrice]}>
              <Text style={generalStyles.tableCell}>{item.itemPrice.toFixed(3)}</Text>
            </View>
            <View style={[generalStyles.tableCol, styles.subTotal]}>
              <Text style={generalStyles.tableCell}>{item.subTotal.toFixed(2)}</Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

const ReportDataRowCreditNote = ({ results }) => {
  return (
    <View>
      {results.map(item => {
        return (
          // <reportDataRow item={n} key={n.costCenterId}/>
          <View style={generalStyles.tableRow} key={item.creditNoteNo}>
            <View style={[generalStyles.tableCol, styles.productCode]}>
              <Text style={generalStyles.tableCell}>{item.creditNoteNo}</Text>
            </View>
            <View style={[generalStyles.tableCol, styles.productName]}>
              <Text style={generalStyles.tableCell}> {item.remarks} </Text>
            </View>
            <View style={[generalStyles.tableCol, styles.issuedQty]}></View>
            <View style={[generalStyles.tableCol, styles.itemPrice]}></View>
            <View style={[generalStyles.tableCol, styles.subTotal]}>
              <Text style={generalStyles.tableCell}>-{item.amount.toFixed(2)}</Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default DepartmentSummaryRpt;
