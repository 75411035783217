import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle, Col, Container, Row, TabContent } from 'reactstrap';

import { AvForm } from 'availity-reactstrap-validation';
import { initialClientPriceState, initialDistributionState } from '../../../store/reducers/initialState';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _users } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';

import ErrorListing from '../../CustomControls/ErrorListing';
import { createDistributionCenter, setOnCleanUp, updateDistributionCenter } from '../../../store/actions/Management/distributionCenter';
import PricingTable from './PricingTable';
import { getAllClientPricing, setOnChange } from '../../../store/actions/Master/pricing';

const Pricing = props => {
  let history = useHistory();
  const backUrl = '/management/distribution-center';
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const distribution = useSelector(state => state.distribution);
  const pricing = useSelector(state => state.pricing);
  const currentActiveTab = useSelector(state => state.distribution.currentActiveTab);
  const [urlId, setUrlId] = useState('');

  const [selectedIds, setSelectedIds] = useState([]);
  const notificationType = user.isError ? 'error' : 'success';
  // const [currentActiveTab, setCurrentActiveTab] = useState('general');
  const [selectedManager, setSelectedManager] = useState('');

  let currentUser = distribution.currentDistribution;
  let distributionDetails = distribution.distributionDetails;
  let heading = 'PRICING DETAILS';

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Pricing', path: '/master-pricing' }
  ];

  //clean up
  useEffect(() => {
    const parts = history.location.pathname.split('/')[4] ?? '';
    setUrlId(parts);

    return () => {
      setLocalCleanUpData({
        data: { ...pricing, ...initialClientPriceState.selectedIds },
        selectedIds: []
      });
      // setSelectedIds([]);
    };
  }, []);

  const setLocalCleanUpData = cleanUpData => {
    dispatch(setOnCleanUp(cleanUpData));
  };

  const setLocalChange = currentState => {
    dispatch(setOnChange(currentState));
  };

  const handleInput = e => {
    setLocalChange({
      ...distribution,
      data: { ...distribution.currentDistribution, [e.target.name]: e.target.value }
    });
  };

  const clientTab = val => {
    setLocalChange({
      ...distribution,
      currentActiveTab: val
    });
  };
  const handleSubmit = e => {
    if (currentActiveTab === 'general') {
      const payload = {
        dcCode: currentUser.dcCode,
        name: currentUser.name
      };
      if (urlId === '') {
        dispatch(createDistributionCenter(payload));
      } else {
        clientTab('clients');
      }
    } else if (currentActiveTab === 'clients') {
      clientTab('managers');
    } else {
      const payload = {
        Dcid: distribution.distributionId,
        ClientProfileIds: selectedIds,
        DcManagerId: selectedManager,
        Name: currentUser.name,
        dcCode: currentUser.dcCode,
        IsActive: true
      };
      dispatch(updateDistributionCenter(payload));
      cancelHandler();
    }
  };
  const dropDownChange = data => {
    setSelectedManager(data);
  };
  const onClientSelectionHandler = (clientId, value) => {
    if (value === true) {
      setSelectedIds(() => [...selectedIds, clientId]);
      let currentClients = pricing.clientList || [];
      const objIndex = currentClients.findIndex(obj => obj.clientProfileId == clientId);
      currentClients[objIndex].isActive = !value;

      setLocalChange({
        ...pricing,
        clientList: currentClients,
        selectedIds: [...selectedIds, clientId]
      });
    } else {
      const filterData = selectedIds.filter(item => {
        return item !== clientId;
      });
      setSelectedIds(filterData);
      let currentClients = pricing.clientList || [];
      const objIndex = currentClients.findIndex(obj => obj.clientProfileId == clientId);
      currentClients[objIndex].isActive = !value;

      setLocalChange({
        ...pricing,
        clientList: currentClients,
        selectedIds: filterData
      });
    }
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  return (
    <React.Fragment>
      {/* {distribution.redirect ? <Redirect to={backUrl} /> : ''} */}
      {distribution.isLoading && <LoadingSpinner />}
      {distribution.showMessage && <Notification notificationType={notificationType} message={distribution.message} />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardTitle>
                  <Row className="buttonrow">
                    <Col md="6">Pricing Details</Col>
                  </Row>
                </CardTitle>
                <CardBody>
                  {currentUser.errorList && currentUser.errorList.length > 0 && <ErrorListing errorList={currentUser.errorList} />}

                  <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                    <TabContent activeTab={currentActiveTab} className="p-3 text-muted">
                      <Row>
                        <Col sm="12">
                          <PricingTable currentUser={currentUser} onClientSelectionHandler={onClientSelectionHandler} />
                        </Col>
                      </Row>
                    </TabContent>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Pricing;
