import { LOCATION_GET_ALL_DATA, LOCATION_GET_DATA, LOCATION_ERROR, LOCATION_IS_LOADING, LOCATION_SAVE_DATA, LOCATION_ON_CHANGE_DATA, LOCATION_CLEAN_UP ,LOCATION_DELETE_DATA, LOCATION_UPDATE_CONFIRMATION} from '../../../constants/index';
import { apiHelper } from '../../../utils/index';
import Cookies from 'js-cookie';
import { setErrorThunk, setLoadingStatus, setGetResults, setDescribeData, setSaveData, setOnChangeData, setOnCleanUpData ,setDeleteData,setOnConfirmationData} from '../actionHelper';

const API_URL = '/location'

export const getAllLocations = () => dispatch => {
    dispatch(_getAllLocations());
};

const _getAllLocations = () =>
    apiHelper({
        url: `${API_URL}`,
        method: 'GET',
        onLoad: status => setLoadingStatus(LOCATION_IS_LOADING, status),
        onSuccess: result => setGetResults(LOCATION_GET_ALL_DATA, result),
        onFailure: err => setErrorThunk(LOCATION_ERROR, true, err),
        accessToken: Cookies.get('accessToken'),
    });

export const describeLocation = (id) => dispatch => {
    dispatch(_getLocation(id));
};

const _getLocation = (id) =>
    apiHelper({
        url: `${API_URL}/${id}`,
        method: 'GET',
        data: {
            id: id
        },
        onLoad: status => setLoadingStatus(LOCATION_IS_LOADING, status),
        onSuccess: result => setDescribeData(LOCATION_GET_DATA, result),
        onFailure: err => setErrorThunk(LOCATION_ERROR, true, err),
        accessToken: Cookies.get('accessToken'),
    });

export const saveLocation = (saveData, isNew, id = null) => dispatch => {
    let MethodType = 'POST';
    if (!isNew) {
        MethodType = 'PUT'
    }

    let apiUrl = id ? `${API_URL}/${id}` : API_URL;

    dispatch(_saveLocation(saveData, MethodType, apiUrl));
}

const _saveLocation = (data, MethodType, apiUrl) =>
    apiHelper({
        url: apiUrl,
        method: MethodType,
        onSuccess: result => setSaveData(LOCATION_SAVE_DATA, true, result),
        onFailure: err => setErrorThunk(LOCATION_ERROR, true, err),
        onLoad: status => setLoadingStatus(LOCATION_IS_LOADING, status),
        data,
        accessToken: Cookies.get('accessToken'),
    });


export const setOnChange = retData => dispatch => {
    dispatch(setOnChangeData(LOCATION_ON_CHANGE_DATA, retData));
};

export const setOnCleanUp = retData => dispatch => {
    dispatch(setOnCleanUpData(LOCATION_CLEAN_UP, retData));
};



// DELETE OPERATION
export const deleteLocation = (id) => dispatch => {
    let MethodType = 'DELETE';
    let apiUrl = id ? `${API_URL}/${id}` : API_URL;

    dispatch(_deleteLocation(MethodType, apiUrl));
    
}

const _deleteLocation = (MethodType, apiUrl) =>
    apiHelper({
        url: apiUrl,
        method: MethodType,
        onSuccess: result => onDeleteSuccess(result),
        onFailure: err => setErrorThunk(LOCATION_ERROR, true, err),
        onLoad: status => setLoadingStatus(LOCATION_IS_LOADING, status),
        accessToken: Cookies.get('accessToken'),
    });

export const setOnConfirmationModal = retData => dispatch => {
    dispatch(setOnConfirmationData(LOCATION_UPDATE_CONFIRMATION, retData));
};


export const onDeleteSuccess = (result) => dispatch => {
    dispatch(setDeleteData(LOCATION_DELETE_DATA, true, result));
    dispatch(_getAllLocations());
};
