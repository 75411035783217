import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import DatePicker from '../../CustomControls/CustomDatePicker';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFirstDateOfLastMonth, getLastDateOfLastMonth } from '../../../utils';
import { getAllRptDeptSummaryData, getCSVReport, setOnCleanUp } from '../../../store/actions/ReportOperation/rptDepartmentSummary';
import LoadingSpinner from '../../CustomControls/Spinner';
import Papa from 'papaparse';
import { initialRptDeptSummaryState } from '../../../store/reducers/initialReportState';
import Dropdown from '../../CustomControls/Dropdown';

const DepartmentSummaryCSV = () => {
  const dispatch = useDispatch();
  const [filterFromDate, setFilterFromDate] = useState({
    field: 'fromdate',
    value: getFirstDateOfLastMonth(),
    label: ''
  });
  const [filterToDate, setFilterToDate] = useState({
    field: 'todate',
    value: getLastDateOfLastMonth(),
    label: ''
  });
  const [filterClient, setFilterClient] = useState({
    field: 'clientProfileId',
    value: 'all',
    label: 'All'
  });
  const [isError, setIsError] = useState(false);
  const rptData = useSelector(state => state.rptDeptSummary);
  const isLoading = rptData.isLoading || false;
  const filterData = rptData.filterData;
  const csvRptData = useSelector(state => state?.rptDeptSummary?.csvDataDep);

  const [csvData, setCsvData] = useState();
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  useEffect(() => {
    setCsvData(csvRptData);
    setCurrentPage(1);
  }, [csvRptData]);
  const reportHeading = 'Department Summary';
  const setLocalCleanUpData = cleanUpData => {
    dispatch(setOnCleanUp(cleanUpData));
  };

  //clean up
  useEffect(() => {
    return () => {
      setLocalCleanUpData({
        data: { ...initialRptDeptSummaryState, csvDataDep: [] }
      });
    };
  }, []);
  useEffect(() => {
    dispatch(getAllRptDeptSummaryData(rptData.filterOptions.filterBy, rptData.filterOptions.sortBy, true));
  }, []);

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    {
      id: 2,
      title: `${reportHeading} CSV`,
      path: '/operation-reports/department-summary-csv',
      isCurrent: true
    }
  ];

  const handleDateInput = (field, dateVal, callSetMethod) => {
    const saveObj = {};
    const setDate = dateVal ? new Date(dateVal) : null;
    saveObj.value = setDate;
    saveObj.field = field;
    saveObj.label = '';
    callSetMethod(saveObj);
  };
  const dropdownChange = (field, selectionOption, callSetMethod) => {
    selectionOption.field = field;
    callSetMethod(selectionOption);
    setIsError(false);
    // onGenerateHandler();
    // callSetMethod(prevState => {
    //   const updatedState = { ...prevState, ...selectionOption };
    //   // onGenerateHandler(updatedState);
    //   return updatedState;
    // });
  };
  const onGenerateHandler = () => {
    if (filterClient.label === 'All') {
      setIsError(true);
      return;
    }
    dispatch(getCSVReport(new Date(filterFromDate.value), new Date(filterToDate.value), filterClient.value));
  };
  const downloadCsv = () => {
    const csvContent = Papa.unparse(csvData);
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    a.click();
    URL.revokeObjectURL(url);
  };

  const totalPages = Math.ceil(csvData?.length / rowsPerPage);
  const currentData = csvData?.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  const handlePageChange = newPage => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={`${reportHeading} CSV`} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              {isError && <div className="text-danger">Please select a specific client.</div>}
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="3">
                      <div className="mb-3">
                        <Dropdown
                          placeholder="All"
                          label="Client"
                          name="clientprofileid"
                          options={filterData.clientProfiles}
                          isMulti={false}
                          value={filterData?.clientProfiles?.find(x => x.value === filterClient?.value)}
                          onChange={val => dropdownChange('clientProfileId', val, setFilterClient)}
                          validate={{
                            required: { value: false }
                          }}
                        />
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="mb-4">
                        <DatePicker
                          label="From Date"
                          selected={filterFromDate.value}
                          onChange={date => handleDateInput('fromdate', date, setFilterFromDate)}
                          dateFormat="dd-MMM-yyyy"
                          disabled={filterClient.label === 'All'}
                        />
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="mb-4">
                        <DatePicker
                          label="To Date"
                          selected={filterToDate.value}
                          onChange={date => handleDateInput('todate', date, setFilterToDate)}
                          dateFormat="dd-MMM-yyyy"
                          minDate={filterFromDate.value}
                          disabled={filterClient.label === 'All'}
                        />
                      </div>
                    </Col>

                    <Col sm="5">
                      <div className="text-sm-end">
                        <Button type="button" className="btn btn-primary btn-block  app-add-button" onClick={onGenerateHandler}>
                          GENERATE
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {csvData?.length > 0 && (
                    <>
                      <Row>
                        <Col>
                          <Button onClick={downloadCsv} type="button" className="btn btn-primary btn-block mb-3 mx-auto ">
                            Download CSV
                          </Button>
                        </Col>
                      </Row>
                      <div style={{ overflowX: 'auto' }}>
                        <Table>
                          <thead>
                            <tr>{csvData?.length > 0 && Object.keys(csvData[0]).map((header, index) => <th key={index}>{header}</th>)}</tr>
                          </thead>
                          <tbody>
                            {currentData?.map((row, rowIndex) => (
                              <tr key={rowIndex}>
                                {Object.values(row).map((cell, cellIndex) => (
                                  <td key={cellIndex}>{cell}</td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      <span>Total records: {csvData?.length}</span>

                      {/* Pagination Controls */}
                      <div className="d-flex justify-content-between align-items-center mt-3">
                        <Button disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
                          Previous
                        </Button>
                        <span>
                          Page {currentPage} of {totalPages}
                        </span>
                        <Button disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
                          Next
                        </Button>
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DepartmentSummaryCSV;
