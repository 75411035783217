// actions
export const _create = 'create';
export const _view = 'view';
export const _delete = 'delete';
export const _update = 'update';
export const _reportprint = 'printreport';
export const _adminops = 'adminops';

// Components

export const _general = 'general';
export const _company = 'company';
export const _users = 'users';
export const _resetpassword = 'resetpassword';
export const _groups = 'groups';
export const _clientprofile = 'clientprofile';
export const _costcenter = 'costcenter';
export const _department = 'department';
export const _producttype = 'producttype';
export const _location = 'location';
export const _auditlogs = 'auditlogs';
export const _product = 'product';
export const _allowbackdate = 'allowbackdate';
export const _requisition = 'requisition';
export const _dispatch = 'dispatch';
export const _dispatchbulk = 'dispatchbulk';
export const _creditnote = 'creditnote';
export const _reportlocationlisting = 'report_location_listing';
export const _reportclientprofilelisting = 'report_clientprofile_listing';
export const _reportcostcenterlisting = 'report_costcenter_listing';
export const _reportdepartmentlisting = 'report_department_listing';
export const _reportproducttypelisting = 'report_producttype_listing';
export const _reportproductlisting = 'report_product_listing';
export const _reportdepartmentsummary = 'report_department_summary';
export const _customerproduct = 'customerproduct';
export const _customerorder = 'customerorder';
export const _allcustomerorders = 'allcustomerorders';
export const _allcustomers = 'allcustomers';
export const _emailconfigurations = 'emailconfigurations';
export const _pricing = 'pricing';
export const _auditpricinglogs = 'auditpricinglogs';
export const _distributioncenter = 'distributioncenter';
export const _productDetails = 'productdetails';
export const _clientadmin = 'clientadmin';
export const _distributionmanager = 'distributionmanager';
export const _pendingorder = 'pendingorder';
export const _internalorder = 'internalorder';
