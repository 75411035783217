import {
  DISTRIBUTION_ERROR,
  DISTRIBUTION_IS_LOADING,
  DISTRIBUTION_ON_CHANGE_DATA,
  DISTRIBUTION_CLEAN_UP,
  PRICING_GET_ALL_DATA,
  PRICING_ON_CHANGE_DATA,
  PRICING_CLEAN_UP
} from '../../../constants/index';
import { initialClientPriceState } from '../initialState';

const pricingReducer = (state = initialClientPriceState, action) => {
  const { payload, type } = action;
  switch (type) {
    case DISTRIBUTION_ERROR: {
      const { showMessage, errorMessage, errorList } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage,
        errorList: errorList
      };
    }
    case PRICING_GET_ALL_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        clientList: resultData
      };
    }
    // case DISTRIBUTION_GET_ALL_CLIENTS: {
    //   const { resultData } = payload;
    //   return {
    //     ...state,
    //     distributionDetails: resultData,
    //     currentDistribution: {
    //       ...state.currentDistribution,
    //       dcCode: resultData.dcCode,
    //       name: resultData.name
    //     }
    //   };
    // }
    // case DISTRIBUTION_ID: {
    //   const { resultData } = payload;
    //   return {
    //     ...state,
    //     distributionId: resultData.createdId,
    //     currentActiveTab: 'clients'
    //   };
    // }
    // case COMPANY_GET_DATA: {
    //   const { resultData } = payload;
    //   return {
    //     ...state,
    //     currentCompany: {
    //       ...state.currentCompany,
    //       ...resultData
    //     }
    //   };
    // }
    case DISTRIBUTION_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }
    // case COMPANY_SAVE_DATA: {
    //   const { showMessage, message, redirect } = payload;
    //   return {
    //     ...state,
    //     isError: false,
    //     redirect: redirect,
    //     showMessage: showMessage,
    //     message: message
    //   };
    // }

    case PRICING_ON_CHANGE_DATA: {
      let { data, clientList, selectedIds } = payload;
      let responseObject = {
        ...state,
        editClient: {
          ...state.editClient,
          ...data
        },
        clientList: clientList,
        selectedIds: selectedIds
      };

      return responseObject;
    }
    case PRICING_CLEAN_UP: {
      let { selectedIds } = payload;

      let responseObject = {
        ...state,
        message: '',
        isError: false,
        showMessage: false,
        redirect: false,
        editClient: {
          price: 0,
          pricrType: ''
        }
      };
      if (selectedIds) responseObject.selectedIds = selectedIds;
      return responseObject;
    }

    default:
      return state;
  }
};

export default pricingReducer;
