import { AUDITLOG_GET_ALL_DATA, AUDITLOG_ERROR, AUDITLOG_IS_LOADING,  AUDITLOG_ON_CHANGE_DATA, AUDITLOG_CLEAN_UP }  from '../../../constants/index';
import { initialAuditLogState } from '../initialState';

const auditLogReducer = (state = initialAuditLogState, action) => {
  const { payload, type } = action;
  switch (type) {
    case AUDITLOG_ERROR: {
      const { showMessage, errorMessage } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage
      };
    }
    case AUDITLOG_GET_ALL_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        auditLogList: resultData.data,
        totalRecords : resultData.totalRecords,
        filterOptions: {
            ...state.filterOptions,
            pageNumber : resultData.pageNumber,
            pageSize  : resultData.pageSize,
          }
      };
    }

    case AUDITLOG_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }

    case AUDITLOG_ON_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          ...data
        }
      };
    }
    case AUDITLOG_CLEAN_UP: {
      let { data } = payload;

      return {
        ...state,
        message: '',
        isError: false,
        showMessage: false,
        redirect: false,
        filterOptions: {
          ...state.filterOptions,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default auditLogReducer;




