import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Table from './DispatchTable';
import Pagination from '../../CustomControls/Pagination';
import { getAllDispatches, setOnSearchChange, setOnConfirmationModal, deleteDispatch } from '../../../store/actions/Operation/dispatch';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _reportprint, _dispatch, _dispatchbulk, _delete } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import { addDaysToDate } from '../../../utils';
import SearchBar from '../../CustomControls/SearchBar';
import DatePicker from '../../CustomControls/CustomDatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import CustomConfirmation from '../../CustomControls/ConfirmationModal';

import './style.scss';

const Dispatch = props => {
  const [searchText, setSearchText] = useState('');
  const [searchFromDate, setSearchFromDate] = useState(addDaysToDate(new Date(), -30));
  const [searchToDate, setSearchToDate] = useState(new Date());
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'desc' });
  const history = useHistory();
  const dispatchAction = useDispatch();
  const dispatch = useSelector(state => state.dispatch);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  const pageSize = ui.layout.pageSize;
  const currentPageNumber = dispatch.filterOptions.pageNumber;

  const notificationType = dispatch.isError ? 'error' : 'success';

  const showSaveOption = hasPermissionToAction(permissions, _dispatch, _create);
  const showBulkDispatch = hasPermissionToAction(permissions, _dispatchbulk, _create);
  const showPrintOption = hasPermissionToAction(permissions, _dispatch, _reportprint);
  const showDeleteOption = hasPermissionToAction(permissions, _dispatch, _delete);

  useEffect(() => {
    dispatchAction(
      getAllDispatches(currentPageNumber, pageSize, dispatch.filterOptions.filterBy, dispatch.filterOptions.sortBy, sortConfig)
    );
  }, [dispatchAction, currentPageNumber, pageSize, sortConfig]);

  const onPageChangeHandler = currentPage => {
    setLocalChange({
      ...dispatch,
      data: { ...dispatch.filterOptions, pageNumber: currentPage }
    });
  };

  const onBulkDispatchHandler = () => {
    history.push('/operation/dispatches/bulk-dispatch');
  };

  const onAddNewHandler = () => {
    history.push('/operation/dispatches/dispatch');
  };

  const onEditHandler = id => {
    history.push(`/operation/dispatches/dispatch/${id}`);
  };

  const onPrintHandler = id => {
    history.push(`/operation/dispatches/dispatch-report/${id}`);
  };

  useEffect(() => {
    return () => {
      let filterListCurrent = dispatch.filterOptions.filterBy || [];
      if (filterListCurrent.length > 0) {
        filterListCurrent = filterListCurrent.filter(x => x.name.toLowerCase() !== 'dispatchref');
      }
      setLocalChange({
        ...dispatch,
        data: { ...dispatch.filterOptions, filterBy: filterListCurrent }
      });
    };
  }, []);

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Dispatch Listing', path: '/operation/dispatches', isCurrent: true }
  ];

  const handleInput = e => {
    const searchVal = e.target.value ?? '';

    setSearchText(searchVal);
    let filterSearchList = dispatch.filterOptions.filterBy || [];

    const objIndex = filterSearchList.findIndex(x => x.name.toLowerCase() === 'dispatchref');

    if (objIndex !== -1) {
      filterSearchList[objIndex].value = searchVal.toLowerCase();
    } else {
      filterSearchList.push({ name: 'dispatchref', value: searchVal.toLowerCase() });
    }
  };
  const handleDateInput = (field, dateVal) => {
    const setDate = dateVal ? new Date(dateVal) : null;
    if (field === 'fromdate') {
      setSearchFromDate(setDate);
    }

    if (field === 'todate') {
      setSearchToDate(setDate);
    }

    let filterSearchList = dispatch.filterOptions.filterBy || [];

    const objIndex = filterSearchList.findIndex(x => x.name.toLowerCase() === field);

    if (objIndex !== -1) {
      filterSearchList[objIndex].value = setDate.toDateString();
    } else {
      filterSearchList.push({ name: field, value: setDate.toDateString() });
    }

    setLocalChange({
      ...dispatch,
      data: { ...dispatch.filterOptions, filterBy: filterSearchList }
    });
  };

  const setLocalChange = currentState => {
    dispatchAction(setOnSearchChange(currentState));
  };

  const onSearchHandler = e => {
    dispatchAction(getAllDispatches(currentPageNumber, pageSize, dispatch.filterOptions.filterBy, dispatch.filterOptions.sortBy));
  };

  const onDeleteHandler = id => {
    dispatchAction(
      setOnConfirmationModal({
        data: {
          showConfirmationModal: true,
          confirmationId: id
        }
      })
    );
  };

  const onDeleteConfirmation = () => {
    if (dispatch.confirmationId) {
      let ftOptions = {
        currentPageNumber: currentPageNumber,
        pageSize: pageSize,
        filterBy: dispatch.filterOptions.filterBy,
        sortBy: dispatch.filterOptions.sortBy
      };
      dispatchAction(deleteDispatch(dispatch.confirmationId, ftOptions));
    }
  };

  const onDeleteCancel = () => {
    dispatchAction(
      setOnConfirmationModal({
        data: {
          showConfirmationModal: false,
          confirmationId: ''
        }
      })
    );
  };

  const handleSort = key => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  return (
    <React.Fragment>
      <CustomConfirmation
        show={dispatch.showConfirmationModal}
        onDeleteClick={onDeleteConfirmation}
        onCloseClick={onDeleteCancel}
        message="Are you sure you want to delete this record?"
        header="Deleting record"
      />
      <div className="page-content">
        {dispatch.showMessage && <Notification notificationType={notificationType} message={dispatch.message} />}
        <Container fluid>
          <Breadcrumbs title="Dispatch Listing " paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="8">
                      <Row>
                        <Col sm="3">
                          <SearchBar onChange={handleInput} placeholder="Search By Dispatch No" value={searchText} />
                        </Col>
                        <Col sm="3">
                          <div className="mb-4">
                            <DatePicker
                              label="From Date"
                              selected={searchFromDate}
                              onChange={date => handleDateInput('fromdate', date)}
                              dateFormat="dd-MMM-yyyy"
                            />
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="mb-4">
                            <DatePicker
                              label="To Date"
                              selected={searchToDate}
                              onChange={date => handleDateInput('todate', date)}
                              dateFormat="dd-MMM-yyyy"
                              minDate={searchFromDate}
                            />
                          </div>
                        </Col>
                        <Col sm="3" className="m-auto">
                          <div className="text-sm-start">
                            <Button type="button" className="btn btn-outline-primary" onClick={onSearchHandler}>
                              <FontAwesomeIcon className="standardIcon" icon={faSearch} /> Search
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="4">
                      <Row>
                        <Col sm="12">
                          <div className="text-sm-end">
                            {showBulkDispatch && (
                              <Button type="button" className="btn btn-primary btn-block app-add-button" onClick={onBulkDispatchHandler}>
                                BULK
                              </Button>
                            )}
                            {showSaveOption && (
                              <Button type="button" className="btn btn-primary btn-block app-add-button" onClick={onAddNewHandler}>
                                NEW
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="tablerow">
                    <Col xl="12">
                      <div>
                        {dispatch.isLoading && <LoadingSpinner />}
                        <Pagination
                          data={dispatch.dispatchList}
                          pageSize={pageSize}
                          onPageChange={onPageChangeHandler}
                          startingPage={currentPageNumber}
                          apiPaging={true}
                          apiDataLength={dispatch.totalRecords}
                        >
                          <Table
                            editHandler={onEditHandler}
                            printHandler={onPrintHandler}
                            showPrint={showPrintOption}
                            deleteHandler={onDeleteHandler}
                            showDelete={showDeleteOption}
                            sortConfig={sortConfig}
                            onSort={handleSort}
                          />
                        </Pagination>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dispatch;
