import { decode } from 'jsonwebtoken';
import moment from 'moment';
import { get as getCookie } from 'js-cookie';

export const getUserState = state => state.user;
export const getUiState = state => state.ui;
export const getRouterState = state => state.router;
export const getAuthState = state => state.auth;

export const getTimezoneState = state => state.timezone;


// USERS
export const getUserLoginError = state => getAuthState(state).loginError;
export const getUserLoginErrorMessage = state => getAuthState(state).loginErrorMessage;

export const getUsername = state => getUserState(state).username;
export const getUserSessionId = state => getUserState(state).sessionId;
export const getIsUserAuthenticated = () => {
  const accessToken = getCookie('accessToken');
  const decodedToken = decode(accessToken);

  if (!decodedToken) return false;

  return moment.unix(decodedToken.exp) > moment();
};



// UI
export const getIsLoading = state => getUiState(state).isLoading;

export const getRoutePathname = state => getRouterState(state).location.pathname;

export const getCurrentTimezone = state => getTimezoneState(state).timezone;





