import { apiHelper } from '../../../utils/index';
import Cookies from 'js-cookie';
import { setErrorThunk, setLoadingStatus, setGetResults, setOnChangeData, setOnCleanUpData } from '../actionHelper';
import {
  DISTRIBUTION_CLEAN_UP,
  DISTRIBUTION_ERROR,
  DISTRIBUTION_GET_ALL_CLIENTS,
  DISTRIBUTION_GET_ALL_DATA,
  DISTRIBUTION_ID,
  DISTRIBUTION_IS_LOADING,
  DISTRIBUTION_ON_CHANGE_DATA
} from '../../../constants';

const API_URL = '/distributionCenter';

export const getAllDistributionCenters = result => dispatch => {
  dispatch(_getAllDistributionCenters());
};

const _getAllDistributionCenters = () =>
  apiHelper({
    url: `${API_URL}`,
    method: 'GET',
    onLoad: status => setLoadingStatus(DISTRIBUTION_IS_LOADING, status),
    onSuccess: result => setGetResults(DISTRIBUTION_GET_ALL_DATA, result),
    onFailure: err => setErrorThunk(DISTRIBUTION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
export const getAllDistributionCenterClients = id => dispatch => {
  dispatch(_getAllDistributionCenterClients(id));
};

const _getAllDistributionCenterClients = id => {
  return apiHelper({
    url: `${API_URL}/GetById?id=${id}`,
    method: 'GET',
    onLoad: status => setLoadingStatus(DISTRIBUTION_IS_LOADING, status),
    onSuccess: result => setGetResults(DISTRIBUTION_GET_ALL_CLIENTS, result),
    onFailure: err => setErrorThunk(DISTRIBUTION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};
export const createDistributionCenter = payload => dispatch => {
  dispatch(_createDistributionCenter(payload, dispatch));
};

const _createDistributionCenter = (payload, dispatch) => {
  return apiHelper({
    url: `${API_URL}`,
    method: 'POST',
    data: payload,
    onLoad: status => setLoadingStatus(DISTRIBUTION_IS_LOADING, status),
    onSuccess: result => {
      dispatch(getAllDistributionCenterClients(result.createdId));
      return setGetResults(DISTRIBUTION_ID, result);
    },
    onFailure: err => setErrorThunk(DISTRIBUTION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};
export const updateDistributionCenter = payload => dispatch => {
  dispatch(_updateDistributionCenter(payload));
};

const _updateDistributionCenter = payload =>
  apiHelper({
    url: `${API_URL}`,
    method: 'PUT',
    data: payload,
    onLoad: status => setLoadingStatus(DISTRIBUTION_IS_LOADING, status),
    onSuccess: result => getAllDistributionCenters(),
    onFailure: err => setErrorThunk(DISTRIBUTION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
export const deleteDistributionCenter = payload => dispatch => {
  dispatch(_deleteDistributionCenter(payload));
};

const _deleteDistributionCenter = payload =>
  apiHelper({
    url: `${API_URL}?id=${payload}`,
    method: 'DELETE',
    onLoad: status => setLoadingStatus(DISTRIBUTION_IS_LOADING, status),
    onSuccess: result => getAllDistributionCenters(),
    onFailure: err => setErrorThunk(DISTRIBUTION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

// export const describeCompany = (id) => dispatch => {
//     dispatch(_getCompany(id));
// };

// const _getCompany = (id) =>
//     apiHelper({
//         url: `${API_URL}/${id}`,
//         method: 'GET',
//         data: {
//             id: id
//         },
//         onLoad: status => setLoadingStatus(COMPANY_IS_LOADING, status),
//         onSuccess: result => setDescribeData(COMPANY_GET_DATA, result),
//         onFailure: err => setErrorThunk(COMPANY_ERROR, true, err),
//         accessToken: Cookies.get('accessToken'),
//     });

// export const saveCompany = (saveData, isNew, id = null) => dispatch => {
//     let MethodType = 'POST';
//     if (!isNew) {
//         MethodType = 'PUT'
//     }

//     let apiUrl = id ? `${API_URL}/${id}` : API_URL;

//     dispatch(_saveCompany(saveData, MethodType, apiUrl));
// }

// const _saveCompany = (data, MethodType, apiUrl) =>
//     apiHelper({
//         url: apiUrl,
//         method: MethodType,
//         onSuccess: result => setSaveData(COMPANY_SAVE_DATA, true, result),
//         onFailure: err => setErrorThunk(COMPANY_ERROR, true, err),
//         onLoad: status => setLoadingStatus(COMPANY_IS_LOADING, status),
//         data,
//         accessToken: Cookies.get('accessToken'),
//     });

export const setOnChange = retData => dispatch => {
  dispatch(setOnChangeData(DISTRIBUTION_ON_CHANGE_DATA, retData));
};

export const setOnCleanUp = retData => dispatch => {
  dispatch(setOnCleanUpData(DISTRIBUTION_CLEAN_UP, retData));
};
