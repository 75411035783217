import React from "react"
import PropTypes from 'prop-types'
import { Col, Modal, ModalBody, Row } from "reactstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import "./style.scss";

const ConfirmationModal = ({ show, onDeleteClick, onCloseClick, message, header, id = null }) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} className="confirmationModal">
      <ModalBody className="model-space">
        <Row>
          <Col lg={12}>
            <div className="text-center">
             <FontAwesomeIcon icon={faExclamationCircle} width={100}  height={100} className="micon"/>
              <h2>{header}</h2>
              <h4>{message}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-danger btn-lg me-2"
                onClick={onDeleteClick}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-secondary btn-lg me-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  message: PropTypes.any,
  header: PropTypes.any
}

export default ConfirmationModal
