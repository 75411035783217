import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, Container, Label, Row } from 'reactstrap';
import { describeCompany, saveCompany, setOnChange, setOnCleanUp } from '../../../store/actions/Management/company';
import { AvForm, AvInput, AvGroup } from 'availity-reactstrap-validation';
import { initialCompanyState } from '../../../store/reducers/initialState';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import CustomInput from '../../CustomControls/CustomInput';
import { _create, _adminops, _clientprofile, _company } from '../../../constants/componentConstants';
import { IS_NEW, STATES } from '../../../constants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import Dropdown from '../../CustomControls/Dropdown';
import CustomAvField from '../../CustomControls/CustomAvField';
import FileUploader from '../../CustomControls/FileUploader';
import ErrorListing from '../../CustomControls/ErrorListing';

const ManageCompany = props => {
  let history = useHistory();
  const [useDeliveryAdd, setUseDeliveryAdd] = useState(false);
  const backUrl = '/management/companies';
  const dispatch = useDispatch();
  const company = useSelector(state => state.company);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  let IsEdit = false;
  let { companyid } = useParams();
  const notificationType = company.isError ? 'error' : 'success';

  const showSaveOption = hasPermissionToAction(permissions, _company, _create);
  const hasAddPermission = hasPermissionToAction(permissions, _company, _adminops);

  let currentCompany = company.currentCompany;
  let localPath = '/management/companies/company';
  let heading = 'Add Company';

  if (companyid) {
    IsEdit = true;
    heading = 'Edit Company';
    localPath = localPath + `/${companyid}`;
  }

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Company Listing', path: '/management/companies' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  useEffect(() => {
    if (companyid) {
      dispatch(describeCompany(companyid));
    } else {
      dispatch(describeCompany(IS_NEW));
    }
  }, [dispatch, companyid]);

  //clean up
  useEffect(() => {
    return () => {
      setLocalCleanUpData({
        data: { ...company.currentCompany, ...initialCompanyState.currentCompany }
      });
    };
  }, []);

  const onPageChangeHandler = currentPage => {};

  const setLocalCleanUpData = cleanUpData => {
    dispatch(setOnCleanUp(cleanUpData));
  };

  const setLocalChange = currentState => {
    dispatch(setOnChange(currentState));
  };

  const handleInput = e => {
    setLocalChange({
      ...company,
      data: { ...company.currentCompany, [e.target.name]: e.target.value }
    });
  };

  const dropDownChange = (field, selectedOption) => {
    setLocalChange({
      ...company,
      data: { ...company.currentCompany, [field]: selectedOption.value }
    });
  };

  const onFilehandlerChange = file => {
    setLocalChange({
      ...company,
      data: { ...company.currentCompany, companyLogo: file.base64 }
    });
  };

  const handleCheckBoxInput = e => {
    let isChecked;
    if (e.target.value === 'false') {
      isChecked = false;
    } else {
      isChecked = true;
    }
    setLocalChange({
      ...company,
      data: { ...company.currentCompany, [e.target.name]: !isChecked }
    });
  };

  const handleSubmit = e => {
    if (company.currentCompany) {
      dispatch(saveCompany(company.currentCompany, !IsEdit, companyid));
    }
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  return (
    <React.Fragment>
      {company.redirect ? <Redirect to={backUrl} /> : ''}
      {company.isLoading && <LoadingSpinner />}
      {company.showMessage && <Notification notificationType={notificationType} message={company.message} />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardTitle>{heading}</CardTitle>
                <CardBody>
                  <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                    {company.errorList && company.errorList.length > 0 && <ErrorListing errorList={company.errorList} />}
                    <Row>
                      <Col lg={12}>
                        <CardSubtitle>Company basic info</CardSubtitle>

                        <CardBody>
                          <Row>
                            <Col md="4">
                              <div className="mb-3">
                                <CustomAvField
                                  name="companyName"
                                  label="Company Name"
                                  className="form-control"
                                  placeholder="Company Name"
                                  value={currentCompany.companyName}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="20"
                                  validate={{
                                    required: { value: true, errorMessage: 'Please enter a valid company name' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="mb-3">
                                <CustomAvField
                                  name="abn"
                                  label="ABN"
                                  className="form-control"
                                  placeholder="ABN"
                                  value={currentCompany.abn}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="20"
                                  validate={{
                                    required: { value: true, errorMessage: 'Please enter a valid ABN' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="mb-3">
                                <Dropdown
                                  placeholder="Select Timezone"
                                  label="Timezone"
                                  name="timezone"
                                  options={currentCompany.timezoneList}
                                  isMulti={false}
                                  value={currentCompany.timezoneList.find(x => x.value === currentCompany.timezone)}
                                  onChange={val => dropDownChange('timezone', val)}
                                  validate={{
                                    required: { value: true, errorMessage: 'Please select a timezone' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="3">
                              <div className="mb-3">
                                <CustomAvField
                                  name="taxName"
                                  label="Tax Name"
                                  className="form-control"
                                  placeholder="Tax Name"
                                  value={currentCompany.taxName}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="20"
                                  validate={{
                                    required: { value: false, errorMessage: '' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="3">
                              <div className="mb-3">
                                <CustomInput
                                  name="tax"
                                  label="Tax"
                                  className="form-control"
                                  placeholder="Tax"
                                  value={currentCompany.tax}
                                  onChange={handleInput}
                                  step=".01"
                                  iconValue="%"
                                  min="0"
                                  max="100"
                                  type="number"
                                  validate={{
                                    required: { value: true, errorMessage: 'Please enter a valid tax' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="3">
                              <div className="mb-3">
                                <CustomInput
                                  name="companyPageSize"
                                  label="Table Page Size"
                                  className="form-control"
                                  placeholder="Table Page Size"
                                  value={currentCompany.companyPageSize}
                                  onChange={handleInput}
                                  step="1"
                                  iconValue="No"
                                  min="0"
                                  max="100"
                                  type="number"
                                  validate={{
                                    required: { value: true, errorMessage: 'Please enter a valid page size' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="3">
                              <div className="mb-3">
                                <CustomInput
                                  name="maxReqQty"
                                  label="Max Request Qty"
                                  className="form-control"
                                  placeholder="Max Request Qty"
                                  value={currentCompany.maxReqQty}
                                  onChange={handleInput}
                                  step="1"
                                  iconValue="No"
                                  min="1"
                                  max="10000000"
                                  type="number"
                                  validate={{
                                    required: { value: false, errorMessage: 'Please enter a valid page size' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="2">
                              <div className="pad-30">
                                <AvGroup check>
                                  <AvInput
                                    type="checkbox"
                                    name="isActive"
                                    label="Is Active"
                                    onChange={handleCheckBoxInput}
                                    checked={currentCompany.isActive}
                                    value={currentCompany.isActive}
                                  />
                                  <Label check for="checkbox">
                                    {' '}
                                    Is Active
                                  </Label>
                                </AvGroup>
                              </div>
                            </Col>
                            {hasAddPermission && (
                              <Col md="2">
                                <div className="pad-30">
                                  <AvGroup check>
                                    <AvInput
                                      type="checkbox"
                                      name="licensed"
                                      label="Licensed"
                                      onChange={handleCheckBoxInput}
                                      checked={currentCompany.licensed}
                                      value={currentCompany.licensed}
                                    />
                                    <Label check for="checkbox">
                                      {' '}
                                      Licensed
                                    </Label>
                                  </AvGroup>
                                </div>
                              </Col>
                            )}

                            <Col sm="8" className="m-auto">
                              <FileUploader
                                multiple={false}
                                label="Company Logo"
                                id="companyLogo"
                                name="companyLogo"
                                value={currentCompany.companyLogo}
                                accept="image/png, image/jpeg"
                                onComplete={onFilehandlerChange}
                              />
                            </Col>
                          </Row>
                        </CardBody>

                        <CardSubtitle>Primary Contact Info</CardSubtitle>

                        <CardBody>
                          <Row>
                            <Col md="4">
                              <div className="mb-3">
                                <CustomAvField
                                  name="primaryContactPerson"
                                  label="Primary Contact Person"
                                  className="form-control"
                                  placeholder="Primary Contact Person"
                                  value={currentCompany.primaryContactPerson}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="50"
                                  validate={{
                                    required: { value: false, errorMessage: '' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="3">
                              <div className="mb-3">
                                <CustomAvField
                                  name="primaryContactEmail"
                                  label="Primary Contact's Email"
                                  className="form-control"
                                  placeholder="Primary Contact Email"
                                  value={currentCompany.primaryContactEmail}
                                  onChange={handleInput}
                                  maxLength="50"
                                  type="email"
                                  validate={{
                                    required: { value: false, errorMessage: 'Please enter an email' },
                                    pattern: {
                                      value: '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$',
                                      errorMessage: 'Please enter a valid email address'
                                    }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="2">
                              <div className="mb-3">
                                <CustomAvField
                                  name="primaryContactPhone"
                                  label="Primary Contact's Phone"
                                  className="form-control"
                                  placeholder="Primary Contact Phone"
                                  value={currentCompany.primaryContactPhone}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="20"
                                  validate={{
                                    required: { value: false, errorMessage: '' }
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                        <CardSubtitle> Dispatch Contact Info</CardSubtitle>

                        <CardBody>
                          <Row>
                            <Col md="4">
                              <div className="mb-3">
                                <CustomAvField
                                  name="dispatchContactPerson"
                                  label="Dispatch Contact Person"
                                  className="form-control"
                                  placeholder="Dispatch Contact Person"
                                  value={currentCompany.dispatchContactPerson}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="50"
                                  validate={{
                                    required: { value: false, errorMessage: '' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="3">
                              <div className="mb-3">
                                <CustomAvField
                                  name="dispatchContactEmail"
                                  label="Dispatch Contact's Email"
                                  className="form-control"
                                  placeholder="Dispatch Contact Email"
                                  value={currentCompany.dispatchContactEmail}
                                  onChange={handleInput}
                                  maxLength="50"
                                  type="email"
                                  validate={{
                                    required: { value: false, errorMessage: 'Please enter an email' },
                                    pattern: {
                                      value: '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$',
                                      errorMessage: 'Please enter a valid email address'
                                    }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="2">
                              <div className="mb-3">
                                <CustomAvField
                                  name="dispatchContactPhone"
                                  label="Dispatch Contact's Phone"
                                  className="form-control"
                                  placeholder="Dispatch Contact Phone"
                                  value={currentCompany.dispatchContactPhone}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="20"
                                  validate={{
                                    required: { value: false, errorMessage: '' }
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>

                        <CardSubtitle> Invoice Contact Info</CardSubtitle>

                        <CardBody>
                          <Row>
                            <Col md="4">
                              <div className="mb-3">
                                <CustomAvField
                                  name="invoiceContactPerson"
                                  label="Invoice Contact Person"
                                  className="form-control"
                                  placeholder="Invoice Contact Person"
                                  value={currentCompany.invoiceContactPerson}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="50"
                                  validate={{
                                    required: { value: false, errorMessage: '' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="3">
                              <div className="mb-3">
                                <CustomAvField
                                  name="invoiceContactEmail"
                                  label="Invoice Contact's Email"
                                  className="form-control"
                                  placeholder="Invoice Contact Email"
                                  value={currentCompany.invoiceContactEmail}
                                  onChange={handleInput}
                                  maxLength="50"
                                  type="email"
                                  validate={{
                                    required: { value: false, errorMessage: 'Please enter an email' },
                                    pattern: {
                                      value: '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$',
                                      errorMessage: 'Please enter a valid email address'
                                    }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="2">
                              <div className="mb-3">
                                <CustomAvField
                                  name="invoiceContactPhone"
                                  label="Invoice Contact's Phone"
                                  className="form-control"
                                  placeholder="Invoice Contact Phone"
                                  value={currentCompany.invoiceContactPhone}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="20"
                                  validate={{
                                    required: { value: false, errorMessage: '' }
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>

                        <CardSubtitle>Address</CardSubtitle>
                        <CardBody>
                          <Row>
                            <Col md="4">
                              <div className="mb-3">
                                <CustomAvField
                                  name="streetAddress"
                                  label="Street Address"
                                  className="form-control"
                                  placeholder="Street Address"
                                  value={currentCompany.streetAddress}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="100"
                                  validate={{
                                    required: { value: false, errorMessage: '' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="2">
                              <div className="mb-3">
                                <CustomAvField
                                  name="suburb"
                                  label="Suburb"
                                  className="form-control"
                                  placeholder="Suburb"
                                  value={currentCompany.suburb}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="50"
                                  validate={{
                                    required: { value: false, errorMessage: '' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="3">
                              <div className="mb-3">
                                <Dropdown
                                  placeholder="Select state"
                                  label="State"
                                  name="state"
                                  options={STATES}
                                  isMulti={false}
                                  value={STATES.find(x => x.value === currentCompany.state)}
                                  onChange={val => dropDownChange('state', val)}
                                  validate={{
                                    required: { value: false, errorMessage: 'Please select a state' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="2">
                              <div className="mb-3">
                                <CustomAvField
                                  name="postCode"
                                  label="Postcode"
                                  className="form-control"
                                  placeholder="Postcode"
                                  value={currentCompany.postCode}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="20"
                                  validate={{
                                    required: { value: false, errorMessage: '' }
                                  }}
                                />
                              </div>
                            </Col>

                            <Col md="4"></Col>
                            <Col md="5"></Col>
                          </Row>
                        </CardBody>
                        {!IsEdit && (
                          <div>
                            <CardSubtitle>Admin User Info</CardSubtitle>
                            <CardBody>
                              <Row>
                                <Col lg="12">
                                  <Row>
                                    <Col sm="6">
                                      <div className="mb-3">
                                        <CustomAvField
                                          name="userFirstName"
                                          label="First Name"
                                          className="form-control"
                                          placeholder="First Name"
                                          value={currentCompany.userFirstName}
                                          onChange={handleInput}
                                          type="text"
                                          validate={{
                                            required: { value: true, errorMessage: 'Please enter a valid first name' }
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col md="6">
                                      <div className="mb-3">
                                        <CustomAvField
                                          name="userLastName"
                                          label="Last Name"
                                          className="form-control"
                                          placeholder="Last Name"
                                          value={currentCompany.userLastName}
                                          onChange={handleInput}
                                          type="text"
                                          validate={{
                                            required: { value: true, errorMessage: 'Please enter a valid last name' }
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="6">
                                      <div className="mb-3">
                                        <CustomAvField
                                          name="userEmail"
                                          label="Email"
                                          className="form-control"
                                          placeholder="Enter email"
                                          value={currentCompany.userEmail}
                                          onChange={handleInput}
                                          disabled={IsEdit}
                                          type="email"
                                          validate={{
                                            required: { value: true, errorMessage: 'Please enter an email' },
                                            pattern: {
                                              value: '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$',
                                              errorMessage: 'Please enter a valid email address'
                                            }
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col md="6">
                                      <div className="mb-3">
                                        <CustomAvField
                                          name="userPhoneNumber"
                                          label="Phone"
                                          className="form-control"
                                          placeholder="Phone"
                                          value={currentCompany.userPhoneNumber}
                                          onChange={handleInput}
                                          type="text"
                                          maxLength="20"
                                          validate={{
                                            required: { value: false, errorMessage: '' }
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md="3">
                                      <div className="mb-3">
                                        <CustomAvField
                                          name="userPassword"
                                          label="Password"
                                          className="form-control"
                                          placeholder="Enter password"
                                          type="password"
                                          value={currentCompany.userPassword}
                                          onChange={handleInput}
                                          validate={{
                                            required: { value: true, errorMessage: 'Please enter a password' },
                                            pattern: {
                                              value: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
                                              errorMessage: 'Password is not complex enough.'
                                            }
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col md="3">
                                      <div className="mb-3">
                                        <CustomAvField
                                          name="userConfirmPassword"
                                          label="Confirm password"
                                          className="form-control"
                                          placeholder="Confirm password"
                                          type="password"
                                          value={currentCompany.userConfirmPassword}
                                          onChange={handleInput}
                                          validate={{
                                            required: { value: true, errorMessage: 'Please enter a password' },
                                            match: { value: 'userPassword', errorMessage: 'Passwords do not match' }
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col md="6">
                                      <div className="mb-3">
                                        <Dropdown
                                          placeholder="Select Group"
                                          label="User Group"
                                          name="userGroupId"
                                          options={currentCompany.groups}
                                          isMulti={false}
                                          value={currentCompany.groups.find(x => x.value === currentCompany.userGroupId)}
                                          onChange={val => dropDownChange('userGroupId', val)}
                                          validate={{
                                            required: { value: true, errorMessage: 'Please select a user group' }
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </CardBody>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      {showSaveOption && (
                        <Button type="submit" color="primary" className="btn mb-3" disabled={company.isLoading}>
                          SAVE
                        </Button>
                      )}
                      <Button onClick={cancelHandler} color="secondary" className="mb-3 ">
                        CANCEL
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageCompany;
