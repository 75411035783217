import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Col, Row, Button } from 'reactstrap';
import Pagination from '../../CustomControls/Pagination';
import SearchBar from '../../CustomControls/SearchBar';
import { _create, _groups } from '../../../constants/componentConstants';
import { getAllDistributionCenterClients } from '../../../store/actions/Management/distributionCenter';
import { useHistory } from 'react-router-dom';
import { getAllClientPricing } from '../../../store/actions/Master/pricing';
import moment from 'moment';

const PricingTable = props => {
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const [results, setResults] = useState([]);
  const [urlId, setUrlId] = useState('');
  let history = useHistory();
  const ui = useSelector(state => state.ui);
  const states = useSelector(state => state);
  const pricing = useSelector(state => state.pricing);
  const distribution = useSelector(state => state.distribution);
  const pageSize = ui.layout.pageSize;
  let currentUser = props.currentUser;
  let clients = pricing.clientList;

  useEffect(() => {
    if (searchText.length > 0) {
      setResults(
        clients.filter(
          x =>
            x.clientName.toLowerCase().includes(searchText.toLowerCase()) || x.clientCode.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setResults(clients);
    }
  }, [searchText, setResults, clients]);

  useEffect(() => {
    dispatch(getAllClientPricing());
  }, [dispatch]);

  const onPageChangeHandler = currentPage => {};

  const onCheckboxHandler = (e, id) => {
    props.onClientSelectionHandler(id, e.target.checked);
  };

  const handleInput = e => {
    setSearchText(e.target.value);
  };
  function onAddNewHandler() {
    history.push('/master/pricing/edit-pricing');
  }

  return (
    <React.Fragment>
      <Row className="mb-2 buttonrow">
        <Col sm="4">
          <SearchBar onChange={handleInput} placeholder="Search" value={searchText} />
        </Col>
        <Col sm="8">
          <div className="text-sm-end">
            <Button
              type="button"
              className="btn btn-primary btn-block "
              onClick={onAddNewHandler}
              disabled={pricing.selectedIds.length < 1}
            >
              EDIT PRICING
            </Button>
          </div>
        </Col>
      </Row>

      <Pagination data={results} pageSize={pageSize} onPageChange={onPageChangeHandler} startingPage={1}>
        <UserTable onCheckboxHandler={onCheckboxHandler} />
      </Pagination>
    </React.Fragment>
  );
};

export default PricingTable;

const UserTable = ({ paginatedData, onCheckboxHandler }) => {
  return (
    <React.Fragment>
      <div>
        <Table className="table" responsive>
          <thead>
            <tr>
              <th>Select</th>
              <th>Client Code</th>
              <th>Client Name</th>
              <th>Last Price Change Date</th>
              <th>Value</th>
              <th>Changed Type</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData?.map(n => {
              const checked = !n.isActive;
              return (
                <tr key={n.clientProfileId}>
                  <td>
                    <input type="checkbox" value={n.isActive} checked={checked} onChange={e => onCheckboxHandler(e, n.clientProfileId)} />
                  </td>
                  <td>{`${n.clientCode}`}</td>
                  <td>{`${n.clientName}`}</td>
                  <td>{`${moment(new Date(n.updatedDate)).format('DD-MMM-YYYY')}`}</td>
                  <td>{`${n.amount}`}</td>
                  <td>{`${n.type === 0 ? '$' : '%'}`}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};
