import axios from 'axios';
import 'core-js/features/promise/finally';
import { API } from '../constants/index';
import { logout, accessDenied } from '../store/actions/auth';
import Cookies from 'js-cookie';


const apiMiddleware = ({ dispatch }) => next => action => {
  next(action);

  if (action.type !== API) return;

  const {
    url,
    method,
    data: inputData,
    accessToken,
    onLoad,
    onSuccess,
    onFailure,
    skipCallback,
    accessid
  } = action.payload;
  const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';

  // axios default configs
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  if (accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }

  const accesslocalid = accessid || Cookies.get('companyid') || null;
  if (accesslocalid) {
    axios.defaults.headers.common['accessid'] = accessid || Cookies.get('companyid');
  }
  if (onLoad) dispatch(onLoad(true));

  return axios
    .request({
      url,
      method,
      [dataOrParams]: inputData
    })
    .then(({ data }) => {
      if (!skipCallback) dispatch(onSuccess(data, inputData));
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        dispatch(logout(false));
      }
      if (error.response && error.response.status === 403) {
        dispatch(accessDenied());
      }
      if (onFailure) {
        dispatch(onFailure(error?.response?.data))
      };
    })
    .finally(() => {
      if (onLoad) dispatch(onLoad(false));
    });
};

export default apiMiddleware;
