import { COSTCENTER_GET_ALL_DATA, COSTCENTER_GET_DATA, COSTCENTER_ERROR, COSTCENTER_IS_LOADING, COSTCENTER_SAVE_DATA, COSTCENTER_ON_CHANGE_DATA, COSTCENTER_CLEAN_UP,COSTCENTER_DELETE_DATA, COSTCENTER_UPDATE_CONFIRMATION } from '../../../constants/index';
import { initialCostCenterState } from '../initialState';

const costCenterReducer = (state = initialCostCenterState, action) => {
  const { payload, type } = action;
  switch (type) {
    case COSTCENTER_ERROR: {
      const { showMessage, errorMessage, errorList } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage,
        errorList: errorList
      };
    }
    case COSTCENTER_GET_ALL_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        costCenterList: resultData
      };
    }
    case COSTCENTER_GET_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        currentCostCenter: {
          ...state.currentCostCenter,
          ...resultData
        }
      };
    }
    case COSTCENTER_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }
    case COSTCENTER_SAVE_DATA: {
      const { showMessage, message, redirect } = payload;
      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message
      };
    }

    case COSTCENTER_ON_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        currentCostCenter: {
          ...state.currentCostCenter,
          ...data
        }
      };
    }
    case COSTCENTER_CLEAN_UP: {
      let { data } = payload;

      return {
        ...state,
        message: '',
        errorList: [],
        isError: false,
        showMessage: false,
        redirect: false,
        showConfirmationModal: false,
        confirmationId : '',
        currentCostCenter: {
          ...state.currentCostCenter,
          ...data
        }
      };
    }

    case COSTCENTER_DELETE_DATA: {
      const { showMessage, message, redirect } = payload;
      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message,
        showConfirmationModal: false,
        confirmationId : ''
      };
    }

    case COSTCENTER_UPDATE_CONFIRMATION: {
      let { data } = payload;

      return {
        ...state,
        ...data
      };
    }

    default:
      return state;
  }
};

export default costCenterReducer;




