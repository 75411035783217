import React from "react";
import { Label, InputGroup, Row, Col } from 'reactstrap';
import { AvGroup } from 'availity-reactstrap-validation';
import "./style.scss"

const FileUploader = props => {
    const handleChange = (e) => {

        let files = e.target.files;
        var addFiles = [];

        for (var i = 0; i < files.length; i++) {
            let file = files[i];
            let fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                let fileInfo = {
                    name: file.name,
                    base64: fileReader.result,
                    type: file.type,
                    filesize: Math.round(file.size / 1000) + ' kb',
                    file: file,
                };

                addFiles.push(fileInfo);

                if (addFiles.length == files.length) {
                    if (props.multiple) {
                        props.onComplete(addFiles);
                    }
                    else {
                        props.onComplete(addFiles[0]);
                    }
                }

            }

        }

    }


    return (


        <div className="customInput">
            <AvGroup>
                <Label for={props.name}>{props.label}</Label>
                <InputGroup>
                    <Row>
                        <Col sm="6">
                            <div className="custom-info-text">
                                <input type="file"
                                    className="custom-file-input"
                                    id={props.id}
                                    name={props.name}
                                    accept={props.accept}
                                    multiple={props.multiple}
                                    onChange={handleChange}
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            {props.value && <div><img src={props.value} alt="" height="25" /></div>}
                        </Col>
                    </Row>


                </InputGroup>
            </AvGroup>

        </div>
    )
}

export default FileUploader;