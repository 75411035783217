import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Table from './OrderTable';
import Pagination from '../../CustomControls/Pagination';
import { getAllOrders, setOnSearchChange, setOnConfirmationModal, setAllOrderDetails } from '../../../store/actions/order';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _reportprint, _requisition, _delete } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import { addDaysToDate } from '../../../utils';
import SearchBar from '../../CustomControls/SearchBar';
import DatePicker from '../../CustomControls/CustomDatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import CustomConfirmation from '../../CustomControls/ConfirmationModal';

import './style.scss';

const Order = props => {
  const [searchText, setSearchText] = useState('');
  const [searchFromDate, setSearchFromDate] = useState(addDaysToDate(new Date(), -30));
  const [searchToDate, setSearchToDate] = useState(new Date());
  const history = useHistory();
  const dispatch = useDispatch();
  const requisition = useSelector(state => state.requisition);
  const orders = useSelector(state => state.order?.orders || []);
  const order = useSelector(state => state.order || []);
  const ui = useSelector(state => state.ui);
  const allData = useSelector(state => state);
  const permissions = ui.layout.permissions;
  const pageSize = ui.layout.pageSize;
  const currentPageNumber = order?.filterOptions?.pageNumber;

  const notificationType = order.isError ? 'error' : 'success';

  const showSaveOption = hasPermissionToAction(permissions, _requisition, _create);
  const showPrintOption = hasPermissionToAction(permissions, _requisition, _reportprint);
  const showDeleteOption = hasPermissionToAction(permissions, _requisition, _delete);

  useEffect(() => {
    initialCall();
  }, [currentPageNumber, pageSize]);

  useEffect(() => {
    initialCall();
  }, []);
  function initialCall() {
    dispatch(getAllOrders(currentPageNumber, pageSize, order?.filterOptions?.filterBy, order?.filterOptions?.sortBy));
  }

  const onPageChange = currentPage => {
    setLocalChange({
      ...order,
      data: { ...order?.filterOptions, pageNumber: currentPage }
    });
  };

  const onAddNewHandler = () => {
    history.push('/operation/requisitions/requisition');
  };

  const onEditHandler = id => {
    dispatch(setAllOrderDetails(id));
    // http://localhost:58646/customer/order-details?orderId=709250c1-5e7b-46c7-9e26-b9fdddc682d4
    history.push(`/order/orderlist/order-report`);
  };

  const onRepeatHandler = id => {
    history.push(`/operation/requisitions/requisition/1/${id}`);
  };

  const onDispatchHandler = id => {
    history.push(`/operation/dispatches/dispatch/1/${id}`);
  };

  const onPrintHandler = id => {
    history.push(`/operation/requisitions/requisition-report/${id}`);
  };

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Order Listing', path: '/order', isCurrent: true }
  ];

  useEffect(() => {
    return () => {
      let filterListCurrent = order?.filterOptions?.filterBy || [];
      if (filterListCurrent.length > 0) {
        filterListCurrent = filterListCurrent.filter(x => x.name.toLowerCase() !== 'requisitionref');
      }
      setLocalChange({
        ...order,
        data: { ...order?.filterOptions, filterBy: filterListCurrent }
      });
    };
  }, []);

  const handleInput = e => {
    const searchVal = e.target.value ?? '';

    setSearchText(searchVal);
    let filterSearchList = order?.filterOptions?.filterBy || [];

    const objIndex = filterSearchList.findIndex(x => x.name.toLowerCase() === 'requisitionref');

    if (objIndex !== -1) {
      filterSearchList[objIndex].value = searchVal.toLowerCase();
    } else {
      filterSearchList.push({ name: 'requisitionref', value: searchVal.toLowerCase() });
    }
  };
  const handleDateInput = (field, dateVal) => {
    const setDate = dateVal ? new Date(dateVal) : null;
    if (field === 'fromdate') {
      setSearchFromDate(setDate);
    }

    if (field === 'todate') {
      setSearchToDate(setDate);
    }

    let filterSearchList = order?.filterOptions?.filterBy || [];

    const objIndex = filterSearchList.findIndex(x => x.name.toLowerCase() === field);

    if (objIndex !== -1) {
      filterSearchList[objIndex].value = setDate.toDateString();
    } else {
      filterSearchList.push({ name: field, value: setDate.toDateString() });
    }

    setLocalChange({
      ...order,
      data: { ...order?.filterOptions, filterBy: filterSearchList }
    });
  };

  const setLocalChange = currentState => {
    dispatch(setOnSearchChange(currentState));
  };

  const onSearchHandler = e => {
    dispatch(getAllOrders(1, pageSize, order?.filterOptions?.filterBy, order?.filterOptions?.sortBy));
  };

  const onDeleteHandler = id => {
    dispatch(
      setOnConfirmationModal({
        data: {
          showConfirmationModal: true,
          confirmationId: id
        }
      })
    );
  };

  const onDeleteConfirmation = () => {
    if (requisition.confirmationId) {
      let ftOptions = {
        currentPageNumber: currentPageNumber,
        pageSize: pageSize,
        filterBy: order?.filterOptions?.filterBy,
        sortBy: order?.filterOptions?.sortBy
      };
      // dispatch(deleteRequisition(requisition.confirmationId, ftOptions));
    }
  };

  const onDeleteCancel = () => {
    dispatch(
      setOnConfirmationModal({
        data: {
          showConfirmationModal: false,
          confirmationId: ''
        }
      })
    );
  };

  return (
    <React.Fragment>
      <CustomConfirmation
        show={requisition.showConfirmationModal}
        onDeleteClick={onDeleteConfirmation}
        onCloseClick={onDeleteCancel}
        message="Are you sure you want to delete this record?"
        header="Deleting record"
      />
      <div className="page-content">
        {requisition.showMessage && <Notification notificationType={notificationType} message={requisition.message} />}
        <Container fluid>
          <Breadcrumbs title="Order Listing " paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="4">
                      <SearchBar onChange={handleInput} placeholder="Search By Order No" value={searchText} />
                    </Col>
                    <Col sm="2">
                      <div className="mb-4">
                        <DatePicker
                          label="From Date"
                          selected={searchFromDate}
                          onChange={date => handleDateInput('fromdate', date)}
                          dateFormat="dd-MMM-yyyy"
                        />
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="mb-4">
                        <DatePicker
                          label="To Date"
                          selected={searchToDate}
                          onChange={date => handleDateInput('todate', date)}
                          dateFormat="dd-MMM-yyyy"
                          minDate={searchFromDate}
                        />
                      </div>
                    </Col>
                    <Col sm="4" className="m-auto">
                      <div className="text-sm-start">
                        <Button type="button" className="btn btn-outline-primary" onClick={onSearchHandler}>
                          <FontAwesomeIcon className="standardIcon" icon={faSearch} /> Search
                        </Button>
                      </div>
                    </Col>
                    {/* <Col sm="3">
                      <div className="text-sm-end">
                        {showSaveOption && (
                          <Button type="button" className="btn btn-primary btn-block  app-add-button" onClick={onAddNewHandler}>
                            NEW REQUISITION
                          </Button>
                        )}
                      </div>
                    </Col> */}
                  </Row>
                  <Row className="tablerow">
                    <Col xl="12">
                      <div>
                        {order.isLoading && <LoadingSpinner />}
                        <Pagination
                          data={orders}
                          pageSize={pageSize}
                          onPageChange={onPageChange}
                          startingPage={currentPageNumber}
                          apiPaging={true}
                          apiDataLength={orders.totalRecords}
                        >
                          <Table
                            editHandler={onEditHandler}
                            repeatHandler={onRepeatHandler}
                            dispatchHandler={onDispatchHandler}
                            printHandler={onPrintHandler}
                            showPrint={showPrintOption}
                            deleteHandler={onDeleteHandler}
                            showDelete={showDeleteOption}
                          />
                        </Pagination>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Order;
