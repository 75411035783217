import React from "react"
import { Table } from "reactstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt,faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import "./style.scss";

const ProductTypeTable = ({ paginatedData, editHandler, deleteHandler, showDelete}) => {


    return (
        <React.Fragment>
            <div>
                <Table className="table" responsive>
                    <thead>
                        <tr>
                            <th>Product Type Code</th>
                            <th>Product Type Name</th>
                            <th>Status</th>
                            <th>Action</th>
                            {showDelete && <th className="col-sm-1">Delete</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map(n => {
                            return (
                                <tr key={n.productTypeId}>
                                    <td>{n.productTypeCode || ''}</td>
                                    <td>{n.productTypeName || ''}</td>
                                    <td>{n.isActive ? 'Active' : 'Inactive'}</td>
                                    {<td><button className="btn tableIcons" onClick={() => editHandler(n.productTypeId)} > <FontAwesomeIcon icon={faPencilAlt} /></button></td>}
                                    {showDelete && <td><button className="btn tableIcons" onClick={() => deleteHandler(n.productTypeId)} > <FontAwesomeIcon icon={faTrashAlt} /></button></td>}

                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

            </div>
        </React.Fragment>
    )
}


export default ProductTypeTable;