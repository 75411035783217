import React, { useEffect } from 'react';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper, faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import './style.scss';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { _clientadmin, _view } from '../../../constants/componentConstants';
const OrderTable = ({ paginatedData, editHandler }) => {
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  return (
    <React.Fragment>
      <div>
        <Table className="table" responsive>
          <thead>
            <tr>
              <th>Client Name</th>
              <th>Product Name</th>
              <th>Ordered Quantity</th>
              <th>Delivered Quantity</th>
              <th>Amount</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
          {
            paginatedData.map(n=>{
              return(<>
              <tr>
               <td>{n.clientName}</td>
                <td>{n.productName || ''}</td>
                    <td>{n.orderedQuantity}</td>
                    <td>{n.deliveredQuantity}</td>
                    <td>{n.totalAmount}</td>
                    {
                      <td>
                        <button
                          className="btn tableIcons"
                          onClick={() => editHandler(n.clientId, n.clientName, n.productId, n.productName)}
                        >
                          {' '}
                          <FontAwesomeIcon icon={faNewspaper} />
                        </button>
                      </td>
                    }
                    </tr></>
              )
            })
          }
          </tbody>
          {/* <tbody>
            {
            // paginatedData?.length > 0 &&
              paginatedData.map(n => {
                return (
                  <tr key={n.clientId}>
                    <td>{n.clientName || ''}</td>
                    <td>{n.productName || ''}</td>
                    <td>{n.orderedQuantity}</td>
                    <td>{n.deliveredQuantity}</td>
                    <td>{n.totalAmount}</td>
                    {
                      <td>
                        <button
                          className="btn tableIcons"
                          onClick={() => editHandler(n.clientId, n.clientName, n.productId, n.productName)}
                        >
                          {' '}
                          <FontAwesomeIcon icon={faNewspaper} />
                        </button>
                      </td>
                    }
                  </tr>
                );
              })}
          </tbody> */}
        </Table>
      </div>
    </React.Fragment>
  );
};

export default OrderTable;
