import React from 'react';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper, faPrint, faTrashAlt, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import './style.scss';
import moment from 'moment';

const DispatchTable = ({ paginatedData, editHandler, printHandler, showPrint, deleteHandler, showDelete, sortConfig, onSort }) => {
  const getSortIcon = key => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? faSortUp : faSortDown;
    }
    return faSort;
  };
  return (
    <React.Fragment>
      <div>
        <Table className="table" responsive>
          <thead>
            <tr>
              <th onClick={() => onSort('dispatchref')}>
                Dispatch No <FontAwesomeIcon icon={getSortIcon('dispatchref')} />
              </th>
              <th onClick={() => onSort('fromdate')}>
                Date
                <FontAwesomeIcon icon={getSortIcon('fromdate')} />
              </th>
              <th onClick={() => onSort('requisitionRef')}>
                Requisition No <FontAwesomeIcon icon={getSortIcon('requisitionRef')} />
              </th>
              <th>Client</th>
              <th>Department</th>
              <th>Expected Delivery Date</th>
              <th>View</th>
              {showDelete && <th className="col-sm-1">Delete</th>}
              {showPrint && <th>Print</th>}
            </tr>
          </thead>
          <tbody>
            {paginatedData.map(n => {
              return (
                <tr key={n.dispatchId}>
                  <td>{n.dispatchRef || ''}</td>
                  <td>{moment(new Date(n.dispatchedDate)).format('DD-MMM-YYYY') || ''}</td>
                  <td>{n.requisitionRef || ''}</td>
                  <td>{n.clientProfileName || ''}</td>
                  <td>{n.departmentName || ''}</td>
                  <td>{moment(new Date(n.expectedDeliveryDate)).format('DD-MMM-YYYY') || ''}</td>
                  {
                    <td>
                      <button className="btn tableIcons" onClick={() => editHandler(n.dispatchId)}>
                        {' '}
                        <FontAwesomeIcon icon={faNewspaper} />
                      </button>
                    </td>
                  }
                  {showDelete && (
                    <td>
                      <button className="btn tableIcons" onClick={() => deleteHandler(n.dispatchId)}>
                        {' '}
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </td>
                  )}
                  {showPrint && (
                    <td>
                      <button className="btn tableIcons" onClick={() => printHandler(n.dispatchId)}>
                        {' '}
                        <FontAwesomeIcon icon={faPrint} />
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default DispatchTable;
