import {
    DISPATCH_GET_ALL_DATA,
    DISPATCH_GET_DATA,
    DISPATCH_ERROR,
    DISPATCH_IS_LOADING,
    DISPATCH_SAVE_DATA,
    DISPATCH_ON_CHANGE_DATA,
    DISPATCH_CLEAN_UP,
    DISPATCH_ON_SEARCH_CHANGE_DATA,
    DISPATCH_GET_PRODUCTS_DEPT,
    DISPATCH_GET_ONLINE_REPORT,
    DISPATCH_DELETE_DATA,
    DISPATCH_UPDATE_CONFIRMATION,
    DISPATCH_BULK_GET_DATA,
    DISPATCH_BULK_ON_CHANGE_DATA,
    DISPATCH_BULK_GET_REQUISTION_CLIENT,
    DISPATCH_BULK_SAVE_DATA,
    DISPATCH_BULK_SAVE_CONFIRMATION,
    DISPATCH_BULK_CLEAN_UP
} from '../../../constants/index';
import {initialCustomerState, initialDispatchState} from '../initialState';

const requisitionReducer = (state = initialDispatchState, action, customState = initialCustomerState) => {
    const {payload, type} = action;
    switch (type) {
        case DISPATCH_ERROR: {
            const {showMessage, errorMessage, errorList} = payload;
            return {
                ...state,
                isError: showMessage ? true : false,
                showMessage: showMessage,
                message: errorMessage,
                errorList: errorList
            };
        }
        case DISPATCH_GET_ALL_DATA: {
            const {resultData} = payload;
            return {
                ...state,
                dispatchList: resultData.data,
                totalRecords: resultData.totalRecords,
                filterOptions: {
                    ...state.filterOptions,
                    pageNumber: resultData.pageNumber,
                    pageSize: resultData.pageSize
                }
            };
        }

        case DISPATCH_GET_DATA: {
            const {resultData} = payload;
            console.log('in dispatch reducer', resultData, 'state is', state.currentDispatch)
            console.log('currentDispatch', {
                currentDispatch: {
                    ...state.currentDispatch,
                    ...resultData
                }
            })
            return {
                ...state,
                currentDispatch: {
                    ...state.currentDispatch,
                    ...resultData
                }
            };
        }
        case DISPATCH_IS_LOADING: {
            const {isLoading} = payload;
            return {
                ...state,
                isLoading: isLoading,
                showMessage: false
            };
        }
        case DISPATCH_SAVE_DATA: {
            let {showMessage, message, redirect, misc} = payload;

            let mergeObj = {};
            redirect = true;

            if (misc && misc.repeatDisp) {
                let reqSelList = state.currentDispatch.requisitionSelectList ? state.currentDispatch.requisitionSelectList : [];
                if (misc.requisitionId) {
                    reqSelList = reqSelList.filter(x => x.value !== misc.requisitionId.toString());
                }

                mergeObj = {
                    currentDispatch: {
                        ...state.currentDispatch,
                        requisitionId: '',
                        dispatchId: '',
                        dispatchRef: '',
                        requisitionRef: '',
                        deliveryDate: '',
                        requisitionDate: '',
                        requisitionRemarks: '',
                        deliveryTypeId: '',
                        departmentId: '',
                        isDispatched: false,
                        clientProfileId: '',
                        clientProfileName: '',
                        departmentName: '',
                        deliveryTypeName: '',
                        dispatchDetails: [],
                        requisitionSelectList: reqSelList
                    }
                };
                redirect = false;
            }

            return {
                ...state,
                isError: false,
                redirect: redirect,
                showMessage: showMessage,
                message: message,
                ...mergeObj
            };
        }

        case DISPATCH_ON_CHANGE_DATA: {
            let {data} = payload;
            console.log('DISPATCH_ON_CHANGE_DATA', data.departmentId)
            if (!data?.departmentId) {
                return {
                    ...customState,
                    ...state,
                    currentDispatch: {
                        ...state.currentDispatch
                    },
                    internalOrder: {
                        ...data
                    }
                };
            } else {
                return {
                    ...state,
                    currentDispatch: {
                        ...state.currentDispatch,
                        ...data
                    }
                };
            }
        }
        case DISPATCH_CLEAN_UP: {
            let {data} = payload;

            return {
                ...state,
                message: '',
                errorList: [],
                isError: false,
                showMessage: false,
                redirect: false,
                showConfirmationModal: false,
                confirmationId: '',
                currentDispatch: {
                    ...state.currentDispatch,
                    ...data
                }
            };
        }

        case DISPATCH_GET_PRODUCTS_DEPT: {
            const {resultData} = payload;

            return {
                ...state,
                currentDispatch: {
                    ...state.currentDispatch,
                    productSelectList: resultData
                }
            };
        }
        case DISPATCH_ON_SEARCH_CHANGE_DATA: {
            let {data} = payload;

            return {
                ...state,
                filterOptions: {
                    ...state.filterOptions,
                    ...data
                }
            };
        }
        case DISPATCH_GET_ONLINE_REPORT: {
            const {resultData} = payload;
            return {
                ...state,
                dispatchReport: {
                    ...state.dispatchReport,
                    ...resultData
                }
            };
        }
        case DISPATCH_DELETE_DATA: {
            const {showMessage, message, redirect} = payload;
            return {
                ...state,
                isError: false,
                redirect: redirect,
                showMessage: showMessage,
                message: message,
                showConfirmationModal: false,
                confirmationId: ''
            };
        }

        case DISPATCH_UPDATE_CONFIRMATION: {
            let {data} = payload;

            return {
                ...state,
                ...data
            };
        }

        case DISPATCH_BULK_GET_DATA: {
            const {resultData} = payload;
            return {
                ...state,
                currentBulkDispatch: {
                    ...state.currentBulkDispatch,
                    ...resultData
                }
            };
        }

        case DISPATCH_BULK_ON_CHANGE_DATA: {
            let {data} = payload;

            return {
                ...state,
                currentBulkDispatch: {
                    ...state.currentBulkDispatch,
                    ...data
                }
            };
        }

        case DISPATCH_BULK_GET_REQUISTION_CLIENT: {
            const {resultData} = payload;
            return {
                ...state,
                currentBulkDispatch: {
                    ...state.currentBulkDispatch,
                    overrideDispatchDate: false,
                    dispatchBulkDetails: resultData
                }
            };
        }

        case DISPATCH_BULK_SAVE_DATA: {
            //As per require we do not redirect in steam preserve some of the data
            let {showMessage, message, redirect, misc} = payload;

            let mergeObj = {};
            redirect = true;

            if (misc && misc.repeatReq) {
                mergeObj = {
                    currentBulkDispatch: {
                        ...state.currentBulkDispatch,
                        clientId: '',
                        dispatchedBulkDate: '',
                        overrideDispatchDate: false,
                        dispatchBulkDetails: []
                    }
                };
                redirect = false;
            }
            return {
                ...state,
                isError: false,
                redirect: redirect,
                showMessage: showMessage,
                message: message,
                ...mergeObj
            };
        }

        case DISPATCH_BULK_SAVE_CONFIRMATION: {
            let {data} = payload;

            return {
                ...state,
                ...data
            };
        }

        case DISPATCH_BULK_CLEAN_UP: {
            let {data} = payload;

            return {
                ...state,
                message: '',
                errorList: [],
                isError: false,
                showMessage: false,
                redirect: false,
                showConfirmationModal: false,
                confirmationId: '',
                currentBulkDispatch: {
                    ...state.currentBulkDispatch,
                    ...data
                }
            };
        }

        default:
            return state;
    }
};

export default requisitionReducer;
