import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { getReportRequisition } from '../../../store/actions/Operation/requisition';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { isEmpty, map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

const RequisitionReport = props => {
  let history = useHistory();
  const dispatch = useDispatch();
  let { requisitionid } = useParams();
  const backUrl = '/operation/requisitions';
  const requisition = useSelector(state => state.requisition);
  let localPath = `/operation/requisitions/requisition-report/${requisitionid}`;
  let heading = 'Requisition Report';
  let reportData = requisition.requisitionReport;

  const imageResult = window.localStorage.getItem('lmsCompanyLogo');

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Requisition Listing', path: '/operation/requisitions' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  useEffect(() => {
    if (requisitionid) {
      dispatch(getReportRequisition(requisitionid));
    }
  }, [dispatch, requisitionid]);

  const printInvoice = () => {
    window.print();
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };
  let totalQty = 0;
  if (reportData && reportData.requisitionDetails && reportData.requisitionDetails.length > 0) {
    totalQty = reportData.requisitionDetails.reduce(function(prev, cur) {
      return prev + parseInt(cur.requestedQty);
    }, 0);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          {!isEmpty(reportData) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="report-container">
                      <div className="report-title">
                        <h4 className="float-end font-size-16">Requisition Form</h4>
                        <div>{imageResult ? <img src={imageResult} alt="logo" height="45" /> : null}</div>
                      </div>
                      <div id="content">
                        <Row>
                          <Col xs="5">
                            <address>
                              <b>{reportData.clientProfileName}</b>
                              <br />
                              <span>
                                {reportData.clientBillingStreetAddress}, {reportData.clientBillingSuburb}, {reportData.clientBillingState},{' '}
                                {reportData.clientBillingPostCode}
                              </span>
                              <br />
                              <span>{reportData.clientPrimaryContactEmail}</span>
                              <br />
                              <span>{reportData.clientPrimaryContactPhone}</span>
                            </address>
                          </Col>
                          <Col xs="7" className="text-end">
                            <address>
                              <Row>
                                <Col xs="3"></Col>
                                <Col xs="5" className="text-end">
                                  Requisition # :
                                </Col>
                                <Col xs="4" className="text-end">
                                  {reportData.requisitionRef}
                                </Col>
                                <Col xs="3"></Col>
                                <Col xs="5" className="text-end">
                                  Date :
                                </Col>
                                <Col xs="4" className="text-end">
                                  {reportData.requisitionDate}
                                </Col>
                              </Row>
                            </address>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6" className="mt-3">
                            <address>
                              <b>Request From:</b>
                              <br />
                              <span>{reportData.departmentName}</span>
                              <br />
                              <span>
                                {reportData.departmentAddress}, {reportData.departmentSuburb}, {reportData.departmentState},{' '}
                                {reportData.departmentPostCode}{' '}
                              </span>
                            </address>
                          </Col>
                          <Col xs="6" className="mt-3 text-end"></Col>
                        </Row>
                        <div className="table-responsive">
                          <Table className="table-nowrap">
                            <thead>
                              <tr className="reportLine">
                                <th style={{ width: '15%' }}>Stock Code</th>
                                <th style={{ width: '65%' }}>Description</th>
                                <th style={{ width: '20%' }} className="text-end">
                                  Requested Qty
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {map(reportData.requisitionDetails, (item, key) => (
                                <tr key={key}>
                                  <td>{item.productCode}</td>
                                  <td>{item.productName}</td>
                                  <td className="text-end">{item.requestedQty}</td>
                                </tr>
                              ))}
                              <tr>
                                <td className="text-start">
                                  <b>{`Line ${reportData.requisitionDetails.length}`}</b>
                                </td>

                                <td colSpan="2" className="text-end">
                                  <b>{`Total    :  ${totalQty}`}</b>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div className="d-print-none">
                        <div className="float-start">
                          <Link to="#" onClick={printInvoice} className="btn btn-outline-primary">
                            <FontAwesomeIcon className="standardIcon" icon={faPrint} /> Print
                          </Link>
                          <Link to="#" onClick={cancelHandler} className="btn btn-secondary w-md button-space">
                            Back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RequisitionReport;
