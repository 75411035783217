import React from "react";
import { Label, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { AvField, AvGroup } from 'availity-reactstrap-validation';
import "./style.scss"

const CustomInput = props => {

    return (
        <div className="customInput">
            <AvGroup>
                <Label for={props.name}>{props.label}</Label>
                <InputGroup>
                    <InputGroupAddon addonType="prepend" className="custom-input-info">
                        <InputGroupText className="custom-input-span" >{props.iconValue}</InputGroupText>
                    </InputGroupAddon>
                    <div className="custom-info-text">
                        <AvField
                            className={`${props.className} `}
                            min={props.min}
                            max={props.max}
                            name={props.name}
                            type={props.type}
                            step={props.step}
                            placeholder={props.placeholder}
                            validate={props.validate}
                            value={props.value}
                            onChange={props.onChange}
                        />
                    </div>
                </InputGroup>
            </AvGroup>
        </div>
    )
}

export default CustomInput;