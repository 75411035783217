import React from "react"
import { Table } from "reactstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import "./style.scss";

const CompanyTable = ({ paginatedData, editHandler }) => {


    return (
        <React.Fragment>
            <div>
                <Table className="table" responsive>
                    <thead>
                        <tr>
                            <th>Company Name</th>
                            <th>Contact Person</th>
                            <th>ABN</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map(n => {
                            return (
                                <tr key={n.id}>
                                    <td>{n.companyName || ''}</td>
                                    <td>{n.primaryContactPerson || ''}</td>
                                    <td>{n.abn || ''}</td>
                                    <td>{n.isActive ? 'Active' : 'Inactive'}</td>
                                    {<td><button className="btn tableIcons" onClick={() => editHandler(n.id)} > <FontAwesomeIcon icon={faPencilAlt} /></button></td>}

                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

            </div>
        </React.Fragment>
    )
}


export default CompanyTable;