import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Table } from 'reactstrap';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, Container, Label, Row } from 'reactstrap';
import {
  describeRequisition,
  saveRequisition,
  getProductsByDept,
  getProductsAndDeptByClient,
  setOnChange,
  setOnCleanUp,
  getAllProductWithoutID
} from '../../../store/actions/Operation/requisition';
import { AvForm } from 'availity-reactstrap-validation';
import { initialRequisitionState } from '../../../store/reducers/initialState';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _requisition, _allowbackdate } from '../../../constants/componentConstants';
import { IS_NEW, IS_REPEAT } from '../../../constants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import Dropdown from '../../CustomControls/Dropdown';
import CustomAvField from '../../CustomControls/CustomAvField';
import DatePicker from '../../CustomControls/CustomDatePicker';
import ErrorListing from '../../CustomControls/ErrorListing';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {
  getAllProductsPlaceOrder,
  setOrderDcManager
} from '../../../store/actions/customer';
import { addDaysToDate } from '../../../utils';

const PlaceOrder = props => {
  let history = useHistory();
  const backUrl = '/customer/place-order';
  const dispatch = useDispatch();
  const requisition = useSelector(state => state.requisition);
  // const customerProducts = useSelector(state => state.customer?.productsList || []);
  const customerProducts = useSelector(state => state.customer?.distributionProducts?.products?.map(p => {
    return {
      ...p,
      label: p.productName,
      value: p.productId
    };
  }) || []);

  const customer = useSelector(state => state.customer);
  const stateData = useSelector(state => state);
  const ui = useSelector(state => state.ui);
  const maxQty = ui.layout.maxQty;
  const permissions = ui.layout.permissions;

  // const product = useSelector(state => state.customer);
  let IsEditView = false;
  let { requisitionid, repeatid, isreferred } = useParams();

  let repeatCreation = true;
  if (repeatid && isreferred == '1') {
    repeatCreation = false;
  }
  if (requisitionid) {
    IsEditView = true;
  }

  const [viewMode, setViewMode] = useState(IsEditView);
  const allDAta = useSelector(state => state);

  let showSaveOption = hasPermissionToAction(permissions, _requisition, _create);
  let allowBackdate = hasPermissionToAction(permissions, _allowbackdate, _create);
  let currentRequisition = requisition.currentRequisition;

  let localPath = '/customer/place-order';
  let heading = 'Place Order';

  if (IsEditView) {
    heading = 'View Requisition';
    localPath = localPath + `/${requisitionid}`;
  }

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  // useEffect(() => {
  //   if (requisitionid) {
  //     dispatch(describeRequisition(requisitionid));
  //   } else if (repeatid && isreferred == '1') {
  //     dispatch(describeRequisition(IS_REPEAT, repeatid));
  //   } else {
  //     //   dispatch(describeRequisition(IS_NEW));
  //   }
  // }, [dispatch, requisitionid, repeatid, isreferred]);
  useEffect(() => {
    dispatch(getAllProductsPlaceOrder());
  }, [dispatch]);
  //clean up

  useEffect(() => {
    return () => {
      setLocalCleanUpData({
        data: { ...requisition.currentRequisition, requisitionDetails: [] }
      });
    };
  }, []);

  const setLocalCleanUpData = cleanUpData => {
    // debugger
    dispatch(setOnCleanUp(cleanUpData));
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  const viewSummaryHandler = status => {
    setViewMode(status);
  };

  const contentHeading = IsEditView ? `${heading} - ${currentRequisition.requisitionRef}` : heading;

  return (
    <React.Fragment>
      {/* {customer.redirect ? <Redirect to={backUrl} /> : ''} */}
      {customer.isLoading && <LoadingSpinner />}
      {/* {customer.showMessage && <Notification notificationType={notificationType} message={customer.message} />} */}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardTitle>{contentHeading} </CardTitle>
                {!viewMode ? (
                  <ManageRequisitionEdit
                    requisition={requisition}
                    products={customerProducts}
                    showSaveOption={showSaveOption}
                    allowBackdate={allowBackdate}
                    maxQty={maxQty}
                    cancelHandler={cancelHandler}
                    viewSummaryHandler={viewSummaryHandler}
                    dispatch={dispatch}
                  />
                ) : (
                  <ManageRequisitionView
                    requisition={requisition}
                    products={customerProducts}
                    showSaveOption={showSaveOption}
                    cancelHandler={cancelHandler}
                    isEditView={IsEditView}
                    viewSummaryHandler={viewSummaryHandler}
                    dispatch={dispatch}
                    repeatCreation={repeatCreation}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PlaceOrder;

const RequisitionDetailTable = ({ data, editItemHandler, deleteItemHandler, maxQty }) => {
  //
  const totalQty = data.reduce(function(accumulator, item) {
    return accumulator + parseInt(item.requestedQty);
  }, 0);

  return (
    <div>
      <Table className="tablelight" responsive>
        <thead>
          <tr>
            <th className="col-sm-4">Product Name</th>
            <th className="col-sm-2">Request Qty</th>
            <th className="col-sm-4">Expected Delivery Date</th>
            <th className="col-sm-2 alignRight"> Delete</th>
          </tr>
        </thead>
        <tbody>
          <React.Fragment>
            {data.map(n => {
              return (
                <UpdateTBRow
                  key={n.productId}
                  item={n}
                  editItemHandler={editItemHandler}
                  deleteItemHandler={deleteItemHandler}
                  maxQty={maxQty}
                />
              );
            })}
            {data.length > 0 && (
              <tr className="summary" key={'summary'}>
                <td>
                  <span className="summaryLabel">Line Count</span> <span className="summaryValue">{data.length}</span>
                </td>
                <td className="">
                  <span className="summaryLabel label-padding">Total Requested</span> <span className="summaryValue">{totalQty}</span>
                </td>
                <td></td>
                <td></td>
              </tr>
            )}
          </React.Fragment>
        </tbody>
      </Table>
    </div>
  );
};

const UpdateTBRow = ({ item, editItemHandler, deleteItemHandler, maxQty }) => {
  const [requestQty, setRequestQty] = useState(item.requestedQty);
  const onChangeHandler = e => {
    setRequestQty(e.target.value);
    item.requestedQty = e.target.value;
    editItemHandler(item);
  };

  const onDeleteHandler = e => {
    deleteItemHandler(item.productId);
  };

  return (
    <tr key={item.productId}>
      <td>{item.productName || ''}</td>
      <td>
        <CustomAvField
          name={`requestQty_${item.productId}`}
          label=""
          className="form-control "
          placeholder=""
          value={requestQty}
          onChange={onChangeHandler}
          step="1"
          min="0"
          max={item.requestedQty}
          type="number"
          maxLength="10"
          validate={{
            required: { value: false, errorMessage: 'Invalid request qty' },
            max: { value: maxQty, errorMessage: `Maximum qty allowed is ${maxQty}` }
          }}
        />
      </td>
      <td>{moment(new Date(item?.expectedDeliveryDate)).format('DD-MMM-YYYY') || ''}</td>
      <td className="alignRight">
        <button className="btn tableIcons" onClick={onDeleteHandler}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </td>
    </tr>
  );
};

const ManageRequisitionEdit = ({
  requisition,
  products,
  showSaveOption,
  allowBackdate,
  maxQty,
  cancelHandler,
  viewSummaryHandler,
  dispatch
}) => {
  const [triggerValidation, setTriggerValidation] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState('');
  const [selectedProductPrice, setSelectedProductPrice] = useState('');
  // const [orderRef, setOrderRef] = useState('');
  const orderRef = useSelector(state => state.customer?.distributionProducts?.orderRef)
  const [selectedProductLabel, setSelectedProductLabel] = useState('');
  const [departmentId, setDepartmentId] = useState('');
  const [selectedQty, setSelectedQty] = useState('0');
  const product = useSelector(state => state.customer);
  const history = useHistory();
  const [searchToDate, setSearchToDate] = useState(new Date());
  const [searchLimit, setSearchLimit] = useState(new Date());

  const handleExpectedDateInput = (field, dateVal) => {
    const setDate = dateVal ? new Date(dateVal) : null;
    setSearchToDate(setDate);
  };

  const searchInput = useRef(null);

  function handleFocus() {
    searchInput.current && searchInput.current.focus();
  }

  let currentRequisition = requisition.currentRequisition;

  const setLocalChange = currentState => {
    dispatch(setOnChange(currentState));
  };

  const handleInput = e => {
    // setLocalChange({
    //   ...requisition,
    //   data: { ...requisition.currentRequisition, [e.target.name]: e.target.value }
    // });
    // setOrderRef(e.target.value)
  };

  const handleDateInput = (field, dateVal) => {
    const setDate = dateVal ? new Date(dateVal) : null;

    const dateData = { [field]: setDate };

    if (field === 'requisitionDate') {
      dateData.deliveryDate = setDate;
    }

    setLocalChange({
      ...requisition,
      data: { ...requisition.currentRequisition, ...dateData }
    });
  };

  const handleSubmit = e => {
    const data = requisition.currentRequisition.requisitionDetails;
    let payload = {
      expectedDeliveryDate: searchToDate.toISOString(),
      orderRef: orderRef,
      orderItems: data.map(item => {
        return {
          productId: item.productId,
          productPrice: item.productPrice,
          productQuantity: +item.requestedQty
        };
      })
    };

    if (!isValid()) {
      setTriggerValidation(true);
      dispatch(setOrderDcManager(payload));
    }
    viewSummaryHandler(true);
    // payload = null;
    // dispatch(setOrderDcManager(payload));

    ClearData();
    // cancelHandler();
    history.push('/view-order');
    // history.goBack();
  };

  const isValid = () => {
    let status = true;
    if (!requisition.currentRequisition.clientProfileId) {
      status = false;
    }

    if (!requisition.currentRequisition.deliveryTypeId) {
      status = false;
    }

    if (!requisition.currentRequisition.departmentId) {
      status = false;
    }

    if (!requisition.currentRequisition.requisitionDate || requisition.currentRequisition.requisitionDate === '') {
      status = false;
    }
    if (requisition.currentRequisition.requisitionDetails.length > 0) {
      const totalQty = requisition.currentRequisition.requisitionDetails.reduce(function(accumulator, item) {
        return accumulator + parseInt(item.requestedQty);
      }, 0);
      if (totalQty < 1) {
        status = false;
      }
    } else {
      status = false;
    }

    return status;
  };
  const handleInvalidSubmit = e => {
    setTriggerValidation(true);
  };

  const onAddUpdatItemHandler = addObj => {
    let currentReqItems = requisition.currentRequisition.requisitionDetails || [];
    const objIndex = currentReqItems.findIndex(x => x.productId === addObj.productId);

    if (objIndex !== -1) {
      currentReqItems[objIndex].requestedQty = addObj.requestedQty;
    } else {
      currentReqItems.push({
        productId: addObj.productId,
        productName: addObj.productName,
        requestedQty: addObj.requestedQty,
        productPrice: addObj.productPrice,
        expectedDeliveryDate: addObj.expectedDeliveryDate
      });
    }
    setLocalChange({
      ...requisition,
      data: { ...requisition.currentRequisition, requisitionDetails: currentReqItems }
    });
  };

  const deleteItemHandler = id => {
    let currentReqItems = requisition.currentRequisition.requisitionDetails || [];

    const filterlist = currentReqItems.filter(x => x.productId !== id);
    setLocalChange({
      ...requisition,
      data: { ...requisition.currentRequisition, requisitionDetails: filterlist }
    });
  };
  const ClearData = () => {
    // let currentReqItems = requisition.currentRequisition.requisitionDetails || [];
    // const filterlist = currentReqItems.filter(x => x.productId === "aoun");

    setLocalChange({
      ...requisition,
      data: { ...requisition.currentRequisition, requisitionDetails: [] }
    });
  };

  /* Add local section */


  const selectedProductHandler = val => {
    setSelectedProductId(val.value);
    setSelectedProductLabel(val.label);
    setSelectedProductPrice(val.productPrice);
  };

  const selectedQtyHandler = e => {
    setSelectedQty(e.target.value);
  };

  const addProductHandler = () => {
    if (selectedProductId != '' && selectedProductId != '0' && parseInt(selectedQty) > 0) {
      onAddUpdatItemHandler({
        productId: selectedProductId,
        productName: selectedProductLabel,
        requestedQty: selectedQty,
        productPrice: selectedProductPrice,
        expectedDeliveryDate: searchToDate.toISOString()
      });
      handleFocus();
      setSelectedProductId('0');
      setSelectedQty('0');
      setSelectedProductPrice('0');
    }
  };

  return (
    <CardBody>
      <AvForm className="form-horizontal" onValidSubmit={handleSubmit} onInvalidSubmit={handleInvalidSubmit}>
        {requisition.errorList && requisition.errorList.length > 0 && <ErrorListing errorList={requisition.errorList} />}

        <Row>
          <Col md="12">
            <CardSubtitle>Order Products</CardSubtitle>
            <Card>
              <CardBody>
                <Row>
                  <Col md="4">
                    <div className="mb-3">
                      <CustomAvField
                        name="orderRef"
                        label="Order Ref"
                        className="form-control"
                        placeholder="Order Ref"
                        value={orderRef}
                        onChange={handleInput}
                        disabled
                        type="text"
                        maxLength="50"
                        validate={{
                          required: { value: true, errorMessage: 'Please enter a valid Order Ref' }
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  {/* <Col sm="4">
                    <div className="mb-3">
                      <Dropdown
                        placeholder="Select Department"
                        getOptionLabel={options => options['departmentName']}
                        getOptionValue={options => options['departmentId']}
                        options={product.department}
                        isMulti={false}
                        label="Department"
                        // value={ProductSelectionOptions(). find(x => x.value === selectedProductId)}
                        onChange={selectedDepartmentHandler}
                        isOptionDisabled={option => option.isdisabled}
                        triggerValidation={false}
                        ref={searchInput}
                        validate={{
                          required: { value: true, errorMessage: 'Please select a product' }
                        }}
                      />
                    </div>
                  </Col> */}

                  <Col sm="4">
                    <div className="mb-3">
                      <Dropdown
                        placeholder="Select Product"
                        getOptionLabel={options => options['productName']}
                        getOptionValue={options => options['productId']}
                        options={products}
                        isMulti={false}
                        label="Product"
                        // value={ProductSelectionOptions(). find(x => x.value === selectedProductId)}
                        onChange={selectedProductHandler}
                        isOptionDisabled={option => option.isdisabled}
                        triggerValidation={false}
                        ref={searchInput}
                        validate={{
                          required: { value: true, errorMessage: 'Please select a product' }
                        }}
                      />
                    </div>
                  </Col>

                  <Col md="2">
                    <div className="mb-3">
                      <CustomAvField
                        name="productqty"
                        label="Product Qty"
                        className="form-control"
                        placeholder="Product Qty"
                        value={selectedQty}
                        onChange={selectedQtyHandler}
                        step="1"
                        min="0"
                        type="number"
                        maxLength="12"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Please enter a valid quantity'
                          }
                        }}
                      />
                    </div>
                  </Col>
                  <Col sm="2">
                    <div className="mb-4">
                      <DatePicker
                        label="Expected Delivery Date"
                        selected={searchToDate}
                        onChange={date => handleExpectedDateInput('expectedDate', date)}
                        dateFormat="dd-MMM-yyyy"
                        minDate={searchLimit}
                      />
                    </div>
                  </Col>
                  <Col md="12" className="m-auto">
                    <Button type="button" color="primary" className="btn " onClick={addProductHandler}>
                      ADD
                    </Button>
                  </Col>
                  <Col md="4" className="m-auto"></Col>
                </Row>
                <hr />

                <RequisitionDetailTable
                  data={requisition.currentRequisition.requisitionDetails}
                  maxQty={maxQty}
                  editItemHandler={onAddUpdatItemHandler}
                  deleteItemHandler={deleteItemHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="d-flex flex-wrap gap-2">
          <Button
            type="submit"
            color="primary"
            className="btn"
            disabled={requisition.isLoading || requisition.currentRequisition.requisitionDetails.length <= 0}
          >
            PLACE ORDER
          </Button>

          {/* <Button onClick={cancelHandler} color="secondary" className=" ">
            CANCEL
          </Button> */}
        </div>
      </AvForm>
    </CardBody>
  );
};

const ManageRequisitionView = ({
  requisition,
  showSaveOption,
  cancelHandler,
  isEditView,
  viewSummaryHandler,
  dispatch,
  repeatCreation,
  products
}) => {
  let currentRequisition = requisition.currentRequisition;

  const saveHandler = () => {
    // if (requisition.currentRequisition) {
    //   dispatch(saveRequisition(requisition.currentRequisition, true, null, repeatCreation));
    //   viewSummaryHandler(false);
    // }
  };

  const backHandler = () => {
    viewSummaryHandler(false);
  };

  return (
    <CardBody>
      {requisition.errorList && requisition.errorList.length > 0 && <ErrorListing errorList={requisition.errorList} />}
      <Row>
        <Col lg={6}>
          {!currentRequisition.refAutoGenerated && (
            <Row>
              <Col sm="2">
                <Label className="label">Requistion Ref</Label>
              </Col>
              <Col sm="4">
                <Label>{currentRequisition.requisitionRef}</Label>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm="2">
              <Label className="label">Client</Label>
            </Col>
            <Col sm="4">
              <Label>{currentRequisition.clientProfileName}</Label>
            </Col>
          </Row>
          <Row>
            <Col sm="2">
              <Label className="label">Department</Label>
            </Col>
            <Col sm="4">
              <Label>{currentRequisition.departmentName}</Label>
            </Col>
          </Row>
          <Row>
            <Col sm="2">
              <Label className="label">Delivery Type</Label>
            </Col>
            <Col sm="4">
              <Label>{currentRequisition.deliveryTypeName}</Label>
            </Col>
          </Row>
          <Row>
            <Col sm="2">
              <Label className="label">Dispatched</Label>
            </Col>
            <Col sm="4">
              <Label>{currentRequisition.isDispatched ? 'Dispatched' : 'New'}</Label>
            </Col>
          </Row>
        </Col>
        <Col lg="6">
          <Row>
            <Col sm="2">
              <Label className="label">Requisition Date</Label>
            </Col>
            <Col sm="4">
              <Label>{moment(new Date(currentRequisition.requisitionDate)).format('DD-MMM-YYYY h:mm:ss a') || ''}</Label>
            </Col>
          </Row>
          <Row>
            <Col sm="2">
              <Label className="label">Delivery Date</Label>
            </Col>
            <Col sm="4">
              <Label>{moment(new Date(currentRequisition.deliveryDate)).format('DD-MMM-YYYY h:mm:ss a') || ''}</Label>
            </Col>
          </Row>
          <Row>
            <Col sm="2">
              <Label className="label">Remarks</Label>
            </Col>
            <Col sm="4">
              <Label>{currentRequisition.remarks}</Label>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <CardSubtitle>Requisition Products</CardSubtitle>
          <Card>
            <CardBody>
              <RequisitionDetailViewTable data={requisition.currentRequisition.requisitionDetails} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <div>
        {showSaveOption && !isEditView ? (
          <div className="d-flex flex-wrap gap-2">
            <Button type="submit" color="primary" className="btn " disabled={requisition.isLoading} onClick={saveHandler}>
              SUBMIT
            </Button>
            <Button onClick={backHandler} color="secondary" className=" ">
              BACK
            </Button>
          </div>
        ) : (
          <Button onClick={cancelHandler} color="secondary" className=" ">
            BACK
          </Button>
        )}
      </div>
    </CardBody>
  );
};

const RequisitionDetailViewTable = ({ data }) => {
  const saveData = data?.filter(x => x.requestedQty > 0) ?? [];

  const totalQty = saveData?.reduce(function(accumulator, item) {
    return accumulator + parseInt(item.requestedQty);
  }, 0);

  return (
    <div>
      <Table className="tablelight" responsive>
        <thead>
          <tr>
            <th className="col-sm-4">Product Name</th>
            <th className="col-sm-4 alignRight label-padding">Request Qty</th>
          </tr>
        </thead>
        <tbody>
          <React.Fragment>
            {saveData?.map(item => {
              return (
                <tr key={item.productId}>
                  <td>{item.productName || ''}</td>
                  <td className="label-padding">{item.requestedQty || ''}</td>
                </tr>
              );
            })}
            {saveData?.length > 0 && (
              <tr className="summary" key={'summary'}>
                <td>
                  <span className="summaryLabel">Line Count</span> <span className="summaryValue">{saveData.length}</span>
                </td>
                <td className="alignRight">
                  <span className="summaryLabel">Total Requested</span> <span>{totalQty}</span>
                </td>
              </tr>
            )}
          </React.Fragment>
        </tbody>
      </Table>
    </div>
  );
};
