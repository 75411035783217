import React from 'react';
import { Container, Row, Col } from "reactstrap"
import notfound from "../../assets/images/404.svg"

const Layout = ({ children }) => (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                {children}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={notfound} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>



);

export default Layout;
