import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/LayoutNoAuth/Layout';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getIsUserAuthenticated } from '../selectors/index';
import { logout } from '../store/actions/auth';

export default Component => {
  class NoAuth extends React.Component {
    render() {
      const { isAuthenticated, logout } = this.props;
      return (
        <Layout isAuthenticated={isAuthenticated} logout={logout}>
          <Component {...this.props} />
        </Layout>
      );
    }
  }

  const mapStateToProps = state => {
    return {
      isAuthenticated: getIsUserAuthenticated()
    };
  };

  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        logout: () => logout()
      },
      dispatch
    );

  NoAuth.propTypes = {
    logout: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
  };

  return connect(mapStateToProps, mapDispatchToProps)(NoAuth);
};
