import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Table from './ProductTypeTable';
import Pagination from '../../CustomControls/Pagination';
import { getAllProductTypes, setOnConfirmationModal, deleteProductType } from '../../../store/actions/Master/productType';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _producttype, _delete } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import SearchBar from '../../CustomControls/SearchBar';
import CustomConfirmation from '../../CustomControls/ConfirmationModal';

import './style.scss';

const ProductType = props => {
  const [searchText, setSearchText] = useState('');
  const [results, setResults] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const productType = useSelector(state => state.productType);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  const pageSize = ui.layout.pageSize;

  const notificationType = productType.isError ? 'error' : 'success';

  const showSaveOption = hasPermissionToAction(permissions, _producttype, _create);
  const showDeleteOption = hasPermissionToAction(permissions, _producttype, _delete);

  useEffect(() => {
    dispatch(getAllProductTypes());
  }, [dispatch]);

  useEffect(() => {
    if (searchText.length > 0) {
      setResults(
        productType.productTypeList.filter(
          x =>
            x.productTypeCode.toLowerCase().includes(searchText.toLowerCase()) ||
            x.productTypeName.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setResults(productType.productTypeList);
    }
  }, [searchText, setResults, productType.productTypeList]);

  const onPageChangeHandler = currentPage => {};

  const onAddNewHandler = () => {
    history.push('/master/product-types/product-type');
  };

  const onEditHandler = id => {
    history.push(`/master/product-types/product-type/${id}`);
  };

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Product Type Listing', path: '/master/product-types', isCurrent: true }
  ];

  const handleInput = e => {
    setSearchText(e.target.value);
  };

  const onDeleteHandler = id => {
    dispatch(
      setOnConfirmationModal({
        data: {
          showConfirmationModal: true,
          confirmationId: id
        }
      })
    );
  };

  const onDeleteConfirmation = () => {
    if (productType.confirmationId) {
      dispatch(deleteProductType(productType.confirmationId));
    }
  };

  const onDeleteCancel = () => {
    dispatch(
      setOnConfirmationModal({
        data: {
          showConfirmationModal: false,
          confirmationId: ''
        }
      })
    );
  };

  return (
    <React.Fragment>
      <CustomConfirmation
        show={productType.showConfirmationModal}
        onDeleteClick={onDeleteConfirmation}
        onCloseClick={onDeleteCancel}
        message="Are you sure you want to delete this record?"
        header="Deleting record"
      />
      <div className="page-content">
        {productType.showMessage && <Notification notificationType={notificationType} message={productType.message} />}
        <Container fluid>
          <Breadcrumbs title="Product Type Listing " paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="4">
                      <SearchBar onChange={handleInput} placeholder="Search" value={searchText} />
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        {showSaveOption && (
                          <Button type="button" className="btn btn-primary btn-block  app-add-button" onClick={onAddNewHandler}>
                            ADD PRODUCT TYPE
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="tablerow">
                    <Col xl="12">
                      <div>
                        {productType.isLoading && <LoadingSpinner />}
                        <Pagination data={results} pageSize={pageSize} onPageChange={onPageChangeHandler} startingPage={1}>
                          <Table editHandler={onEditHandler} deleteHandler={onDeleteHandler} showDelete={showDeleteOption} />
                        </Pagination>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProductType;
