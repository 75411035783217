import { LOCATION_GET_ALL_DATA, LOCATION_GET_DATA, LOCATION_ERROR, LOCATION_IS_LOADING, LOCATION_SAVE_DATA, LOCATION_ON_CHANGE_DATA, LOCATION_CLEAN_UP,LOCATION_DELETE_DATA, LOCATION_UPDATE_CONFIRMATION }  from '../../../constants/index';
import { initialLocationState } from '../initialState';

const locationReducer = (state = initialLocationState, action) => {
  const { payload, type } = action;
  switch (type) {
    case LOCATION_ERROR: {
      const { showMessage, errorMessage, errorList } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage,
        errorList: errorList
      };
    }
    case LOCATION_GET_ALL_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        locationList: resultData
      };
    }
    case LOCATION_GET_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          ...resultData
        }
      };
    }
    case LOCATION_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }
    case LOCATION_SAVE_DATA: {
      const { showMessage, message, redirect } = payload;
      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message
      };
    }

    case LOCATION_ON_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          ...data
        }
      };
    }
    case LOCATION_CLEAN_UP: {
      let { data } = payload;

      return {
        ...state,
        message: '',
        errorList: [],
        isError: false,
        showMessage: false,
        redirect: false,
        showConfirmationModal: false,
        confirmationId : '',
        currentLocation: {
          ...state.currentLocation,
          ...data
        }
      };
    }
    case LOCATION_DELETE_DATA: {
      const { showMessage, message, redirect } = payload;
      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message,
        showConfirmationModal: false,
        confirmationId : ''
      };
    }

    case LOCATION_UPDATE_CONFIRMATION: {
      let { data } = payload;

      return {
        ...state,
        ...data
      };
    }


    default:
      return state;
  }
};

export default locationReducer;




