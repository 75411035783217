import { IS_LOADING, SET_SETTINGS, UI_ERROR } from '../../constants/index';
import { initialUiState } from './initialState';

const uiReducer = (state = initialUiState, action) => {
  const { payload, type } = action;
  switch (type) {
    case IS_LOADING: {
      const { value } = payload;
      return {
        ...state,
        isLoading: value
      };
    }
    case SET_SETTINGS:

      const { setting } = payload;
      return {
        ...state,
        layout: {
          ...state.layout,
          permissions: setting.permissions,
          pageSize : setting.pageSize,
          maxQty : setting.maxQty,
          dataFetched: true
        }
      };
    case UI_ERROR: {
      const { showMessage, errorMessage } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage,
        layout: {
          ...state.layout,
          dataFetched: true
        }
      };
    }
    default:
      return state;
  }
};

export default uiReducer;
