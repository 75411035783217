import { COMPANY_GET_ALL_DATA, COMPANY_GET_DATA, COMPANY_ERROR, COMPANY_IS_LOADING, COMPANY_SAVE_DATA, COMPANY_ON_CHANGE_DATA, COMPANY_CLEAN_UP } from '../../../constants/index';
import { apiHelper } from '../../../utils/index';
import Cookies from 'js-cookie';
import { setErrorThunk, setLoadingStatus, setGetResults, setDescribeData, setSaveData, setOnChangeData, setOnCleanUpData } from '../actionHelper';

const API_URL = '/company'

export const getAllCompany = () => dispatch => {
    dispatch(_getAllCompany());
};

const _getAllCompany = () =>
    apiHelper({
        url: `${API_URL}`,
        method: 'GET',
        onLoad: status => setLoadingStatus(COMPANY_IS_LOADING, status),
        onSuccess: result => setGetResults(COMPANY_GET_ALL_DATA, result),
        onFailure: err => setErrorThunk(COMPANY_ERROR, true, err),
        accessToken: Cookies.get('accessToken'),
    });

export const describeCompany = (id) => dispatch => {
    dispatch(_getCompany(id));
};

const _getCompany = (id) =>
    apiHelper({
        url: `${API_URL}/${id}`,
        method: 'GET',
        data: {
            id: id
        },
        onLoad: status => setLoadingStatus(COMPANY_IS_LOADING, status),
        onSuccess: result => setDescribeData(COMPANY_GET_DATA, result),
        onFailure: err => setErrorThunk(COMPANY_ERROR, true, err),
        accessToken: Cookies.get('accessToken'),
    });

export const saveCompany = (saveData, isNew, id = null) => dispatch => {
    let MethodType = 'POST';
    if (!isNew) {
        MethodType = 'PUT'
    }

    let apiUrl = id ? `${API_URL}/${id}` : API_URL;

    dispatch(_saveCompany(saveData, MethodType, apiUrl));
}

const _saveCompany = (data, MethodType, apiUrl) =>
    apiHelper({
        url: apiUrl,
        method: MethodType,
        onSuccess: result => setSaveData(COMPANY_SAVE_DATA, true, result),
        onFailure: err => setErrorThunk(COMPANY_ERROR, true, err),
        onLoad: status => setLoadingStatus(COMPANY_IS_LOADING, status),
        data,
        accessToken: Cookies.get('accessToken'),
    });


export const setOnChange = retData => dispatch => {
    dispatch(setOnChangeData(COMPANY_ON_CHANGE_DATA, retData));
};

export const setOnCleanUp = retData => dispatch => {
    dispatch(setOnCleanUpData(COMPANY_CLEAN_UP, retData));
};



