import React from "react";
import { useDispatch } from 'react-redux';
import { Table } from "reactstrap"
import {
    Col,
    Row,
    Card,
    CardBody,
    Button
} from "reactstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Pagination from "../../CustomControls/Pagination";
import { PAGINATION_SUB_SIZE } from "../../../constants"
import LoadingSpinner from "../../CustomControls/Spinner";
import Dropdown from "../../CustomControls/Dropdown";
import { AvForm } from "availity-reactstrap-validation";
import { saveLocation, deleteLocation } from '../../../store/actions/Master/product';
import CustomAvField from "../../CustomControls/CustomAvField";
import "./style.scss";

const ProductLocation = ({ product, handleTabDataChangeHandler }) => {
    const dispatch = useDispatch();
    const currentProduct = product.currentProduct;
    const currentProductLocation = product.currentProduct.currentLocation;

    const onEditHandler = id => {

        const editObj = currentProduct.locations.find((obj => obj.locationId === id))

        onLocalChange({ ...currentProductLocation, ...editObj });
    };

    const onDeleteHandler = (productId, locationId) => {

        if (productId && locationId) {
            dispatch(deleteLocation(productId, locationId))
        }

    }

    const onPageChangeHandler = id => {

    };

    const onLocalChange = (value) => {

        handleTabDataChangeHandler('currentLocation', value)
    }

    const handleCheckBoxInput = (e) => {
        let isChecked;

        if (e.target.value === "false") {
            isChecked = false;
        } else {
            isChecked = true;
        }
        onLocalChange({ ...currentProductLocation, [e.target.name]: !isChecked });
    }

    const dropDownChange = (selectedOption) => {

        onLocalChange({ ...currentProductLocation, locationId: selectedOption.value, locationName: selectedOption.label });

    };

    const handleInput = (e) => {

        onLocalChange({ ...currentProductLocation, [e.target.name]: e.target.value });
    }

    const handleSubmit = (e) => {

        if (currentProductLocation) {
            let saveObj = currentProductLocation;
            saveObj.productId = currentProduct.productId;
            if (saveObj.locationId) {
                dispatch(saveLocation(saveObj))
            }
        }
    }

    const locationList = () => {
        let retList = currentProduct.locationSelectList;
        const locationList = currentProduct.locations;
        retList.forEach(element => {
            if (locationList.some(x => x.locationId === element.value)) {
                element.isdisabled = true;
            } else {
                element.isdisabled = false;
            }
        });

        return retList
    }

    return (
        <div>
            <Row>
                <Col md="4">
                    <h6>Add/Update Locations </h6>
                    <Card>
                        <CardBody>
                            <AvForm className="form-horizontal" onValidSubmit={handleSubmit} >

                                <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Dropdown
                                                placeholder="Select Location"
                                                label="Location"
                                                name="locationId"
                                                options={locationList()}
                                                isMulti={false}
                                                value={currentProduct.locationSelectList.find(x => x.value === currentProductLocation.locationId)}
                                                onChange={(val) => dropDownChange(val)}
                                                isOptionDisabled={(option) => option.isdisabled}
                                                validate={{
                                                    required: { value: true, errorMessage: 'Please select a product type' },
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3">
                                            <CustomAvField
                                                name="storageLocation"
                                                label="Storage Location"
                                                className="form-control"
                                                placeholder="Storage Location"
                                                value={currentProductLocation.storageLocation}
                                                onChange={handleInput}
                                                type="text"
                                                maxLength="25"
                                                validate={{
                                                    required: { value: false, errorMessage: 'Please enter a valid storage location' },
                                                }}

                                            />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3">
                                            <CustomAvField
                                                name="currentStock"
                                                label="Current Stock"
                                                className="form-control"
                                                placeholder="Current Stock"
                                                value={currentProductLocation.currentStock}
                                                onChange={handleInput}
                                                type="number"
                                                step="1"
                                                min="0"
                                                maxLength="20"
                                                validate={{
                                                    required: { value: false, errorMessage: 'Please enter a valid current stock' },
                                                }}

                                            />
                                        </div>
                                    </Col>
                                    <Col md="12" className="m-auto">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn "
                                            disabled={product.isLoading}
                                        >
                                            SAVE
                                        </Button>
                                    </Col>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="8">
                    <h6>Manage Product Location</h6>
                    <Card>
                        <CardBody>
                            <Row className="tablerow">
                                <Col xl="12">
                                    <div >
                                        {product.isLoading && <LoadingSpinner />}
                                        <Pagination
                                            data={currentProduct.locations}
                                            pageSize={PAGINATION_SUB_SIZE}
                                            onPageChange={onPageChangeHandler}
                                            startingPage={1}
                                        >
                                            <ProductLocationTable editHandler={onEditHandler} deleteHandler={onDeleteHandler} currentProduct={currentProduct} />
                                        </Pagination>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div >

    )
}
export default ProductLocation;

const ProductLocationTable = ({ paginatedData, editHandler, deleteHandler }) => {


    return (
        <React.Fragment>
            <div>
                <Table className="tablelight" responsive>
                    <thead>
                        <tr>
                            <th>Location Name</th>
                            <th>Storage Location</th>
                            <th>Current Stock</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map(n => {
                            return (
                                <tr key={n.locationId}>
                                    <td>{n.locationName || ''}</td>
                                    <td>{n.storageLocation || ''}</td>
                                    <td>{n.currentStock || ''}</td>
                                    {<td>

                                        <div className="flex-container flex-end">
                                            <div className="flex-item"> <button className="btn tableIcons" onClick={() => editHandler(n.locationId)} > <FontAwesomeIcon icon={faPencilAlt} /></button></div>
                                            <div className="flex-item"><button className="btn tableIcons" onClick={() => deleteHandler(n.productId, n.locationId)} > <FontAwesomeIcon icon={faTrashAlt} /></button></div>
                                        </div>


                                    </td>}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    )
}

