import React, { useEffect } from 'react';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper, faRedo, faShareSquare, faPrint, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import './style.scss';
import moment from 'moment';

const OrderTable = ({ paginatedData, editHandler, repeatHandler, dispatchHandler, printHandler, showPrint, deleteHandler, showDelete }) => {
  return (
    <React.Fragment>
      <div>
        <Table className="table" responsive>
          <thead>
            <tr>
              <th>Order Ref</th>
              <th>Date</th>
              <th>Expected Delivery Date</th>
              <th>Rejection Reason</th>
              <th>Status</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData?.data?.map(n => {
              return (
                <tr key={n.orderId}>
                  <td>{n.orderRef || ''}</td>
                  <td>{moment(new Date(n.orderDate)).format('DD-MMM-YYYY') || ''}</td>
                  <td>{moment(new Date(n.expectedDeliveryDate)).format('DD-MMM-YYYY') || ''}</td>
                  <td>{n.reason}</td>
                  <td>{n.status}</td>
                  {
                    <td>
                      <button className="btn tableIcons" onClick={() => editHandler(n.orderId)}>
                        {' '}
                        <FontAwesomeIcon icon={faNewspaper} />
                      </button>
                    </td>
                  }
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default OrderTable;
