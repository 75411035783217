import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
// import Table from './DistributionCenterTable';
import Pagination from '../../CustomControls/Pagination';

import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { hasPermissionToAction } from '../../../utils/accessUtil';
// import { getAllDistributionCenters } from '../../../store/actions/Management/distributionCenter';
import { Table } from 'reactstrap';
import { getAllClients } from '../../../store/actions/Management/viewClient';
import { useParams } from 'react-router-dom';
import ViewClientTable from './ViewClientTable';
// import "./style.scss";

const ViewClient = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const ui = useSelector(state => state.ui);
  const client = useSelector(state => state.client);
  const permissions = ui.layout.permissions;

  const notificationType = user.isError ? 'error' : 'success';

  const showSaveOption = hasPermissionToAction(permissions, 'users', 'create');
  //   const showResetPasswordOption = hasPermissionToAction(permissions, 'resetpassword', 'create');
  const { distributionid } = useParams();

  useEffect(() => {
    dispatch(getAllClients(distributionid));
  }, [dispatch]);
  let clientList = client.clientList.profiles;

  const onPageChangeHandler = currentPage => {};

  const onAddNewHandler = () => {
    history.push('/management/distribution-center/manage-dc');
  };

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Remote Linen Room', path: '/management/distribution-center' },
    { id: 3, title: 'Clients Details', path: 'management/distribution-center/view-client/', isCurrent: true }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        {client.showMessage && <Notification notificationType={notificationType} message={client.message} />}
        <Container fluid>
          <Breadcrumbs title="Clients Details" paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="tablerow">
                    <Col xl="12">
                      <div>
                        {client.isLoading && <LoadingSpinner />}
                        <Pagination data={clientList} pageSize={10} onPageChange={onPageChangeHandler} startingPage={1}>
                          <ViewClientTable />
                        </Pagination>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewClient;
