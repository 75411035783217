import React, { useState, useEffect  } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss"
import { Label } from "reactstrap";

const CustomDatePicker = ({ label, ...props }) => {
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    if (props.required || props.validate && props.validate.required.value) {
        label = <>{label}<sup>*</sup></>;
    }

    useEffect(() => {
        if(props.triggerValidation || props.selected) {
            doValidation(props.selected)
        }
    }, [props.triggerValidation, props.selected]);

    const localOnChange = selectOption => {
        doValidation(selectOption);
        props.onChange(selectOption);
    }

    const doValidation = (val) => {
        if (props.validate) {
            if (props.validate.required && props.validate.required.value) {
                if (!val || val === null || val === '') {
                    setShowError(true);
                    setErrorMessage(props.validate.required.errorMessage || '')
                }
                else {
                    setShowError(false);
                    setErrorMessage('')
                }
            }
        }
    }

    const errorLabelClass = showError ? 'label-error' : '';

    return (
        <div className="customDatePicker">
            <Label for={props.label} className={`label ${errorLabelClass}`} >{label}</Label>
            <DatePicker {...props } onChange={localOnChange} className={ showError ? 'custom-input-error' : ''} />
            {(showError && errorMessage.length > 0) && <div className="invalid-feedback">{errorMessage}</div>}
        </div>
    );
};

export default CustomDatePicker;