import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Table from './ProductTable';
import Pagination from '../../CustomControls/Pagination';
import { getAllProducts, setOnConfirmationModal, deleteProduct } from '../../../store/actions/Master/product';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _product, _delete } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import SearchBar from '../../CustomControls/SearchBar';
import CustomConfirmation from '../../CustomControls/ConfirmationModal';

import './style.scss';

const Product = props => {
  const [searchText, setSearchText] = useState('');
  const [results, setResults] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const product = useSelector(state => state.product);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  const pageSize = ui.layout.pageSize;

  const notificationType = product.isError ? 'error' : 'success';

  const showSaveOption = hasPermissionToAction(permissions, _product, _create);
  const showDeleteOption = hasPermissionToAction(permissions, _product, _delete);

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  useEffect(() => {
    if (searchText.length > 0) {
      setResults(
        product.productList.filter(
          x =>
            x.productCode.toLowerCase().includes(searchText.toLowerCase()) ||
            x.productName.toLowerCase().includes(searchText.toLowerCase()) ||
            x.productTypeCode.toLowerCase().includes(searchText.toLowerCase()) ||
            x.productTypeName.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setResults(product.productList);
    }
  }, [searchText, setResults, product.productList]);

  const onPageChangeHandler = currentPage => {};

  const onAddNewHandler = () => {
    history.push('/master/products/product');
  };

  const onEditHandler = id => {
    history.push(`/master/products/product/${id}`);
  };

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Product Listing', path: '/master/products', isCurrent: true }
  ];

  const handleInput = e => {
    setSearchText(e.target.value);
  };
  const onDeleteHandler = id => {
    dispatch(
      setOnConfirmationModal({
        data: {
          showConfirmationModal: true,
          confirmationId: id
        }
      })
    );
  };

  const onDeleteConfirmation = () => {
    if (product.confirmationId) {
      dispatch(deleteProduct(product.confirmationId));
    }
  };

  const onDeleteCancel = () => {
    dispatch(
      setOnConfirmationModal({
        data: {
          showConfirmationModal: false,
          confirmationId: ''
        }
      })
    );
  };

  return (
    <React.Fragment>
      <CustomConfirmation
        show={product.showConfirmationModal}
        onDeleteClick={onDeleteConfirmation}
        onCloseClick={onDeleteCancel}
        message="Are you sure you want to delete this record?"
        header="Deleting record"
      />
      <div className="page-content">
        {product.showMessage && <Notification notificationType={notificationType} message={product.message} />}
        <Container fluid>
          <Breadcrumbs title="Product Listing " paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="4">
                      <SearchBar onChange={handleInput} placeholder="Search" value={searchText} />
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        {showSaveOption && (
                          <Button type="button" className="btn btn-primary btn-block  app-add-button" onClick={onAddNewHandler}>
                            ADD PRODUCT
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="tablerow">
                    <Col xl="12">
                      <div>
                        {product.isLoading && <LoadingSpinner />}
                        <Pagination data={results} pageSize={pageSize} onPageChange={onPageChangeHandler} startingPage={1}>
                          <Table editHandler={onEditHandler} deleteHandler={onDeleteHandler} showDelete={showDeleteOption} />
                        </Pagination>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Product;
