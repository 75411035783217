import Cookies from 'js-cookie';
import { apiHelper } from '../../utils';
import { setErrorThunk, setGetResults, setOnSearchChangeData, setOnConfirmationData, setLoadingStatus, setOnChangeData } from './actionHelper';
import {
  ORDER_IS_LOADING,
  ORDER_ERROR,
  ORDER_SET_DATA,
  ORDER_ON_SEARCH_CHANGE_DATA,
  ORDER_UPDATE_CONFIRMATION,
  ORDER_DETAILS,
  ORDER_SET_PENDING,
  ORDER_ON_CHANGE_DATA,
  URL_IS_LOADING,
  URL_SET_PENDING,
  URL_ERROR

} from '../../constants';

// const API_URL = '/product/all';

export const getAllOrders = (currentPageNumber, pageSize, filterBy, sortBy) => dispatch => {
  dispatch(_getAllOrders(currentPageNumber, pageSize, filterBy, sortBy));
};

const _getAllOrders = (currentPageNumber, pageSize, filterBy, sortBy) => {
  const requisitionRefFilter = filterBy?.find(item => item.name === 'requisitionref');
  const startDateFilter = filterBy?.find(item => item.name === 'fromdate');
  const endDateFilter = filterBy?.find(item => item.name === 'todate');

  const filterByRequisitionRef = requisitionRefFilter?.value ?? '';
  const startDate = new Date(startDateFilter?.value) ?? null;
  const endDate = new Date(endDateFilter?.value) ?? null;
  return apiHelper({
    url: `/customer/get-orders`,
    method: 'POST',
    data: {
      pageNumber: currentPageNumber,
      pageSize: pageSize,
      filterBy: filterBy,
      sortBy: sortBy,
      searchQuery: filterByRequisitionRef,
      startDate: startDate,
      endDate: endDate
    },
    onLoad: status => setLoadingStatus(ORDER_IS_LOADING, status),
    onSuccess: result => setGetResults(ORDER_SET_DATA, result),
    onFailure: err => setErrorThunk(ORDER_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};

export const setOnSearchChange = retData => dispatch => {
  dispatch(setOnSearchChangeData(ORDER_ON_SEARCH_CHANGE_DATA, retData));
};
export const setOnConfirmationModal = retData => dispatch => {
  dispatch(setOnConfirmationData(ORDER_UPDATE_CONFIRMATION, retData));
};
export const setAllOrderDetails = data => dispatch => {
  dispatch(_setAllOrderDetails(data));
};

const _setAllOrderDetails = data =>
  apiHelper({
    url: `/customer/order-details?orderId=${data}`,
    method: 'GET',
    data: data,
    onLoad: status => setLoadingStatus(ORDER_IS_LOADING, status),
    onSuccess: result => setGetResults(ORDER_DETAILS, result),
    onFailure: err => setErrorThunk(ORDER_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
export const getPendingOrders = data => dispatch => {
  dispatch(_getPendingOrders(data));
};

const _getPendingOrders = data =>
  apiHelper({
    url: `/customer/pending-orders`,
    method: 'GET',
    onLoad: status => setLoadingStatus(ORDER_IS_LOADING, status),
    onSuccess: result => setGetResults(ORDER_SET_PENDING, result),
    onFailure: err => setErrorThunk(ORDER_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
  export const getSignalRUrl = data => dispatch => {
    dispatch( _getSignalRUrl(data));
  };
  
  const _getSignalRUrl = data =>
  apiHelper({
    url: `/customer/SignalRUrl`,
    method: 'GET',
    onLoad: status => setLoadingStatus(URL_IS_LOADING, status),
    onSuccess: result => setGetResults(URL_SET_PENDING, result),
    onFailure: err => setErrorThunk(URL_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

  export const setOnChangeCustomer = retData => dispatch => {
    dispatch(setOnChangeData(ORDER_ON_CHANGE_DATA, retData));
  };