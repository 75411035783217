import {
  RPT_CLIENTPROFILE_LISTING_SET_DATA,
  RPT_CLIENTPROFILE_LISTING_ERROR,
  RPT_CLIENTPROFILE_LISTING_IS_LOADING,
  RPT_CLIENTPROFILE_LISTING_ON_SEARCH_CHANGE_DATA,
  RPT_CLIENTPROFILE_LISTING_CLEAN_UP
} from '../../../constants/index';
import { initialRptClientProfileListingState } from '../initialReportState';

const rptClientProfileListingReducer = (state = initialRptClientProfileListingState, action) => {
  const { payload, type } = action;

  switch (type) {
    case RPT_CLIENTPROFILE_LISTING_ERROR: {
      const { showMessage, errorMessage } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage
      };
    }
    case RPT_CLIENTPROFILE_LISTING_SET_DATA: {
      const { resultData } = payload;

      let filterDataResult = state.filterData;

      if (resultData.filterData) {
        filterDataResult = resultData.filterData;
      }

      return {
        ...state,
        rptList: resultData.reportData,
        filterData: filterDataResult
      };
    }
    case RPT_CLIENTPROFILE_LISTING_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }

    case RPT_CLIENTPROFILE_LISTING_CLEAN_UP: {
      let { data } = payload;

      return {
        ...state,
        ...data
      };
    }

    case RPT_CLIENTPROFILE_LISTING_ON_SEARCH_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          ...data
        }
      };
    }
    default:
      return state;
  }
};

export default rptClientProfileListingReducer;
