import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  footerContainer: {
    flexDirection: 'row',
    borderStyle: 'solid',
    borderTopColor: '#bfbfbf',
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    position: 'absolute',
    fontSize: 10,
    bottom: 10,
    left: 20,
    right: 20,
    paddingTop: 10,
    paddingBottom: 5,
    marginTop: 10
  },
  footerInfo: {
    color: 'grey'
  },
  paging: {
    marginLeft: 'auto'
  }
});

const ReportFooter = ({ footerInfo, children }) => {
  return (
    <View style={styles.footerContainer} fixed>
      <View>
        <Text style={styles.footerInfo}>{footerInfo}</Text>
      </View>
      <View style={styles.paging}>{children}</View>
    </View>
  );
};

export default ReportFooter;
