import React from "react"
import { Table } from "reactstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import "./style.scss";

const ClientProfileTable = ({ paginatedData, editHandler, deleteHandler, showDelete }) => {


    return (
        <React.Fragment>
            <div>
                <Table className="table" responsive>
                    <thead>
                        <tr>
                            <th>Client Code</th>
                            <th>Client Name</th>
                            <th>ABN</th>
                            <th>Status</th>
                            <th>Is Online</th>
                            <th>Action</th>
                            {showDelete && <th className="col-sm-1">Delete</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map(n => {
                            return (
                                <tr key={n.clientProfileId}>
                                    <td>{n.clientCode || ''}</td>
                                    <td>{n.clientName || ''}</td>
                                    <td>{n.abn || ''}</td>
                                    <td>{n.isActive ? 'Active' : 'Inactive'}</td>
                                    <td>{n.isOnline ? 'Yes' : 'No'}</td>
                                    {<td>
                                        <button className="btn tableIcons" onClick={() => editHandler(n.clientProfileId)} > <FontAwesomeIcon icon={faPencilAlt} /></button>
                                    </td>}
                                    {showDelete && <td><button className="btn tableIcons" onClick={() => deleteHandler(n.clientProfileId)} > <FontAwesomeIcon icon={faTrashAlt} /></button></td>}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

            </div>
        </React.Fragment>
    )
}


export default ClientProfileTable;