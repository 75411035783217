import {
  ORDER_IS_LOADING,
  ORDER_ERROR,
  ORDER_SET_DATA,
  ORDER_DETAILS,
  ORDER_SET_INTERNAL,
  ORDER_ON_SEARCH_CHANGE_DATA,
  ORDER_SET_PENDING,
  ORDER_ON_CHANGE_DATA,
  URL_SET_PENDING
} from '../../constants';
import { initialCustomerState } from './initialState';

const orderReducer = (state = initialCustomerState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ORDER_ERROR: {
      const { showMessage, errorMessage, errorList } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage,
        errorList: errorList
      };
    }
    case ORDER_SET_INTERNAL: {
      const { resultData } = payload;
      return { ...state, internalOrder: resultData };
    }
    case ORDER_SET_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        orders: resultData,
        totalRecords: resultData.totalRecords
      };
    }
    case ORDER_SET_PENDING: {
      const { resultData } = payload;
      return {
        ...state,
        count: resultData
      };
    }
    case URL_SET_PENDING: {
      const { resultData } = payload;
      return {
        ...state,
        url: resultData
      };
    }
    case ORDER_ON_SEARCH_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        filterOptions: {
          ...state.data,
          ...data
        }
      };
    }
    case ORDER_DETAILS: {
      const { resultData } = payload;

      return {
        ...state,
        ordersDetails: resultData
      };
    }
    case ORDER_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }
    case ORDER_ON_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        internalOrder: {
          ...state.internalOrder,
          ...data
        }
      };
    }
    default:
      return state;
  }
};

export default orderReducer;
