import { CREDITNOTE_GET_ALL_DATA, CREDITNOTE_GET_DATA, CREDITNOTE_ERROR, CREDITNOTE_IS_LOADING, CREDITNOTE_SAVE_DATA, CREDITNOTE_ON_CHANGE_DATA, CREDITNOTE_CLEAN_UP, CREDITNOTE_ON_SEARCH_CHANGE_DATA, CREDITNOTE_GET_ONLINE_REPORT ,CREDITNOTE_DELETE_DATA, CREDITNOTE_UPDATE_CONFIRMATION}  from '../../../constants/index';
import { initialCreditNoteState } from '../initialState';

const creditNoteReducer = (state = initialCreditNoteState, action) => {
  const { payload, type } = action;
  switch (type) {
    case CREDITNOTE_ERROR: {
      const { showMessage, errorMessage } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage
      };
    }
    case CREDITNOTE_GET_ALL_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        creditNoteList: resultData.data,
        totalRecords : resultData.totalRecords,
        filterOptions: {
            ...state.filterOptions,
            pageNumber : resultData.pageNumber,
            pageSize  : resultData.pageSize,
          }
      };
    }
    case CREDITNOTE_GET_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        currentCreditNote: {
          ...state.currentCreditNote,
          ...resultData
        }
      };
    }
    case CREDITNOTE_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }
    case CREDITNOTE_SAVE_DATA: {
      const { showMessage, message, redirect } = payload;
      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message
      };
    }

    case CREDITNOTE_ON_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        currentCreditNote: {
          ...state.currentCreditNote,
          ...data
        }
      };
    }
    case CREDITNOTE_CLEAN_UP: {
      let { data } = payload;

      return {
        ...state,
        message: '',
        isError: false,
        showMessage: false,
        redirect: false,
        showConfirmationModal: false,
        confirmationId : '',
        currentCreditNote: {
          ...state.currentCreditNote,
          ...data
        }
      };
    }

    case CREDITNOTE_ON_SEARCH_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          ...data
        }
      };
    }
    case CREDITNOTE_GET_ONLINE_REPORT: {
      const { resultData } = payload;
      return {
        ...state,
        creditNoteReport: {
          ...state.creditNoteReport,
          ...resultData
        }
      };
    }
    case CREDITNOTE_DELETE_DATA: {
      const { showMessage, message, redirect } = payload;
      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message,
        showConfirmationModal: false,
        confirmationId : ''
      };
    }

    case CREDITNOTE_UPDATE_CONFIRMATION: {
      let { data } = payload;

      return {
        ...state,
        ...data
      };
    }


    default:
      return state;
  }
};

export default creditNoteReducer;




