import { CREDITNOTE_GET_ALL_DATA, CREDITNOTE_GET_DATA, CREDITNOTE_ERROR, CREDITNOTE_IS_LOADING, CREDITNOTE_SAVE_DATA, CREDITNOTE_ON_CHANGE_DATA, CREDITNOTE_CLEAN_UP,CREDITNOTE_ON_SEARCH_CHANGE_DATA, CREDITNOTE_GET_ONLINE_REPORT,CREDITNOTE_DELETE_DATA, CREDITNOTE_UPDATE_CONFIRMATION } from '../../../constants/index';
import { apiHelper } from '../../../utils/index';
import Cookies from 'js-cookie';
import { setErrorThunk, setLoadingStatus, setGetResults, setDescribeData, setSaveData, setOnChangeData, setOnCleanUpData, setOnSearchChangeData,setDeleteData,setOnConfirmationData } from '../actionHelper';

const API_URL = '/creditnote'



export const getAllCreditNotes = (currentPageNumber, pageSize, filterBy, sortBy) => dispatch => {
    dispatch(_getAllCreditNotes(currentPageNumber, pageSize, filterBy, sortBy));
};

const _getAllCreditNotes = (currentPageNumber, pageSize, filterBy, sortBy) =>
    apiHelper({
        url: `${API_URL}/filter`,
        method: 'POST',
        data : {
            pageNumber : currentPageNumber,
            pageSize : pageSize,
            filterBy : filterBy, 
            sortBy : sortBy
        },
        onLoad: status => setLoadingStatus(CREDITNOTE_IS_LOADING, status),
        onSuccess: result => setGetResults(CREDITNOTE_GET_ALL_DATA, result),
        onFailure: err => setErrorThunk(CREDITNOTE_ERROR, true, err),
        accessToken: Cookies.get('accessToken'),
    });


export const describeCreditNote = (id) => dispatch => {
    dispatch(_getCreditNote(id));
};

const _getCreditNote = (id) =>
    apiHelper({
        url: `${API_URL}/${id}`,
        method: 'GET',
        data: {
            id: id
        },
        onLoad: status => setLoadingStatus(CREDITNOTE_IS_LOADING, status),
        onSuccess: result => setDescribeData(CREDITNOTE_GET_DATA, result),
        onFailure: err => setErrorThunk(CREDITNOTE_ERROR, true, err),
        accessToken: Cookies.get('accessToken'),
    });

export const saveCreditNote = (saveData, isNew, id = null) => dispatch => {
    let MethodType = 'POST';
    if (!isNew) {
        MethodType = 'PUT'
    }

    let apiUrl = id ? `${API_URL}/${id}` : API_URL;

    dispatch(_saveCreditNote(saveData, MethodType, apiUrl));
}

const _saveCreditNote = (data, MethodType, apiUrl) =>
    apiHelper({
        url: apiUrl,
        method: MethodType,
        onSuccess: result => setSaveData(CREDITNOTE_SAVE_DATA, true, result),
        onFailure: err => setErrorThunk(CREDITNOTE_ERROR, true, err),
        onLoad: status => setLoadingStatus(CREDITNOTE_IS_LOADING, status),
        data,
        accessToken: Cookies.get('accessToken'),
    });


export const setOnChange = retData => dispatch => {
    dispatch(setOnChangeData(CREDITNOTE_ON_CHANGE_DATA, retData));
};

export const setOnCleanUp = retData => dispatch => {
    dispatch(setOnCleanUpData(CREDITNOTE_CLEAN_UP, retData));
};

export const setOnSearchChange = retData => dispatch => {
    dispatch(setOnSearchChangeData(CREDITNOTE_ON_SEARCH_CHANGE_DATA, retData));
};



export const getReportCreditNote = (id) => dispatch => {
    dispatch(_getReportCreditNote(id));
};

const _getReportCreditNote = (id) =>
    apiHelper({
        url: `${API_URL}/report/${id}`,
        method: 'GET',
        data: {
            id: id,
        },
        onLoad: status => setLoadingStatus(CREDITNOTE_IS_LOADING, status),
        onSuccess: result => setDescribeData(CREDITNOTE_GET_ONLINE_REPORT, result),
        onFailure: err => setErrorThunk(CREDITNOTE_ERROR, true, err),
        accessToken: Cookies.get('accessToken'),
    });


    // DELETE OPERATION
export const deleteCreditNote = (id, filterOptions) => dispatch => {
    let MethodType = 'DELETE';
    let apiUrl = id ? `${API_URL}/${id}` : API_URL;

    dispatch(_deleteCreditNote(MethodType, apiUrl,filterOptions));
    
}

const _deleteCreditNote = (MethodType, apiUrl,filterOptions) =>
    apiHelper({
        url: apiUrl,
        method: MethodType,
        onSuccess: result => onDeleteSuccess(result,filterOptions),
        onFailure: err => setErrorThunk(CREDITNOTE_ERROR, true, err),
        onLoad: status => setLoadingStatus(CREDITNOTE_IS_LOADING, status),
        accessToken: Cookies.get('accessToken'),
    });

export const setOnConfirmationModal = retData => dispatch => {
    dispatch(setOnConfirmationData(CREDITNOTE_UPDATE_CONFIRMATION, retData));
};


export const onDeleteSuccess = (result,filterOptions) => dispatch => {
    dispatch(setDeleteData(CREDITNOTE_DELETE_DATA, true, result));
    dispatch(_getAllCreditNotes(filterOptions.currentPageNumber, filterOptions.pageSize, filterOptions.filterBy, filterOptions.sortBy));
};
