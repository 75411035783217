import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
// import { getReportRequisition } from '../../../store/actions/order';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { isEmpty, map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { setCustomerDetailsByID } from '../../../store/actions/customerDetail';

const ViewCustomer = props => {
  let history = useHistory();
  const dispatch = useDispatch();
  let { requisitionid } = useParams();
  const backUrl = '/order/orderlist';
  const requisition = useSelector(state => state.requisition);
  const ordersDetails = useSelector(state => state?.customerDetail?.customerDetailsById);
  let localPath = `/order/orderlist/order-report/${requisitionid}`;
  const allData = useSelector(state => state);
  let heading = 'Customer Details';
  let reportData = requisition.requisitionReport;

  const imageResult = window.localStorage.getItem('lmsCompanyLogo');

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Customer Details', path: '/customer/customer-info' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  useEffect(() => {
    if (requisitionid) {
      // dispatch(getReportRequisition(requisitionid));
    }
  }, [dispatch, requisitionid]);

  const printInvoice = () => {
    window.print();
  };

  const cancelHandler = () => {
    history.push('/customer/customer-info');
  };
  let totalQty = 0;
  if (reportData && ordersDetails?.length > 0) {
    totalQty = ordersDetails.reduce(function(prev, cur) {
      return prev + parseInt(cur.productQuantity);
    }, 0);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          {!isEmpty(reportData) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="report-container">
                      <div id="content">
                        <div className="table-responsive">
                          <Table className="table-nowrap">
                            <thead style={{ overflowX: 'auto' }}>
                              <tr className="reportLine">
                                <th style={{ width: 'auto', whiteSpace: 'nowrap' }}>Customer Name</th>
                                <th style={{ width: 'auto', whiteSpace: 'nowrap' }}>Delivery Street Address</th>
                                <th style={{ width: 'auto', whiteSpace: 'nowrap' }}>Delivery Suburb</th>
                                <th style={{ width: 'auto', whiteSpace: 'nowrap' }}>Delivery State</th>
                                <th style={{ width: 'auto', whiteSpace: 'nowrap' }}>Delivery Post Code</th>
                                <th style={{ width: 'auto', whiteSpace: 'nowrap' }}>Billing Street Address</th>
                                <th style={{ width: 'auto', whiteSpace: 'nowrap' }}>Billing Suburb</th>
                                <th style={{ width: 'auto', whiteSpace: 'nowrap' }}>Billing State</th>
                                <th style={{ width: 'auto', whiteSpace: 'nowrap' }}>Billing Post Code</th>
                                <th style={{ width: 'auto', whiteSpace: 'nowrap' }}>Primary Contact Person</th>
                                <th style={{ width: 'auto', whiteSpace: 'nowrap' }}>Primary Contact Email</th>
                                <th style={{ width: 'auto', whiteSpace: 'nowrap' }}>Primary Contact Phone</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{ordersDetails?.clientName}</td>
                                <td>{ordersDetails?.deliveryStreetAddress}</td>
                                <td>{ordersDetails?.deliverySuburb}</td>
                                <td>{ordersDetails?.deliveryState}</td>
                                <td>{ordersDetails?.deliveryPostCode}</td>
                                <td>{ordersDetails?.billingStreetAddress}</td>
                                <td>{ordersDetails?.billingSuburb}</td>
                                <td>{ordersDetails?.billingState}</td>
                                <td>{ordersDetails?.billingPostCode}</td>
                                <td>{ordersDetails?.primaryContactPerson}</td>
                                <td>{ordersDetails?.primaryContactEmail}</td>
                                <td>{ordersDetails?.primaryContactPhone}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div className="d-print-none">
                        <div className="float-start">
                          {/* <Link to="#" onClick={cancelHandler} className="btn btn-primary w-md button-space">
                            Submit
                          </Link> */}
                          <Link to="#" onClick={cancelHandler} className="btn btn-secondary w-md button-space">
                            Back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewCustomer;
