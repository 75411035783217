import { AUDITLOG_GET_ALL_DATA, AUDITLOG_ERROR, AUDITLOG_IS_LOADING,  AUDITLOG_ON_CHANGE_DATA, AUDITLOG_CLEAN_UP } from '../../../constants/index';
import { apiHelper } from '../../../utils/index';
import Cookies from 'js-cookie';
import { setErrorThunk, setLoadingStatus, setGetResults,  setOnChangeData, setOnCleanUpData } from '../actionHelper';


const API_URL = '/auditlog'

export const getAllAuditLogs = ( currentPageNumber, pageSize, filterBy, sortBy) => dispatch => {
    dispatch(_getAllAuditLogs(currentPageNumber, pageSize, filterBy, sortBy));
};

const _getAllAuditLogs = (currentPageNumber, pageSize, filterBy, sortBy) =>
    apiHelper({
        url: `${API_URL}/filterauditlogs`,
        method: 'POST',
        data : {
            pageNumber : currentPageNumber,
            pageSize : pageSize,
            filterBy : filterBy, 
            sortBy : sortBy
        },
        onLoad: status => setLoadingStatus(AUDITLOG_IS_LOADING, status),
        onSuccess: result => setGetResults(AUDITLOG_GET_ALL_DATA, result),
        onFailure: err => setErrorThunk(AUDITLOG_ERROR, true, err),
        accessToken: Cookies.get('accessToken'),
    });


export const setOnChange = retData => dispatch => {
    dispatch(setOnChangeData(AUDITLOG_ON_CHANGE_DATA, retData));
};

export const setOnCleanUp = retData => dispatch => {
    dispatch(setOnCleanUpData(AUDITLOG_CLEAN_UP, retData));
};