import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { _create, _reportprint, _requisition, _delete } from '../../../constants/componentConstants';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { sendJobReport, setOnSearchChange } from '../../../store/actions/ReportOperation/rptDepartmentSummary';
import DatePicker from '../../CustomControls/CustomDatePicker';
import { getFirstDateOfLastMonth } from '../../../utils/index';
import LoadingSpinner from '../../CustomControls/Spinner';
import Papa from 'papaparse';

const JobReport = props => {
  const dispatch = useDispatch();
  const [filterFromDate, setFilterFromDate] = useState({ field: 'fromdate', value: getFirstDateOfLastMonth(), label: '' });
  const [filterToDate, setFilterToDate] = useState({ field: 'todate', value: new Date(), label: '' });
  const csvCustomerData = useSelector(state => state.customerDetail);
  const csvCustomerJobData = useSelector(state => state?.customerDetail?.csvDataCustomerJobReport);
  const isLoading = csvCustomerData.isLoading || false;
  console.log('---state---', csvCustomerJobData);
  const [csvData, setCsvData] = useState();
  useEffect(() => {
    setCsvData(csvCustomerJobData);
  }, [csvCustomerJobData]);
  // const [filterFromDate, setFilterFromDate] = useState(addDaysToDate(new Date(), -30));
  // const [filterToDate, setFilterToDate] = useState(new Date());
  const reportHeading = 'Job';

  const ui = useSelector(state => state.ui);
  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: `${reportHeading} Report`, path: '/operation-reports/job-report', isCurrent: true }
  ];

  const handleDateInput = (field, dateVal, callSetMethod) => {
    const saveObj = {};
    const setDate = dateVal ? new Date(dateVal) : null;
    saveObj.value = setDate;
    saveObj.field = field;
    saveObj.label = '';
    callSetMethod(saveObj);
  };

  const onGenerateHandler = () => {
    dispatch(sendJobReport(filterFromDate.value, filterToDate.value));
  };

  const downloadCsv = () => {
    const csvContent = Papa.unparse(csvData);
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    a.click();
    URL.revokeObjectURL(url);
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={`${reportHeading} Report`} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="2">
                      <div className="mb-4">
                        <DatePicker
                          label="Start Date"
                          selected={filterFromDate.value}
                          onChange={date => handleDateInput('fromdate', date, setFilterFromDate)}
                          dateFormat="dd-MMM-yyyy"
                        />
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="mb-4">
                        <DatePicker
                          label="End Day"
                          selected={filterToDate.value}
                          onChange={date => handleDateInput('todate', date, setFilterToDate)}
                          dateFormat="dd-MMM-yyyy"
                        />
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="text-sm-end">
                        <Button type="button" className="btn btn-primary btn-block  app-add-button" onClick={onGenerateHandler}>
                          Send
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {csvData?.length > 0 && (
                    <>
                      <Row>
                        <Col>
                          <Button onClick={downloadCsv} type="button" className="btn btn-primary btn-block mb-3 mx-auto ">
                            Download CSV
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default JobReport;
