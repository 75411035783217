import React, { useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumb from '../CustomControls/Breadcrumb';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { useDispatch, useSelector } from 'react-redux';
import { getIsLoading } from '../../selectors';
import LoadingSpinner from '../CustomControls/Spinner';

import './style.scss';
import { useEffect } from 'react';
import { getEmailConfigration, submitEmailConfigration } from '../../store/actions/email';
import { setLoadingStatus } from '../../store/actions/actionHelper';

const Email = props => {
  const dispatch = useDispatch();
  const email = useSelector(state => state?.email?.emailConfigration || []);
  const emailLoading = useSelector(state => state?.email || []);
  const [form, setForm] = useState({
    mail: email.mail,
    displayName: email.displayName,
    host: email.host,
    port: email.port,
    recipient: email.recipient,
    password: email.password
  });
  useEffect(() => {
    dispatch(getEmailConfigration());
  }, [dispatch]);

  const isLoading = useSelector(state => getIsLoading(state));

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Email', path: '/email', isCurrent: true }
  ];

  const handleInput = e => {
    const { name, value } = e.target;
    setForm({
      ...email,
      [name]: value
    });
  };
  const handleSubmit = e => {
    dispatch(submitEmailConfigration(form));
    // dispatch(getEmailConfigration())
  };

  return (
    <React.Fragment>
      {/* <CustomConfirmation
        show={requisition.showConfirmationModal}
        onDeleteClick={onDeleteConfirmation}
        onCloseClick={onDeleteCancel}
        message="Are you sure you want to delete this record?"
        header="Deleting record"
      /> */}
      <div className="page-content">
        {emailLoading.isLoading && <LoadingSpinner />}
        {/* {email.showMessage && <Notification notificationType={notificationType} message={email.message} />} */}
        <Container fluid>
          <Breadcrumb title="Email " paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={() => {
                      handleSubmit();
                    }}
                  >
                    <Row>
                      <Col xs="3">
                        <AvField
                          name="displayName"
                          label="Name *"
                          className="form-control"
                          placeholder="Enter Your Name"
                          value={email.displayName || form.displayName}
                          onChange={handleInput}
                          type="text"
                          validate={{
                            required: { value: false }
                          }}
                          // , errorMessage: 'Please enter a name'
                        />
                      </Col>
                      <Col xs="3">
                        <AvField
                          name="mail"
                          label="Email *"
                          className="form-control"
                          placeholder="Enter Your Email"
                          value={email.mail || form.mail}
                          onChange={handleInput}
                          type="email"
                          validate={{
                            required: { value: false }
                            // , errorMessage: 'Please enter an email'
                            // pattern: {
                            //   value: '/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/',
                            //   errorMessage: 'Please enter a valid email address'
                            // }
                          }}
                        />
                      </Col>
                      <Col xs="3">
                        <AvField
                          name="port"
                          label="Port *"
                          className="form-control"
                          placeholder="Enter port"
                          value={email.port || form.port}
                          onChange={handleInput}
                          type="text"
                          validate={{
                            required: { value: false }
                          }}
                          // , errorMessage: 'Please enter a contact person'
                        />
                      </Col>
                      <Col xs="3">
                        <AvField
                          name="host"
                          label="Host *"
                          className="form-control"
                          placeholder="Enter host"
                          value={email.host || form.host}
                          onChange={handleInput}
                          type="text"
                          validate={{
                            required: { value: false }
                          }}
                          // , errorMessage: 'Please enter an email'
                        />
                      </Col>
                      <Col xs="3">
                        <AvField
                          name="recipient"
                          label="Recipient *"
                          className="form-control"
                          placeholder="Enter Recipient"
                          value={email.recipient || form.recipient}
                          onChange={handleInput}
                          type="text"
                          validate={{
                            required: { value: false }
                          }}
                          // , errorMessage: 'Please enter an email'
                        />
                      </Col>
                      <Col xs="3">
                        <AvField
                          name="password"
                          label="Password *"
                          className="form-control"
                          placeholder="Enter Your Password"
                          value={email.password || form.password}
                          onChange={handleInput}
                          type="password"
                          validate={{
                            required: { value: false }
                            // pattern: {
                            // , errorMessage: 'Please enter a password'
                            //   value: '/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/',
                            //   errorMessage: 'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:'
                            // }
                          }}
                        />
                      </Col>
                    </Row>

                    <div className="mt-3 btn-block">
                      {isLoading ? (
                        <LoadingSpinner centeredLoading={false} />
                      ) : (
                        <Button disabled={isLoading} className="btn btn-primary">
                          Save
                        </Button>
                      )}
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Email;
