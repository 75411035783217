import {
  DISPATCH_GET_ALL_DATA,
  DISPATCH_GET_DATA,
  DISPATCH_ERROR,
  DISPATCH_IS_LOADING,
  DISPATCH_SAVE_DATA,
  DISPATCH_ON_CHANGE_DATA,
  DISPATCH_CLEAN_UP,
  DISPATCH_ON_SEARCH_CHANGE_DATA,
  DISPATCH_GET_PRODUCTS_DEPT,
  DISPATCH_GET_ONLINE_REPORT,
  DISPATCH_DELETE_DATA,
  DISPATCH_UPDATE_CONFIRMATION,
  DISPATCH_BULK_GET_DATA,
  DISPATCH_BULK_ON_CHANGE_DATA,
  DISPATCH_BULK_GET_REQUISTION_CLIENT,
  DISPATCH_BULK_SAVE_DATA,
  DISPATCH_BULK_SAVE_CONFIRMATION,
  DISPATCH_BULK_CLEAN_UP,
  DISPATCH_ON_CHANGE_DATA_CUSTOM
} from '../../../constants/index';
import { apiHelper } from '../../../utils/index';
import Cookies from 'js-cookie';
import {
  setErrorThunk,
  setLoadingStatus,
  setGetResults,
  setDescribeData,
  setSaveData,
  setOnChangeData,
  setOnCleanUpData,
  setOnSearchChangeData,
  setDeleteData,
  setOnConfirmationData
} from '../actionHelper';

const API_URL = '/dispatch';

export const getAllDispatches = (currentPageNumber, pageSize, filterBy, sortBy, sortConfig) => dispatch => {
  const newSortBy = {
    name: sortConfig?.key ?? 'requisitionref',
    order: sortConfig?.direction ?? 'desc'
  };
  dispatch(_getAllDispatches(currentPageNumber, pageSize, filterBy, newSortBy));
};

const _getAllDispatches = (currentPageNumber, pageSize, filterBy, sortBy) =>
  apiHelper({
    url: `${API_URL}/filter`,
    method: 'POST',
    data: {
      pageNumber: currentPageNumber,
      pageSize: pageSize,
      filterBy: filterBy,
      sortBy: sortBy
    },
    onLoad: status => setLoadingStatus(DISPATCH_IS_LOADING, status),
    onSuccess: result => setGetResults(DISPATCH_GET_ALL_DATA, result),
    onFailure: err => setErrorThunk(DISPATCH_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

export const describeDispatch = (id, reqId = null) => dispatch => {
  dispatch(_getDispatch(id, reqId));
};

const _getDispatch = (id, reqId) =>
  apiHelper({
    url: `${API_URL}/${id}`,
    method: 'GET',
    data: {
      id: id,
      reqId: reqId
    },
    onLoad: status => setLoadingStatus(DISPATCH_IS_LOADING, status),
    onSuccess: result => {
      console.log('in dispatch action', result);
      return setDescribeData(DISPATCH_GET_DATA, result);
    },
    onFailure: err => setErrorThunk(DISPATCH_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

export const saveDispatch = (saveData, isNew, id = null, repeatDisp = true) => dispatch => {
  let MethodType = 'POST';
  if (!isNew) {
    MethodType = 'PUT';
  }

  let apiUrl = id ? `${API_URL}/${id}` : API_URL;

  dispatch(_saveDispatch(saveData, MethodType, apiUrl, repeatDisp));
};

const _saveDispatch = (data, MethodType, apiUrl, repeatDisp) =>
  apiHelper({
    url: apiUrl,
    method: MethodType,
    onSuccess: result => setSaveData(DISPATCH_SAVE_DATA, true, result, { repeatDisp: repeatDisp, requisitionId: data.requisitionId }),
    onFailure: err => setErrorThunk(DISPATCH_ERROR, true, err),
    onLoad: status => setLoadingStatus(DISPATCH_IS_LOADING, status),
    data,
    accessToken: Cookies.get('accessToken')
  });

export const setOnChange = retData => dispatch => {
  dispatch(setOnChangeData(DISPATCH_ON_CHANGE_DATA, retData));
};
export const setOnChangeCustom = retData => dispatch => {
  dispatch(setOnChangeData(DISPATCH_ON_CHANGE_DATA_CUSTOM, retData));
};

export const setOnCleanUp = retData => dispatch => {
  dispatch(setOnCleanUpData(DISPATCH_CLEAN_UP, retData));
};

// Get Products by Dept
export const getProductsByDept = id => dispatch => {
  dispatch(_getProductsByDept(id));
};

const _getProductsByDept = id =>
  apiHelper({
    url: `/product/department/${id}`,
    method: 'GET',
    data: {
      id: id
    },
    onLoad: status => setLoadingStatus(DISPATCH_IS_LOADING, status),
    onSuccess: result => {
      return setProductDeptData(DISPATCH_GET_PRODUCTS_DEPT, result);
    },
    onFailure: err => setErrorThunk(DISPATCH_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

export const setProductDeptData = (RTYPE, result) => ({
  type: RTYPE,
  payload: { resultData: result }
});

export const setOnSearchChange = retData => dispatch => {
  dispatch(setOnSearchChangeData(DISPATCH_ON_SEARCH_CHANGE_DATA, retData));
};

export const getReportDispatch = id => dispatch => {
  dispatch(_getReportDispatch(id));
};

const _getReportDispatch = id =>
  apiHelper({
    url: `${API_URL}/report/${id}`,
    method: 'GET',
    data: {
      id: id
    },
    onLoad: status => setLoadingStatus(DISPATCH_IS_LOADING, status),
    onSuccess: result => setDescribeData(DISPATCH_GET_ONLINE_REPORT, result),
    onFailure: err => setErrorThunk(DISPATCH_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

// DELETE OPERATION
export const deleteDispatch = (id, filterOptions) => dispatch => {
  let MethodType = 'DELETE';
  let apiUrl = id ? `${API_URL}/${id}` : API_URL;

  dispatch(_deleteDispatch(MethodType, apiUrl, filterOptions));
};

const _deleteDispatch = (MethodType, apiUrl, filterOptions) =>
  apiHelper({
    url: apiUrl,
    method: MethodType,
    onSuccess: result => onDeleteSuccess(result, filterOptions),
    onFailure: err => setErrorThunk(DISPATCH_ERROR, true, err),
    onLoad: status => setLoadingStatus(DISPATCH_IS_LOADING, status),
    accessToken: Cookies.get('accessToken')
  });

export const setOnConfirmationModal = retData => dispatch => {
  dispatch(setOnConfirmationData(DISPATCH_UPDATE_CONFIRMATION, retData));
};

export const onDeleteSuccess = (result, filterOptions) => dispatch => {
  dispatch(setDeleteData(DISPATCH_DELETE_DATA, true, result));
  dispatch(_getAllDispatches(filterOptions.currentPageNumber, filterOptions.pageSize, filterOptions.filterBy, filterOptions.sortBy));
};

export const describeBulkDispatch = (clientid = null) => dispatch => {
  dispatch(_getBulkDispatch(clientid));
};

const _getBulkDispatch = clientid =>
  apiHelper({
    url: clientid ? `${API_URL}/bulkdispatch/${clientid}` : `${API_URL}/bulkdispatch`,
    method: 'GET',
    data: clientid ? { clientid: clientid } : {},
    onLoad: status => setLoadingStatus(DISPATCH_IS_LOADING, status),
    onSuccess: result => setDescribeData(DISPATCH_BULK_GET_DATA, result),
    onFailure: err => setErrorThunk(DISPATCH_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

export const setOnBulkChange = retData => dispatch => {
  dispatch(setOnChangeData(DISPATCH_BULK_ON_CHANGE_DATA, retData));
};

// Get Products by Dept
export const getRequisitionByClient = id => dispatch => {
  dispatch(_getRequisitionByClient(id));
};

const _getRequisitionByClient = id =>
  apiHelper({
    url: `${API_URL}/client/${id}`,
    method: 'GET',
    data: {
      id: id
    },
    onLoad: status => setLoadingStatus(DISPATCH_IS_LOADING, status),
    onSuccess: result => setRequisitionBulkData(DISPATCH_BULK_GET_REQUISTION_CLIENT, result),
    onFailure: err => setErrorThunk(DISPATCH_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

export const setRequisitionBulkData = (RTYPE, result) => ({
  type: RTYPE,
  payload: { resultData: result }
});

export const saveBulkDispatch = saveData => dispatch => {
  let MethodType = 'POST';
  let apiUrl = `${API_URL}/bulkdispatch`;

  dispatch(_saveBulkDispatch(saveData, MethodType, apiUrl));
};

const _saveBulkDispatch = (data, MethodType, apiUrl) =>
  apiHelper({
    url: apiUrl,
    method: MethodType,
    onSuccess: result => setSaveData(DISPATCH_BULK_SAVE_DATA, true, result, {}),
    onFailure: err => setErrorThunk(DISPATCH_ERROR, true, err),
    onLoad: status => setLoadingStatus(DISPATCH_IS_LOADING, status),
    data,
    accessToken: Cookies.get('accessToken')
  });

export const setOnBulkConfirmationModal = retData => dispatch => {
  dispatch(setOnConfirmationData(DISPATCH_BULK_SAVE_CONFIRMATION, retData));
};

export const setOnBulkCleanUp = retData => dispatch => {
  dispatch(setOnCleanUpData(DISPATCH_BULK_CLEAN_UP, retData));
};
