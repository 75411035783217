import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Table from './UserTable';
import Pagination from '../../CustomControls/Pagination';
import { deleteHandlerCall, getAllUsers } from '../../../store/actions/user';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import CustomConfirmation from '../../CustomControls/ConfirmationModal';
import './style.scss';
import { useState } from 'react';

const Users = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  const UserData = user.userList || [];
  const [show, setShow] = useState(false);
  const [selectedDc, setSelectedDc] = useState('');
  const notificationType = user.isError ? 'error' : 'success';

  const showSaveOption = hasPermissionToAction(permissions, 'users', 'create');
  const showResetPasswordOption = hasPermissionToAction(permissions, 'resetpassword', 'create');

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const onPageChangeHandler = currentPage => {};

  const onAddNewHandler = () => {
    history.push('/management/users/user');
  };

  const onEditHandler = id => {
    history.push(`/management/users/user/${id}`);
  };

  const resetPasswordHandler = id => {
    history.push(`/management/users/reset-password/${id}`);
  };

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'User Listing', path: '/management/users', isCurrent: true }
  ];

  const onDeleteHandler = id => {
    setSelectedDc(id);
    setShow(true);
  };
  const onDeleteConfirmation = () => {
    dispatch(deleteHandlerCall(selectedDc));
    setShow(!show);
  };

  const onDeleteCancel = () => {
    setShow(!show);
  };
  return (
    <React.Fragment>
      <CustomConfirmation
        show={show}
        onDeleteClick={onDeleteConfirmation}
        onCloseClick={onDeleteCancel}
        message="Are you sure you want to delete this record?"
        header="Deleting record"
      />
      <div className="page-content">
        {user.showMessage && <Notification notificationType={notificationType} message={user.message} />}
        <Container fluid>
          <Breadcrumbs title="User Listing" paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        {showSaveOption && (
                          <Button type="button" className="btn btn-primary btn-block " onClick={onAddNewHandler}>
                            ADD USER
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="tablerow">
                    <Col xl="12">
                      <div>
                        {user.isLoading && <LoadingSpinner />}
                        <Pagination data={UserData} pageSize={10} onPageChange={onPageChangeHandler} startingPage={1}>
                          <Table
                            deleteHandler={onDeleteHandler}
                            editHandler={onEditHandler}
                            resetPasswordHandler={resetPasswordHandler}
                            showResetPasswordOption={showResetPasswordOption}
                          />
                        </Pagination>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Users;
