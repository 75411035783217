import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, Container, Label, Row } from 'reactstrap';
import { describeDepartment, saveDepartment, setOnChange, setOnCleanUp } from '../../../store/actions/Master/department';
import { AvForm, AvInput, AvGroup } from 'availity-reactstrap-validation';
import { initialDepartmentState } from '../../../store/reducers/initialState';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import CustomInput from '../../CustomControls/CustomInput';
import { _create, _department } from '../../../constants/componentConstants';
import { IS_NEW, STATES } from '../../../constants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import Dropdown from '../../CustomControls/Dropdown';
import CustomAvField from '../../CustomControls/CustomAvField';
import ErrorListing from '../../CustomControls/ErrorListing';

const ManageDepartment = props => {
  let history = useHistory();
  const backUrl = '/master/departments';
  const dispatch = useDispatch();
  const department = useSelector(state => state.department);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  let IsEdit = false;
  let { departmentid } = useParams();
  const notificationType = department.isError ? 'error' : 'success';

  const showSaveOption = hasPermissionToAction(permissions, _department, _create);

  let currentDepartment = department.currentDepartment;
  let localPath = '/master/departments/department';
  let heading = 'Add Department';

  if (departmentid) {
    IsEdit = true;
    heading = 'Edit Department';
    localPath = localPath + `/${departmentid}`;
  }

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Department Listing', path: '/master/departments' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  useEffect(() => {
    if (departmentid) {
      dispatch(describeDepartment(departmentid));
    } else {
      dispatch(describeDepartment(IS_NEW));
    }
  }, [dispatch, departmentid]);

  //clean up
  useEffect(() => {
    return () => {
      setLocalCleanUpData({
        data: { ...department.currentDepartment, ...initialDepartmentState.currentDepartment }
      });
    };
  }, []);

  const onPageChangeHandler = currentPage => {};

  const setLocalCleanUpData = cleanUpData => {
    dispatch(setOnCleanUp(cleanUpData));
  };

  const setLocalChange = currentState => {
    dispatch(setOnChange(currentState));
  };

  const handleInput = e => {
    setLocalChange({
      ...department,
      data: { ...department.currentDepartment, [e.target.name]: e.target.value }
    });
  };

  const dropDownChange = (field, selectedOption, cascadeEffectDropDowns = []) => {
    let changeObj = { [field]: selectedOption.value };

    if (cascadeEffectDropDowns.length > 0) {
      cascadeEffectDropDowns.forEach(element => {
        changeObj = { ...changeObj, [element]: '' };
      });
    }

    setLocalChange({
      ...department,
      data: { ...department.currentDepartment, ...changeObj }
    });
  };

  const CostCenterOptions = () => {
    const options = [
      {
        label: 'Select Cost Center',
        value: ''
      }
    ];

    const filterCostCenter = currentDepartment.costCenters.filter(x => x.filterId === currentDepartment.clientProfileId);

    filterCostCenter.forEach(element => {
      options.push(element);
    });

    return options;
  };

  const handleCheckBoxInput = e => {
    let isChecked;
    if (e.target.value === 'false') {
      isChecked = false;
    } else {
      isChecked = true;
    }
    setLocalChange({
      ...department,
      data: { ...department.currentDepartment, [e.target.name]: !isChecked }
    });
  };

  const handleSubmit = e => {
    if (department.currentDepartment) {
      dispatch(saveDepartment(department.currentDepartment, !IsEdit, departmentid));
    }
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  return (
    <React.Fragment>
      {department.redirect ? <Redirect to={backUrl} /> : ''}
      {department.isLoading && <LoadingSpinner />}
      {department.showMessage && <Notification notificationType={notificationType} message={department.message} />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardTitle>{heading}</CardTitle>
                <CardBody>
                  <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                    {department.errorList && department.errorList.length > 0 && <ErrorListing errorList={department.errorList} />}
                    <Row>
                      <Col lg={12}>
                        <CardSubtitle>Basic department info</CardSubtitle>

                        <CardBody>
                          <Row>
                            <Col md="4">
                              <div className="mb-3">
                                <CustomAvField
                                  name="departmentCode"
                                  label="Department Code"
                                  className="form-control"
                                  placeholder="Department Code"
                                  value={currentDepartment.departmentCode}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="20"
                                  validate={{
                                    required: { value: true, errorMessage: 'Please enter a valid cost center code' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="mb-3">
                                <CustomAvField
                                  name="departmentName"
                                  label="Department Name"
                                  className="form-control"
                                  placeholder="Department Name"
                                  value={currentDepartment.departmentName}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="50"
                                  validate={{
                                    required: { value: true, errorMessage: 'Please enter a valid cost center name' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="mb-3">
                                <Dropdown
                                  placeholder="Select Client"
                                  label="Client"
                                  name="clientProfileId"
                                  options={currentDepartment.clientProfiles}
                                  isMulti={false}
                                  value={currentDepartment.clientProfiles.find(x => x.value === currentDepartment.clientProfileId)}
                                  onChange={val => dropDownChange('clientProfileId', val, ['costCenterId'])}
                                  validate={{
                                    required: { value: true, errorMessage: 'Please select a client' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="mb-3">
                                <Dropdown
                                  placeholder="Select Cost Center"
                                  label="Cost Center"
                                  name="costCenterId"
                                  options={CostCenterOptions()}
                                  isMulti={false}
                                  value={CostCenterOptions().find(x => x.value === currentDepartment.costCenterId)}
                                  onChange={val => dropDownChange('costCenterId', val)}
                                  validate={{
                                    required: { value: true, errorMessage: 'Please select a cost center' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="2">
                              <div className="mb-3">
                                <CustomAvField
                                  name="debtorCode"
                                  label="Debtor Code"
                                  className="form-control"
                                  placeholder="Debtor Code"
                                  value={currentDepartment.debtorCode}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="20"
                                  validate={{
                                    required: { value: false, errorMessage: '' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="2">
                              <div className="mb-3">
                                <CustomInput
                                  name="additionalCharge"
                                  label="Additional Charge"
                                  className="form-control"
                                  placeholder="Additional Charge"
                                  value={currentDepartment.additionalCharge}
                                  onChange={handleInput}
                                  step=".01"
                                  min="0"
                                  iconValue="$"
                                  type="number"
                                  validate={{
                                    required: { value: false, errorMessage: 'Please enter a valid addtional charge' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="2">
                              <div className="mb-3">
                                <CustomAvField
                                  name="noOfDeliveryPerWeek"
                                  label="Deliveries Per Week"
                                  className="form-control"
                                  placeholder="Deliveries Per Week"
                                  value={currentDepartment.noOfDeliveryPerWeek}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="20"
                                  validate={{
                                    required: { value: false, errorMessage: '' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="2" className="m-auto">
                              <AvGroup check>
                                <AvInput
                                  type="checkbox"
                                  name="isActive"
                                  label="Is Active"
                                  onChange={handleCheckBoxInput}
                                  value={currentDepartment.isActive}
                                />
                                <Label check for="checkbox">
                                  {' '}
                                  Is Active
                                </Label>
                              </AvGroup>
                            </Col>
                          </Row>
                        </CardBody>

                        <CardSubtitle>Address Info</CardSubtitle>
                        <CardBody>
                          <Row>
                            <Col md="4">
                              <div className="mb-3">
                                <CustomAvField
                                  name="streetAddress"
                                  label="Street Address"
                                  className="form-control"
                                  placeholder="Street Address"
                                  value={currentDepartment.streetAddress}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="100"
                                  validate={{
                                    required: { value: false, errorMessage: '' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="2">
                              <div className="mb-3">
                                <CustomAvField
                                  name="suburb"
                                  label="Suburb"
                                  className="form-control"
                                  placeholder="Suburb"
                                  value={currentDepartment.suburb}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="50"
                                  validate={{
                                    required: { value: false, errorMessage: '' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="2">
                              <div className="mb-3">
                                <Dropdown
                                  placeholder="Select state"
                                  label="State"
                                  name="state"
                                  options={STATES}
                                  isMulti={false}
                                  value={STATES.find(x => x.value === currentDepartment.state)}
                                  onChange={val => dropDownChange('state', val)}
                                  validate={{
                                    required: { value: false, errorMessage: 'Please select a state' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="2">
                              <div className="mb-3">
                                <CustomAvField
                                  name="postCode"
                                  label="Postcode"
                                  className="form-control"
                                  placeholder="Postcode"
                                  value={currentDepartment.postCode}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="20"
                                  validate={{
                                    required: { value: false, errorMessage: '' }
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>

                        <CardSubtitle>Contact Info</CardSubtitle>

                        <CardBody>
                          <Row>
                            <Col md="4">
                              <div className="mb-3">
                                <CustomAvField
                                  name="primaryContactPerson"
                                  label="Primary Contact Person"
                                  className="form-control"
                                  placeholder="Primary Contact Person"
                                  value={currentDepartment.primaryContactPerson}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="50"
                                  validate={{
                                    required: { value: false, errorMessage: '' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="2">
                              <div className="mb-3">
                                <CustomAvField
                                  name="primaryContactEmail"
                                  label="Primary Contact's Email"
                                  className="form-control"
                                  placeholder="Primary Contact Email"
                                  value={currentDepartment.primaryContactEmail}
                                  onChange={handleInput}
                                  maxLength="50"
                                  type="email"
                                  validate={{
                                    required: { value: false, errorMessage: 'Please enter an email' },
                                    pattern: {
                                      value: '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$',
                                      errorMessage: 'Please enter a valid email address'
                                    }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="2">
                              <div className="mb-3">
                                <CustomAvField
                                  name="primaryContactPhone"
                                  label="Primary Contact's Phone"
                                  className="form-control"
                                  placeholder="Primary Contact Phone"
                                  value={currentDepartment.primaryContactPhone}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="30"
                                  validate={{
                                    required: { value: false, errorMessage: '' }
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      {showSaveOption && (
                        <Button
                          type="submit"
                          color="primary"
                          className="btn "
                          disabled={department.isLoading || (department.showMessage && !department.isError)}
                        >
                          SAVE
                        </Button>
                      )}
                      <Button onClick={cancelHandler} color="secondary" className=" ">
                        CANCEL
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageDepartment;
