import { COSTCENTER_GET_ALL_DATA, COSTCENTER_GET_DATA, COSTCENTER_ERROR, COSTCENTER_IS_LOADING, COSTCENTER_SAVE_DATA, COSTCENTER_ON_CHANGE_DATA, COSTCENTER_CLEAN_UP,COSTCENTER_DELETE_DATA, COSTCENTER_UPDATE_CONFIRMATION } from '../../../constants/index';
import { apiHelper } from '../../../utils/index';
import Cookies from 'js-cookie';
import { setErrorThunk, setLoadingStatus, setGetResults, setDescribeData, setSaveData, setOnChangeData, setOnCleanUpData,setDeleteData,setOnConfirmationData } from '../actionHelper';

const API_URL = '/costcenter'

export const getAllCostCenters = () => dispatch => {
    dispatch(_getAllCostCenters());
};

const _getAllCostCenters = () =>
    apiHelper({
        url: `${API_URL}`,
        method: 'GET',
        onLoad: status => setLoadingStatus(COSTCENTER_IS_LOADING, status),
        onSuccess: result => setGetResults(COSTCENTER_GET_ALL_DATA, result),
        onFailure: err => setErrorThunk(COSTCENTER_ERROR, true, err),
        accessToken: Cookies.get('accessToken'),
    });

export const describeCostCenter = (id) => dispatch => {
    dispatch(_getCostCenter(id));
};

const _getCostCenter = (id) =>
    apiHelper({
        url: `${API_URL}/${id}`,
        method: 'GET',
        data: {
            id: id
        },
        onLoad: status => setLoadingStatus(COSTCENTER_IS_LOADING, status),
        onSuccess: result => setDescribeData(COSTCENTER_GET_DATA, result),
        onFailure: err => setErrorThunk(COSTCENTER_ERROR, true, err),
        accessToken: Cookies.get('accessToken'),
    });

export const saveCostCenter = (saveData, isNew, id = null) => dispatch => {
    let MethodType = 'POST';
    if (!isNew) {
        MethodType = 'PUT'
    }

    let apiUrl = id ? `${API_URL}/${id}` : API_URL;

    dispatch(_saveCostCenter(saveData, MethodType, apiUrl));
}

const _saveCostCenter = (data, MethodType, apiUrl) =>
    apiHelper({
        url: apiUrl,
        method: MethodType,
        onSuccess: result => setSaveData(COSTCENTER_SAVE_DATA, true, result),
        onFailure: err => setErrorThunk(COSTCENTER_ERROR, true, err),
        onLoad: status => setLoadingStatus(COSTCENTER_IS_LOADING, status),
        data,
        accessToken: Cookies.get('accessToken'),
    });


export const setOnChange = retData => dispatch => {
    dispatch(setOnChangeData(COSTCENTER_ON_CHANGE_DATA, retData));
};

export const setOnCleanUp = retData => dispatch => {
    dispatch(setOnCleanUpData(COSTCENTER_CLEAN_UP, retData));
};




// DELETE OPERATION

export const deleteCostCenter = (id) => dispatch => {
    let MethodType = 'DELETE';
    let apiUrl = id ? `${API_URL}/${id}` : API_URL;

    dispatch(_deleteCostCenter(MethodType, apiUrl));
    
}

export const setOnConfirmationModal = retData => dispatch => {
    dispatch(setOnConfirmationData(COSTCENTER_UPDATE_CONFIRMATION, retData));
};


export const onDeleteSuccess = (result) => dispatch => {
    dispatch(setDeleteData(COSTCENTER_DELETE_DATA, true, result));
    dispatch(_getAllCostCenters());
};



const _deleteCostCenter = (MethodType, apiUrl) =>
    apiHelper({
        url: apiUrl,
        method: MethodType,
        onSuccess: result => onDeleteSuccess(result),
        onFailure: err => setErrorThunk(COSTCENTER_ERROR, true, err),
        onLoad: status => setLoadingStatus(COSTCENTER_IS_LOADING, status),
        accessToken: Cookies.get('accessToken'),
    });