import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import SideBarMenu from './SideBarMenu';
import logoLightSvg from "../../assets/images/naztec.svg";

const Sidebar = props => {

    const imageResult = window.localStorage.getItem('lmsCompanyLogo');

    return (
        <React.Fragment>
            <div className="side-menu">
                <div className="navbar-company-box">
                    <Link to="/" className="logo logo-dark">
                        <span className="logo-lg">
                            {imageResult ?  <img src={imageResult} alt="" height="45" />: <img src={logoLightSvg} alt="" height="45" /> }
                        </span>
                    </Link>
                </div>
                <div data-simplebar className="h-100">
                    <SideBarMenu />
                </div>
                <div className="sidebar-background"></div>
            </div>
        </React.Fragment>
    )
}

Sidebar.propTypes = {
    type: PropTypes.string,
}

export default Sidebar
