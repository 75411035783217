import {
  REQUISITION_GET_ALL_DATA,
  REQUISITION_GET_DATA,
  REQUISITION_ERROR,
  REQUISITION_IS_LOADING,
  REQUISITION_SAVE_DATA,
  REQUISITION_ON_CHANGE_DATA,
  REQUISITION_CLEAN_UP,
  REQUISITION_ON_SEARCH_CHANGE_DATA,
  REQUISITION_GET_PRODUCTS_DEPT,
  ORDER_SET_INTERNAL,
  REQUISITION_GET_PRODUCTS_AND_DEPT_CLIENT,
  REQUISITION_GET_ONLINE_REPORT,
  REQUISITION_DELETE_DATA,
  REQUISITION_UPDATE_CONFIRMATION
} from '../../../constants/index';
import { apiHelper } from '../../../utils/index';
import Cookies from 'js-cookie';
import {
  setErrorThunk,
  setLoadingStatus,
  setGetResults,
  setDescribeData,
  setSaveData,
  setOnChangeData,
  setOnCleanUpData,
  setOnSearchChangeData,
  setDeleteData,
  setOnConfirmationData
} from '../actionHelper';

const API_URL = '/requisition';

export const getAllRequisitions = (currentPageNumber, pageSize, filterBy, sortBy, startDate, endDate, sortConfig) => dispatch => {
  const newSortBy = {
    name: sortConfig?.key ?? 'requisitionref',
    order: sortConfig?.direction ?? 'desc'
  };
  console.log('--sortBY--', sortBy);
  console.log('--sortConfig--', sortConfig);
  console.log('--n1ewSortBy--', newSortBy);
  console.log('--apifromdate--', new Date(startDate.toDateString()));
  dispatch(
    _getAllRequisitions(
      currentPageNumber,
      pageSize,
      filterBy,
      newSortBy,
      new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toJSON(),
      new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toJSON()
    )
  );
};

const _getAllRequisitions = (currentPageNumber, pageSize, filterBy, sortBy, startDate, endDate) =>
  apiHelper({
    url: `${API_URL}/filter`,
    method: 'POST',
    data: {
      pageNumber: currentPageNumber,
      pageSize: pageSize,
      filterBy: filterBy,
      sortBy: sortBy,
      startDate: startDate,
      endDate: endDate
    },
    onLoad: status => setLoadingStatus(REQUISITION_IS_LOADING, status),
    onSuccess: result => setGetResults(REQUISITION_GET_ALL_DATA, result),
    onFailure: err => setErrorThunk(REQUISITION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

export const getAllInternalOrder = (currentPageNumber, pageSize, filterBy, sortBy, searchText) => dispatch => {
  dispatch(_getAllInternalOrder(currentPageNumber, pageSize, filterBy, sortBy, searchText));
};

const _getAllInternalOrder = (currentPageNumber, pageSize, filterBy, sortBy, searchText) => {
  const dateObject1 = new Date(filterBy[1].value);
  const dateObject2 = new Date(filterBy[0].value);
  return apiHelper({
    url: `/internalorder/all`,
    method: 'POST',
    data: {
      endDate: dateObject1,
      // filterBy: filterBy,
      pageNumber: currentPageNumber,
      pageSize: pageSize,
      startDate: dateObject2,
      searchQuery: searchText
    },
    onLoad: status => setLoadingStatus(REQUISITION_IS_LOADING, status),
    onSuccess: result => setGetResults(ORDER_SET_INTERNAL, result),
    onFailure: err => setErrorThunk(REQUISITION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};
export const getOneInternalOrder = id => dispatch => {
  dispatch(_getOneInternalOrder(id));
};

const _getOneInternalOrder = id =>
  apiHelper({
    url: `/internalorder/${id}`,
    method: 'GET',
    onLoad: status => setLoadingStatus(REQUISITION_IS_LOADING, status),
    onSuccess: result => setGetResults(ORDER_SET_INTERNAL, result),
    onFailure: err => setErrorThunk(REQUISITION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
export const setOneInternalOrder = payload => dispatch => {
  dispatch(_setOneInternalOrder(payload));
};

const _setOneInternalOrder = payload =>
  apiHelper({
    url: `/internalorder/${payload.OrderId}`,
    method: 'PUT',
    data: payload,
    onLoad: status => setLoadingStatus(REQUISITION_IS_LOADING, status),
    // onSuccess: result => setGetResults(ORDER_SET_INTERNAL, result),
    // onFailure: err => setErrorThunk(REQUISITION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
export const deleteAllInternalOrder = id => dispatch => {
  dispatch(_deleteAllInternalOrder(id));
};

const _deleteAllInternalOrder = id =>
  apiHelper({
    url: `/internalorder/${id}`,
    method: 'DELETE',
    onLoad: status => setLoadingStatus(REQUISITION_IS_LOADING, status),
    onSuccess: result => setGetResults(ORDER_SET_INTERNAL, result),
    onFailure: err => setErrorThunk(REQUISITION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

export const describeRequisition = (id, repeatid = null) => dispatch => {
  dispatch(_getRequisition(id, repeatid));
};

const _getRequisition = (id, repeatid) =>
  apiHelper({
    url: `${API_URL}/${id}`,
    method: 'GET',
    data: {
      id: id,
      repeatid: repeatid
    },
    onLoad: status => setLoadingStatus(REQUISITION_IS_LOADING, status),
    onSuccess: result => setDescribeData(REQUISITION_GET_DATA, result),
    onFailure: err => setErrorThunk(REQUISITION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

export const saveRequisition = (saveData, isNew, id = null, repeatReq = true) => dispatch => {
  let MethodType = 'POST';
  if (!isNew) {
    MethodType = 'PUT';
  }

  let apiUrl = id ? `${API_URL}/${id}` : API_URL;

  dispatch(_saveRequisition(saveData, MethodType, apiUrl, repeatReq));
};

const _saveRequisition = (data, MethodType, apiUrl, repeatReq) =>
  apiHelper({
    url: apiUrl,
    method: MethodType,
    onSuccess: result => setSaveData(REQUISITION_SAVE_DATA, true, result, { repeatReq: repeatReq }),
    onFailure: err => setErrorThunk(REQUISITION_ERROR, true, err),
    onLoad: status => setLoadingStatus(REQUISITION_IS_LOADING, status),
    data,
    accessToken: Cookies.get('accessToken')
  });

export const setOnChange = retData => dispatch => {
  dispatch(setOnChangeData(REQUISITION_ON_CHANGE_DATA, retData));
};

export const setOnCleanUp = retData => dispatch => {
  dispatch(setOnCleanUpData(REQUISITION_CLEAN_UP, retData));
};

export const setProductDeptData = (RTYPE, result) => ({
  type: RTYPE,
  payload: { resultData: result }
});

// Get Products and Dept by Client
export const getProductsAndDeptByClient = id => dispatch => {
  dispatch(_getProductsAndDeptByClient(id));
};

const _getProductsAndDeptByClient = id =>
  apiHelper({
    url: `${API_URL}/client/${id}`,
    method: 'GET',
    data: {
      id: id
    },
    onLoad: status => setLoadingStatus(REQUISITION_IS_LOADING, status),
    onSuccess: result => setProductDeptData(REQUISITION_GET_PRODUCTS_AND_DEPT_CLIENT, result),
    onFailure: err => setErrorThunk(REQUISITION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
// Get All Data
export const getAllProductWithoutID = () => dispatch => {
  dispatch(_getAllProductsWithoutID());
};

const _getAllProductsWithoutID = () =>
  apiHelper({
    url: `/product/all`,
    method: 'GET',
    onLoad: status => setLoadingStatus(REQUISITION_IS_LOADING, status),
    onSuccess: result => setProductDeptData(REQUISITION_GET_PRODUCTS_AND_DEPT_CLIENT, result),
    onFailure: err => setErrorThunk(REQUISITION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

// Get Products by Dept
export const getProductsByDept = id => dispatch => {
  dispatch(_getProductsByDept(id));
};

const _getProductsByDept = id =>
  apiHelper({
    url: `${API_URL}/department/${id}`,
    method: 'GET',
    data: {
      id: id
    },
    onLoad: status => setLoadingStatus(REQUISITION_IS_LOADING, status),
    onSuccess: result => setProductDeptData(REQUISITION_GET_PRODUCTS_DEPT, result),
    onFailure: err => setErrorThunk(REQUISITION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

export const setOnSearchChange = retData => dispatch => {
  dispatch(setOnSearchChangeData(REQUISITION_ON_SEARCH_CHANGE_DATA, retData));
};

// Online Report

export const getReportRequisition = id => dispatch => {
  dispatch(_getReportRequisition(id));
};

const _getReportRequisition = id =>
  apiHelper({
    url: `${API_URL}/report/${id}`,
    method: 'GET',
    data: {
      id: id
    },
    onLoad: status => setLoadingStatus(REQUISITION_IS_LOADING, status),
    onSuccess: result => setDescribeData(REQUISITION_GET_ONLINE_REPORT, result),
    onFailure: err => setErrorThunk(REQUISITION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

// DELETE OPERATION
export const deleteRequisition = (id, filterOptions) => dispatch => {
  let MethodType = 'DELETE';
  let apiUrl = id ? `${API_URL}/${id}` : API_URL;

  dispatch(_deleteRequisition(MethodType, apiUrl, filterOptions));
};

const _deleteRequisition = (MethodType, apiUrl, filterOptions) =>
  apiHelper({
    url: apiUrl,
    method: MethodType,
    onSuccess: result => onDeleteSuccess(result, filterOptions),
    onFailure: err => setErrorThunk(REQUISITION_ERROR, true, err),
    onLoad: status => setLoadingStatus(REQUISITION_IS_LOADING, status),
    accessToken: Cookies.get('accessToken')
  });

export const setOnConfirmationModal = retData => dispatch => {
  dispatch(setOnConfirmationData(REQUISITION_UPDATE_CONFIRMATION, retData));
};

export const onDeleteSuccess = (result, filterOptions) => dispatch => {
  dispatch(setDeleteData(REQUISITION_DELETE_DATA, true, result));
  dispatch(_getAllRequisitions(filterOptions.currentPageNumber, filterOptions.pageSize, filterOptions.filterBy, filterOptions.sortBy));
};
