import React from "react"
import { Table } from "reactstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import "./style.scss";

const CostCenterTable = ({ paginatedData, editHandler, deleteHandler, showDelete }) => {


    return (
        <React.Fragment>
            <div>
                <Table className="table" responsive>
                    <thead>
                        <tr>
                            <th>Cost Center Code</th>
                            <th>Cost Center Name</th>
                            <th>Client</th>
                            <th>Status</th>
                            <th>Action</th>
                            {showDelete && <th className="col-sm-1">Delete</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map(n => {
                            return (
                                <tr key={n.costCenterId}>
                                    <td>{n.costCenterCode || ''}</td>
                                    <td>{n.costCenterName || ''}</td>
                                    <td>{n.clientProfileName || ''}</td>
                                    <td>{n.isActive ? 'Active' : 'Inactive'}</td>
                                    {<td><button className="btn tableIcons" onClick={() => editHandler(n.costCenterId)} > <FontAwesomeIcon icon={faPencilAlt} /></button></td>}
                                    {showDelete && <td><button className="btn tableIcons" onClick={() => deleteHandler(n.costCenterId)} > <FontAwesomeIcon icon={faTrashAlt} /></button></td>}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

            </div>
        </React.Fragment>
    )
}


export default CostCenterTable;