import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import s from './pagination.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';

const Pagination = props => {
  let { startingPage, pageSize, data = [], onPageChange, children, apiPaging = false, apiDataLength = null } = props;

  const [currentPage, setCurrentPage] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const dataLength = apiPaging && apiDataLength ? apiDataLength : data.length;

  useEffect(() => {
    updateCurrentPage(dataLength, pageSize, startingPage);
  }, [dataLength, pageSize, startingPage]);

  const updateCurrentPage = (dataLength, pageSize, startingPage) => {
    let pageCount = parseInt(dataLength / pageSize);
    if (dataLength % pageSize > 0) {
      pageCount++;
    }
    setCurrentPage(startingPage);
    setPageCount(pageCount);
  };

  const createPageLink = (targetPage, disabled = false, text = null) => {
    if (!text && (targetPage <= 0 || targetPage > pageCount)) return null;
    return (
      <button
        type="button"
        disabled={disabled}
        className={classNames({ [s.active]: targetPage === currentPage, [s.numbers]: !text })}
        onClick={() => {
          setCurrentPage(targetPage);
          if (onPageChange) {
            onPageChange(targetPage);
          }
        }}
      >
        {text || targetPage}
      </button>
    );
  };

  const paginationNavigation = () => {
    let showingInfo = '';
    let showStartDataCount = 0;
    let showEndDataCount = 0;

    showStartDataCount = pageSize * (currentPage - 1) + 1;
    showEndDataCount = dataLength > currentPage * pageSize ? currentPage * pageSize : dataLength;

    if (dataLength > 0 && showStartDataCount > 0 && showEndDataCount > 0) {
      showingInfo = `Results ${showStartDataCount}-${showEndDataCount} of ${dataLength}`;
    }
    return (
      <React.Fragment>
        <div className={s.showing}>
          <span className={s.info}> {showingInfo}</span>
        </div>
        <div className={s.controls}>
          {createPageLink(currentPage - 1, currentPage === 1, <FontAwesomeIcon icon={faChevronCircleLeft} className={s.arrowClass} />)}
          <div className={s.pageLinks}>
            {createPageLink(currentPage - 2)}
            {createPageLink(currentPage - 1)}
            {createPageLink(currentPage)}
            {createPageLink(currentPage + 1)}
            {createPageLink(currentPage + 2)}
          </div>
          {createPageLink(
            currentPage + 1,
            currentPage === pageCount,
            <FontAwesomeIcon icon={faChevronCircleRight} className={s.arrowClass} />
          )}
        </div>
      </React.Fragment>
    );
  };

  const paginatedData = () => {
    if (apiPaging) {
      return data;
    } else {
      const upperLimit = currentPage * pageSize;
      return data.slice(upperLimit - pageSize, upperLimit);
    }
  };

  return (
    <div className={s.pagination}>
      {React.cloneElement(children, { currentPage: currentPage, paginatedData: paginatedData() })}
      {dataLength > 0 && paginationNavigation()}
    </div>
  );
};

Pagination.propTypes = {
  data: PropTypes.array.isRequired,
  pageSize: PropTypes.number.isRequired,
  startingPage: PropTypes.number.isRequired
};

Pagination.defaultProps = {
  pageSize: 20,
  startingPage: 1
};

export default Pagination;
