import { LOGIN_ERROR, USER_DETAILS } from '../../constants/index';
import { initialAuthState } from './initialState';

const userReducer = (state = initialAuthState, action) => {
  const { payload, type } = action;
  switch (type) {
    case LOGIN_ERROR: {
      const { value, errorMessage } = payload;
      return {
        ...state,
        loginError: value,
        loginErrorMessage: errorMessage
      };
    }
    case USER_DETAILS: {
      const { user } = payload;
      return {
        ...state,
        ...user,
        loginError: false
      };
    }

    default:
      return state;
  }
};

export default userReducer;
