import Cookies from 'js-cookie';
import { apiHelper } from '../../utils';
import { setErrorThunk, setGetResults, setLoadingStatus, setOnConfirmationData, setOnSearchChangeData, setSaveData } from './actionHelper';
import { EMAIL_SET_DATA, EMAIL_ERROR, EMAIL_IS_LOADING } from '../../constants';

// const API_URL = '/product/all';

export const getEmailConfigration = () => dispatch => {
  dispatch(_getEmailConfigration());
};

const _getEmailConfigration = () =>
  apiHelper({
    url: `/emailconfiguration`,
    method: 'GET',
    onLoad: status => setLoadingStatus(EMAIL_IS_LOADING, status),
    onSuccess: result => setGetResults(EMAIL_SET_DATA, result),
    onFailure: err => setErrorThunk(EMAIL_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
export const submitEmailConfigration = data => dispatch => {
  dispatch(_submitEmailConfigration(data));
};

const _submitEmailConfigration = data =>
  apiHelper({
    url: `/emailconfiguration`,
    method: 'PATCH',
    data: data,
    skipRefresh: true,
    onLoad: status => setLoadingStatus(EMAIL_IS_LOADING, status),
    onSuccess: result => setSaveData(EMAIL_SET_DATA, true, result),
    onFailure: err => setErrorThunk(EMAIL_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
// export const setOnSearchChange = retData => dispatch => {
//     dispatch(setOnSearchChangeData(ORDER_ON_SEARCH_CHANGE_DATA, retData));
// };
// export const setOnConfirmationModal = retData => dispatch => {
//     dispatch(setOnConfirmationData(ORDER_UPDATE_CONFIRMATION, retData));
// };
