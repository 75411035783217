import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { describeCreditNote, saveCreditNote, setOnChange, setOnCleanUp } from '../../../store/actions/Operation/creditNote';
import { AvForm } from 'availity-reactstrap-validation';
import { initialCreditNoteState } from '../../../store/reducers/initialState';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _creditnote, _allowbackdate } from '../../../constants/componentConstants';
import { IS_NEW } from '../../../constants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import Dropdown from '../../CustomControls/Dropdown';
import CustomAvField from '../../CustomControls/CustomAvField';
import DatePicker from '../../CustomControls/CustomDatePicker';
import CustomInput from '../../CustomControls/CustomInput';

const ManageCreditNote = props => {
  let history = useHistory();
  const backUrl = '/operation/credit-notes';
  const dispatch = useDispatch();
  const creditNote = useSelector(state => state.creditNote);
  const ui = useSelector(state => state.ui);
  const [triggerValidation, setTriggerValidation] = useState(false);
  const permissions = ui.layout.permissions;
  let IsEdit = false;
  let { creditnoteid } = useParams();
  const notificationType = creditNote.isError ? 'error' : 'success';

  const showSaveOption = hasPermissionToAction(permissions, _creditnote, _create);
  let allowBackdate = hasPermissionToAction(permissions, _allowbackdate, _create);

  let currentCreditNote = creditNote.currentCreditNote;
  let localPath = '/operation/credit-notes/credit-note';
  let heading = 'Add Credit Note';

  if (creditnoteid) {
    IsEdit = true;
    heading = 'Credit Note';
    localPath = localPath + `/${creditnoteid}`;
  }

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Credit Note Listing', path: '/operation/credit-notes' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  useEffect(() => {
    if (creditnoteid) {
      dispatch(describeCreditNote(creditnoteid));
    } else {
      dispatch(describeCreditNote(IS_NEW));
    }
  }, [dispatch, creditnoteid]);

  //clean up
  useEffect(() => {
    return () => {
      setLocalCleanUpData({
        data: { ...creditNote.currentCreditNote, ...initialCreditNoteState.currentCreditNote }
      });
    };
  }, []);

  const setLocalCleanUpData = cleanUpData => {
    dispatch(setOnCleanUp(cleanUpData));
  };

  const setLocalChange = currentState => {
    dispatch(setOnChange(currentState));
  };

  const dropDownChange = (field, selectedOption, cascadeEffectDropDowns = []) => {
    let changeObj = { [field]: selectedOption.value };

    if (cascadeEffectDropDowns.length > 0) {
      cascadeEffectDropDowns.forEach(element => {
        changeObj = { ...changeObj, [element]: '' };
      });
    }

    if (field === 'clientProfileId') {
      const filterData = currentCreditNote.departmentSelectList.filter(x => x.filterId === selectedOption.value);
      changeObj.departmentId = filterData.length > 0 ? filterData[0].value : '';
    }

    setLocalChange({
      ...creditNote,
      data: { ...creditNote.currentCreditNote, ...changeObj }
    });
  };

  const handleInput = e => {
    setLocalChange({
      ...creditNote,
      data: { ...creditNote.currentCreditNote, [e.target.name]: e.target.value }
    });
  };

  const handleDateInput = (field, dateVal) => {
    const setDate = dateVal ? new Date(dateVal) : null;
    setLocalChange({
      ...creditNote,
      data: { ...creditNote.currentCreditNote, [field]: setDate }
    });
  };

  const handleSubmit = e => {
    if (!isValid()) {
      setTriggerValidation(true);
      return;
    }

    if (creditNote.currentCreditNote) {
      dispatch(saveCreditNote(creditNote.currentCreditNote, !IsEdit, creditnoteid));
    }
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  const DepartmentOptions = () => {
    const options = [
      {
        label: 'Select Department',
        value: ''
      }
    ];

    const filterData = currentCreditNote.departmentSelectList.filter(x => x.filterId === currentCreditNote.clientProfileId);

    filterData.forEach(element => {
      options.push(element);
    });

    return options;
  };

  const isValid = () => {
    let status = true;
    if (!creditNote.currentCreditNote.clientProfileId) {
      status = false;
    }

    if (!creditNote.currentCreditNote.departmentId) {
      status = false;
    }

    if (!creditNote.currentCreditNote.creditNoteDate || creditNote.currentCreditNote.creditNoteDate === '') {
      status = false;
    }

    return status;
  };

  const handleInvalidSubmit = e => {
    setTriggerValidation(true);
  };

  const contentHeading = IsEdit ? `${heading} - ${currentCreditNote.creditNoteNo}` : heading;
  const colSpanSplit = !currentCreditNote.refAutoGenerated ? 3 : 4;

  return (
    <React.Fragment>
      {creditNote.redirect ? <Redirect to={backUrl} /> : ''}
      {creditNote.isLoading && <LoadingSpinner />}
      {creditNote.showMessage && <Notification notificationType={notificationType} message={creditNote.message} />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardTitle>{contentHeading}</CardTitle>
                <CardBody>
                  <AvForm className="form-horizontal" onValidSubmit={handleSubmit} onInvalidSubmit={handleInvalidSubmit}>
                    <Row>
                      <Col lg={12}>
                        <Row>
                          {!currentCreditNote.refAutoGenerated && (
                            <Col sm={colSpanSplit}>
                              <div className="mb-3">
                                <CustomAvField
                                  name="creditNoteNo"
                                  label="Credit Note No"
                                  className="form-control"
                                  placeholder="Credit Note No"
                                  value={currentCreditNote.creditNoteNo}
                                  disabled={IsEdit}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="10"
                                  validate={{
                                    required: { value: true, errorMessage: 'Please enter a credit note no' }
                                  }}
                                />
                              </div>
                            </Col>
                          )}
                          <Col md="4">
                            <div className="mb-3">
                              <Dropdown
                                placeholder="Select Client"
                                label="Client"
                                name="clientProfileId"
                                options={currentCreditNote.clientProfileSelectList}
                                isMulti={false}
                                value={currentCreditNote.clientProfileSelectList.find(x => x.value === currentCreditNote.clientProfileId)}
                                onChange={val => dropDownChange('clientProfileId', val, ['departmentId'])}
                                isDisabled={IsEdit}
                                triggerValidation={triggerValidation}
                                validate={{
                                  required: { value: true, errorMessage: 'Please select a client' }
                                }}
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Dropdown
                                placeholder="Select Department"
                                label="Department"
                                name="departmentId"
                                options={DepartmentOptions()}
                                isMulti={false}
                                value={DepartmentOptions().find(x => x.value === currentCreditNote.departmentId)}
                                isDisabled={IsEdit}
                                onChange={val => dropDownChange('departmentId', val)}
                                triggerValidation={triggerValidation}
                                validate={{
                                  required: { value: true, errorMessage: 'Please select a department' }
                                }}
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-4">
                              <DatePicker
                                label="Credit Note Date"
                                selected={currentCreditNote.creditNoteDate ? new Date(currentCreditNote.creditNoteDate) : null}
                                onChange={date => handleDateInput('creditNoteDate', date)}
                                triggerValidation={triggerValidation}
                                dateFormat="dd-MMM-yyyy"
                                minDate={allowBackdate ? new Date('January 1, 1990 00:00:00') : new Date()}
                                validate={{
                                  required: { value: true, errorMessage: 'Please select a valid credit note date' }
                                }}
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <CustomInput
                                name="amount"
                                label="Amount"
                                className="form-control"
                                placeholder="Amount"
                                value={currentCreditNote.amount}
                                onChange={handleInput}
                                step=".01"
                                min="0"
                                iconValue="$"
                                type="number"
                                validate={{
                                  required: { value: true, errorMessage: 'Please enter a valid amount' }
                                }}
                              />
                            </div>
                          </Col>
                          <Col md="8">
                            <div className="mb-3">
                              <CustomAvField
                                name="remarks"
                                label="Remarks"
                                className="form-control"
                                placeholder="Remarks"
                                value={currentCreditNote.remarks}
                                onChange={handleInput}
                                type="text"
                                maxLength="200"
                                validate={{
                                  required: { value: false, errorMessage: '' }
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      {showSaveOption && !IsEdit && (
                        <Button type="submit" color="primary" className="btn " disabled={creditNote.isLoading}>
                          SAVE
                        </Button>
                      )}
                      <Button onClick={cancelHandler} color="secondary" className=" ">
                        {IsEdit ? 'BACK' : 'CANCEL'}
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageCreditNote;
