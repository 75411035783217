import React from 'react';
import './style.scss';

const LoadingSpinner = ({ centeredLoading = true }) => {
    const loadClass = centeredLoading ? "loadingContainer" : '';

    return (
        <div className={loadClass}>
            <div className="spinnerContainer">
                <div id="spinner1" />
            </div>
        </div>
    );
};

export default LoadingSpinner;
