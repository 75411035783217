import Cookies from 'js-cookie';
import { apiHelper } from '../../utils';
import {
  setErrorThunk,
  setGetResults,
  setOnSearchChangeData,
  setOnConfirmationData,
  setLoadingStatus,
  generateCSVCustomer,
  generatePDFCustomer,
  setOnChangeData
} from './actionHelper';
import {
  ORDER_DETAIL_IS_LOADING,
  ORDER_DETAIL_ERROR,
  ORDER_DETAIL_SET_DATA,
  ORDER_DETAIL_ON_SEARCH_CHANGE_DATA,
  ORDER_DETAIL_UPDATE_CONFIRMATION,
  ORDER_DETAIL_DETAILS,
  ORDER_DETAIL_SET_DATA_CSV,
  ORDER_DETAIL_SET_DATA_PDF,
  ORDER_DETAIL_REJECT_DATA,
  ORDER_DETAIL_DEPARTMENT_SET_DATA,
  ORDER_DETAIL_ON_CHANGE_DATA,
  PRODUCT_ORDER_DETAIL_SET_DATA,
  PRODUCT_ORDER_DETAIL_ALL_SET_DATA
} from '../../constants';

// imans code start from here
export const rejectOrdersDetails = (id, data) => dispatch => {
  dispatch(_rejectOrdersDetails(id, data));
};
const _rejectOrdersDetails = (id, data) => {
  return apiHelper({
    url: `/customer/order/reject/`,
    method: 'PAtCH',
    data: {
      reason: data,
      orderId: id
    },
    onLoad: status => setLoadingStatus(ORDER_DETAIL_IS_LOADING, status),
    onSuccess: result => getAllOrdersDetails(),
    onFailure: err => setErrorThunk(ORDER_DETAIL_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};
// imans code end here

// const API_URL = '/product/all';

export const getAllOrdersDetails = (currentPageNumber, pageSize, filterBy, sortBy, data, department,statusId) => dispatch => {
  dispatch(_getAllOrders(currentPageNumber, pageSize, filterBy, sortBy, data, department,statusId));
};

const _getAllOrders = (currentPageNumber, pageSize, filterBy, sortBy, data, department,statusId) => {
  const requisitionRefFilter = filterBy?.find(item => item.name === 'requisitionref');
  const startDateFilter = filterBy?.find(item => item.name === 'fromdate');
  const endDateFilter = filterBy?.find(item => item.name === 'todate');

  // const filterByRequisitionRef = requisitionRefFilter?.value ?? '';
  // const startDate = startDateFilter ? new Date(startDateFilter?.value) : null;
  // let SDate;
  // const endDate = endDateFilter ? new Date(endDateFilter?.value) : null;
  // if (startDate !== null) {
  //   startDate.setDate(startDate.getDate());
  //   const dateString  = startDate.toLocaleDateString()
  //   const dateParts = dateString.split('/');
  //    SDate = new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
  // }
  // if (endDate !== null) {
  //   endDate.setDate(endDate.getDate());
  // }
  const filterByRequisitionRef = requisitionRefFilter?.value ?? '';
  const startDate = new Date(startDateFilter?.value) ?? null;
  const endDate = new Date(endDateFilter?.value) ?? null;
  return apiHelper({
    url: `/customer/all-orders`,
    method: 'POST',
    data: {
      pageNumber: currentPageNumber,
      pageSize: pageSize,
      filterBy: filterBy,
      sortBy: sortBy,
      searchQuery: filterByRequisitionRef,
      startDate: startDate,
      // startDate: startDate !== null  ? SDate.toISOString() : null,
      endDate: endDate,
      // endDate: endDate !== null ? endDate.toLocaleDateString() : null,
      customerId: data ?? '',
      departmentId: department,
      status: statusId
    },
    onLoad: status => setLoadingStatus(ORDER_DETAIL_IS_LOADING, status),
    onSuccess: result => setGetResults(ORDER_DETAIL_SET_DATA, result),
    onFailure: err => setErrorThunk(ORDER_DETAIL_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};
export const getAllProductDetails = (data) => dispatch => {
  dispatch(_getAllProductDetails(data));
};

const _getAllProductDetails = (data) => {
  return apiHelper({
    url: `/operationreport/productreportall`,
    method: 'POST',
    data: data,
    onLoad: status => setLoadingStatus(ORDER_DETAIL_IS_LOADING, status),
    onSuccess: result => setGetResults(PRODUCT_ORDER_DETAIL_SET_DATA, result),
    // onFailure: err => setErrorThunk(ORDER_DETAIL_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};
export const getAllProductsOrdered = (data) => dispatch => {
  dispatch(_getAllProductsOrdered(data));
};

const _getAllProductsOrdered = (data) => {
  return apiHelper({
    url: `/operationreport/productreportdepartmentwise`,
    method: 'POST',
    data: data,
    onLoad: status => setLoadingStatus(ORDER_DETAIL_IS_LOADING, status),
    onSuccess: result => setGetResults(PRODUCT_ORDER_DETAIL_ALL_SET_DATA, result),
    // onFailure: err => setErrorThunk(ORDER_DETAIL_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};
export const getAllOrdersDetailsCSV = (currentPageNumber, pageSize, filterBy, sortBy, data) => dispatch => {
  dispatch(_getAllOrdersDetailsCSV(currentPageNumber, pageSize, filterBy, sortBy, data));
};

const _getAllOrdersDetailsCSV = (currentPageNumber, pageSize, filterBy, sortBy, data) => {
  const requisitionRefFilter = filterBy?.find(item => item.name === 'requisitionref');
  const startDateFilter = filterBy?.find(item => item.name === 'fromdate');
  const endDateFilter = filterBy?.find(item => item.name === 'todate');

  const filterByRequisitionRef = requisitionRefFilter?.value ?? '';
  const startDate = startDateFilter ? new Date(startDateFilter?.value) : null;

  const endDate = endDateFilter ? new Date(endDateFilter?.value) : null;
  if (startDate !== null) {
    startDate.setDate(startDate.getDate());
  }
  if (endDate !== null) {
    endDate.setDate(endDate.getDate());
  }

  return apiHelper({
    url: `/customer/csvreport`,
    method: 'POST',
    data: {
      searchQuery: filterByRequisitionRef,
      startDate: startDate,
      endDate: endDate,
      customerId: data ?? null
    },
    onLoad: status => setLoadingStatus(ORDER_DETAIL_IS_LOADING, status),
    onSuccess: result => setGetResults(ORDER_DETAIL_SET_DATA_CSV, result, true),
    onFailure: err => setErrorThunk(ORDER_DETAIL_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};
export const getAllOrdersDetailsPDF = (currentPageNumber, pageSize, filterBy, sortBy, data) => dispatch => {
  dispatch(_getAllOrdersDetailsPDF(currentPageNumber, pageSize, filterBy, sortBy, data));
};

const _getAllOrdersDetailsPDF = (currentPageNumber, pageSize, filterBy, sortBy, data) => {
  const requisitionRefFilter = filterBy?.find(item => item.name === 'requisitionref');
  const startDateFilter = filterBy?.find(item => item.name === 'fromdate');
  const endDateFilter = filterBy?.find(item => item.name === 'todate');

  const filterByRequisitionRef = requisitionRefFilter?.value ?? '';
  const startDate = startDateFilter ? new Date(startDateFilter?.value) : null;

  const endDate = endDateFilter ? new Date(endDateFilter?.value) : null;
  if (startDate !== null) {
    startDate.setDate(startDate.getDate());
  }
  if (endDate !== null) {
    endDate.setDate(endDate.getDate());
  }

  return apiHelper({
    url: `/customer/pdfreport`,
    method: 'POST',

    data: {
      searchQuery: filterByRequisitionRef,
      startDate: startDate,
      endDate: endDate,
      customerId: data ?? null
    },
    onLoad: status => setLoadingStatus(ORDER_DETAIL_IS_LOADING, status),
    onSuccess: result => setGetResults(ORDER_DETAIL_SET_DATA_PDF, result, true),
    onFailure: err => setErrorThunk(ORDER_DETAIL_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};

export const setOnSearchChange = retData => dispatch => {
  dispatch(setOnSearchChangeData(ORDER_DETAIL_ON_SEARCH_CHANGE_DATA, retData));
};
export const setOnChange = retData => dispatch => {
  dispatch(setOnChangeData(ORDER_DETAIL_ON_CHANGE_DATA, retData));
};
export const setOnConfirmationModal = retData => dispatch => {
  dispatch(setOnConfirmationData(ORDER_DETAIL_UPDATE_CONFIRMATION, retData));
};
export const setAllOrderDetails = data => dispatch => {
  dispatch(_setAllOrderDetails(data));
};

const _setAllOrderDetails = data =>
  apiHelper({
    url: `/customer/order-details?orderId=${data}`,
    method: 'GET',
    data: data,
    onLoad: status => setLoadingStatus(ORDER_DETAIL_IS_LOADING, status),
    onSuccess: result => setGetResults(ORDER_DETAIL_DETAILS, result),
    onFailure: err => setErrorThunk(ORDER_DETAIL_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
export const setApproveOrder = (id, status) => dispatch => {
  dispatch(_setApproveOrder(id, status));
};

const _setApproveOrder = (id, status) => {
  return apiHelper({
    url: `/customer/order/updatestatus?orderId=${id}&status=${status}`,
    method: 'PATCH',
    onSuccess: result => setGetResults(ORDER_DETAIL_DETAILS, result),
    onFailure: err => setErrorThunk(ORDER_DETAIL_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};
export const setOrderDelete = id => dispatch => {
  dispatch(_setOrderDelete(id));
};

const _setOrderDelete = id => {
  return apiHelper({
    url: `/customer/order/delete/${id}`,
    method: 'DELETE',
    onSuccess: result => setGetResults(ORDER_DETAIL_DETAILS, result),
    onFailure: err => setErrorThunk(ORDER_DETAIL_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};
export const getAllCustomerDeparmentsWithId = (id) => dispatch => {
  
  dispatch(_getAllCustomerDeparmentsWithId(id));
};

const _getAllCustomerDeparmentsWithId = (id) => {
  return apiHelper({
    url: `/customer/departments/${id}`,
    method: 'GET',
    onLoad: status => setLoadingStatus(ORDER_DETAIL_IS_LOADING, status),
    onSuccess: result => setGetResults(ORDER_DETAIL_DEPARTMENT_SET_DATA, result),
    onFailure: err => setErrorThunk(ORDER_DETAIL_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};


export const getAllProductsDeparmentsWithId = (id) => dispatch => {
  
  dispatch(_getAllProductsDeparmentsWithId(id));
};


const _getAllProductsDeparmentsWithId = (id) => {
  return apiHelper({
    url: `/customer/departments/${id}`,
    method: 'GET',
    onLoad: status => setLoadingStatus(ORDER_DETAIL_IS_LOADING, status),
    onSuccess: result => setGetResults(ORDER_DETAIL_DEPARTMENT_SET_DATA, result),
    onFailure: err => setErrorThunk(ORDER_DETAIL_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
};