import { PRODUCTTYPE_GET_ALL_DATA, PRODUCTTYPE_GET_DATA, PRODUCTTYPE_ERROR, PRODUCTTYPE_IS_LOADING, PRODUCTTYPE_SAVE_DATA, PRODUCTTYPE_ON_CHANGE_DATA, PRODUCTTYPE_CLEAN_UP,PRODUCTTYPE_DELETE_DATA, PRODUCTTYPE_UPDATE_CONFIRMATION }  from '../../../constants/index';
import { initialProductTypeState } from '../initialState';

const productTypeReducer = (state = initialProductTypeState, action) => {
  const { payload, type } = action;
  switch (type) {
    case PRODUCTTYPE_ERROR: {
      const { showMessage, errorMessage, errorList } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage,
        errorList: errorList
      };
    }
    case PRODUCTTYPE_GET_ALL_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        productTypeList: resultData
      };
    }
    case PRODUCTTYPE_GET_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        currentProductType: {
          ...state.currentProductType,
          ...resultData
        }
      };
    }
    case PRODUCTTYPE_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }
    case PRODUCTTYPE_SAVE_DATA: {
      const { showMessage, message, redirect } = payload;
      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message
      };
    }

    case PRODUCTTYPE_ON_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        currentProductType: {
          ...state.currentProductType,
          ...data
        }
      };
    }
    case PRODUCTTYPE_CLEAN_UP: {
      let { data } = payload;

      return {
        ...state,
        message: '',
        errorList: [],
        isError: false,
        showMessage: false,
        redirect: false,
        showConfirmationModal: false,
        confirmationId : '',
        currentProductType: {
          ...state.currentProductType,
          ...data
        }
      };
    }
    case PRODUCTTYPE_DELETE_DATA: {
      const { showMessage, message, redirect } = payload;
      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message,
        showConfirmationModal: false,
        confirmationId : ''
      };
    }

    case PRODUCTTYPE_UPDATE_CONFIRMATION: {
      let { data } = payload;

      return {
        ...state,
        ...data
      };
    }

    default:
      return state;
  }
};

export default productTypeReducer;




