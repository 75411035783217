import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { Table, Button, Card, CardBody, CardSubtitle, CardTitle, Col, Container, Row } from 'reactstrap';
import { initialGroupState } from '../../../store/reducers/initialState';
import Pagination from '../../CustomControls/Pagination';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { getGroupUsers, setOnGroupUserChange, saveGroupUsers, setOnCleanUp } from '../../../store/actions/group';
import SearchBar from '../../CustomControls/SearchBar';
import { _create, _groups } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';

const GroupUsers = props => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [results, setResults] = useState([]);
  const group = useSelector(state => state.group);
  const ui = useSelector(state => state.ui);
  const pageSize = ui.layout.pageSize;
  const permissions = ui.layout.permissions;
  let { groupid } = useParams();
  const notificationType = group.isError ? 'error' : 'success';
  let currentGroup = group.currentGroup;

  let localPath = '/management/groups/group';
  let heading = `Group Users`;
  const backUrl = '/management/groups';
  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Group Listing', path: '/management/groups' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  const showSaveOption = hasPermissionToAction(permissions, _groups, _create);

  useEffect(() => {
    if (searchText.length > 0) {
      setResults(
        currentGroup.users.filter(
          x =>
            x.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
            x.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
            x.username.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setResults(currentGroup.users);
    }
  }, [searchText, setResults, currentGroup.users]);

  useEffect(() => {
    if (groupid) {
      dispatch(getGroupUsers(groupid));
    }
  }, [dispatch, groupid]);

  //clean up
  useEffect(() => {
    return () => {
      setLocalCleanUpData({
        data: { ...group.currentGroup, ...initialGroupState.currentGroup }
      });
    };
  }, []);

  const setLocalCleanUpData = cleanUpData => {
    dispatch(setOnCleanUp(cleanUpData));
  };

  const onPageChangeHandler = currentPage => {};

  const onCheckboxHandler = (e, id) => {
    dispatch(setOnGroupUserChange({ data: { id: id, value: e.target.checked } }));
  };

  const handleInput = e => {
    setSearchText(e.target.value);
  };

  const handleSave = e => {
    if (group.currentGroup && groupid) {
      const listSelectedUsers = group.currentGroup.users.filter(x => x.selected).map(res => res.id);
      var saveObj = {
        id: groupid,
        groupUsers: listSelectedUsers
      };

      dispatch(saveGroupUsers(saveObj, groupid));
    }
  };

  return (
    <React.Fragment>
      {group.redirect ? <Redirect to={backUrl} /> : ''}
      {group.isLoading && <LoadingSpinner />}
      {group.showMessage && <Notification notificationType={notificationType} message={group.message} />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardTitle>{`${heading} - ${currentGroup.groupName}`}</CardTitle>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="4">
                      <SearchBar onChange={handleInput} placeholder="Search" value={searchText} />
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        {showSaveOption && (
                          <Button type="button" className="btn btn-primary btn-block " onClick={handleSave}>
                            SAVE USERS
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <CardSubtitle className="mb-4">Select the users in the group</CardSubtitle>
                  <Pagination data={results} pageSize={pageSize} onPageChange={onPageChangeHandler} startingPage={1}>
                    <UserTable onCheckboxHandler={onCheckboxHandler} />
                  </Pagination>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GroupUsers;

const UserTable = ({ paginatedData, onCheckboxHandler }) => {
  return (
    <React.Fragment>
      <div>
        <Table className="table" responsive>
          <thead>
            <tr>
              <th>Select</th>
              <th>Name</th>
              <th>Username</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData &&
              paginatedData.map(n => {
                return (
                  <tr key={n.id}>
                    <td>
                      <input type="checkbox" value={n.selected} checked={n.selected} onChange={e => onCheckboxHandler(e, n.id)} />
                    </td>
                    <td>{`${n.firstName} ${n.lastName}`}</td>
                    <td>{n.username}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};
