import {
  RPT_PRODUCTTYPE_LISTING_SET_DATA,
  RPT_PRODUCTTYPE_LISTING_ERROR,
  RPT_PRODUCTTYPE_LISTING_IS_LOADING,
  RPT_PRODUCTTYPE_LISTING_ON_SEARCH_CHANGE_DATA,
  RPT_PRODUCTTYPE_LISTING_CLEAN_UP
} from '../../../constants/index';
import { apiHelper } from '../../../utils/index';
import Cookies from 'js-cookie';
import { setErrorThunk, setLoadingStatus, setGetResults, setOnSearchChangeData, setOnCleanUpData } from '../actionHelper';

const API_URL = '/report/listing';

export const getAllRptProductTypeData = (filterBy, sortBy, includeFilters = false) => dispatch => {
  dispatch(_getAllRptProductTypeData(filterBy, sortBy, includeFilters));
};

const _getAllRptProductTypeData = (filterBy, sortBy, includeFilters) =>
  apiHelper({
    url: `${API_URL}/producttype`,
    method: 'POST',
    data: {
      filterBy: filterBy,
      sortBy: sortBy,
      includeFilters: includeFilters
    },
    onLoad: status => setLoadingStatus(RPT_PRODUCTTYPE_LISTING_IS_LOADING, status),
    onSuccess: result => setGetResults(RPT_PRODUCTTYPE_LISTING_SET_DATA, result),
    onFailure: err => setErrorThunk(RPT_PRODUCTTYPE_LISTING_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

export const setOnSearchChange = retData => dispatch => {
  dispatch(setOnSearchChangeData(RPT_PRODUCTTYPE_LISTING_ON_SEARCH_CHANGE_DATA, retData));
};

export const setOnCleanUp = retData => dispatch => {
  dispatch(setOnCleanUpData(RPT_PRODUCTTYPE_LISTING_CLEAN_UP, retData));
};
