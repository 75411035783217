import {
    RPT_COMPANY_LISTING_SET_DATA,
    RPT_COMPANY_LISTING_ERROR,
    RPT_COMPANY_LISTING_IS_LOADING,
    RPT_COMPANY_LISTING_ON_SEARCH_CHANGE_DATA,
    RPT_COMPANY_LISTING_CLEAN_UP
  } from '../../../constants/index';
  import { apiHelper } from '../../../utils/index';
  import Cookies from 'js-cookie';
  import { setErrorThunk, setLoadingStatus, setGetResults, setOnSearchChangeData, setOnCleanUpData } from '../actionHelper';
  
  const API_URL = '/adminreport/listing';
  
  export const getAllRptCompanyData = (filterBy, sortBy, includeFilters = false) => dispatch => {
    dispatch(_getAllRptCompanyData(filterBy, sortBy, includeFilters));
  };
  
  const _getAllRptCompanyData = (filterBy, sortBy, includeFilters) =>
    apiHelper({
      url: `${API_URL}/company`,
      method: 'POST',
      data: {
        filterBy: filterBy,
        sortBy: sortBy,
        includeFilters: includeFilters
      },
      onLoad: status => setLoadingStatus(RPT_COMPANY_LISTING_IS_LOADING, status),
      onSuccess: result => setGetResults(RPT_COMPANY_LISTING_SET_DATA, result),
      onFailure: err => setErrorThunk(RPT_COMPANY_LISTING_ERROR, true, err),
      accessToken: Cookies.get('accessToken')
    });
  
  export const setOnSearchChange = retData => dispatch => {
    dispatch(setOnSearchChangeData(RPT_COMPANY_LISTING_ON_SEARCH_CHANGE_DATA, retData));
  };
  
  export const setOnCleanUp = retData => dispatch => {
    dispatch(setOnCleanUpData(RPT_COMPANY_LISTING_CLEAN_UP, retData));
  };
  