import {

    CLIENT_ERROR,
    CLIENT_GET_ALL_DATA,
    CLIENT_IS_LOADING,

  } from '../../../constants/index';
  import { initialClientState } from '../initialState';
  
  const clientCenterReducer = (state = initialClientState, action) => {
    const { payload, type } = action;
    switch (type) {
      case CLIENT_ERROR: {
        const { showMessage, errorMessage, errorList } = payload;
        return {
          ...state,
          isError: showMessage ? true : false,
          showMessage: showMessage,
          message: errorMessage,
          errorList: errorList
        };
      }
      case CLIENT_GET_ALL_DATA: {
        const { resultData } = payload;
        return {
          ...state,
          clientList: resultData
        };
      }
      // case COMPANY_GET_DATA: {
      //   const { resultData } = payload;
      //   return {
      //     ...state,
      //     currentCompany: {
      //       ...state.currentCompany,
      //       ...resultData
      //     }
      //   };
      // }
      case CLIENT_IS_LOADING: {
        const { isLoading } = payload;
        return {
          ...state,
          isLoading: isLoading
        };
      }
      // case COMPANY_SAVE_DATA: {
      //   const { showMessage, message, redirect } = payload;
      //   return {
      //     ...state,
      //     isError: false,
      //     redirect: redirect,
      //     showMessage: showMessage,
      //     message: message
      //   };
      // }
  
    //   case DISTRIBUTION_ON_CHANGE_DATA: {
    //     let { data } = payload;
  
    //     return {
    //       ...state,
    //       currentDistribution: {
    //         ...state.currentDistribution,
    //         ...data
    //       }
    //     };
    //   }
    //   case DISTRIBUTION_CLEAN_UP: {
    //     let { data } = payload;
  
    //     return {
    //       ...state,
    //       message: '',
    //       errorList: [],
    //       isError: false,
    //       showMessage: false,
    //       redirect: false,
    //       currentDistribution: {
    //         ...state.currentDistribution,
    //         ...data
    //       }
    //     };
    //   }
  
      default:
        return state;
    }
  };
  
  export default clientCenterReducer;
  