//OP
export const IS_NEW = 'IS_NEW';
export const IS_REPEAT = 'IS_REPEAT';
export const IS_DISPATCH_FROM_REQ = 'IS_DISPATCH_FROM_REQ';
export const IS_DISPATCH_FROM_REQ_FILTER = 'IS_DISPATCH_FROM_REQ_FILTER';

//UI
export const IS_LOADING = 'IS_LOADING';
export const SET_SETTINGS = 'SET_SETTINGS';
export const UI_ERROR = 'UI_ERROR';
export const PAGINATION_SUB_SIZE = 10;

//domain name to set cookies

export const IS_LOCALHOST = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' ? true : false;

export const API = 'API';

//USERS
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const USER_DETAILS = 'USER_DETAILS';
export const LOGOUT = 'LOGOUT';

export const LINE_COLOURS = [
  '#0081b3',
  '#5B995A',
  '#D9196C',
  '#FFB300',
  '#3D4A74',
  '#3BABFD',
  '#96D65E',
  '#3A506B',
  '#8D5524',
  '#F95F41',
  '#EFB0C9',
  '#8746F1',
  '#D03A67'
];

export const STATES = [
  { label: 'ACT', value: 'ACT' },
  { label: 'NSW', value: 'NSW' },
  { label: 'NT', value: 'NT' },
  { label: 'QLD', value: 'QLD' },
  { label: 'SA', value: 'SA' },
  { label: 'TAS', value: 'TAS' },
  { label: 'VIC', value: 'VIC' },
  { label: 'WA', value: 'WA' }
];

//Environment Names
export const DEV_ENV = 'Development';
export const TEST_ENV = 'Testing';
export const STAGING_ENV = 'Staging';
export const PRODUCTION_ENV = 'Production';

// General DAta
export const GET_GENERAL_DATA = 'GET_GENERAL_DATA';
export const GENERAL_DATA_IS_LOADING = 'GENERAL_DATA_IS_LOADING';
export const GENERAL_DATA_ERROR = 'GENERAL_DATA_ERROR';

// Company
export const COMPANY_GET_ALL_DATA = 'COMPANY_GET_ALL_DATA';
export const COMPANY_GET_DATA = 'COMPANY_GET_DATA';
export const COMPANY_ERROR = 'COMPANY_ERROR';
export const COMPANY_IS_LOADING = 'COMPANY_IS_LOADING';
export const COMPANY_SAVE_DATA = 'COMPANY_SAVE_DATA';
export const COMPANY_ON_CHANGE_DATA = 'COMPANY_ON_CHANGE_DATA';
export const COMPANY_CLEAN_UP = 'COMPANY_CLEAN_UP';

// Distribution Center
export const DISTRIBUTION_GET_ALL_DATA = 'DISTRIBUTION_GET_ALL_DATA';
export const DISTRIBUTION_GET_DATA = 'DISTRIBUTION_GET_DATA';
export const DISTRIBUTION_ERROR = 'DISTRIBUTION_ERROR';
export const DISTRIBUTION_IS_LOADING = 'DISTRIBUTION_IS_LOADING';
export const DISTRIBUTION_SAVE_DATA = 'DISTRIBUTION_SAVE_DATA';
export const DISTRIBUTION_ON_CHANGE_DATA = 'DISTRIBUTION_ON_CHANGE_DATA';
export const DISTRIBUTION_CLEAN_UP = 'DISTRIBUTION_CLEAN_UP';
export const DISTRIBUTION_GET_ALL_CLIENTS = 'DISTRIBUTION_GET_ALL_CLIENTS';
export const DISTRIBUTION_ID = 'DISTRIBUTION_ID';
export const DISTRIBUTION_PRODUCTS = 'DISTRIBUTION_PRODUCTS';

// PRICING
export const PRICING_GET_ALL_DATA = 'PRICING_GET_ALL_DATA';
export const PRICING_GET_DATA = 'PRICING_GET_DATA';
export const PRICING_ERROR = 'PRICING_ERROR';
export const PRICING_IS_LOADING = 'PRICING_IS_LOADING';
export const PRICING_SAVE_DATA = 'PRICING_SAVE_DATA';
export const PRICING_ON_CHANGE_DATA = 'PRICING_ON_CHANGE_DATA';
export const PRICING_CLEAN_UP = 'PRICING_CLEAN_UP';
export const PRICING_GET_ALL_CLIENTS = 'PRICING_GET_ALL_CLIENTS';
export const PRICING_ID = 'PRICING_ID';

// clients
export const CLIENT_GET_ALL_DATA = 'CLIENT_GET_ALL_DATA';
export const CLIENT_ERROR = 'CLIENT_ERROR';
export const CLIENT_IS_LOADING = 'CLIENT_IS_LOADING';
// User
export const USER_GET_ALL_DATA = 'USER_GET_ALL_DATA';
export const USER_GET_DATA = 'USER_GET_DATA';
export const USER_ERROR = 'USER_ERROR';
export const USER_IS_LOADING = 'USER_IS_LOADING';
export const USER_SAVE_DATA = 'USER_SAVE_DATA';
export const USER_ON_CHANGE_DATA = 'USER_ON_CHANGE_DATA';
export const USER_CLEAN_UP = 'USER_CLEAN_UP';
export const USER_CHANGED_PASSWORD = 'USER_CHANGED_PASSWORD';
export const USER_RESET_PASSWORD = 'USER_CHANGED_PASSWORD';
export const USER_ALL_DETAILS = 'USER_ALL_DETAILS';

// Group
export const GROUP_GET_ALL_DATA = 'GROUP_GET_ALL_DATA';
export const GROUP_GET_DATA = 'GROUP_GET_DATA';
export const GROUP_ERROR = 'GROUP_ERROR';
export const GROUP_IS_LOADING = 'GROUP_IS_LOADING';
export const GROUP_SAVE_DATA = 'GROUP_SAVE_DATA';
export const GROUP_ON_CHANGE_DATA = 'GROUP_ON_CHANGE_DATA';
export const GROUP_CLEAN_UP = 'GROUP_CLEAN_UP';
export const GROUP_USERS_CHANGE = 'GROUP_USERS_CHANGE';
export const GROUP_USERS_SAVE = 'GROUP_USERS_SAVE';
export const GROUP_PERMISSIONS_CHANGE = 'GROUP_PERMISSIONS_CHANGE';
export const GROUP_PERMISSIONS_SAVE = 'GROUP_PERMISSIONS_SAVE';

// Client Profile
export const CLIENTPROFILE_GET_ALL_DATA = 'CLIENTPROFILE_GET_ALL_DATA';
export const CLIENTPROFILE_GET_DATA = 'CLIENTPROFILE_GET_DATA';
export const CLIENTPROFILE_ERROR = 'CLIENTPROFILE_ERROR';
export const CLIENTPROFILE_IS_LOADING = 'CLIENTPROFILE_IS_LOADING';
export const CLIENTPROFILE_SAVE_DATA = 'CLIENTPROFILE_SAVE_DATA';
export const CLIENTPROFILE_ON_CHANGE_DATA = 'CLIENTPROFILE_ON_CHANGE_DATA';
export const CLIENTPROFILE_CLEAN_UP = 'CLIENTPROFILE_CLEAN_UP';
export const CLIENTPROFILE_DELETE_DATA = 'CLIENTPROFILE_DELETE_DATA';
export const CLIENTPROFILE_UPDATE_CONFIRMATION = 'CLIENTPROFILE_UPDATE_CONFIRMATION';

// Cost Center
export const COSTCENTER_GET_ALL_DATA = 'COSTCENTER_GET_ALL_DATA';
export const COSTCENTER_GET_DATA = 'COSTCENTER_GET_DATA';
export const COSTCENTER_ERROR = 'COSTCENTER_ERROR';
export const COSTCENTER_IS_LOADING = 'COSTCENTER_IS_LOADING';
export const COSTCENTER_SAVE_DATA = 'COSTCENTER_SAVE_DATA';
export const COSTCENTER_ON_CHANGE_DATA = 'COSTCENTER_ON_CHANGE_DATA';
export const COSTCENTER_CLEAN_UP = 'COSTCENTER_CLEAN_UP';
export const COSTCENTER_DELETE_DATA = 'COSTCENTER_DELETE_DATA';
export const COSTCENTER_UPDATE_CONFIRMATION = 'COSTCENTER_UPDATE_CONFIRMATION';

// Department
export const DEPARTMENT_GET_ALL_DATA = 'DEPARTMENT_GET_ALL_DATA';
export const DEPARTMENT_GET_DATA = 'DEPARTMENT_GET_DATA';
export const DEPARTMENT_ERROR = 'DEPARTMENT_ERROR';
export const DEPARTMENT_IS_LOADING = 'DEPARTMENT_IS_LOADING';
export const DEPARTMENT_SAVE_DATA = 'DEPARTMENT_SAVE_DATA';
export const DEPARTMENT_ON_CHANGE_DATA = 'DEPARTMENT_ON_CHANGE_DATA';
export const DEPARTMENT_CLEAN_UP = 'DEPARTMENT_CLEAN_UP';
export const DEPARTMENT_DELETE_DATA = 'DEPARTMENT_DELETE_DATA';
export const DEPARTMENT_UPDATE_CONFIRMATION = 'DEPARTMENT_UPDATE_CONFIRMATION';

// Product Type
export const PRODUCTTYPE_GET_ALL_DATA = 'PRODUCTTYPE_GET_ALL_DATA';
export const PRODUCTTYPE_GET_DATA = 'PRODUCTTYPE_GET_DATA';
export const PRODUCTTYPE_ERROR = 'PRODUCTTYPE_ERROR';
export const PRODUCTTYPE_IS_LOADING = 'PRODUCTTYPE_IS_LOADING';
export const PRODUCTTYPE_SAVE_DATA = 'PRODUCTTYPE_SAVE_DATA';
export const PRODUCTTYPE_ON_CHANGE_DATA = 'PRODUCTTYPE_ON_CHANGE_DATA';
export const PRODUCTTYPE_CLEAN_UP = 'PRODUCTTYPE_CLEAN_UP';
export const PRODUCTTYPE_DELETE_DATA = 'PRODUCTTYPE_DELETE_DATA';
export const PRODUCTTYPE_UPDATE_CONFIRMATION = 'PRODUCTTYPE_UPDATE_CONFIRMATION';

// Audit Log
export const AUDITLOG_GET_ALL_DATA = 'AUDITLOG_GET_ALL_DATA';
export const AUDITLOG_ERROR = 'AUDITLOG_ERROR';
export const AUDITLOG_IS_LOADING = 'AUDITLOG_IS_LOADING';
export const AUDITLOG_ON_CHANGE_DATA = 'AUDITLOG_ON_CHANGE_DATA';
export const AUDITLOG_CLEAN_UP = 'AUDITLOG_CLEAN_UP';

// Audit Log
export const AUDITPRICINGLOG_GET_ALL_DATA = 'AUDITPRICINGLOG_GET_ALL_DATA';
export const AUDITPRICINGLOG_ERROR = 'AUDITPRICINGLOG_ERROR';
export const AUDITPRICINGLOG_IS_LOADING = 'AUDITPRICINGLOG_IS_LOADING';
export const AUDITPRICINGLOG_ON_CHANGE_DATA = 'AUDITPRICINGLOG_ON_CHANGE_DATA';
export const AUDITPRICINGLOG_CLEAN_UP = 'AUDITPRICINGLOG_CLEAN_UP';

// Location
export const LOCATION_GET_ALL_DATA = 'LOCATION_GET_ALL_DATA';
export const LOCATION_GET_DATA = 'LOCATION_GET_DATA';
export const LOCATION_ERROR = 'LOCATION_ERROR';
export const LOCATION_IS_LOADING = 'LOCATION_IS_LOADING';
export const LOCATION_SAVE_DATA = 'LOCATION_SAVE_DATA';
export const LOCATION_ON_CHANGE_DATA = 'LOCATION_ON_CHANGE_DATA';
export const LOCATION_CLEAN_UP = 'LOCATION_CLEAN_UP';
export const LOCATION_DELETE_DATA = 'LOCATION_DELETE_DATA';
export const LOCATION_UPDATE_CONFIRMATION = 'LOCATION_UPDATE_CONFIRMATION';

// Product
export const PRODUCT_GET_ALL_DATA = 'PRODUCT_GET_ALL_DATA';
export const PRODUCT_GET_DATA = 'PRODUCT_GET_DATA';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';
export const PRODUCT_IS_LOADING = 'PRODUCT_IS_LOADING';
export const PRODUCT_SAVE_DATA = 'PRODUCT_SAVE_DATA';
export const PRODUCT_ON_CHANGE_DATA = 'PRODUCT_ON_CHANGE_DATA';
export const PRODUCT_CLEAN_UP = 'PRODUCT_CLEAN_UP';
export const PRODUCT_DEPARTMENT_SAVE = 'PRODUCT_DEPARTMENT_SAVE';
export const PRODUCT_DEPARTMENT_DELETE = 'PRODUCT_DEPARTMENT_DELETE';
export const PRODUCT_LOCATION_SAVE = 'PRODUCT_LOCATION_SAVE';
export const PRODUCT_LOCATION_DELETE = 'PRODUCT_LOCATION_DELETE';
export const PRODUCT_SUPPLIER_SAVE = 'PRODUCT_SUPPLIER_SAVE';
export const PRODUCT_SUPPLIER_DELETE = 'PRODUCT_SUPPLIER_DELETE';
export const PRODUCT_DELETE_DATA = 'PRODUCT_DELETE_DATA';
export const PRODUCT_UPDATE_CONFIRMATION = 'PRODUCT_UPDATE_CONFIRMATION';

// Requisition
export const REQUISITION_GET_ALL_DATA = 'REQUISITION_GET_ALL_DATA';
export const REQUISITION_GET_DATA = 'REQUISITION_GET_DATA';
export const REQUISITION_ERROR = 'REQUISITION_ERROR';
export const REQUISITION_IS_LOADING = 'REQUISITION_IS_LOADING';
export const REQUISITION_SAVE_DATA = 'REQUISITION_SAVE_DATA';
export const REQUISITION_ON_CHANGE_DATA = 'REQUISITION_ON_CHANGE_DATA';
export const REQUISITION_CLEAN_UP = 'REQUISITION_CLEAN_UP';
export const REQUISITION_ON_SEARCH_CHANGE_DATA = 'REQUISITION_ON_SEARCH_CHANGE_DATA';
export const REQUISITION_GET_PRODUCTS_DEPT = 'REQUISITION_GET_PRODUCTS_DEPT';
export const REQUISITION_GET_PRODUCTS_AND_DEPT_CLIENT = 'REQUISITION_GET_PRODUCTS_AND_DEPT_CLIENT';
export const REQUISITION_GET_ONLINE_REPORT = 'REQUISITION_GET_ONLINE_REPORT';
export const REQUISITION_DELETE_DATA = 'REQUISITION_DELETE_DATA';
export const REQUISITION_UPDATE_CONFIRMATION = 'REQUISITION_UPDATE_CONFIRMATION';

// Dispatch
export const DISPATCH_GET_ALL_DATA = 'DISPATCH_GET_ALL_DATA';
export const DISPATCH_GET_DATA = 'DISPATCH_GET_DATA';
export const DISPATCH_ERROR = 'DISPATCH_ERROR';
export const DISPATCH_IS_LOADING = 'DISPATCH_IS_LOADING';
export const DISPATCH_SAVE_DATA = 'DISPATCH_SAVE_DATA';
export const DISPATCH_ON_CHANGE_DATA = 'DISPATCH_ON_CHANGE_DATA';
export const DISPATCH_ON_CHANGE_DATA_CUSTOM = 'DISPATCH_ON_CHANGE_DATA_CUSTOM';
export const DISPATCH_CLEAN_UP = 'DISPATCH_CLEAN_UP';
export const DISPATCH_ON_SEARCH_CHANGE_DATA = 'DISPATCH_ON_SEARCH_CHANGE_DATA';
export const DISPATCH_GET_PRODUCTS_DEPT = 'DISPATCH_GET_PRODUCTS_DEPT';
export const DISPATCH_GET_ONLINE_REPORT = 'DISPATCH_GET_ONLINE_REPORT';
export const DISPATCH_DELETE_DATA = 'DISPATCH_DELETE_DATA';
export const DISPATCH_UPDATE_CONFIRMATION = 'DISPATCH_UPDATE_CONFIRMATION';

export const DISPATCH_BULK_GET_DATA = 'DISPATCH_BULK_GET_DATA';
export const DISPATCH_BULK_ON_CHANGE_DATA = 'DISPATCH_BULK_ON_CHANGE_DATA';
export const DISPATCH_BULK_GET_REQUISTION_CLIENT = 'DISPATCH_BULK_GET_REQUISTION_CLIENT';
export const DISPATCH_BULK_SAVE_DATA = 'DISPATCH_BULK_SAVE_DATA';
export const DISPATCH_BULK_SAVE_CONFIRMATION = 'DISPATCH_BULK_SAVE_CONFIRMATION';
export const DISPATCH_BULK_CLEAN_UP = 'DISPATCH_BULK_CLEAN_UP';

//Credit Note
export const CREDITNOTE_GET_ALL_DATA = 'CREDITNOTE_GET_ALL_DATA';
export const CREDITNOTE_GET_DATA = 'CREDITNOTE_GET_DATA';
export const CREDITNOTE_ERROR = 'CREDITNOTE_ERROR';
export const CREDITNOTE_IS_LOADING = 'CREDITNOTE_IS_LOADING';
export const CREDITNOTE_SAVE_DATA = 'CREDITNOTE_SAVE_DATA';
export const CREDITNOTE_ON_CHANGE_DATA = 'CREDITNOTE_ON_CHANGE_DATA';
export const CREDITNOTE_CLEAN_UP = 'CREDITNOTE_CLEAN_UP';
export const CREDITNOTE_ON_SEARCH_CHANGE_DATA = 'CREDITNOTE_ON_SEARCH_CHANGE_DATA';
export const CREDITNOTE_GET_ONLINE_REPORT = 'CREDITNOTE_GET_ONLINE_REPORT';
export const CREDITNOTE_DELETE_DATA = 'CREDITNOTE_DELETE_DATA';
export const CREDITNOTE_UPDATE_CONFIRMATION = 'CREDITNOTE_UPDATE_CONFIRMATION';

/* REPORTS LISTING */

//location listing

export const RPT_LOCATION_LISTING_SET_DATA = 'RPT_LOCATION_LISTING_SET_DATA';
export const RPT_LOCATION_LISTING_ERROR = 'RPT_LOCATION_LISTING_ERROR';
export const RPT_LOCATION_LISTING_IS_LOADING = 'RPT_LOCATION_LISTING_IS_LOADING';
export const RPT_LOCATION_LISTING_ON_SEARCH_CHANGE_DATA = 'RPT_LOCATION_LISTING_ON_SEARCH_CHANGE_DATA';
export const RPT_LOCATION_LISTING_CLEAN_UP = 'RPT_LOCATION_LISTING_CLEAN_UP';

//Client listing

export const RPT_CLIENTPROFILE_LISTING_SET_DATA = 'RPT_CLIENTPROFILE_LISTING_SET_DATA';
export const RPT_CLIENTPROFILE_LISTING_ERROR = 'RPT_CLIENTPROFILE_LISTING_ERROR';
export const RPT_CLIENTPROFILE_LISTING_IS_LOADING = 'RPT_CLIENTPROFILE_LISTING_IS_LOADING';
export const RPT_CLIENTPROFILE_LISTING_ON_SEARCH_CHANGE_DATA = 'RPT_CLIENTPROFILE_LISTING_ON_SEARCH_CHANGE_DATA';
export const RPT_CLIENTPROFILE_LISTING_CLEAN_UP = 'RPT_CLIENTPROFILE_LISTING_CLEAN_UP';

//Cost Center listing

export const RPT_COSTCENTER_LISTING_SET_DATA = 'RPT_COSTCENTER_LISTING_SET_DATA';
export const RPT_COSTCENTER_LISTING_ERROR = 'RPT_COSTCENTER_LISTING_ERROR';
export const RPT_COSTCENTER_LISTING_IS_LOADING = 'RPT_COSTCENTER_LISTING_IS_LOADING';
export const RPT_COSTCENTER_LISTING_ON_SEARCH_CHANGE_DATA = 'RPT_COSTCENTER_LISTING_ON_SEARCH_CHANGE_DATA';
export const RPT_COSTCENTER_LISTING_CLEAN_UP = 'RPT_COSTCENTER_LISTING_CLEAN_UP';

//Department listing

export const RPT_DEPARTMENT_LISTING_SET_DATA = 'RPT_DEPARTMENT_LISTING_SET_DATA';
export const RPT_DEPARTMENT_LISTING_ERROR = 'RPT_DEPARTMENT_LISTING_ERROR';
export const RPT_DEPARTMENT_LISTING_IS_LOADING = 'RPT_DEPARTMENT_LISTING_IS_LOADING';
export const RPT_DEPARTMENT_LISTING_ON_SEARCH_CHANGE_DATA = 'RPT_DEPARTMENT_LISTING_ON_SEARCH_CHANGE_DATA';
export const RPT_DEPARTMENT_LISTING_CLEAN_UP = 'RPT_DEPARTMENT_LISTING_CLEAN_UP';

//Product Type listing

export const RPT_PRODUCTTYPE_LISTING_SET_DATA = 'RPT_PRODUCTTYPE_LISTING_SET_DATA';
export const RPT_PRODUCTTYPE_LISTING_ERROR = 'RPT_PRODUCTTYPE_LISTING_ERROR';
export const RPT_PRODUCTTYPE_LISTING_IS_LOADING = 'RPT_PRODUCTTYPE_LISTING_IS_LOADING';
export const RPT_PRODUCTTYPE_LISTING_ON_SEARCH_CHANGE_DATA = 'RPT_PRODUCTTYPE_LISTING_ON_SEARCH_CHANGE_DATA';
export const RPT_PRODUCTTYPE_LISTING_CLEAN_UP = 'RPT_PRODUCTTYPE_LISTING_CLEAN_UP';

//Product listing

export const RPT_PRODUCT_LISTING_SET_DATA = 'RPT_PRODUCT_LISTING_SET_DATA';
export const RPT_PRODUCT_LISTING_ERROR = 'RPT_PRODUCT_LISTING_ERROR';
export const RPT_PRODUCT_LISTING_IS_LOADING = 'RPT_PRODUCT_LISTING_IS_LOADING';
export const RPT_PRODUCT_LISTING_ON_SEARCH_CHANGE_DATA = 'RPT_PRODUCT_LISTING_ON_SEARCH_CHANGE_DATA';
export const RPT_PRODUCT_LISTING_CLEAN_UP = 'RPT_PRODUCT_LISTING_CLEAN_UP';
// EMAIL
export const EMAIL_SET_DATA = 'EMAIL_SET_DATA';
export const EMAIL_ERROR = 'EMAIL_ERROR';
export const EMAIL_IS_LOADING = 'EMAIL_IS_LOADING';
export const EMAIL_ON_SEARCH_CHANGE_DATA = 'EMAIL_ON_SEARCH_CHANGE_DATA';
export const EMAIL_CLEAN_UP = 'EMAIL_CLEAN_UP';

//Department Summary
export const RPT_DEPT_SUMMARY_PROD_OPERATION_SET_DATA = 'RPT_DEPT_SUMMARY_PROD_OPERATION_SET_DATA';
export const RPT_DEPT_SUMMARY_OPERATION_SET_DATA_INTERNAL = 'RPT_DEPT_SUMMARY_OPERATION_SET_DATA_INTERNAL';
export const RPT_DEPT_PROD_SUMMARY_OPERATION_SET_DATA = 'RPT_DEPT_PROD_SUMMARY_OPERATION_SET_DATA';
export const RPT_DEPT_SUMMARY_OPERATION_ERROR = 'RPT_DEPT_SUMMARY_OPERATION_ERROR';
export const RPT_DEPT_PROD_SUMMARY_OPERATION_ERROR = 'RPT_DEPT_PROD_SUMMARY_OPERATION_ERROR';
export const RPT_DEPT_SUMMARY_CSV_OPERATION_ERROR = 'RPT_DEPT_SUMMARY_CSV_OPERATION_ERROR';
export const RPT_DEPT_SUMMARY_OPERATION_IS_LOADING = 'RPT_DEPT_SUMMARY_OPERATION_IS_LOADING';
export const RPT_DEPT_PROD_SUMMARY_OPERATION_IS_LOADING = 'RPT_DEPT_PROD_SUMMARY_OPERATION_IS_LOADING';
export const RPT_DEPT_SUMMARY_OPERATION_CSV_IS_LOADING = 'RPT_DEPT_SUMMARY_OPERATION_CSV_IS_LOADING';
export const RPT_DEPT_SUMMARY_CSV_OPERATION_IS_LOADING = 'RPT_DEPT_SUMMARY_CSV_OPERATION_IS_LOADING';
export const RPT_DEPT_SUMMARY_OPERATION_ON_SEARCH_CHANGE_DATA = 'RPT_DEPT_SUMMARY_OPERATION_ON_SEARCH_CHANGE_DATA';
export const RPT_DEPT_SUMMARY_OPERATION_CLEAN_UP = 'RPT_DEPT_SUMMARY_OPERATION_CLEAN_UP';
export const RPT_DEPT_SUMMARY_OPERATION_SET_DATA_CSV = 'RPT_DEPT_SUMMARY_OPERATION_SET_DATA_CSV';
export const RPT_DEPT_SUMMARY_OPERATION_SET_DATA_CSV_DEP = 'RPT_DEPT_SUMMARY_OPERATION_SET_DATA_CSV_DEP';
export const RPT_DEPT_SUMMARY_OPERATION_SET_DATA = 'RPT_DEPT_SUMMARY_OPERATION_SET_DATA';

// Customer
export const CUSTOMER_PRODUCTS_SET_DATA = 'CUSTOMER_PRODUCTS_SET_DATA';
export const CUSTOMER_PRODUCTS_SET_DATA_DETAILS = 'CUSTOMER_PRODUCTS_SET_DATA_DETAILS';
export const CUSTOMER_PRODUCTS_ERROR = 'CUSTOMER_PRODUCTS_ERROR';
export const CUSTOMER_IS_LOADING = 'CUSTOMER_IS_LOADING';
export const CUSTOMER_ORDER_PLACED = 'CUSTOMER_ORDER_PLACED';
export const CUSTOMER_ON_SEARCH_CHANGE_DATA = 'ORDER_ON_SEARCH_CHANGE_DATA';
export const CUSTOMER_UPDATE_CONFIRMATION = 'ORDER_UPDATE_CONFIRMATION';
export const CUSTOMER_DETAILS = 'ORDER_DETAILS';
export const CUSTOMER_DEPARMENTS_SET_DATA = 'CUSTOMER_DEPARMENTS_SET_DATA';
export const CUSTOMER_DETAILS_BY_ID = 'CUSTOMER_DETAILS_BY_ID';
export const CUSTOMER_JOB_REPORT_IS_LOADING = 'CUSTOMER_JOB_REPORT_IS_LOADING';
export const CUSTOMER_JOB_REPORT_GET_RESULT = 'CUSTOMER_JOB_REPORT_GET_RESULT';
export const CUSTOMER_JOB_REPORT_ERROR = 'CUSTOMER_JOB_REPORT_ERROR';

// Order
export const ORDER_SET_DATA = 'ORDER_SET_DATA';
export const ORDER_ERROR = 'ORDER_ERROR';
export const ORDER_IS_LOADING = 'ORDER_IS_LOADING';
export const ORDER_ON_SEARCH_CHANGE_DATA = 'ORDER_ON_SEARCH_CHANGE_DATA';
export const ORDER_ON_CHANGE_DATA = 'ORDER_ON_CHANGE_DATA';
export const ORDER_UPDATE_CONFIRMATION = 'ORDER_UPDATE_CONFIRMATION';
export const ORDER_DETAILS = 'ORDER_DETAILS';
export const ORDER_SET_PENDING = 'ORDER_SET_PENDING';
export const ORDER_SET_INTERNAL = 'ORDER_SET_INTERNAL';
// Order Details
export const ORDER_DETAIL_SET_DATA = 'ORDER_DETAIL_SET_DATA';
export const PRODUCT_ORDER_DETAIL_SET_DATA = 'PRODUCT_ORDER_DETAIL_SET_DATA';
export const PRODUCT_ORDER_DETAIL_ALL_SET_DATA = 'PRODUCT_ORDER_DETAIL_ALL_SET_DATA';
export const ORDER_DETAIL_REJECT_DATA = 'ORDER_DETAIL_REJECT_DATA';
export const ORDER_DETAIL_SET_DATA_CSV = 'ORDER_DETAIL_SET_DATA_CSV';
export const ORDER_DETAIL_ERROR = 'ORDER_DETAIL_ERROR';
export const ORDER_DETAIL_IS_LOADING = 'ORDER_DETAIL_IS_LOADING';
export const ORDER_DETAIL_ON_SEARCH_CHANGE_DATA = 'ORDER_DETAIL_ON_SEARCH_CHANGE_DATA';
export const ORDER_DETAIL_ON_CHANGE_DATA = 'ORDER_DETAIL_ON_CHANGE_DATA';
export const ORDER_DETAIL_UPDATE_CONFIRMATION = 'ORDER_DETAIL_UPDATE_CONFIRMATION';
export const ORDER_DETAIL_DETAILS = 'ORDER_DETAIL_DETAILS';
export const ORDER_DETAIL_SET_DATA_PDF = 'ORDER_DETAIL_SET_DATA_PDF';
export const ORDER_DETAIL_DEPARTMENT_SET_DATA = 'ORDER_DETAIL_DEPARTMENT_SET_DATA';

// Customer Details
export const CUSTOMER_DETAIL_PRODUCTS_SET_DATA = 'CUSTOMER_DETAIL_PRODUCTS_SET_DATA';
export const CUSTOMER_DETAIL_PRODUCTS_ERROR = 'CUSTOMER_DETAIL_PRODUCTS_ERROR';
export const CUSTOMER_DETAIL_IS_LOADING = 'CUSTOMER_DETAIL_IS_LOADING';
export const CUSTOMER_DETAIL_ORDER_PLACED = 'CUSTOMER_DETAIL_ORDER_PLACED';
export const CUSTOMER_DETAIL_ON_SEARCH_CHANGE_DATA = 'CUSTOMER_ORDER_ON_SEARCH_CHANGE_DATA';
export const CUSTOMER_DETAIL_UPDATE_CONFIRMATION = 'CUSTOMER_ORDER_UPDATE_CONFIRMATION';
export const CUSTOMER_DETAIL_DETAILS = 'CUSTOMER_ORDER_DETAILS';
export const CUSTOMER_DETAIL_BY_NAME = 'CUSTOMER_DETAIL_BY_NAME';

export const RPT_COMPANY_LISTING_SET_DATA = 'RPT_COMPANY_LISTING_SET_DATA';
export const RPT_COMPANY_LISTING_ERROR = 'RPT_COMPANY_LISTING_ERROR';
export const RPT_COMPANY_LISTING_IS_LOADING = 'RPT_COMPANY_LISTING_IS_LOADING';
export const RPT_COMPANY_LISTING_ON_SEARCH_CHANGE_DATA = 'RPT_COMPANY_LISTING_ON_SEARCH_CHANGE_DATA';
export const RPT_COMPANY_LISTING_CLEAN_UP = 'RPT_COMPANY_LISTING_CLEAN_UP';

//URL
export const URL_IS_LOADING = 'URL_IS_LOADING';
export const URL_SET_PENDING = 'URL_SET_PENDING';
export const URL_ERROR = 'URL_ERROR';
