import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    CardSubtitle,
    CardTitle,
    Col,
    Container,
    Label,
    Row,
} from "reactstrap"
import { describeProductType, saveProductType, setOnChange, setOnCleanUp } from '../../../store/actions/Master/productType';
import { AvForm, AvInput, AvGroup } from "availity-reactstrap-validation";
import { initialProductTypeState } from '../../../store/reducers/initialState';
import LoadingSpinner from "../../CustomControls/Spinner";
import Notification from "../../CustomControls/Notification";
import Breadcrumbs from "../../CustomControls/Breadcrumb";
import { _create, _producttype } from "../../../constants/componentConstants"
import { IS_NEW } from "../../../constants";
import { hasPermissionToAction } from "../../../utils/accessUtil";
import CustomAvField from "../../CustomControls/CustomAvField";
import ErrorListing from '../../CustomControls/ErrorListing';

const ManageProductType = props => {
    let history = useHistory();
    const backUrl = '/master/product-types';
    const dispatch = useDispatch();
    const productType = useSelector(state => state.productType);
    const ui = useSelector(state => state.ui);
    const permissions = ui.layout.permissions;
    let IsEdit = false;
    let { producttypeid } = useParams();
    const notificationType = productType.isError ? "error" : "success";

    const showSaveOption = hasPermissionToAction(permissions, _producttype, _create)

    let currentProductType = productType.currentProductType;
    let localPath = '/master/product-types/product-type';
    let heading = 'Add Product Type';

    if (producttypeid) {
        IsEdit = true;
        heading = 'Edit Product Type';
        localPath = localPath + `/${producttypeid}`
    }

    const breadCrumbPaths = [
        { id: 1, title: 'Home', path: '/' },
        { id: 2, title: 'Product Type Listing', path: '/master/product-types' },
        { id: 3, title: heading, path: localPath, isCurrent: true }
    ]

    useEffect(() => {
        if (producttypeid) {
            dispatch(describeProductType(producttypeid));
        } else {
            dispatch(describeProductType(IS_NEW));
        }
    }, [dispatch, producttypeid]);


    //clean up
    useEffect(() => {
        return () => {
            setLocalCleanUpData({
                data: { ...productType.currentProductType, ...initialProductTypeState.currentProductType }
            })
        };
    }, []);



    const setLocalCleanUpData = cleanUpData => {
        dispatch(setOnCleanUp(cleanUpData))
    }

    const setLocalChange = currentState => {
        dispatch(setOnChange(currentState));
    };

    const handleInput = (e) => {
        setLocalChange({
            ...productType,
            data: { ...productType.currentProductType, [e.target.name]: e.target.value }
        });
    }


    const handleCheckBoxInput = (e) => {
        let isChecked;
        if (e.target.value === "false") {
            isChecked = false;
        } else {
            isChecked = true;
        }
        setLocalChange({
            ...productType,
            data: { ...productType.currentProductType, [e.target.name]: !isChecked }
        });
    }

    const handleSubmit = (e) => {

        if (productType.currentProductType) {
            dispatch(saveProductType(productType.currentProductType, !IsEdit, producttypeid))
        }
    }


    const cancelHandler = () => {
        history.push(backUrl);
    };


    return (
        <React.Fragment>
            {productType.redirect ? <Redirect to={backUrl} /> : ''}
            {productType.isLoading && <LoadingSpinner />}
            {productType.showMessage && <Notification notificationType={notificationType} message={productType.message} />}
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={heading} paths={breadCrumbPaths} />
                    <Row>
                        <Col xs="12">
                            <Card>
                            <CardTitle>{heading}</CardTitle>
                                <CardBody>
                                    <AvForm className="form-horizontal" onValidSubmit={handleSubmit} >
                                    {productType.errorList && productType.errorList.length > 0 && <ErrorListing errorList={productType.errorList} />}
                                        <Row>
                                            <Col lg={12}>
                                                <Row>
                                                    <Col sm="4">

                                                        <div className="mb-3">
                                                            <CustomAvField
                                                                name="productTypeCode"
                                                                label="Product Type Code"
                                                                className="form-control"
                                                                placeholder="Product Type Code"
                                                                value={currentProductType.productTypeCode}
                                                                onChange={handleInput}
                                                                type="text"
                                                                maxLength="20"
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'Please enter a valid product type code' },
                                                                }}

                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <CustomAvField
                                                                name="productTypeName"
                                                                label="Product Type Name"
                                                                className="form-control"
                                                                placeholder="Product Type Name"
                                                                value={currentProductType.productTypeName}
                                                                onChange={handleInput}
                                                                type="text"
                                                                maxLength="50"
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'Please enter a valid product type name' },
                                                                }}

                                                            />
                                                        </div>

                                                    </Col>
                                                    <Col sm="12">
                                                        <div className="mb-3">
                                                            <AvGroup check>
                                                                <AvInput type="checkbox" name="isActive" label="Is Active" onChange={handleCheckBoxInput} value={currentProductType.isActive} />
                                                                <Label check for="checkbox"> Is Active</Label>
                                                            </AvGroup>

                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <div className="d-flex flex-wrap gap-2">
                                            {showSaveOption && <Button
                                                type="submit"
                                                color="primary"
                                                className="btn "
                                                disabled={productType.isLoading || (productType.showMessage && !productType.isError)}
                                            >
                                                SAVE
                                            </Button>
                                            }
                                            <Button
                                                onClick={cancelHandler}
                                                color="secondary"
                                                className=" "
                                            >
                                                CANCEL
                                            </Button>
                                        </div>
                                    </AvForm>

                                </CardBody>
                            </Card>
                        </Col>

                    </Row>

                </Container>

            </div>
        </React.Fragment>
    )
}

export default ManageProductType;
