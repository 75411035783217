import React from 'react';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faKey, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import './style.scss';

const UserTable = ({ paginatedData, editHandler, resetPasswordHandler, showResetPasswordOption, deleteHandler }) => {
  return (
    <React.Fragment>
      <div>
        <Table className="table" responsive>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Status</th>
              {showResetPasswordOption && <th>Reset Password</th>}
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map(n => {
              return (
                <tr key={n.id}>
                  <td>{n.firstName || ''}</td>
                  <td>{n.lastName || ''}</td>
                  <td>{n.email || ''}</td>
                  <td>{n.phoneNumber || ''}</td>
                  <td>{n.isActive ? 'Active' : 'Inactive'}</td>
                  {showResetPasswordOption && (
                    <td>
                      <button className="btn tableIcons" onClick={() => resetPasswordHandler(n.id)}>
                        {' '}
                        <FontAwesomeIcon icon={faKey} />
                      </button>
                    </td>
                  )}
                  <td style={{ display: 'flex', gap: '1rem' }}>
                    <button className="btn tableIcons" onClick={() => editHandler(n.id)}>
                      {' '}
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </button>
                    <button className="btn tableIcons" onClick={() => deleteHandler(n.id)}>
                      {' '}
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default UserTable;
