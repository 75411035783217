import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Table } from 'reactstrap';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, Container, Label, Row } from 'reactstrap';
import {
  describeRequisition,
  saveRequisition,
  getProductsByDept,
  getProductsAndDeptByClient,
  setOnChange,
  setOnCleanUp
} from '../../../store/actions/Operation/requisition';
import { AvForm } from 'availity-reactstrap-validation';
import { initialRequisitionState } from '../../../store/reducers/initialState';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _requisition, _allowbackdate } from '../../../constants/componentConstants';
import { IS_NEW, IS_REPEAT } from '../../../constants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import Dropdown from '../../CustomControls/Dropdown';
import CustomAvField from '../../CustomControls/CustomAvField';
import DatePicker from '../../CustomControls/CustomDatePicker';
import ErrorListing from '../../CustomControls/ErrorListing';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const ManageRequisition = props => {
  let history = useHistory();
  const backUrl = '/operation/requisitions';
  const dispatch = useDispatch();
  const requisition = useSelector(state => state.requisition);

  const ui = useSelector(state => state.ui);
  // const maxQty = ui.layout.maxQty;
  const permissions = ui.layout.permissions;
  let IsEditView = false;
  let { requisitionid, repeatid, isreferred } = useParams();

  const notificationType = requisition.isError ? 'error' : 'success';
  let repeatCreation = true;
  if (repeatid && isreferred == '1') {
    repeatCreation = false;
  }
  if (requisitionid) {
    IsEditView = true;
  }
  const [viewMode, setViewMode] = useState(IsEditView);

  let showSaveOption = hasPermissionToAction(permissions, _requisition, _create);
  let allowBackdate = hasPermissionToAction(permissions, _allowbackdate, _create);
  let currentRequisition = requisition.currentRequisition;

  let localPath = '/operation/requisitions/requisition';
  let heading = 'New Requisition';

  if (IsEditView) {
    heading = 'View Requisition';
    localPath = localPath + `/${requisitionid}`;
  }

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Requisition Listing', path: '/operation/requisitions' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];
  useEffect(() => {
    if (requisitionid) {
      dispatch(describeRequisition(requisitionid));
    } else if (repeatid && isreferred == '1') {
      dispatch(describeRequisition(IS_REPEAT, repeatid));
    } else {
      dispatch(describeRequisition(IS_NEW));
    }
  }, [dispatch, requisitionid, repeatid, isreferred]);

  //clean up

  useEffect(() => {
    return () => {
      setLocalCleanUpData({
        data: { ...requisition.currentRequisition, ...initialRequisitionState.currentRequisition }
      });
    };
  }, []);

  const setLocalCleanUpData = cleanUpData => {
    dispatch(setOnCleanUp(cleanUpData));
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  const viewSummaryHandler = status => {
    setViewMode(status);
  };

  const contentHeading = IsEditView ? `${heading} - ${currentRequisition.requisitionRef}` : heading;

  return (
    <React.Fragment>
      {requisition.redirect ? <Redirect to={backUrl} /> : ''}
      {requisition.isLoading && <LoadingSpinner />}
      {requisition.showMessage && <Notification notificationType={notificationType} message={requisition.message} />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardTitle>{contentHeading} </CardTitle>
                {!viewMode ? (
                  <ManageRequisitionEdit
               
                    requisition={requisition}
                    showSaveOption={showSaveOption}
                    allowBackdate={allowBackdate}
                    // maxQty={maxQty}
                    cancelHandler={cancelHandler}
                    viewSummaryHandler={viewSummaryHandler}
                    dispatch={dispatch}
                  />
                ) : (
                  <ManageRequisitionView
                    requisition={requisition}
                    showSaveOption={showSaveOption}
                    cancelHandler={cancelHandler}
                    isEditView={IsEditView}
                    viewSummaryHandler={viewSummaryHandler}
                    dispatch={dispatch}
                    repeatCreation={repeatCreation}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageRequisition;

const RequisitionDetailTable = ({ data, editItemHandler, deleteItemHandler,setQty }) => {
  const filteredData = data.map(item => {
    return {
      value: item.value,
      label: item.label,
      requestedQty: '0'
    };
  });
  const saveData = data.filter(x => x.requestedQty > 0);
  const totalQty = saveData.reduce(function(accumulator, item) {
    return accumulator + parseInt(item.requestedQty);
  }, 0);
useEffect(()=>{
  setQty(totalQty)
},[totalQty])
  // const [totalQty,setTotalQty] = useState()
  // useEffect(()=>{
  //   setTotalQty(filteredData.reduce(function(accumulator, item) {
  //     return accumulator + parseInt(item.requestedQty);
  //   }, 0));
  // },[])

  return (
    <div>
      <Table className="tablelight" responsive>
        <thead>
          <tr>
            <th className="col-sm-8">Product Name</th>
            <th className="col-sm-4 alignRight label-padding">Request Qty</th>
            {/* <th className="col-sm-2 alignRight"> Delete</th> */}
          </tr>
        </thead>
        <tbody>
          <React.Fragment>
            {filteredData.map(n => {
              return (
                <UpdateTBRow
                  key={n.productId}
                  item={n}
                  editItemHandler={editItemHandler}
                  deleteItemHandler={deleteItemHandler}
                  // setTotalQty={setTotalQty}
                  // totalQty={totalQty}
                  // maxQty={maxQty}
                />
              );
            })}
            {data.length > 0 && (
              <tr className="summary" key={'summary'}>
                <td>
                  <span className="summaryLabel">Line Count</span> <span className="summaryValue">{data.length}</span>
                </td>
                <td className="alignRight">
                  <span className="summaryLabel label-padding">Total Requested</span> <span className="summaryValue">{totalQty}</span>
                </td>
            
              </tr>
            )}
          </React.Fragment>
        </tbody>
      </Table>
    </div>
  );
};
// totalQty,setTotalQty
const UpdateTBRow = ({ item, editItemHandler, deleteItemHandler }) => {
  const [requestQty, setRequestQty] = useState(1);
  const onChangeHandler = e => {
    setRequestQty(e.target.value);
    item.requestedQty = e.target.value;
    editItemHandler(item);
  };
  return (
    <tr key={item.value}>
      <td className='col-sm-8'>{item.label || ''}</td>
      <td className='col-sm-4'>
        <CustomAvField
          name={`requestQty_${item.value}`}
          label=""
          className="form-control alignRight small-txtbx align-textbox"
          placeholder=""
          value={item?.requestedQty}
          onChange={onChangeHandler}
          step="1"
          min="0"
          type="number"
          maxLength="10"
        />
      </td>

    </tr>
  );
};

const ManageRequisitionEdit = ({ requisition, showSaveOption, allowBackdate, cancelHandler, viewSummaryHandler, dispatch }) => {
  const [triggerValidation, setTriggerValidation] = useState(false);
  const [qty,setQty] = useState()
  const [selectedProductId, setSelectedProductId] = useState('');
  const [selectedProductPrice, setSelectedProductPrice] = useState('');
  const [selectedProductLabel, setSelectedProductLabel] = useState('');
  const [selectedQty, setSelectedQty] = useState('0');

  const searchInput = useRef(null);

  function handleFocus() {
    searchInput.current && searchInput.current.focus();
  }

  let currentRequisition = requisition.currentRequisition;

  const setLocalChange = currentState => {
    dispatch(setOnChange(currentState));
  };

  const handleInput = e => {
    setLocalChange({
      ...requisition,
      data: { ...requisition.currentRequisition, [e.target.name]: e.target.value }
    });
  };

  const handleDateInput = (field, dateVal) => {
    const setDate = dateVal ? new Date(dateVal) : null;

    const dateData = { [field]: setDate };

    if (field === 'requisitionDate') {
      dateData.deliveryDate = setDate;
    }

    setLocalChange({
      ...requisition,
      data: { ...requisition.currentRequisition, ...dateData }
    });
  };

  const dropDownChange = (field, labelText, selectedOption) => {
    let changeObj = { [field]: selectedOption.value, [labelText]: selectedOption.label };

    setLocalChange({
      ...requisition,
      data: { ...requisition.currentRequisition, ...changeObj }
    });

    if (field === 'clientProfileId' && selectedOption.value) {
      dispatch(getProductsAndDeptByClient(selectedOption.value));
    } else if (field === 'departmentId' && selectedOption.value) {
      dispatch(getProductsByDept(selectedOption.value));
    }
  };

  const handleSubmit = e => {
    if (!isValid()) {
      setTriggerValidation(true);
      return;
    }

    viewSummaryHandler(true);
  };

  const isValid = () => {
    let status = true;
    if (!requisition.currentRequisition.clientProfileId) {
      status = false;
    }

    if (!requisition.currentRequisition.deliveryTypeId) {
      status = false;
    }

    if (!requisition.currentRequisition.departmentId) {
      status = false;
    }

    if (!requisition.currentRequisition.requisitionDate || requisition.currentRequisition.requisitionDate === '') {
      status = false;
    }
    if (requisition?.currentRequisition?.productSelectList?.length > 0) {
      const totalQty = requisition.currentRequisition.productSelectList.reduce(function(accumulator, item) {
        return accumulator + parseInt(item.requestedQty);
      }, 0);
      if (totalQty < 1) {
        status = false;
      }
    } else {
      status = false;
    }

    return status;
  };
  const handleInvalidSubmit = e => {
    setTriggerValidation(true);
  };

  const onAddUpdatItemHandler = addObj => {
    let currentReqItems = requisition.currentRequisition.productSelectList || [];
    const objIndex = currentReqItems.findIndex(x => x.value === addObj.value);

    if (objIndex !== -1) {
      currentReqItems[objIndex].requestedQty = addObj.requestedQty;
    } else {
      currentReqItems.push({
        productId: addObj.value,
        productName: addObj.label,
        requestedQty: addObj.requestedQty,
        productPrice: addObj.price
      });
    }

    setLocalChange({
      ...requisition,
      data: { ...requisition.currentRequisition, productSelectList: currentReqItems }
    });
    const newFilter = currentReqItems.filter(item => {
      return item.requestedQty;
    });
    setLocalChange({
      ...requisition,
      data: {
        ...requisition.currentRequisition,
        requisitionDetails: newFilter.map(item => {
          return {
            productId: item.value,
            productName: item.label,
            requestedQty: item.requestedQty,
            productPrice: item.price
          };
        })
      }
    });
  };

  const deleteItemHandler = id => {
    let currentReqItems = requisition.currentRequisition.requisitionDetails || [];

    const filterlist = currentReqItems?.filter(x => x.productId !== id);
    setLocalChange({
      ...requisition,
      data: { ...requisition.currentRequisition, requisitionDetails: filterlist }
    });
  };

  /* Add local section */

  const selectedProductHandler = val => {
    setSelectedProductId(val.value);
    setSelectedProductLabel(val.label);
    setSelectedProductPrice(val.price);
  };

  const selectedQtyHandler = e => {
    setSelectedQty(e.target.value);
  };

  const addProductHandler = () => {
    if (selectedProductId != '' && selectedProductId != '0' && parseInt(selectedQty) > 0) {
      onAddUpdatItemHandler({
        productId: selectedProductId,
        productName: selectedProductLabel,
        requestedQty: selectedQty,
        productPrice: parseFloat(selectedProductPrice)
      });
      handleFocus();
      setSelectedProductId('0');
      setSelectedQty('0');
      setSelectedProductPrice('0');
    }
  };

  const ProductSelectionOptions = () => {
    const options = [];

    let filterData = currentRequisition.productSelectList || [];

    const productList = currentRequisition.requisitionDetails;
    filterData.forEach(element => {
      if (productList.some(x => x.productId === element.value)) {
        element.isdisabled = true;
      } else {
        element.isdisabled = false;
      }
    });

    filterData.forEach(element => {
      options.push(element);
    });

    return options;
  };

  const colSpanSplit = !currentRequisition.refAutoGenerated ? 3 : 4;

  return (
    <CardBody>
      <AvForm className="form-horizontal" onValidSubmit={handleSubmit} onInvalidSubmit={handleSubmit}>
        {requisition.errorList && requisition.errorList.length > 0 && <ErrorListing errorList={requisition.errorList} />}
        <Row>
          <Col lg={12}>
            <Row>
              {!currentRequisition.refAutoGenerated && (
                <Col sm={colSpanSplit}>
                  <div className="mb-3">
                    <CustomAvField
                      name="requisitionRef"
                      label="Requisition Ref"
                      className="form-control"
                      placeholder="Requisition Ref"
                      value={currentRequisition.requisitionRef}
                      onChange={handleInput}
                      type="text"
                      maxLength="11"
                      disabled={true}
                      // validate={{
                      //   required: { value: true, errorMessage: 'Please enter a requisition ref' }
                      // }}
                    />
                  </div>
                </Col>
              )}
              <Col sm={colSpanSplit}>
                <div className="mb-3">
                  <Dropdown
                    placeholder="Select Client"
                    label="Client"
                    name="clientProfileId"
                    options={currentRequisition.clientProfileSelectList}
                    isMulti={false}
                    value={currentRequisition.clientProfileSelectList.find(x => x.value === currentRequisition.clientProfileId)}
                    onChange={val => dropDownChange('clientProfileId', 'clientProfileName', val)}
                    isDisabled={false}
                    triggerValidation={triggerValidation}
                    validate={{
                      required: { value: true, errorMessage: 'Please select a client' }
                    }}
                  />
                </div>
              </Col>
              <Col sm={colSpanSplit}>
                <div className="mb-3">
                  <Dropdown
                    placeholder="Select Department"
                    label="Department"
                    name="departmentId"
                    options={currentRequisition.departmentSelectList}
                    isMulti={false}
                    value={currentRequisition.departmentSelectList.find(x => x.value === currentRequisition.departmentId)}
                    onChange={val => dropDownChange('departmentId', 'departmentName', val)}
                    isDisabled={false}
                    triggerValidation={triggerValidation}
                    validate={{
                      required: { value: true, errorMessage: 'Please select a department' }
                    }}
                  />
                </div>
              </Col>
              <Col sm={colSpanSplit}>
                <div className="mb-3">
                  <Dropdown
                    placeholder="Select Delivery Type"
                    label="Delivery Type"
                    name="deliveryTypeId"
                    options={currentRequisition.deliveryTypeSelectList}
                    isMulti={false}
                    value={currentRequisition.deliveryTypeSelectList.find(x => x.value === currentRequisition.deliveryTypeId)}
                    onChange={val => dropDownChange('deliveryTypeId', 'DeliveryTypeName', val)}
                    triggerValidation={triggerValidation}
                    validate={{
                      required: { value: true, errorMessage: 'Please select a delivery type' }
                    }}
                  />
                </div>
              </Col>
              <Col md="2">
                <div className="mb-4">
                  <DatePicker
                    label="Requisition Date"
                    triggerValidation={triggerValidation}
                    selected={currentRequisition.requisitionDate ? new Date(currentRequisition.requisitionDate) : null}
                    onChange={date => handleDateInput('requisitionDate', date)}
                    dateFormat="dd-MMM-yyyy"
                    minDate={allowBackdate ? new Date('January 1, 1990 00:00:00') : new Date()}
                    validate={{
                      required: { value: true, errorMessage: 'Please select a valid requisition date' }
                    }}
                  />
                </div>
              </Col>
              <Col md="2">
                <div className="mb-4">
                  <DatePicker
                    label="Delivery Date"
                    triggerValidation={triggerValidation}
                    selected={currentRequisition.deliveryDate ? new Date(currentRequisition.deliveryDate) : null}
                    onChange={date => handleDateInput('deliveryDate', date)}
                    dateFormat="dd-MMM-yyyy"
                    minDate={new Date(currentRequisition.requisitionDate)}
                    validate={{
                      required: { value: true, errorMessage: 'Please select a valid delivery date' }
                    }}
                  />
                </div>
              </Col>
              <Col md="8">
                <div className="mb-3">
                  <CustomAvField
                    name="remarks"
                    label="Remarks"
                    className="form-control"
                    placeholder="Remarks"
                    value={currentRequisition.remarks}
                    onChange={handleInput}
                    type="text"
                    maxLength="200"
                    validate={{
                      required: { value: false, errorMessage: '' }
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <CardSubtitle>Requisition Products</CardSubtitle>
            <Card>
              <CardBody>
                {/* <Row>
                  <Col sm="4">
                    <div className="mb-3">
                      <Dropdown
                        placeholder="Select Product"
                        label="Product"
                        name="selectedProductId"
                        options={ProductSelectionOptions()}
                        isMulti={false}
                        value={ProductSelectionOptions().find(x => x.value === selectedProductId)}
                        onChange={selectedProductHandler}
                        isOptionDisabled={option => option.isdisabled}
                        triggerValidation={false}
                        ref={searchInput}
                        validate={{
                          required: { value: true, errorMessage: 'Please select a product' }
                        }}
                      />
                    </div>
                  </Col>

                  <Col md="2">
                    <div className="mb-3">
                      <CustomAvField
                        name="productqty"
                        label="Product Qty"
                        className="form-control"
                        placeholder="Product Qty"
                        value={selectedQty}
                        onChange={selectedQtyHandler}
                        step="1"
                        min="0"
                        type="number"
                        maxLength="12"
                        validate={{
                          required: { value: true, errorMessage: 'Please enter a valid quantity' }
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="2" className="m-auto">
                    <Button type="button" color="primary" className="btn " onClick={addProductHandler}>
                      ADD
                    </Button>
                  </Col>
                  <Col md="4" className="m-auto"></Col>
                </Row> */}
                <hr />
                <RequisitionDetailTable
                  data={
                    requisition?.currentRequisition?.productSelectList?.filter(item => {
                      return item.value !== '0';
                    }) ?? []
                  }
                  qty={qty}
                  setQty={setQty}

                  // maxQty={maxQty}
                  editItemHandler={onAddUpdatItemHandler}
                  deleteItemHandler={deleteItemHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="d-flex flex-wrap gap-2">
          {showSaveOption && (
            <Button type="submit" color="primary" className="btn " disabled={requisition.isLoading || qty == 0}>
              VIEW SUMMARY
            </Button>
          )}
          <Button onClick={cancelHandler} color="secondary" className=" ">
            CANCEL
          </Button>
        </div>
      </AvForm>
    </CardBody>
  );
};

const ManageRequisitionView = ({
  requisition,
  showSaveOption,
  cancelHandler,
  isEditView,
  viewSummaryHandler,
  dispatch,
  repeatCreation
}) => {
  let currentRequisition = requisition.currentRequisition;

  const saveHandler = () => {
    const setLocalChange = currentState => {
      dispatch(setOnChange(currentState));
    };
    if (requisition.currentRequisition) {
      dispatch(saveRequisition(requisition.currentRequisition, true, null, repeatCreation));
      viewSummaryHandler(false);
    }
    setLocalChange({
      ...requisition,
      data: { ...requisition.currentRequisition, productSelectList: [] }
    });
  };

  const backHandler = () => {
    viewSummaryHandler(false);
  };

  return (
    <CardBody>
      {requisition.errorList && requisition.errorList.length > 0 && <ErrorListing errorList={requisition.errorList} />}
      <Row>
        <Col lg={6}>
          {!currentRequisition.refAutoGenerated && (
            <Row>
              <Col sm="2">
                <Label className="label">Requistion Ref</Label>
              </Col>
              <Col sm="4">
                <Label>{currentRequisition.requisitionRef}</Label>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm="2">
              <Label className="label">Client</Label>
            </Col>
            <Col sm="4">
              <Label>{currentRequisition.clientProfileName}</Label>
            </Col>
          </Row>
          <Row>
            <Col sm="2">
              <Label className="label">Department</Label>
            </Col>
            <Col sm="4">
              <Label>{currentRequisition.departmentName}</Label>
            </Col>
          </Row>
          <Row>
            <Col sm="2">
              <Label className="label">Delivery Type</Label>
            </Col>
            <Col sm="4">
              <Label>{currentRequisition.deliveryTypeName}</Label>
            </Col>
          </Row>
          <Row>
            <Col sm="2">
              <Label className="label">Dispatched</Label>
            </Col>
            <Col sm="4">
              <Label>{currentRequisition.isDispatched ? 'Dispatched' : 'New'}</Label>
            </Col>
          </Row>
        </Col>
        <Col lg="6">
          <Row>
            <Col sm="2">
              <Label className="label">Requisition Date</Label>
            </Col>
            <Col sm="4">
              <Label>{moment(new Date(currentRequisition.requisitionDate)).format('DD-MMM-YYYY h:mm:ss a') || ''}</Label>
            </Col>
          </Row>
          <Row>
            <Col sm="2">
              <Label className="label">Delivery Date</Label>
            </Col>
            <Col sm="4">
              <Label>{moment(new Date(currentRequisition.deliveryDate)).format('DD-MMM-YYYY h:mm:ss a') || ''}</Label>
            </Col>
          </Row>
          <Row>
            <Col sm="2">
              <Label className="label">Remarks</Label>
            </Col>
            <Col sm="4">
              <Label>{currentRequisition.remarks}</Label>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <CardSubtitle>Requisition Products</CardSubtitle>
          <Card>
            <CardBody>
              <RequisitionDetailViewTable
                data={requisition.currentRequisition.requisitionDetails.map(item => {
                  return {
                    value: item.productId,
                    label: item.productName,
                    requestedQty: item.requestedQty,
                    price: item.productPrice
                  };
                })}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <div>
        {showSaveOption && !isEditView ? (
          <div className="d-flex flex-wrap gap-2">
            <Button type="submit" color="primary" className="btn " disabled={requisition.isLoading} onClick={saveHandler}>
              SUBMIT
            </Button>
            <Button onClick={backHandler} color="secondary" className=" ">
              BACK
            </Button>
          </div>
        ) : (
          <Button onClick={cancelHandler} color="secondary" className=" ">
            BACK
          </Button>
        )}
      </div>
    </CardBody>
  );
};

const RequisitionDetailViewTable = ({ data }) => {
  const saveData = data.filter(x => x.requestedQty > 0);

  const totalQty = saveData.reduce(function(accumulator, item) {
    return accumulator + parseInt(item.requestedQty);
  }, 0);
  return (
    <div>
      <Table className="tablelight" responsive>
        <thead>
          <tr>
            <th className="col-sm-4">Product Name</th>
            <th className="col-sm-4 alignRight label-padding">Request Qty</th>
          </tr>
        </thead>
        <tbody>
          <React.Fragment>
            {saveData.map(item => {
              return (
                <tr key={item.value}>
                  <td>{item.label}</td>
                  <td className="label-padding">{item.requestedQty || ''}</td>
                </tr>
              );
            })}
            {saveData.length > 0 && (
              <tr className="summary" key={'summary'}>
                <td>
                  <span className="summaryLabel">Line Count</span> <span className="summaryValue">{saveData.length}</span>
                </td>
                <td className="alignRight">
                  <span className="summaryLabel">Total Requested</span> <span>{totalQty}</span>
                </td>
              </tr>
            )}
          </React.Fragment>
        </tbody>
      </Table>
    </div>
  );
};
