import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import './Login.scss';
import { useSelector, useDispatch } from 'react-redux';
import { checkAndRedirect } from '../../utils/';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
// import { submitLogin } from '../../store/actions/auth';
import { getIsLoading, getUserLoginError, getUserLoginErrorMessage } from '../../selectors/index';
import { Button } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import naztec from '../../assets/images/naztec.svg';
import LoadingSpinner from '../CustomControls/Spinner';
import { submitResetPassword } from '../../store/actions/auth';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
const ResetPassword = ({ isAuthenticated }) => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const [form, setForm] = useState({ newPassword: '', confirmPassword: '' });
  const isLoading = useSelector(state => getIsLoading(state));
  const loginError = useSelector(state => getUserLoginError(state));
  const loginErrorMessage = useSelector(state => getUserLoginErrorMessage(state));

  //   const redirect = () => push('/dashboard');
  // const

  const handleInput = e => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSubmit = e => {
    e.persist();

    const passwordError = !(form.newPassword && form.newPassword.length > 1);
    const confirmPasswordError = !(form.confirmPassword && form.confirmPassword.length > 1);

    if (!confirmPasswordError && !passwordError) {
      //Removing cookies
      if (form.username !== Cookies.get('username')) {
        Cookies.remove('name');
        Cookies.remove('username');
        Cookies.remove('userid');
        Cookies.remove('accessToken');
      }
    }

    dispatch(submitResetPassword(form, token));
  };

  const getGreeting = () => {
    let greetingText = 'Hello!';
    const date = new Date();
    const hour = date.getHours();
    if (hour < 12) {
      greetingText = 'Good Morning!';
    } else if (hour < 18) {
      greetingText = 'Good Afternoon!';
    } else {
      greetingText = 'Good Evening!';
    }
    return greetingText;
  };

  return (
    <div className="main-container">
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10">
              <div className="wrap d-md-flex">
                <div className="text-wrap p-4 p-lg-5 text-center d-flex align-items-center order-md-last">
                  <div className="text w-100">
                    <div className="mb-4">
                      <h2>{getGreeting()}</h2>
                    </div>
                    <div className="mb-4">
                      {' '}
                      <h4>Welcome to the Naztec LMS</h4>
                    </div>
                  </div>
                </div>
                <div className="login-wrap p-4 p-lg-5">
                  <div className="d-flex">
                    <div className="w-100">
                      <h3 className="mb-4">Reset Password</h3>
                    </div>
                    <div className="mb-4 mb-md-5">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img src={naztec} alt="" height="50" />
                      </Link>
                    </div>
                  </div>
                  <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                    <div className="mb-3">
                      <AvField
                        name="newPassword"
                        label="Password"
                        className="form-control"
                        placeholder="Enter New password"
                        type="password"
                        value={form.newPassword}
                        onChange={handleInput}
                        validate={{
                          required: { value: true, errorMessage: 'Please enter a new password' },
                          pattern: {
                            value: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
                            errorMessage: 'Password is not complex enough.'
                          }
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="confirmPassword"
                        label="Confirm Password"
                        className="form-control"
                        placeholder="Enter confirm password"
                        type="password"
                        value={form.confirmPassword}
                        onChange={handleInput}
                        validate={{
                          required: { value: true, errorMessage: 'Please enter a confirm password' },
                          match: { value: 'newPassword', errorMessage: 'Passowords do not match' }
                        }}
                      />
                    </div>
                    {loginError && (
                      <div>
                        <p className="show-custom-error">{loginErrorMessage}</p>{' '}
                      </div>
                    )}
                    <div className="mt-3">
                      {isLoading ? (
                        <LoadingSpinner centeredLoading={false} />
                      ) : (
                        <Button disabled={isLoading} className="btn-primary w-100">
                          Reset
                        </Button>
                      )}
                    </div>
                  </AvForm>
                  {/* <div className="mt-2 text-center">
                    <p className="mb-0">
                      Don't have an account?{" "}
                      <Link to="/signup" className="text-[#3A37A6]">
                        Signup
                      </Link>
                    </p>
                  </div> */}
                  <div className="mt-2 text-center">
                    <p className="mb-0">© {new Date().getFullYear()} Softlogic Australia</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

ResetPassword.defaultProps = {
  isAuthenticated: false
};

ResetPassword.propTypes = {
  isAuthenticated: PropTypes.bool
};

export default ResetPassword;
