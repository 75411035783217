import React from 'react';
import { View, Image, StyleSheet, Text } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    marginTop: 5,
    marginBottom: 5
  },
  logo: {
    height: 35,
    textAlign: 'left'
  },
  logoContainer: {},
  heading: {
    marginLeft: 'auto'
  }
});

const ReportHeader = props => {
  const imageResult = window.localStorage.getItem('lmsCompanyLogo');

  return (
    <View style={styles.headerContainer}>
      <View style={styles.logoContainer}>
        <Image style={styles.logo} src={imageResult} />
      </View>
      <View style={styles.heading}>
        <Text>{props.reportTitle}</Text>
      </View>
    </View>
  );
};

export default ReportHeader;
