import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DISPATCH_GET_PRODUCTS_DEPT } from '../../../constants/index';
// import { useSelector, useDispatch } from 'react-redux';
import { getAllCustomerDeparments, getAllCustomerProducts } from '../../../store/actions/customer';
import { getProductsByDept } from '../../../store/actions/Operation/dispatch';
import { useHistory } from 'react-router-dom';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import { _create, _reportprint, _requisition, _delete } from '../../../constants/componentConstants';

import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
// import ReportViewer from '../ReportViewer';
import ReportHeader from './ReportHeader';
// import ReportFooter from '../Utils/ReportFooter';
import { generalStyles, noBorderTableStyle } from './ReportStyling';
import { getCustomersName } from '../../../store/actions/customerDetail';

import { addDaysToDate, getLastDateOfLastMonth, getFirstDateOfLastMonth } from '../../../utils';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import {
  getAllOrdersDetails,
  setOnConfirmationModal,
  setAllOrderDetails,
  setApproveOrder,
  setOrderDelete,
  getAllOrdersDetailsCSV,
  getAllOrdersDetailsPDF,
  rejectOrdersDetails,
  getAllCustomerDeparmentsWithId
} from '../../../store/actions/orderDetail';
import {
  getAllProductInternalPickList,
  getAllProductPickList,
  getAllRptDeptSummaryData,
  getAllRptProdSummaryData,
  setOnCleanUp,
  setOnSearchChange
} from '../../../store/actions/ReportOperation/rptDepartmentSummary';
import Dropdown from '../../CustomControls/Dropdown';
import DatePicker from '../../CustomControls/CustomDatePicker';
import moment from 'moment';

import LoadingSpinner from '../../CustomControls/Spinner';
import { initialRptDeptSummaryState } from '../../../store/reducers/initialReportState';
import ReportViewer from '../../Reporting/ReportViewer';
import ReportFooter from '../../Reporting/Utils/ReportFooter';

const styles = StyleSheet.create({
  productName: {
    width: '40%'
  },
  productCode: {
    width: '18%'
  },
  dispatches: {
    width: '20.7%'
  },
  issuedQty: {
    width: '12%',
    textAlign: 'right'
  },
  itemPrice: {
    width: '15%',
    textAlign: 'right'
  },
  subTotal: {
    width: '15%',
    textAlign: 'right'
  },
  filterView: {
    paddingTop: 10
  },
  filterText: {
    fontSize: 9,
    fontWeight: 'bold'
  }
});

const headerStyle = StyleSheet.create({
  leftCol: {
    width: '70%',
    textAlign: 'left'
  },
  rightLabelCol: {
    width: '15%',
    textAlign: 'right'
  },
  rightCol: {
    width: '15%',
    textAlign: 'right'
  },
  fullCol: {
    width: '100%',
    textAlign: 'left'
  },
  highlight: {
    fontWeight: 'bold'
  }
});

const summaryStyles = StyleSheet.create({
  leftCol: {
    width: '58%',
    textAlign: 'left'
  },
  rightLabelCol: {
    width: '27%',
    textAlign: 'left'
  },
  rightCol: {
    width: '15%',
    textAlign: 'right',
    borderBottom: 0.5,
    borderStyle: 'solid',
    borderColor: '#bfbfbf'
  }
});

const InternalPickList = props => {
  const dispatch = useDispatch();
  const [filterFromDate, setFilterFromDate] = useState({ field: 'fromdate', value: new Date(), label: '' });
  const [filterToDate, setFilterToDate] = useState({ field: 'todate', value: getLastDateOfLastMonth(), label: '' });
  // const [filterFromDate, setFilterFromDate] = useState(addDaysToDate(new Date(), -30));
  // const [filterToDate, setFilterToDate] = useState(new Date());
  const [filterClient, setFilterClient] = useState([{ name: 'productid', value: 'all', label: 'All' }]);
  const rptData = useSelector(state => state.rptDeptSummary);
  const customer = useSelector(state => state.customerDetail?.customerDetailsByName);
  const productsDispatch = useSelector(state => state.dispatch.currentDispatch.productSelectList);

  const [selectedDep, setSelectedDep] = useState();
  // const products=useSelector(state=>state.productDetail?.products || []);
  const isLoading = rptData.isLoading || false;
  const data = rptData.rptListInternal;
  const filterData = rptData.filterData;
  const reportHeading = 'Internal Pick List';
  const setLocalCleanUpData = cleanUpData => {
    dispatch(setOnCleanUp(cleanUpData));
  };
  const history = useHistory();
  // const dispatch = useDispatch();
  const orders = useSelector(state => state.orderDetail?.orders || []);
  const order = useSelector(state => state.orderDetail || []);
  // const csvData = useSelector(state => state.orderDetail?.csvData || []);

  const ui = useSelector(state => state.ui);
  const allData = useSelector(state => state);
  const permissions = ui.layout.permissions;
  const pageSize = ui.layout.pageSize;
  const currentPageNumber = order?.filterOptions?.pageNumber;

  const notificationType = orders?.isError ? 'error' : 'success';
  const showPrintOption = hasPermissionToAction(permissions, _requisition, _reportprint);
  const showDeleteOption = hasPermissionToAction(permissions, _requisition, _delete);
  const [showModal, setShowModal] = useState(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [orderID, setOrderID] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [reason, setReason] = useState('');
  const state = useSelector(state => state);
  const [products, setProducts] = useState([]);

  //clean up
  useEffect(() => {
    return () => {
      setLocalCleanUpData({
        data: { ...initialRptDeptSummaryState }
      });
    };
  }, []);
  useEffect(() => {
    dispatch(getAllOrdersDetails(currentPageNumber, pageSize, order?.filterOptions?.filterBy, order?.filterOptions?.sortBy));
  }, [dispatch, currentPageNumber, pageSize]);
  useEffect(() => {
    dispatch(getAllRptProdSummaryData(rptData.filterOptions.filterByProd, rptData.filterOptions.sortBy, true));
  }, []);

  useEffect(() => {
    dispatch(getCustomersName());
  }, [dispatch]);

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: `${reportHeading} Report`, path: '/operation/internal-product-list', isCurrent: true }
  ];
  const [departmentData, setDepartmentData] = useState();
  const [customerData, setCustomerData] = useState();
  const handleDateInput = (field, dateVal, callSetMethod) => {
    const saveObj = {};
    const setDate = dateVal ? new Date(dateVal) : null;
    saveObj.value = setDate;
    saveObj.field = field;
    saveObj.label = '';
    callSetMethod(saveObj);
  };

  const dropdownChange = (field, selectedOption, callSetMethod) => {
    let selectedList = [];
    if (selectedOption.length > 0) {
      selectedList = selectedOption?.map(item => {
        return {
          value: item.value || 'all',
          displayName: 'Product',
          valueName: item.label,
          name: 'productid'
        };
      });
    }

    callSetMethod(selectedList);
  };
  const dropDownChangeDepartment = data => {
    setDepartmentData({
      valueName: data.label,
      value: data.value,
      name: 'departmentid',
      displayName: 'Department'
    });
    dispatch(getProductsByDept(data.value));
    // setProducts(productSelectList);
  };
  const onAddNewHandler = () => {
    history.push('/operation/requisitions/requisition');
  };
  const product = useSelector(state => state.customer);

  // const selectedProductHandler = val => {
  //   setSelectedDep(val.value);
  //   dispatch(
  //     getAllCustomerProducts(currentPageNumber, pageSize, product?.filterOptions?.filterBy, product?.filterOptions?.sortBy, '', val.value)
  //   );
  // };
  const selectedProductHandler = val => {
    setSelectedDep(val.value); // Assuming setSelectedDep updates the selected department correctly
    dispatch(
      getAllCustomerProducts(
        currentPageNumber,
        pageSize,
        product?.filterOptions?.filterBy,
        product?.filterOptions?.sortBy,
        '', // You might need to provide other parameters here
        val.value // The selected department ID
      )
    );
  };

  const onEditHandler = id => {
    dispatch(setAllOrderDetails(id));
    // http://localhost:58646/customer/order-details?orderId=709250c1-5e7b-46c7-9e26-b9fdddc682d4
    history.push(`/customer/order-info/admin`);
  };

  const onRepeatHandler = id => {
    history.push(`/operation/requisitions/requisition/1/${id}`);
  };

  const onDispatchHandler = id => {
    history.push(`/operation/dispatches/dispatch/1/${id}`);
  };

  const onPrintHandler = id => {
    history.push(`/operation/requisitions/requisition-report/${id}`);
  };
  const setLocalChange = currentState => {
    dispatch(setOnSearchChange(currentState));
  };

  const onGenerateHandler = () => {
    let toFilter = rptData.filterOptions.filterByProd || [];
    let filterSearchList = toFilter.filter(item => {
      return item.name === 'fromdate';
    });
    filterSearchList = getFilterData(filterSearchList, filterFromDate);
    // filterSearchList = getFilterData(filterSearchList, filterToDate);
    const a = [
      {
        displayName: "User",
        name: "userid",
        value: "all",
        valueName: "All"
    },
      ...filterSearchList
    ];

    setLocalChange({
      ...rptData,
      data: { ...rptData.filterOptions, filterByProd: a }
    });
    dispatch(getAllProductInternalPickList(a, rptData.filterOptions.sortBy));
  };

  const dropDownChange = data => {
    setCustomerData({
      valueName: data.label,
      value: data.value,
      name: 'clientprofileid',
      displayName: 'ClientProfile'
    });

    setSelectedUser(data.value);
    dispatch(getAllCustomerDeparmentsWithId(data.value));
    dispatch(getAllOrdersDetails(currentPageNumber, pageSize, order?.filterOptions?.filterBy, order?.filterOptions?.sortBy, data.value));
    // setLocalChange({
    //   ...orders,
    //   data: { ...orders.currentClientProfile, [field]: selectedOption.value }
    // });
  };

  const getFilterData = (filterSearchList, filterObj) => {
    const objIndex = filterSearchList.findIndex(x => x.name.toLowerCase() === filterObj.field);
    if (objIndex !== -1) {
      filterSearchList[objIndex].value = filterObj[0]?.value ?? filterObj?.value;
      filterSearchList[objIndex].valueName = filterObj[0]?.label ?? filterObj?.label;
    } else {
      filterSearchList.push({
        name: filterObj[0]?.field ?? filterObj?.field,
        value: filterObj[0]?.value ?? filterObj?.value,
        valueName: filterObj[0]?.label ?? filterObj?.label
      });
    }
    return filterSearchList;
  };

  const currentFromDate = rptData.filterOptions?.filterByProd?.find(x => x?.name === 'fromdate')?.value;
  const currentToDate = rptData.filterOptions?.filterByProd?.find(x => x?.name === 'todate')?.value;

  const filterText = `Order List for the Date : ${moment(new Date(currentFromDate)).format('DD-MMM-YYYY') || ''}`;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={`${reportHeading} Report`} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    {/* <Col md="4">
                      <div className="mb-3">
                        <Dropdown
                          placeholder="Select Customer"
                          label="Customers"
                          name="customerName"
                          options={customer}
                          isMulti={false}
                          onChange={val => dropDownChange(val)}
                          validate={{
                            required: { value: true, errorMessage: 'Please select a Customer' }
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <Dropdown
                          placeholder="Select Department"
                          label="Departments"
                          name="departmentName"
                          options={order.departmentDetails}
                          isMulti={false}
                          onChange={val => dropDownChangeDepartment(val)}
                          validate={{
                            required: { value: true, errorMessage: 'Please select a Department' }
                          }}
                        />
                      </div>
                    </Col> */}

                    {/* <Col sm="3">
                      <div className="mb-3">
                        <Dropdown
                          placeholder="All"
                          label="Products"
                          name="productid"
                          options={productsDispatch}
                          isMulti={true}
                          value={filterData?.products?.find(x => x?.value === filterClient?.value)}
                          onChange={val => dropdownChange('productid', val, setFilterClient)}
                          validate={{
                            required: { value: false }
                          }}
                        />
                      </div>
                    </Col> */}
                    <Col sm="2">
                      <div className="mb-4">
                        <DatePicker
                          label="Select Day"
                          selected={filterFromDate.value}
                          onChange={date => handleDateInput('fromdate', date, setFilterFromDate)}
                          dateFormat="dd-MMM-yyyy"
                        />
                      </div>
                    </Col>
                    {/* <Col sm="2">
                      <div className="mb-4">
                        <DatePicker
                          label="To Date"
                          selected={filterToDate.value}
                          onChange={date => handleDateInput('todate', date, setFilterToDate)}
                          dateFormat="dd-MMM-yyyy"
                          minDate={filterFromDate.value}
                        />
                      </div>
                    </Col> */}

                    <Col sm="2">
                      <div className="text-sm-end">
                        <Button type="button" className="btn btn-primary btn-block  app-add-button" onClick={onGenerateHandler}>
                          GENERATE
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      {isLoading ? (
                        <LoadingSpinner />
                      ) : data.length === 0 ? (
                        <div className="no-report-data">
                          {' '}
                          <div className="no-report-data-text">No Data Available </div>{' '}
                        </div>
                      ) : (
                        <ReportViewer>
                          <Document title={reportHeading}>
                            <Page style={generalStyles.body}>
                              <ReportHeader reportTitle={reportHeading} />

                              <View style={noBorderTableStyle.table}>
                                <View style={noBorderTableStyle.tableRow}>
                                  {/* <View style={[noBorderTableStyle.tableCol, headerStyle.leftCol]}> */}
                                    {/* <Text style={[noBorderTableStyle.tableCell, headerStyle.highlight]}>
                                      Client : {customerData.valueName}
                                    </Text> */}
                                  {/* </View> */}
                                  <View style={[noBorderTableStyle.tableCol, headerStyle.rightLabelCol]}>
                                    {/* <Text style={noBorderTableStyle.tableCell}> Reference :</Text> */}
                                  </View>
                                  <View style={[noBorderTableStyle.tableCol, headerStyle.rightCol]}>
                                    <Text style={noBorderTableStyle.tableCell}> {data?.departmentCode} </Text>
                                  </View>
                                </View>
                                <View style={noBorderTableStyle.tableRow}>
                                  <View style={[noBorderTableStyle.tableCol, headerStyle.leftCol]}>
                                    {/* <Text style={noBorderTableStyle.tableCell}>Department : {departmentData.valueName} </Text> */}
                                  </View>
                                  <View style={[noBorderTableStyle.tableCol, headerStyle.rightLabelCol]}>
                                    <Text style={noBorderTableStyle.tableCell}> Date :</Text>
                                  </View>
                                  <View style={[noBorderTableStyle.tableCol, headerStyle.rightCol]}>
                                    <Text style={noBorderTableStyle.tableCell}> {moment(new Date()).format('DD-MMM-YYYY')}</Text>
                                  </View>
                                </View>
                                {/* <View style={noBorderTableStyle.tableRow}>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.leftCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> {data?.clientAddress2} </Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightLabelCol]}>
                                        <Text style={noBorderTableStyle.tableCell}></Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightCol]}>
                                        <Text style={noBorderTableStyle.tableCell}></Text>
                                      </View>
                                    </View>
                                    <View style={noBorderTableStyle.tableRow}>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.leftCol]}>
                                        <Text style={noBorderTableStyle.tableCell}>
                                          Contact : {data?.clientContactNo} for inquires{' '}
                                        </Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightLabelCol]}>
                                        <Text style={noBorderTableStyle.tableCell}></Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightCol]}>
                                        <Text style={noBorderTableStyle.tableCell}></Text>
                                      </View>
                                    </View> */}
                                {/* <View style={noBorderTableStyle.tableRow}>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.leftCol]}>
                                        <Text style={[noBorderTableStyle.tableCell, headerStyle.highlight]}>
                                          ABN : {data?.clientProfileABN}{' '}
                                        </Text>
                                      </View> 
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightLabelCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> </Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> </Text>
                                      </View>
                                    </View>
                                    <View style={noBorderTableStyle.tableRow}>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.leftCol]}>
                                        <Text style={[noBorderTableStyle.tableCell, headerStyle.highlight]}> </Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightLabelCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> </Text>
                                      </View>
                                      <View style={[noBorderTableStyle.tableCol, headerStyle.rightCol]}>
                                        <Text style={noBorderTableStyle.tableCell}> </Text>
                                      </View>
                                    </View> */}
                                <View style={noBorderTableStyle.tableRow}>
                                  <View style={[noBorderTableStyle.tableCol, headerStyle.leftCol]}>
                                    <Text style={[noBorderTableStyle.tableCell, headerStyle.highlight]}>{data?.departmentName} </Text>
                                  </View>
                                  <View style={[noBorderTableStyle.tableCol, headerStyle.rightLabelCol]}>
                                    <Text style={noBorderTableStyle.tableCell}> </Text>
                                  </View>
                                  <View style={[noBorderTableStyle.tableCol, headerStyle.rightCol]}>
                                    <Text style={noBorderTableStyle.tableCell}> </Text>
                                  </View>
                                </View>
                              </View>
                              <View style={styles.filterView}>
                                <Text style={styles.filterText}>{filterText}</Text>
                              </View>

                              <View style={generalStyles.table}>
                                <View style={generalStyles.tableRow}>
                                  <View style={[generalStyles.tableColHeader, styles.productCode]}>
                                    <Text style={generalStyles.tableCellHeader}>Order Ref</Text>
                                  </View>
                                  <View style={[generalStyles.tableColHeader, styles.productName]}>
                                    <Text style={generalStyles.tableCellHeader}>Product Name</Text>
                                  </View>
                                  <View style={[generalStyles.tableColHeader, styles.dispatches]}>
                                    <Text style={generalStyles.tableCellHeader}>Manager Name</Text>
                                  </View>
                                  {/* <View style={[generalStyles.tableColHeader, styles.dispatches]}>
                                    <Text style={generalStyles.tableCellHeader}>Department Name</Text>
                                  </View> */}
                                  <View style={[generalStyles.tableColHeader, styles.dispatches]}>
                                    <Text style={generalStyles.tableCellHeader}>Requested Quantity</Text>
                                  </View>
                                </View>
                                {data.length > 0 && <ReportDataRow results={data} />}
                              </View>

                              <ReportFooter footerInfo={reportHeading}>
                                <Text
                                  style={generalStyles.pageNumber}
                                  render={({ pageNumber, totalPages }) => `Page ${pageNumber} of  ${totalPages}`}
                                />
                              </ReportFooter>
                            </Page>
                          </Document>
                        </ReportViewer>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const ReportDataRow = ({ results }) => {
  return (
    <View>
      {results.map(item => {
        return (
          <View style={generalStyles.tableRow} key={item.productCode}>
            <View style={[generalStyles.tableCol, styles.productCode]}>
              <Text style={generalStyles.tableCell}>{item.orderRef}</Text>
            </View>
            <View style={[generalStyles.tableCol, styles.productName]}>
              <Text style={generalStyles.tableCell}> {item.productName} </Text>
            </View>
            <View style={[generalStyles.tableCol, styles.dispatches]}>
              <Text style={generalStyles.tableCell}> {item.managerFullName} </Text>
            </View>
            {/* <View style={[generalStyles.tableCol, styles.dispatches]}>
              <Text style={generalStyles.tableCell}> {item.departmentName} </Text>
            </View> */}
            <View style={[generalStyles.tableCol, styles.dispatches]}>
              <Text style={generalStyles.tableCell}> {item.requestedQuantity} </Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default InternalPickList;
