import React from 'react';
import { Link } from 'react-router-dom';
import s from './NoRoute.module.scss';

const NoRoute = () => (
  <div className={s.noRoute}>
    <h1>Sorry we are unable to find this page!</h1>
    <Link to={'/'}>Click here to return</Link>
  </div>
);
export default NoRoute;
