import React, { useEffect } from 'react';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper, faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import './style.scss';
import { hasPermissionToAction } from "../../../utils/accessUtil";
import moment from 'moment';
import { useSelector } from 'react-redux';
import { _clientadmin, _view } from '../../../constants/componentConstants';
const OrderTable = ({
  paginatedData,
  editHandler,
  approveHandler,
  rejectionHandler,
  deleteHandler,
  showDelete
}) => {
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  return (
    <React.Fragment>
      <div>
        <Table className="table" responsive>
          <thead>
            <tr>
              {(hasPermissionToAction(permissions, _clientadmin,_view))
               ? (<th className="col-sm-1">User Name</th>)
                :(<th className="col-sm-1">Customer Name</th>) }
              {(hasPermissionToAction(permissions, _clientadmin,_view)) ? (<th className="col-sm-1">User Email</th>) :(<th className="col-sm-1">Customer Email</th>) }
              {(hasPermissionToAction(permissions, _clientadmin,_view)) ? (<th className="col-sm-1">User Phone</th>) :(<th className="col-sm-1">Customer Phone</th>) }
              {(hasPermissionToAction(permissions, _clientadmin,_view)) ? (<th className="col-sm-1">User Address</th>) :(<th className="col-sm-1">Customer Adress</th>) }

          
              {/* <th className="col-sm-2">Customer Name</th>
              <th className="col-sm-2">Customer Email</th>
              <th className="col-sm-2">Customer Phone</th>
              <th className="col-sm-2">Customer Address</th> */}
              <th className="col-sm-2">Order Ref</th>
              <th className="col-sm-3">Order Date</th>
              <th className="col-sm-1">Status</th>
              {!(hasPermissionToAction(permissions, _clientadmin,_view)) && <th className="col-sm-1">Reason</th>}
               <th className="col-sm-1">View</th>
              {!(hasPermissionToAction(permissions, _clientadmin,_view)) && <th className="col-sm-1">Approve</th>}
              {!(hasPermissionToAction(permissions, _clientadmin,_view)) &&<th className="col-sm-1">Reject</th>}
              {showDelete && !(hasPermissionToAction(permissions, _clientadmin,_view)) && <th className="col-sm-1">Delete</th>}
              {/* <th className="col-sm-1">Repeat</th>
                            <th className="col-sm-1">Dispatch</th>
                            {showDelete && <th className="col-sm-1">Delete</th>}
                            {showPrint && <th className="col-sm-1">Print</th>} */}
            </tr>
          </thead>
          <tbody>
            {paginatedData?.map(n => {
              return (
                <tr key={n.orderId}>
                  <td>{n.customerName || ''}</td>
                  <td>{n.customerEmail || ''}</td>
                  <td>{n.customerPhone || ''}</td>
                  <td>{n.customerAddress || ''}</td>
                  <td>{n.orderRef || ''}</td>
                  <td>{moment(new Date(n.orderDate)).format('DD-MMM-YYYY') || ''}</td>
                  {/* <td>{n.clientProfileName || ''}</td>
                                    <td>{n.departmentName || ''}</td> */}
                  <td>{n.status}</td>
                  {!(hasPermissionToAction(permissions, _clientadmin,_view)) &&<td>{ n?.reason }</td>}
                  {
                    <td>
                      <button className="btn tableIcons" onClick={() => editHandler(n.orderId)}>
                        {' '}
                        <FontAwesomeIcon icon={faNewspaper} />
                      </button>
                    </td>
                  }
                  {!(hasPermissionToAction(permissions, _clientadmin,_view)) && n.status !== 'Accepted' && n.status !== 'Rejected' && n.status !== 'Delivered' && (
                    <td>
                      <button className="btn tableIcons" onClick={() => approveHandler(n.orderId, 'Accepted')}>
                        {' '}
                        <FontAwesomeIcon icon={faCheck} />
                      </button>
                    </td>
                  )}
                  {!(hasPermissionToAction(permissions, _clientadmin,_view)) && (n.status === 'Accepted' || n.status === 'Rejected' || n.status === 'Delivered' ) && (
                    <td>
                      <button className="btn tableIcons"> {/* <FontAwesomeIcon icon={faCheck} /> */}</button>
                    </td>
                  )}
                  { !(hasPermissionToAction(permissions, _clientadmin,_view)) && n.status !== 'Accepted' && n.status !== 'Rejected'  && n.status !== 'Delivered' &&(
                    <td>
                      <button className="btn tableIcons" onClick={() => rejectionHandler(n.orderId, 'Accepted')}>
                        {' '}
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </td>
                  )}
                  {!(hasPermissionToAction(permissions, _clientadmin,_view)) && (n.status === 'Accepted' || n.status === 'Rejected' || n.status === 'Delivered' ) && (
                    <td>
                      <button className="btn tableIcons"> {/* <FontAwesomeIcon icon={faCheck} /> */}</button>
                    </td>
                  )}

                  {!(hasPermissionToAction(permissions, _clientadmin,_view)) && showDelete && (
                    <td>
                      <button
                        className="btn tableIcons"
                        onClick={() => {
                          deleteHandler(n.orderId);
                        }}
                      >
                        {' '}
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </td>
                  )}
                  {/* {<td><button className="btn tableIcons" onClick={() => repeatHandler(n.requisitionId)} >  <FontAwesomeIcon icon={faRedo} /></button></td>}
                                    <td>{n.isDispatched ? '' : <button className="btn tableIcons" onClick={() => dispatchHandler(n.requisitionId)} >  <FontAwesomeIcon icon={faShareSquare} /></button>}</td>
                                    {showDelete && <td><button className="btn tableIcons" onClick={() => deleteHandler(n.requisitionId)} > <FontAwesomeIcon icon={faTrashAlt} /></button></td>}
                                    {showPrint && <td><button className="btn tableIcons" onClick={() => printHandler(n.requisitionId)} > <FontAwesomeIcon icon={faPrint} /></button></td>} */}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default OrderTable;
