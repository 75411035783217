import {
  AUDITPRICINGLOG_IS_LOADING,
  AUDITPRICINGLOG_GET_ALL_DATA,
  AUDITPRICINGLOG_ERROR,
  AUDITPRICINGLOG_ON_CHANGE_DATA,
  AUDITPRICINGLOG_CLEAN_UP
} from '../../../constants/index';
import { apiHelper } from '../../../utils/index';
import Cookies from 'js-cookie';
import { setErrorThunk, setLoadingStatus, setGetResults, setOnChangeData, setOnCleanUpData } from '../actionHelper';

const API_URL = '/auditlog';

export const getAllAuditPricingLogs = (currentPageNumber, pageSize, filterBy, sortBy) => dispatch => {
  dispatch(_getAllAuditPricingLogs(currentPageNumber, pageSize, filterBy, sortBy));
};

const _getAllAuditPricingLogs = (currentPageNumber, pageSize, filterBy, sortBy) =>
  apiHelper({
    url: `${API_URL}/filterpricinglogs`,
    method: 'POST',
    data: {
      pageNumber: currentPageNumber,
      pageSize: pageSize,
      filterBy: filterBy,
      sortBy: sortBy
    },
    onLoad: status => setLoadingStatus(AUDITPRICINGLOG_IS_LOADING, status),
    onSuccess: result => setGetResults(AUDITPRICINGLOG_GET_ALL_DATA, result),
    onFailure: err => setErrorThunk(AUDITPRICINGLOG_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

export const setOnChange = retData => dispatch => {
  dispatch(setOnChangeData(AUDITPRICINGLOG_ON_CHANGE_DATA, retData));
};

export const setOnCleanUp = retData => dispatch => {
  dispatch(setOnCleanUpData(AUDITPRICINGLOG_CLEAN_UP, retData));
};
