import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import ReportViewer from '../ReportViewer';
import ReportHeader from '../Utils/ReportHeader';
import ReportFooter from '../Utils/ReportFooter';
import ReportFilterInfo from '../Utils/ReportFilterInfo';
import { generalStyles } from '../Utils/ReportStyling';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { getAllRptProductTypeData, setOnSearchChange } from '../../../store/actions/ReportListing/rptProductTypeListing';
import Dropdown from '../../CustomControls/Dropdown';
import { STATUSES } from '../../../constants/filterConstants';
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../../CustomControls/Spinner';

const styles = StyleSheet.create({
  productTypeCode: {
    width: '20%'
  },
  productTypeName: {
    width: '60%'
  },
  status: {
    width: '20%'
  }
});

const exportHeader = [
  { label: "Product Type Code", key: "productTypeCode" },
  { label: "Product Type Name", key: "productTypeName" },
  { label: "Status", key: "isActive" }
];


const ProductTypeListingRpt = props => {
  const dispatch = useDispatch();
  const [filterStatus, setFilterStatus] = useState({ field: 'isactive', value: 'all', label: 'All' });
  const rptData = useSelector(state => state.rptProductTypeListing);

  const isLoading = rptData.isLoading || false;
  const data = rptData.rptList;
  const reportHeading = 'Product Type Listing';

  useEffect(() => {
    dispatch(getAllRptProductTypeData(rptData.filterOptions.filterBy, rptData.filterOptions.sortBy));
  }, []);

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: `${reportHeading} Report`, path: '/reports/product-type-listing', isCurrent: true }
  ];

  const dropdownChange = (field, selectionOption) => {
    selectionOption.field = field;
    setFilterStatus(selectionOption);
  };

  const setLocalChange = currentState => {
    dispatch(setOnSearchChange(currentState));
  };

  const onGenerateHandler = () => {
    let filterSearchList = rptData.filterOptions.filterBy || [];

    const objIndex = filterSearchList.findIndex(x => x.name.toLowerCase() === filterStatus.field);

    if (objIndex !== -1) {
      filterSearchList[objIndex].value = filterStatus.value;
      filterSearchList[objIndex].valueName = filterStatus.label;
    } else {
      filterSearchList.push({ name: filterStatus.field, value: filterStatus.value, valueName: filterStatus.label });
    }

    setLocalChange({
      ...rptData,
      data: { ...rptData.filterOptions, filterBy: filterSearchList }
    });

    dispatch(getAllRptProductTypeData(rptData.filterOptions.filterBy, rptData.filterOptions.sortBy));
  };




  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={`${reportHeading} Report`} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="4">
                      <div className="mb-3">
                        <Dropdown
                          placeholder="Select Status"
                          label="Status"
                          name="isactive"
                          options={STATUSES}
                          isMulti={false}
                          value={STATUSES.find(x => x.value === filterStatus.value)}
                          onChange={val => dropdownChange('isactive', val)}
                          validate={{
                            required: { value: false }
                          }}
                        />
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="text-sm-start">
                        <Button type="button" className="btn btn-primary btn-block  app-add-button" onClick={onGenerateHandler}>
                          GENERATE
                        </Button>
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="text-sm-end">
                        <CSVLink
                          data={data}
                          headers={exportHeader}
                          filename={"productTypeListing.csv"}
                          className="btn btn-primary download-button"
                        >
                          <FontAwesomeIcon icon={faFileExcel} /> EXPORT
                        </CSVLink>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      {isLoading ? (
                        <LoadingSpinner />
                      ) : (
                        <ReportViewer>
                          <Document title={reportHeading}>
                            <Page style={generalStyles.body}>
                              <ReportHeader reportTitle={reportHeading} />
                              <ReportFilterInfo filterList={rptData.filterOptions.filterBy} />
                              <View style={generalStyles.table}>
                                <View style={generalStyles.tableRow}>
                                  <View style={[generalStyles.tableColHeader, styles.productTypeCode]}>
                                    <Text style={generalStyles.tableCellHeader}>Product Type Code</Text>
                                  </View>
                                  <View style={[generalStyles.tableColHeader, styles.productTypeName]}>
                                    <Text style={generalStyles.tableCellHeader}>Product Type Name</Text>
                                  </View>
                                  <View style={[generalStyles.tableColHeader, styles.status]}>
                                    <Text style={generalStyles.tableCellHeader}>Status</Text>
                                  </View>
                                </View>
                                {data.length > 0 && <ReportDataRow results={data} />}
                              </View>
                              <ReportFooter footerInfo={reportHeading}>
                                <Text
                                  style={generalStyles.pageNumber}
                                  render={({ pageNumber, totalPages }) => `Page ${pageNumber} of  ${totalPages}`}
                                />
                              </ReportFooter>
                            </Page>
                          </Document>
                        </ReportViewer>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const ReportDataRow = ({ results }) => {
  return (
    <View>
      {results.map(item => {
        return (
          <View style={generalStyles.tableRow} key={item.productTypeId}>
            <View style={[generalStyles.tableCol, styles.productTypeCode]}>
              <Text style={generalStyles.tableCell}> {item.productTypeCode} </Text>
            </View>
            <View style={[generalStyles.tableCol, styles.productTypeName]}>
              <Text style={generalStyles.tableCell}>{item.productTypeName}</Text>
            </View>
            <View style={[generalStyles.tableCol, styles.status]}>
              <Text style={generalStyles.tableCell}>{item.isActive}</Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default ProductTypeListingRpt;
