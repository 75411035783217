import {
  AUDITPRICINGLOG_ERROR,
  AUDITPRICINGLOG_GET_ALL_DATA,
  AUDITPRICINGLOG_IS_LOADING,
  AUDITPRICINGLOG_ON_CHANGE_DATA,
  AUDITPRICINGLOG_CLEAN_UP
} from '../../../constants/index';
import { initialAuditPriceLogState } from '../initialState';

const auditPricingLogReducer = (state = initialAuditPriceLogState, action) => {
  const { payload, type } = action;
  switch (type) {
    case AUDITPRICINGLOG_ERROR: {
      const { showMessage, errorMessage } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage
      };
    }
    case AUDITPRICINGLOG_GET_ALL_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        auditPricingLogList: resultData.data,
        totalRecords: resultData.totalRecords,
        filterOptions: {
          ...state.filterOptions,
          pageNumber: resultData.pageNumber,
          pageSize: resultData.pageSize
        }
      };
    }

    case AUDITPRICINGLOG_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }

    case AUDITPRICINGLOG_ON_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          ...data
        }
      };
    }
    case AUDITPRICINGLOG_CLEAN_UP: {
      let { data } = payload;

      return {
        ...state,
        message: '',
        isError: false,
        showMessage: false,
        redirect: false,
        filterOptions: {
          ...state.filterOptions,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default auditPricingLogReducer;
