import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Table from './CreditNoteTable';
import Pagination from '../../CustomControls/Pagination';
import {
  getAllCreditNotes,
  setOnSearchChange,
  setOnConfirmationModal,
  deleteCreditNote
} from '../../../store/actions/Operation/creditNote';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _creditnote, _reportprint, _delete } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import SearchBar from '../../CustomControls/SearchBar';
import DatePicker from '../../CustomControls/CustomDatePicker';
import { addDaysToDate } from '../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import CustomConfirmation from '../../CustomControls/ConfirmationModal';

import './style.scss';

const CreditNote = props => {
  const [searchText, setSearchText] = useState('');
  const [searchFromDate, setSearchFromDate] = useState(addDaysToDate(new Date(), -30));
  const [searchToDate, setSearchToDate] = useState(new Date());
  const history = useHistory();
  const dispatch = useDispatch();
  const creditNote = useSelector(state => state.creditNote);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  const pageSize = ui.layout.pageSize;
  const currentPageNumber = creditNote.filterOptions.pageNumber;

  const notificationType = creditNote.isError ? 'error' : 'success';

  const showSaveOption = hasPermissionToAction(permissions, _creditnote, _create);
  const showPrintOption = hasPermissionToAction(permissions, _creditnote, _reportprint);
  const showDeleteOption = hasPermissionToAction(permissions, _creditnote, _delete);

  useEffect(() => {
    dispatch(getAllCreditNotes(currentPageNumber, pageSize, creditNote.filterOptions.filterBy, creditNote.filterOptions.sortBy));
  }, [dispatch, currentPageNumber, pageSize, creditNote.filterOptions.filterBy, creditNote.filterOptions.sortBy]);

  const onPageChangeHandler = currentPage => {};

  const onAddNewHandler = () => {
    history.push('/operation/credit-notes/credit-note');
  };

  const onEditHandler = id => {
    history.push(`/operation/credit-notes/credit-note/${id}`);
  };

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Credit Note Listing', path: '/operation/credit-notes', isCurrent: true }
  ];

  const setLocalChange = currentState => {
    dispatch(setOnSearchChange(currentState));
  };

  const handleDateInput = (field, dateVal) => {
    const setDate = dateVal ? new Date(dateVal) : null;

    if (field === 'fromdate') {
      setSearchFromDate(setDate);
    }

    if (field === 'todate') {
      setSearchToDate(setDate);
    }

    let filterSearchList = creditNote.filterOptions.filterBy || [];

    const objIndex = filterSearchList.findIndex(x => x.name.toLowerCase() === field);

    if (objIndex !== -1) {
      filterSearchList[objIndex].value = setDate.toDateString();
    } else {
      filterSearchList.push({ name: field, value: setDate.toDateString() });
    }

    setLocalChange({
      ...creditNote,
      data: { ...creditNote.filterOptions, filterBy: filterSearchList }
    });
  };

  const handleInput = e => {
    const searchVal = e.target.value ?? '';

    setSearchText(searchVal);
    let filterSearchList = creditNote.filterOptions.filterBy || [];

    const objIndex = filterSearchList.findIndex(x => x.name.toLowerCase() === 'creditnoteno');

    if (objIndex !== -1) {
      filterSearchList[objIndex].value = searchVal.toLowerCase();
    } else {
      filterSearchList.push({ name: 'creditnoteno', value: searchVal.toLowerCase() });
    }
  };

  const onSearchHandler = e => {
    dispatch(getAllCreditNotes(currentPageNumber, pageSize, creditNote.filterOptions.filterBy, creditNote.filterOptions.sortBy));
  };

  const onPrintHandler = id => {
    history.push(`/operation/credit-notes/credit-note-report/${id}`);
  };

  const onDeleteHandler = id => {
    dispatch(
      setOnConfirmationModal({
        data: {
          showConfirmationModal: true,
          confirmationId: id
        }
      })
    );
  };

  const onDeleteConfirmation = () => {
    if (creditNote.confirmationId) {
      let ftOptions = {
        currentPageNumber: currentPageNumber,
        pageSize: pageSize,
        filterBy: creditNote.filterOptions.filterBy,
        sortBy: creditNote.filterOptions.sortBy
      };
      dispatch(deleteCreditNote(creditNote.confirmationId, ftOptions));
    }
  };

  const onDeleteCancel = () => {
    dispatch(
      setOnConfirmationModal({
        data: {
          showConfirmationModal: false,
          confirmationId: ''
        }
      })
    );
  };

  return (
    <React.Fragment>
      <CustomConfirmation
        show={creditNote.showConfirmationModal}
        onDeleteClick={onDeleteConfirmation}
        onCloseClick={onDeleteCancel}
        message="Are you sure you want to delete this record?"
        header="Deleting record"
      />
      <div className="page-content">
        {creditNote.showMessage && <Notification notificationType={notificationType} message={creditNote.message} />}
        <Container fluid>
          <Breadcrumbs title="Credit Note Listing " paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="3">
                      <SearchBar onChange={handleInput} placeholder="Search By Credit Note No" value={searchText} />
                    </Col>
                    <Col sm="2">
                      <div className="mb-4">
                        <DatePicker
                          label="From Date"
                          selected={searchFromDate}
                          onChange={date => handleDateInput('fromdate', date)}
                          dateFormat="dd-MMM-yyyy"
                        />
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="mb-4">
                        <DatePicker
                          label="To Date"
                          selected={searchToDate}
                          onChange={date => handleDateInput('todate', date)}
                          dateFormat="dd-MMM-yyyy"
                          minDate={searchFromDate}
                        />
                      </div>
                    </Col>
                    <Col sm="2" className="m-auto">
                      <div className="text-sm-start">
                        <Button type="button" className="btn btn-outline-primary" onClick={onSearchHandler}>
                          <FontAwesomeIcon className="standardIcon" icon={faSearch} /> Search
                        </Button>
                      </div>
                    </Col>
                    <Col sm="3">
                      <div className="text-sm-end">
                        {showSaveOption && (
                          <Button type="button" className="btn btn-primary btn-block  app-add-button" onClick={onAddNewHandler}>
                            ADD CREDIT NOTE
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="tablerow">
                    <Col xl="12">
                      <div>
                        {creditNote.isLoading && <LoadingSpinner />}
                        <Pagination
                          data={creditNote.creditNoteList}
                          pageSize={pageSize}
                          onPageChange={onPageChangeHandler}
                          startingPage={currentPageNumber}
                          apiPaging={true}
                          apiDataLength={creditNote.totalRecords}
                        >
                          <Table
                            editHandler={onEditHandler}
                            printHandler={onPrintHandler}
                            showPrint={showPrintOption}
                            deleteHandler={onDeleteHandler}
                            showDelete={showDeleteOption}
                          />
                        </Pagination>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreditNote;
