import get from 'lodash/get';
import { push } from 'connected-react-router';
import Cookies from 'js-cookie';
import { LOGIN_ERROR, USER_DETAILS, LOGOUT } from '../../constants/index';
import { apiHelper } from '../../utils/index';
import { setIsLoading } from './ui';

export const setLoginError = (value, msg) => ({
  type: LOGIN_ERROR,
  payload: { value, msg }
});

export const setUserDetails = user => ({
  type: USER_DETAILS,
  payload: { user }
});

export const logoutUser = () => ({
  type: LOGOUT,
  payload: {}
});

const setUserAuthData = ({ AccessToken, UserName, UserId, Name, CompanyId, Timezone, PageSize, CompanyLogo, CustomerId, Session }) => {
  if (AccessToken) Cookies.set('accessToken', AccessToken);
  if (UserName) Cookies.set('username', UserName);
  if (UserId) Cookies.set('userid', UserId);
  if (Name) Cookies.set('name', Name);
  if (CompanyId) Cookies.set('companyid', CompanyId);
  if (Timezone) Cookies.set('timezone', Timezone);
  if (PageSize) Cookies.set('pagesize', PageSize);
  if (CustomerId) Cookies.set('customerid', CustomerId);
  if (Session) {
    Cookies.set('session', Session);
  } else {
    Cookies.remove('session');
  }
  if (CompanyLogo) {
    window.localStorage.setItem('lmsCompanyLogo', CompanyLogo);
  } else {
    window.localStorage.removeItem('lmsCompanyLogo');
  }
};
export const submitResetPassword = (data, token) => dispatch => {
  dispatch(ResetPassword(data, token));
};
export const ResetPassword = (data, token) => {
  return apiHelper({
    url: `/user/password-reset`,
    method: 'PATCH',
    onSuccess: res => resetPasswordSuccess(res),
    onFailure: msg => setLoginError(true, msg),
    data: {
      newPassword: data.confirmPassword,
      resetToken: token
    },
    onLoad: setIsLoading,
    skipRefresh: true
  });
};
export const resetPasswordSuccess = res => dispatch => {
  if (res.isSuccess === true) {
    dispatch(push('/login'));
  } else {
    setLoginError(true, res.message);
  }
};
export const submitSignup = data => dispatch => {
  dispatch(submitUserSignup(data));
};
export const submitUserSignup = data =>
  apiHelper({
    url: `/customer/register`,
    method: 'POST',
    onSuccess: signupSuccess,
    onFailure: msg => setLoginError(true, msg),
    onLoad: setIsLoading,
    data: {
      firstName: data.firstname,
      lastName: data.lastname,
      debtorCode: data.debtorcode,
      primaryContactPhone: data.contactphone,
      primarContactPerson: data.contactperson,
      primaryContactEmail: data.username,
      deliveryState: data.state,
      deliverySuburb: data.suburb,
      deliveryStreetAddress: data.streetaddress,
      deliveryPostCode: data.postcode,
      billingState: data.billingstate,
      billingSuburb: data.billingsuburb,
      billingStreetAddress: data.billingstreetaddress,
      billingPostCode: data.billingpostcode,
      abn: data.banknumber,
      password: data.password,
      DepartmentName: data.DepartmentName
    },
    skipRefresh: true
  });
export const submitLogin = data => dispatch => {
  dispatch(submitUserLogin(data));
};

export const submitUserLogin = data =>
  apiHelper({
    url: `/auth/login`,
    method: 'POST',
    onSuccess: loginSuccess,
    onFailure: msg => setLoginError(true, msg),
    onLoad: setIsLoading,
    data,
    skipRefresh: true
  });

export const loginSuccess = (response, { username }) => dispatch => {
  const userData = { username };
  let redirectURL = '/dashboard';
  let authData = get(response, ['AuthenticationResult']);
  if (!authData) {
    authData = {};
  }

  authData.AccessToken = get(response, ['accessToken']);
  authData.Name = get(response, ['name']);
  authData.UserName = get(response, ['username']);
  authData.UserId = get(response, ['userId']);
  authData.CompanyId = get(response, ['companyId']);
  authData.Timezone = get(response, ['timezone']) || '';
  authData.PageSize = get(response, ['pageSize']) || '20';
  authData.Session = get(response, ['session']) || '';
  authData.CompanyLogo = get(response, ['companyLogo']) || '';

  if (authData) {
    setUserAuthData(authData);
  }

  dispatch(setUserDetails(userData));
  dispatch(push(redirectURL));
};
export const signupSuccess = (response, { username }) => dispatch => {
  const userData = { username };
  let redirectURL = '/login';
  let authData = get(response, ['AuthenticationResult']);
  if (!authData) {
    authData = {};
  }

  authData.AccessToken = get(response, ['accessToken']);
  authData.Name = get(response, ['name']);
  authData.UserName = get(response, ['username']);
  authData.UserId = get(response, ['userId']);
  authData.CompanyId = get(response, ['companyId']);
  authData.CustomerId = get(response, ['customerId']);
  authData.Timezone = get(response, ['timezone']) || '';
  authData.PageSize = get(response, ['pageSize']) || '20';
  authData.Session = get(response, ['session']) || '';
  authData.CompanyLogo = get(response, ['companyLogo']) || '';

  if (authData) {
    setUserAuthData(authData);
  }

  dispatch(setUserDetails(userData));
  dispatch(push(redirectURL));
};
export const logout = () => dispatch => {
  Object.keys(Cookies.get()).forEach(cookieName => {
    Cookies.remove(cookieName);
  });
  dispatch(logoutUser());
  dispatch(push('/'));
  window.localStorage.removeItem('lmsCompanyLogo');
};

export const accessDenied = () => dispatch => {
  dispatch(push('/'));
};
