import { apiHelper } from '../../../utils/index';
import Cookies from 'js-cookie';
import { setErrorThunk, setLoadingStatus, setGetResults, setOnChangeData, setOnCleanUpData } from '../actionHelper';
import {
  DISTRIBUTION_CLEAN_UP,
  DISTRIBUTION_ERROR,
  DISTRIBUTION_IS_LOADING,
  DISTRIBUTION_ON_CHANGE_DATA,
  PRICING_CLEAN_UP,
  PRICING_GET_ALL_DATA,
  PRICING_ON_CHANGE_DATA
} from '../../../constants';

const API_URL = '/product/';

export const getAllClientPricing = result => dispatch => {
  dispatch(_getAllClientPricing());
};

const _getAllClientPricing = () =>
  apiHelper({
    url: `${API_URL}clientCharges`,
    method: 'GET',
    onLoad: status => setLoadingStatus(DISTRIBUTION_IS_LOADING, status),
    onSuccess: result => setGetResults(PRICING_GET_ALL_DATA, result),
    onFailure: err => setErrorThunk(DISTRIBUTION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
// export const getAllDistributionCenterClients = id => dispatch => {
//   dispatch(_getAllDistributionCenterClients(id));
// };

// const _getAllDistributionCenterClients = id =>
//   apiHelper({
//     url: `${API_URL}/GetById?id=${id}`,
//     method: 'GET',
//     onLoad: status => setLoadingStatus(DISTRIBUTION_IS_LOADING, status),
//     onSuccess: result => setGetResults(DISTRIBUTION_GET_ALL_CLIENTS, result),
//     onFailure: err => setErrorThunk(DISTRIBUTION_ERROR, true, err),
//     accessToken: Cookies.get('accessToken')
//   });
// export const createDistributionCenter = payload => dispatch => {
//   dispatch(_createDistributionCenter(payload));
// };

// const _createDistributionCenter = payload =>
//   apiHelper({
//     url: `${API_URL}`,
//     method: 'PATCH',
//     data: payload,
//     onLoad: status => setLoadingStatus(DISTRIBUTION_IS_LOADING, status),
//     onSuccess: result => setGetResults(DISTRIBUTION_ID, result),
//     onFailure: err => setErrorThunk(DISTRIBUTION_ERROR, true, err),
//     accessToken: Cookies.get('accessToken')
//   });
export const updatePriceCenter = payload => dispatch => {
  dispatch(_updatePriceCenter(payload));
};

const _updatePriceCenter = payload =>
  apiHelper({
    url: `${API_URL}increase`,
    method: 'PATCH',
    data: payload,
    onLoad: status => setLoadingStatus(DISTRIBUTION_IS_LOADING, status),
    onSuccess: result => getAllClientPricing(result),
    onFailure: err => setErrorThunk(DISTRIBUTION_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
// export const deleteDistributionCenter = payload => dispatch => {
//   dispatch(_deleteDistributionCenter(payload));
// };

// const _deleteDistributionCenter = payload =>
//   apiHelper({
//     url: `${API_URL}?id=${payload}`,
//     method: 'DELETE',
//     onLoad: status => setLoadingStatus(DISTRIBUTION_IS_LOADING, status),
//     onSuccess: result => getAllDistributionCenters(),
//     onFailure: err => setErrorThunk(DISTRIBUTION_ERROR, true, err),
//     accessToken: Cookies.get('accessToken')
//   });

export const setOnChange = retData => dispatch => {
  dispatch(setOnChangeData(PRICING_ON_CHANGE_DATA, retData));
};

export const setOnCleanUp = retData => dispatch => {
  dispatch(setOnCleanUpData(PRICING_CLEAN_UP, retData));
};
