import React, { useState, useEffect } from "react";
import Select from "react-select"
import "./style.scss";
import { Label } from "reactstrap";

const Dropdown = React.forwardRef((props, ref) => {
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
  
    useEffect(() => {
        if (props.triggerValidation || props.value && props.value.value) {
            if (props.value && !props.value.value) {
                doValidation(props.value.value)
            }
        }
    }, [props.triggerValidation, props.value]);


    const localOnChange = selectOption => {

        doValidation(selectOption.value);
        props.onChange(selectOption);
    }

    const doValidation = (val) => {
        if (props.validate) {
            if (props.validate.required && props.validate.required.value) {
                if (!val || val === '' || val === '0') {
                    setShowError(true);
                    setErrorMessage(props.validate.required.errorMessage || '')
                }
                else {
                    setShowError(false);
                    setErrorMessage('')
                }
            }
        }
    }

    let label = <>{props.label}</>;
    if (props.required || (props.validate && props.validate.required.value)) {
        label = <>{label}<sup>*</sup></>;
    }
    const errorLabelClass = showError ? 'label-error' : '';
    const customErrorBorder = showError ? 'custom-dropdown-error' : '';

    return (
        <div className="dropdown">
            <Label for={props.label} className={`label ${errorLabelClass}`} >{label}</Label>
            <Select
                classNamePrefix="select2-selection"
                placeholder={props.placeholder}
                title={props.label}
                name={props.name}
                className={customErrorBorder}
                options={props.options}
                isMulti={props.isMulti}
                value={props.value}
                isDisabled={props.isDisabled}
                onChange={localOnChange}
                isOptionDisabled={props.isOptionDisabled}
                ref={ref}
            />
            {(showError && errorMessage.length > 0) && <div className="invalid-feedback">{errorMessage}</div>}
        </div>
    )
})

export default Dropdown;