import { StyleSheet } from '@react-pdf/renderer';

export const generalStyles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 35,
    paddingHorizontal: 20
  },
  pageNumber: {
    fontSize: 9,
    left: 0,
    right: 20,
    textAlign: 'right',
    color: 'grey'
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
    fontSize: 9
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  tableColHeader: {
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderBottomColor: '#000',
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: 500
  },
  tableCell: {
    margin: 5,
    fontSize: 8
  }
});

export const noBorderTableStyle = StyleSheet.create({
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    marginTop: 10,
    fontSize: 9,
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  tableCol: {
    borderStyle: 'solid',
    borderColor: '#bfbfbf'
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: 500
  },
  tableCell: {
    margin: 5,
    fontSize: 9
  }
});
