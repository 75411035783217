import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle, Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { describeUser } from '../../../store/actions/user';
import { AvForm } from 'availity-reactstrap-validation';
import { initialDistributionState } from '../../../store/reducers/initialState';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import Dropdown from '../../CustomControls/Dropdown';
import { IS_NEW } from '../../../constants';
import classnames from 'classnames';
import { _create, _users } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import CustomAvField from '../../CustomControls/CustomAvField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSitemap } from '@fortawesome/free-solid-svg-icons';
import ManageClientDistribution from './ManageClientDistribution';
import ErrorListing from '../../CustomControls/ErrorListing';
import {
  createDistributionCenter,
  setOnChange,
  setOnCleanUp,
  updateDistributionCenter
} from '../../../store/actions/Management/distributionCenter';

const ManageDistributionCenter = props => {
  let history = useHistory();
  const backUrl = '/management/distribution-center';
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const distribution = useSelector(state => state.distribution);
  const currentActiveTab = useSelector(state => state.distribution.currentActiveTab);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  const [urlId, setUrlId] = useState('');

  const [selectedIds, setSelectedIds] = useState([]);
  let IsEdit = false;
  let { userid } = useParams();
  const notificationType = user.isError ? 'error' : 'success';
  // const [currentActiveTab, setCurrentActiveTab] = useState('general');
  const [selectedManager, setSelectedManager] = useState('');
  const showSaveOption = hasPermissionToAction(permissions, _users, _create);

  let currentUser = distribution.currentDistribution;
  let distributionDetails = distribution.distributionDetails;
  let dcManagers = distributionDetails.dcManagers;
  let localPath = '/management/distribution-center/manage-dc';
  let heading =  `${urlId ? 'EDIT REMOTE LINEN ROOM' : 'Add Remote Linen Room' }`;

  if (userid) {
    IsEdit = true;
    heading = 'Edit User';
    localPath = localPath + `/${userid}`;
  }

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Remote Linen Room', path: '/management/distribution-center' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  // useEffect(() => {
  //   if (userid) {
  //     dispatch(describeUser(userid));
  //   } else {
  //     dispatch(describeUser(IS_NEW));
  //   }
  // }, [dispatch, userid]);

  //clean up
  useEffect(() => {
    const parts = history.location.pathname.split('/')[4] ?? '';
    setUrlId(parts);

    return () => {
      setLocalCleanUpData({
        data: { ...distribution, ...initialDistributionState.distributionDetails }
      });
    };
  }, []);

  const setLocalCleanUpData = cleanUpData => {
    dispatch(setOnCleanUp(cleanUpData));
  };

  const setLocalChange = currentState => {
    dispatch(setOnChange(currentState));
  };

  const handleInput = e => {
    setLocalChange({
      ...distribution,
      data: { ...distribution.currentDistribution, [e.target.name]: e.target.value }
    });
  };

  // const handleCheckBoxInput = e => {
  //   let isChecked;
  //   if (e.target.value === 'false') {
  //     isChecked = false;
  //   } else {
  //     isChecked = true;
  //   }
  //   setLocalChange({
  //     ...distribution,
  //     data: { ...distribution.currentDistribution, [e.target.name]: !isChecked }
  //   });
  // };

  // const setErrorMessage = err => {
  //   let errorlist = distribution.currentDistribution.errorList || [];
  //   if (err) {
  //     errorlist.push(err);
  //   } else {
  //     errorlist = [];
  //   }
  //   setLocalChange({
  //     ...distribution,
  //     data: { ...distribution.currentDistribution, errorList: errorlist }
  //   });
  // };
  const clientTab = val => {
    setLocalChange({
      ...distribution,
      currentActiveTab: val
    });
  };
  const handleSubmit = e => {
    if (currentActiveTab === 'general') {
      const payload = {
        dcCode: currentUser.dcCode,
        name: currentUser.name
      };
      if (urlId === '') {
        dispatch(createDistributionCenter(payload));
      } else {
        clientTab('clients');
      }
    } else if (currentActiveTab === 'clients') {
      clientTab('managers');
    } else {
      const payload = {
        Dcid: distribution.distributionId,
        ClientProfileIds: selectedIds,
        DcManagerId: selectedManager,
        Name: currentUser.name,
        dcCode: currentUser.dcCode,
        IsActive: true
      };
      dispatch(updateDistributionCenter(payload));
      cancelHandler();
    }
  };
  const dropDownChange = data => {
    setSelectedManager(data);
  };
  const onClientSelectionHandler = (clientId, value) => {
    setSelectedIds(() => [...selectedIds, clientId]);
    let currentClients = distributionDetails.clients || [];
    let errorList = currentUser.errorList || [];
    const objIndex = currentClients.findIndex(obj => obj.clientProfileId == clientId);
    currentClients[objIndex].isActive = value;

    if (currentClients.length > 0) {
      errorList = [];
    }

    setLocalChange({
      ...distribution,
      data: { ...distribution.currentDistribution, clientUsers: currentClients, errorList: errorList }
    });
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  return (
    <React.Fragment>
      {distribution.redirect ? <Redirect to={backUrl} /> : ''}
      {distribution.isLoading && <LoadingSpinner />}
      {distribution.showMessage && <Notification notificationType={notificationType} message={distribution.message} />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardTitle>
                  <Row className="buttonrow">
                    {urlId !== '' ? <Col md="4">EDIT REMOTE LINEN ROOM</Col> : <Col md="4">ADD REMOTE LINEN ROOM</Col>}
                    <Col md="8">
                      <Nav tabs className="custom-tab nav-justified customTab">
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: currentActiveTab === 'general'
                            })}
                            onClick={() => {
                              clientTab('general');
                            }}
                          >
                            <span className="d-none d-sm-block">
                              <FontAwesomeIcon icon={faHome} /> <span className="pd-left">Basic</span>
                            </span>
                            <span className="d-block d-sm-none">
                              <FontAwesomeIcon icon={faHome} />
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: currentActiveTab === 'clients'
                            })}
                            onClick={() => {
                              clientTab('clients');
                            }}
                            disabled={currentActiveTab === 'general'}
                          >
                            <span className="d-none d-sm-block">
                              <FontAwesomeIcon icon={faSitemap} />
                              <span className="pd-left">Clients</span>
                            </span>
                            <span className="d-block d-sm-none">
                              <FontAwesomeIcon icon={faSitemap} />
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: currentActiveTab === 'managers'
                            })}
                            onClick={() => {
                              clientTab('managers');
                            }}
                            disabled={currentActiveTab !== 'managers'}
                          >
                            <span className="d-none d-sm-block">
                              <FontAwesomeIcon icon={faSitemap} />
                              <span className="pd-left">RLR Managers</span>
                            </span>
                            <span className="d-block d-sm-none">
                              <FontAwesomeIcon icon={faSitemap} />
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                  </Row>
                </CardTitle>
                <CardBody>
                  {currentUser.errorList && currentUser.errorList.length > 0 && <ErrorListing errorList={currentUser.errorList} />}

                  <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                    <TabContent activeTab={currentActiveTab} className="p-3 text-muted">
                      <TabPane tabId="general">
                        <Row>
                          <Col lg="12">
                            <Row>
                              <Col sm="6">
                                <div className="mb-3">
                                  <CustomAvField
                                    name="dcCode"
                                    label="Remote Linen Room Code"
                                    className="form-control"
                                    placeholder="Enter Remote Linen Room Code"
                                    value={currentUser.dcCode}
                                    onChange={handleInput}
                                    type="text"
                                    validate={{
                                      required: { value: true, errorMessage: 'Please enter a valid Remote Linen Room Code' }
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="mb-3">
                                  <CustomAvField
                                    name="name"
                                    label="Remote Linen Room Name"
                                    className="form-control"
                                    placeholder="Enter Remote Linen Room Name"
                                    value={currentUser.name}
                                    onChange={handleInput}
                                    type="text"
                                    validate={{
                                      required: { value: true, errorMessage: 'Please enter a valid Remote Linen Room Name' }
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row></Row>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="clients">
                        <Row>
                          <Col sm="12">
                            <ManageClientDistribution currentUser={currentUser} onClientSelectionHandler={onClientSelectionHandler} />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="managers">
                        <Row>
                          <Col md="4">
                            <div className="mb-3">
                              <Dropdown
                                placeholder="Select Remote Linen Room Manager"
                                label="RLR Managers"
                                name="userFullName"
                                options={dcManagers?.map(p => {
                                  return {
                                    ...p,
                                    label: p.userFullName,
                                    value: p.userId
                                  };
                                })}
                                isMulti={false}
                                onChange={val => {
                                  dropDownChange(val.value);
                                }}
                                validate={{
                                  required: { value: true, errorMessage: 'Please select a client type' }
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                    <div className="d-flex flex-wrap gap-2">
                      {showSaveOption && (
                        <Button
                          type="submit"
                          color="primary"
                          className="btn "
                          disabled={
                            (currentActiveTab === 'managers' && selectedManager === '') ||
                            (currentActiveTab !== 'general' && selectedIds.length === 0)
                          }
                        >
                          SAVE
                        </Button>
                      )}
                      <Button onClick={cancelHandler} color="secondary" className=" ">
                        CANCEL
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageDistributionCenter;
