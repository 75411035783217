import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Table from './DistributionCenterTable';
import Pagination from '../../CustomControls/Pagination';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import { deleteDistributionCenter, getAllDistributionCenters, setOnChange } from '../../../store/actions/Management/distributionCenter';
import { setOnConfirmationModal } from '../../../store/actions/Master/clientProfile';
import CustomConfirmation from '../../CustomControls/ConfirmationModal';
import { useState } from 'react';
// import "./style.scss";

const Users = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const ui = useSelector(state => state.ui);
  const distribution = useSelector(state => state.distribution);
  const permissions = ui.layout.permissions;
  const [show, setShow] = useState(false);
  const [selectedDc, setSelectedDc] = useState('');
  const notificationType = user.isError ? 'error' : 'success';

  const showSaveOption = hasPermissionToAction(permissions, 'users', 'create');
  const showResetPasswordOption = hasPermissionToAction(permissions, 'resetpassword', 'create');
  useEffect(() => {
    dispatch(getAllDistributionCenters());
  }, [dispatch]);
  let distributionList = distribution.distributionList;

  const onPageChangeHandler = currentPage => {};

  const onAddNewHandler = () => {
    history.push('/management/distribution-center/manage-dc');
  };

  const setLocalChange = currentState => {
    dispatch(setOnChange(currentState));
  };
  const clientViewHandler = id => {
    setLocalChange({
      ...distribution,
      distributionId: id
    });
    history.push(`/management/distribution-center/view-client/${id}`);
  };
  const editHandler = id => {
    history.push(`/management/distribution-center/edit-distribution-center/${id}`);
  };

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Remote Linen Room', path: '/management/distribution-center', isCurrent: true }
  ];
  const onDeleteHandler = id => {
    setSelectedDc(id);
    setShow(true);
  };
  const onDeleteConfirmation = () => {
    dispatch(deleteDistributionCenter(selectedDc));
    setShow(!show);
  };

  const onDeleteCancel = () => {
    setShow(!show);
  };

  return (
    <React.Fragment>
      <CustomConfirmation
        show={show}
        onDeleteClick={onDeleteConfirmation}
        onCloseClick={onDeleteCancel}
        message="Are you sure you want to delete this record?"
        header="Deleting record"
      />
      <div className="page-content">
        {distribution.showMessage && <Notification notificationType={notificationType} message={distribution.message} />}
        <Container fluid>
          <Breadcrumbs title="Remote Linen Room" paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        {showSaveOption && (
                          <Button type="button" className="btn btn-primary btn-block " onClick={onAddNewHandler}>
                            ADD REMOTE LINEN ROOM
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="tablerow">
                    <Col xl="12">
                      <div>
                        {distribution.isLoading && <LoadingSpinner />}
                        <Pagination data={distributionList ?? []} pageSize={10} onPageChange={onPageChangeHandler} startingPage={1}>
                          <Table
                            deleteHandler={onDeleteHandler}
                            clientViewHandler={clientViewHandler}
                            showResetPasswordOption={showResetPasswordOption}
                            editHandler={editHandler}
                          />
                        </Pagination>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Users;
