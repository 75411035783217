import {
  PRODUCT_GET_ALL_DATA,
  PRODUCT_GET_DATA,
  PRODUCT_ERROR,
  PRODUCT_IS_LOADING,
  PRODUCT_SAVE_DATA,
  PRODUCT_ON_CHANGE_DATA,
  PRODUCT_CLEAN_UP,
  PRODUCT_DEPARTMENT_SAVE,
  PRODUCT_DEPARTMENT_DELETE,
  PRODUCT_LOCATION_SAVE,
  PRODUCT_LOCATION_DELETE,
  PRODUCT_SUPPLIER_SAVE,
  PRODUCT_SUPPLIER_DELETE,
  PRODUCT_DELETE_DATA,
  PRODUCT_UPDATE_CONFIRMATION
} from '../../../constants/index';
import { initialProductState } from '../initialState';

const productReducer = (state = initialProductState, action) => {
  const { payload, type } = action;
  switch (type) {
    case PRODUCT_ERROR: {
      const { showMessage, errorMessage, errorList } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage,
        errorList: errorList
      };
    }
    case PRODUCT_GET_ALL_DATA: {
      const { resultData } = payload;

      return {
        ...state,
        productList: resultData
      };
    }
    case PRODUCT_GET_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          ...resultData,
          currentDepartment: {
            ...state.currentProduct.currentDepartment,
            price: resultData.standardCharge
          }
        },
        createdId: ''
      };
    }
    case PRODUCT_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }
    case PRODUCT_SAVE_DATA: {
      const { showMessage, message, redirect, createdId } = payload;
      return {
        ...state,
        isError: false,
        redirect: redirect && !createdId,
        showMessage: showMessage,
        createdId: createdId,
        message: message
      };
    }

    case PRODUCT_ON_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          ...data
        }
      };
    }
    case PRODUCT_CLEAN_UP: {
      let { data } = payload;

      return {
        ...state,
        message: '',
        errorList: [],
        isError: false,
        showMessage: false,
        redirect: false,
        showConfirmationModal: false,
        confirmationId: '',
        currentProduct: {
          ...state.currentProduct,
          ...data
        }
      };
    }

    case PRODUCT_DEPARTMENT_SAVE: {
      let { showMessage, message, data } = payload;
      let currentList = state.currentProduct.departments;
      const objIndex = currentList.findIndex(obj => obj.departmentId === data.departmentId);
      if (objIndex > -1) {
        currentList[objIndex].price = data.price;
        currentList[objIndex].orderInPack = data.orderInPack;
      } else {
        currentList.push(data);
      }

      return {
        ...state,
        message: message,
        isError: false,
        showMessage: showMessage,
        currentProduct: {
          ...state.currentProduct,
          departments: currentList,
          currentDepartment: {
            clientProfileId: state.currentProduct.currentDepartment.clientProfileId,
            departmentId: '',
            productId: '',
            departmentName: '',
            price: state.currentProduct.standardCharge,
            orderInPack: false
          }
        }
      };
    }

    case PRODUCT_DEPARTMENT_DELETE: {
      let { showMessage, message, data } = payload;
      let currentList = state.currentProduct.departments;
      const filteredList = currentList.filter(obj => obj.departmentId !== data.departmentId);

      return {
        ...state,
        message: message,
        isError: false,
        showMessage: showMessage,
        currentProduct: {
          ...state.currentProduct,
          departments: filteredList
        }
      };
    }

    case PRODUCT_LOCATION_SAVE: {
      let { showMessage, message, data } = payload;
      let currentList = state.currentProduct.locations;
      const objIndex = currentList.findIndex(obj => obj.locationId === data.locationId);
      if (objIndex > -1) {
        currentList[objIndex].storageLocation = data.storageLocation;
        currentList[objIndex].currentStock = data.currentStock;
      } else {
        currentList.push(data);
      }

      return {
        ...state,
        message: message,
        isError: false,
        showMessage: showMessage,
        currentProduct: {
          ...state.currentProduct,
          locations: currentList,
          currentLocation: {
            locationId: '',
            productId: '',
            locationName: '',
            currentStock: '0',
            storageLocation: ''
          }
        }
      };
    }

    case PRODUCT_LOCATION_DELETE: {
      let { showMessage, message, data } = payload;
      let currentList = state.currentProduct.locations;
      const filteredList = currentList.filter(obj => obj.locationId !== data.locationId);

      return {
        ...state,
        message: message,
        isError: false,
        showMessage: showMessage,
        currentProduct: {
          ...state.currentProduct,
          locations: filteredList
        }
      };
    }

    case PRODUCT_SUPPLIER_SAVE: {
      let { showMessage, message, data } = payload;
      let currentList = state.currentProduct.suppliers;
      const objIndex = currentList.findIndex(obj => obj.supplierId === data.supplierId);
      if (objIndex > -1) {
        currentList[objIndex].packPrice = data.packPrice;
        currentList[objIndex].priorityOrder = data.priorityOrder;
      } else {
        currentList.push(data);
      }

      return {
        ...state,
        message: message,
        isError: false,
        showMessage: showMessage,
        currentProduct: {
          ...state.currentProduct,
          suppliers: currentList,
          currentSupplier: {
            supplierId: '',
            productId: '',
            supplierName: '',
            packPrice: '0',
            priorityOrder: '0'
          }
        }
      };
    }

    case PRODUCT_SUPPLIER_DELETE: {
      let { showMessage, message, data } = payload;
      let currentList = state.currentProduct.suppliers;
      const filteredList = currentList.filter(obj => obj.supplierId !== data.supplierId);

      return {
        ...state,
        message: message,
        isError: false,
        showMessage: showMessage,
        currentProduct: {
          ...state.currentProduct,
          suppliers: filteredList
        }
      };
    }
    case PRODUCT_DELETE_DATA: {
      const { showMessage, message, redirect } = payload;
      return {
        ...state,
        isError: false,
        redirect: false,
        showMessage: showMessage,
        message: message,
        showConfirmationModal: false,
        confirmationId: ''
      };
    }

    case PRODUCT_UPDATE_CONFIRMATION: {
      let { data } = payload;

      return {
        ...state,
        ...data
      };
    }

    default:
      return state;
  }
};

export default productReducer;
