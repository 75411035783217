import React from 'react';
import { Table } from 'reactstrap';

const ViewClientTable = props => {
  return (
    <div>
      <Table className="table" responsive>
        <thead>
          <tr>
            <th>Client Code</th>
            <th>Client Name</th>
          </tr>
        </thead>
        <tbody>
          {props?.paginatedData?.map(n => {
            return (
              <tr key={n.clientProfileId}>
                <td>{n.clientCode || ''}</td>
                <td>{n.clientName || ''}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default ViewClientTable;
