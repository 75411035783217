import { PRODUCTTYPE_GET_ALL_DATA, PRODUCTTYPE_GET_DATA, PRODUCTTYPE_ERROR, PRODUCTTYPE_IS_LOADING, PRODUCTTYPE_SAVE_DATA, PRODUCTTYPE_ON_CHANGE_DATA, PRODUCTTYPE_CLEAN_UP,PRODUCTTYPE_DELETE_DATA, PRODUCTTYPE_UPDATE_CONFIRMATION } from '../../../constants/index';
import { apiHelper } from '../../../utils/index';
import Cookies from 'js-cookie';
import { setErrorThunk, setLoadingStatus, setGetResults, setDescribeData, setSaveData, setOnChangeData, setOnCleanUpData,setDeleteData,setOnConfirmationData } from '../actionHelper';

const API_URL = '/producttype'

export const getAllProductTypes = () => dispatch => {
    dispatch(_getAllProductTypes());
};

const _getAllProductTypes = () =>
    apiHelper({
        url: `${API_URL}`,
        method: 'GET',
        onLoad: status => setLoadingStatus(PRODUCTTYPE_IS_LOADING, status),
        onSuccess: result => setGetResults(PRODUCTTYPE_GET_ALL_DATA, result),
        onFailure: err => setErrorThunk(PRODUCTTYPE_ERROR, true, err),
        accessToken: Cookies.get('accessToken'),
    });

export const describeProductType = (id) => dispatch => {
    dispatch(_getProductType(id));
};

const _getProductType = (id) =>
    apiHelper({
        url: `${API_URL}/${id}`,
        method: 'GET',
        data: {
            id: id
        },
        onLoad: status => setLoadingStatus(PRODUCTTYPE_IS_LOADING, status),
        onSuccess: result => setDescribeData(PRODUCTTYPE_GET_DATA, result),
        onFailure: err => setErrorThunk(PRODUCTTYPE_ERROR, true, err),
        accessToken: Cookies.get('accessToken'),
    });

export const saveProductType = (saveData, isNew, id = null) => dispatch => {
    let MethodType = 'POST';
    if (!isNew) {
        MethodType = 'PUT'
    }

    let apiUrl = id ? `${API_URL}/${id}` : API_URL;

    dispatch(_saveProductType(saveData, MethodType, apiUrl));
}

const _saveProductType = (data, MethodType, apiUrl) =>
    apiHelper({
        url: apiUrl,
        method: MethodType,
        onSuccess: result => setSaveData(PRODUCTTYPE_SAVE_DATA, true, result),
        onFailure: err => setErrorThunk(PRODUCTTYPE_ERROR, true, err),
        onLoad: status => setLoadingStatus(PRODUCTTYPE_IS_LOADING, status),
        data,
        accessToken: Cookies.get('accessToken'),
    });


export const setOnChange = retData => dispatch => {
    dispatch(setOnChangeData(PRODUCTTYPE_ON_CHANGE_DATA, retData));
};

export const setOnCleanUp = retData => dispatch => {
    dispatch(setOnCleanUpData(PRODUCTTYPE_CLEAN_UP, retData));
};



// DELETE OPERATION
export const deleteProductType = (id) => dispatch => {
    let MethodType = 'DELETE';
    let apiUrl = id ? `${API_URL}/${id}` : API_URL;

    dispatch(_deleteProductType(MethodType, apiUrl));
    
}

const _deleteProductType = (MethodType, apiUrl) =>
    apiHelper({
        url: apiUrl,
        method: MethodType,
        onSuccess: result => onDeleteSuccess(result),
        onFailure: err => setErrorThunk(PRODUCTTYPE_ERROR, true, err),
        onLoad: status => setLoadingStatus(PRODUCTTYPE_IS_LOADING, status),
        accessToken: Cookies.get('accessToken'),
    });

export const setOnConfirmationModal = retData => dispatch => {
    dispatch(setOnConfirmationData(PRODUCTTYPE_UPDATE_CONFIRMATION, retData));
};


export const onDeleteSuccess = (result) => dispatch => {
    dispatch(setDeleteData(PRODUCTTYPE_DELETE_DATA, true, result));
    dispatch(_getAllProductTypes());
};
