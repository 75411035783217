import React from 'react';
import { AvField } from 'availity-reactstrap-validation';

const CustomAvField = ({ label, ...props }) => {
  if (props.required || (props.validate && props.validate.required.value)) {
    label = label ? (
      <>
        {label}
        <sup>*</sup>
      </>
    ) : null;
  }
  return <AvField label={label} {...props} />;
};

export default CustomAvField;
