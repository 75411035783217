import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle, Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { describeUser } from '../../../store/actions/user';
import { AvForm } from 'availity-reactstrap-validation';
import { initialDistributionState } from '../../../store/reducers/initialState';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import Dropdown from '../../CustomControls/Dropdown';
import { IS_NEW } from '../../../constants';
import classnames from 'classnames';
import { _create, _pricing, _update, _users } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import CustomAvField from '../../CustomControls/CustomAvField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSitemap } from '@fortawesome/free-solid-svg-icons';
import ErrorListing from '../../CustomControls/ErrorListing';
import { createDistributionCenter, updateDistributionCenter } from '../../../store/actions/Management/distributionCenter';
import { setOnChange, setOnCleanUp, updatePriceCenter } from '../../../store/actions/Master/pricing';

const EditPrice = props => {
  let history = useHistory();
  const backUrl = '/master/pricing';
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const distribution = useSelector(state => state.distribution);
  const pricing = useSelector(state => state.pricing);
  const currentActiveTab = useSelector(state => state.distribution.currentActiveTab);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  const [urlId, setUrlId] = useState('');

  const [selectedIds, setSelectedIds] = useState([]);
  let IsEdit = false;
  let { userid } = useParams();
  const notificationType = user.isError ? 'error' : 'success';
  // const [currentActiveTab, setCurrentActiveTab] = useState('general');
  const [selectedManager, setSelectedManager] = useState('');
  const showSaveOption = hasPermissionToAction(permissions, _pricing, _update);

  let currentUser = distribution.currentDistribution;
  let distributionDetails = distribution.distributionDetails;
  let dcManagers = distributionDetails.dcManagers;
  //   let localPath = '/management/distribution-center/manage-dc';
  let heading = 'EDIT PRICING DETAILS';

  //   if (userid) {
  //     IsEdit = true;
  //     heading = 'Edit User';
  //     localPath = localPath + `/${userid}`;
  //   }

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Pricing', path: '/master/pricing' },
    { id: 3, title: 'Edit Pricing', path: '/master/pricing/edit-pricing', isCurrent: true }
  ];

  //clean up
  useEffect(() => {
    const parts = history.location.pathname.split('/')[4] ?? '';
    setUrlId(parts);

    return () => {
      setLocalCleanUpData({
        selectedIds: []
      });
    };
  }, []);

  const setLocalCleanUpData = cleanUpData => {
    dispatch(setOnCleanUp(cleanUpData));
  };

  const setLocalChange = currentState => {
    dispatch(setOnChange(currentState));
  };

  const handleInput = e => {
    setLocalChange({
      ...pricing,
      data: { [e?.target?.name ?? 'priceType']: e?.target?.value ?? e.value }
    });
  };

  const clientTab = val => {
    setLocalChange({
      ...distribution,
      currentActiveTab: val
    });
  };
  const handleSubmit = e => {
    const payload = {
      ClientProfileIds: pricing.selectedIds,
      Amount: pricing.editClient.price,
      Type: pricing.editClient.priceType
    };
    dispatch(updatePriceCenter(payload));
    history.push('/master/pricing');
  };
  const dropDownChange = data => {
    setSelectedManager(data);
  };
  const onClientSelectionHandler = (clientId, value) => {
    setSelectedIds(() => [...selectedIds, clientId]);
    let currentClients = distributionDetails.clients || [];
    let errorList = currentUser.errorList || [];
    const objIndex = currentClients.findIndex(obj => obj.clientProfileId == clientId);
    currentClients[objIndex].isActive = value;

    if (currentClients.length > 0) {
      errorList = [];
    }

    setLocalChange({
      ...distribution,
      data: { ...distribution.currentDistribution, clientUsers: currentClients, errorList: errorList }
    });
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  return (
    <React.Fragment>
      {distribution.redirect ? <Redirect to={backUrl} /> : ''}
      {distribution.isLoading && <LoadingSpinner />}
      {distribution.showMessage && <Notification notificationType={notificationType} message={distribution.message} />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardTitle>
                  <Row className="buttonrow">
                    <Col md="6">EDIT PRICING DETAILS</Col>
                  </Row>
                </CardTitle>
                <CardBody>
                  {currentUser.errorList && currentUser.errorList.length > 0 && <ErrorListing errorList={currentUser.errorList} />}

                  <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                    <TabContent activeTab={currentActiveTab} className="p-3 text-muted">
                      <Row>
                        <Col lg="12">
                          <Row>
                            <Col md="4">
                              <div className="mb-3">
                                <Dropdown
                                  placeholder="Select Price Type"
                                  label="Price Type"
                                  name="priceType"
                                  options={[
                                    {
                                      label: 'Select Price Type'
                                    },
                                    {
                                      label: 'Fixed',
                                      value: 'Fixed'
                                    },
                                    {
                                      label: 'Percentage',
                                      value: 'Percentage'
                                    }
                                  ]}
                                  isMulti={false}
                                  onChange={event => {
                                    handleInput(event);
                                  }}
                                  validate={{
                                    required: { value: true, errorMessage: 'Please select a price type' }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="mb-3">
                                <CustomAvField
                                  name="price"
                                  label="Price"
                                  className="form-control"
                                  placeholder="Enter Price"
                                  value={pricing.editClient.price}
                                  onChange={handleInput}
                                  type="number"
                                  validate={{
                                    required: { value: true, errorMessage: 'Please enter a valid price' }
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row></Row>
                        </Col>
                      </Row>
                    </TabContent>
                    <div className="d-flex flex-wrap gap-2">
                      {showSaveOption && (
                        <Button
                          type="submit"
                          color="primary"
                          className="btn "
                          disabled={pricing.editClient.priceType === '' || pricing.editClient.priceType === 'Select Price Type'}
                        >
                          SAVE
                        </Button>
                      )}
                      <Button onClick={cancelHandler} color="secondary" className=" ">
                        CANCEL
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditPrice;
