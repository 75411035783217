import {
  USER_GET_ALL_DATA,
  USER_GET_DATA,
  USER_ERROR,
  USER_IS_LOADING,
  USER_SAVE_DATA,
  USER_ON_CHANGE_DATA,
  USER_CHANGED_PASSWORD,
  USER_CLEAN_UP,
  USER_ALL_DETAILS
} from '../../constants/index';
import { initialUserState } from './initialState';

const userReducer = (state = initialUserState, action) => {
  const { payload, type } = action;
  switch (type) {
    case USER_ERROR: {
      const { showMessage, errorMessage } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage
      };
    }
    case USER_GET_ALL_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        userList: resultData
      };
    }
    case USER_ALL_DETAILS: {
      const { resultData } = payload;
      return {
        ...state,
        userDetails: resultData
      };
    }
    case USER_GET_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        errorList: [],
        currentUser: {
          ...state.currentUser,
          ...resultData,
          errorList: []
        }
      };
    }
    case USER_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading,
        message: ''
      };
    }
    case USER_SAVE_DATA: {
      const { showMessage, message, redirect } = payload;
      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message
      };
    }

    case USER_ON_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...data
        }
      };
    }

    case USER_CHANGED_PASSWORD: {
      const { showMessage, message, redirect } = payload;
      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message
      };
    }
    case USER_CLEAN_UP: {
      let { data } = payload;

      return {
        ...state,
        message: '',
        isError: false,
        showMessage: false,
        redirect: false,
        currentUser: {
          ...state.currentUser,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default userReducer;
