import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { getReportCreditNote } from '../../../store/actions/Operation/creditNote';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

const CreditNoteReport = props => {
  let history = useHistory();
  const dispatch = useDispatch();
  let { creditnoteid } = useParams();
  const backUrl = '/operation/credit-notes';
  const creditNote = useSelector(state => state.creditNote);
  let localPath = `/operation/credit-notes/creditNote-report/${creditnoteid}`;
  let heading = 'Credit Note Report';
  let reportData = creditNote.creditNoteReport;

  const imageResult = window.localStorage.getItem('lmsCompanyLogo');

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Credit Note Listing', path: '/operation/credit-notes' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  useEffect(() => {
    if (creditnoteid) {
      dispatch(getReportCreditNote(creditnoteid));
    }
  }, [dispatch, creditnoteid]);

  const printInvoice = () => {
    window.print();
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          {!isEmpty(reportData) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="report-container">
                      <div className="report-title">
                        <h4 className="float-end font-size-16">Credit Note</h4>
                        <div>{imageResult ? <img src={imageResult} alt="logo" height="45" /> : null}</div>
                      </div>
                      <div id="content">
                        <Row>
                          <Col xs="5">
                            <address>
                              <b>{reportData.clientProfileName}</b>
                              <br />
                              <span>
                                {reportData.clientBillingStreetAddress}, {reportData.clientBillingSuburb}, {reportData.clientBillingState},{' '}
                                {reportData.clientBillingPostCode}
                              </span>
                              <br />
                              <span>{reportData.clientPrimaryContactEmail}</span>
                              <br />
                              <span>{reportData.clientPrimaryContactPhone}</span>
                            </address>
                          </Col>
                          <Col xs="7" className="text-end">
                            <address>
                              <Row>
                                <Col xs="3"></Col>
                                <Col xs="5" className="text-end">
                                  CreditNote # :
                                </Col>
                                <Col xs="4" className="text-end">
                                  {reportData.creditNoteNo}
                                </Col>
                                <Col xs="3"></Col>
                                <Col xs="5" className="text-end">
                                  Date :
                                </Col>
                                <Col xs="4" className="text-end">
                                  {reportData.creditNoteDate}
                                </Col>
                              </Row>
                            </address>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6" className="mt-3">
                            <address>
                              <b>Request From:</b>
                              <br />
                              <span>{reportData.departmentName}</span>
                              <br />
                              <span>
                                {reportData.departmentAddress}, {reportData.departmentSuburb}, {reportData.departmentState},{' '}
                                {reportData.departmentPostCode}{' '}
                              </span>
                            </address>
                          </Col>
                          <Col xs="6" className="mt-3 text-end"></Col>
                        </Row>
                        <Row>
                          <Col xs="6" className="mt-4">
                            Credit amount of <b> ${reportData.amount}</b>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6" className="mt-4 mb-4">
                            Remarks : {reportData.remarks}
                          </Col>
                        </Row>
                      </div>
                      <div className="d-print-none">
                        <div className="float-start">
                          <Link to="#" onClick={printInvoice} className="btn btn-outline-primary">
                            <FontAwesomeIcon className="standardIcon" icon={faPrint} /> Print
                          </Link>
                          <Link to="#" onClick={cancelHandler} className="btn btn-secondary w-md button-space">
                            Back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreditNoteReport;
