import React from 'react';
import './AppLoading.scss';

const AppLoading = () => <Apploader />;
export default AppLoading;

const Apploader = () => {
  return (
    <div className="loadingScreen">
      <div className='loadingcontainer'>
        <div className='block'>
          <div className='item'></div>
          <div className='item'></div>
          <div className='item'></div>
          <div className='item'></div>
          <div className='item'></div>
          <div className='item'></div>
          <div className='item'></div>
          <div className='item'></div>
        </div>
      </div>
    </div>
  );
};
