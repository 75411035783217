import { REQUISITION_GET_ALL_DATA, REQUISITION_GET_DATA, REQUISITION_ERROR, REQUISITION_IS_LOADING, REQUISITION_SAVE_DATA, REQUISITION_ON_CHANGE_DATA, REQUISITION_CLEAN_UP,REQUISITION_ON_SEARCH_CHANGE_DATA, REQUISITION_GET_PRODUCTS_DEPT, REQUISITION_GET_PRODUCTS_AND_DEPT_CLIENT, REQUISITION_GET_ONLINE_REPORT,REQUISITION_DELETE_DATA, REQUISITION_UPDATE_CONFIRMATION }  from '../../../constants/index';
import { initialRequisitionState } from '../initialState';

const requisitionReducer = (state = initialRequisitionState, action) => {
  const { payload, type } = action;
  switch (type) {
    case REQUISITION_ERROR: {
      const { showMessage, errorMessage, errorList } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage,
        errorList: errorList
      };
    }
    case REQUISITION_GET_ALL_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        requisitionList: resultData.data,
        totalRecords : resultData.totalRecords,
        filterOptions: {
            ...state.filterOptions,
            pageNumber : resultData.pageNumber,
            pageSize  : resultData.pageSize,
          }
      };
    }

    case REQUISITION_GET_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        currentRequisition: {
          ...state.currentRequisition,
          ...resultData
        }
      };
    }
    case REQUISITION_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading,
        showMessage: false
      };
    }
    case REQUISITION_SAVE_DATA: {
      //As per require we do not redirect in steam preserve some of the data
      let { showMessage, message, redirect, misc } = payload;

      let mergeObj = {};
      redirect = true;

      if(misc && misc.repeatReq){

          mergeObj =  {
            currentRequisition: {
              ...state.currentRequisition,
              requisitionId: '',
              requisitionRef: '',
              isDispatched: false,
              requisitionDetails: []
            }
          };
          redirect = false;
      }
      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message,
        ...mergeObj
      };
    }

    case REQUISITION_ON_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        currentRequisition: {
          ...state.currentRequisition,
          ...data
        }
      };
    }
    case REQUISITION_CLEAN_UP: {
      let { data } = payload;

      return {
        ...state,
        message: '',
        errorList: [],
        isError: false,
        showMessage: false,
        redirect: false,
        showConfirmationModal: false,
        confirmationId : '',
        currentRequisition: {
          ...state.currentRequisition,
          ...data
        }
      };
    }

    case REQUISITION_GET_PRODUCTS_DEPT: {
      const { resultData } = payload;
    

      return {
        ...state,
        currentRequisition: {
          ...state.currentRequisition,
          requisitionDetails : [],
          productSelectList : resultData
        }
      };
    }
    case REQUISITION_GET_PRODUCTS_AND_DEPT_CLIENT: {
      const { resultData } = payload;
      return {
        ...state,
        currentRequisition: {
          ...state.currentRequisition,
          requisitionDetails : [],
          ...resultData
        }
      };
    }
    
    case REQUISITION_ON_SEARCH_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          ...data
        }
      };
    }

    case REQUISITION_GET_ONLINE_REPORT: {
      const { resultData } = payload;
      return {
        ...state,
        requisitionReport: {
          ...state.requisitionReport,
          ...resultData
        }
      };
    }
    case REQUISITION_DELETE_DATA: {
      const { showMessage, message, redirect } = payload;
      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message,
        showConfirmationModal: false,
        confirmationId : ''
      };
    }

    case REQUISITION_UPDATE_CONFIRMATION: {
      let { data } = payload;

      return {
        ...state,
        ...data
      };
    }


    default:
      return state;
  }
};

export default requisitionReducer;




