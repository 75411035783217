import React from "react"
import { Table } from "reactstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPrint,faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import "./style.scss";

const CreditNoteTable = ({ paginatedData, editHandler, printHandler, showPrint, deleteHandler, showDelete }) => {


    return (
        <React.Fragment>
            <div>
                <Table className="table" responsive>
                    <thead>
                        <tr>
                            <th>Credit Note No</th>
                            <th>Client Name</th>
                            <th>Department Name</th>
                            <th>Credit Note Date</th>
                            <th>Amount</th>
                            <th>View</th>
                            {showDelete && <th className="col-sm-1">Delete</th>}
                            {showPrint && <th className="col-sm-1">Print</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map(n => {
                            return (
                                <tr key={n.creditNoteId}>
                                    <td>{n.creditNoteNo || ''}</td>
                                    <td>{n.clientProfileName || ''}</td>
                                    <td>{n.departmentName || ''}</td>
                                    <td>{moment(new Date(n.creditNoteDate)).format("DD-MMM-YYYY") || ''}</td>
                                    <td>{n.amount || ''}</td>
                                    {<td><button className="btn tableIcons" onClick={() => editHandler(n.creditNoteId)} > <FontAwesomeIcon icon={faPencilAlt} /></button></td>}
                                    {showDelete && <td><button className="btn tableIcons" onClick={() => deleteHandler(n.creditNoteId)} > <FontAwesomeIcon icon={faTrashAlt} /></button></td>}
                                    {showPrint && <td><button className="btn tableIcons" onClick={() => printHandler(n.creditNoteId)} > <FontAwesomeIcon icon={faPrint} /></button></td>}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

            </div>
        </React.Fragment>
    )
}


export default CreditNoteTable;