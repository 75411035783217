import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, Col, Row } from 'reactstrap';
import Pagination from '../../CustomControls/Pagination';
import SearchBar from '../../CustomControls/SearchBar';
import { _create, _groups } from '../../../constants/componentConstants';

const ManageClientUsers = props => {
  const [searchText, setSearchText] = useState('');
  const [results, setResults] = useState([]);
  const ui = useSelector(state => state.ui);
  const pageSize = ui.layout.pageSize;
  let currentUser = props.currentUser;

  useEffect(() => {
    if (searchText.length > 0) {
      setResults(
        currentUser.clientUsers.filter(
          x =>
            x.clientProfileName.toLowerCase().includes(searchText.toLowerCase()) ||
            x.clientCode.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setResults(currentUser.clientUsers);
    }
  }, [searchText, setResults, currentUser.clientUsers]);

  const onPageChangeHandler = currentPage => {};

  const onCheckboxHandler = (e, id) => {
    props.onClientSelectionHandler(id, e.target.checked);
  };

  const handleInput = e => {
    setSearchText(e.target.value);
  };

  const heading = currentUser.firstname
    ? ` Clients assigned to ${currentUser.firstname || ''} ${currentUser.lastname || ''}`
    : 'Clients assigned to the new user';

  return (
    <React.Fragment>
      <Row>
        <Col sm="8">
          <h5> {heading}</h5>
        </Col>
      </Row>
      <Row className="mb-2 buttonrow">
        <Col sm="4">
          <SearchBar onChange={handleInput} placeholder="Search" value={searchText} />
        </Col>
        <Col sm="8"></Col>
      </Row>

      <Pagination data={results} pageSize={pageSize} onPageChange={onPageChangeHandler} startingPage={1}>
        <UserTable onCheckboxHandler={onCheckboxHandler} />
      </Pagination>
    </React.Fragment>
  );
};

export default ManageClientUsers;

const UserTable = ({ paginatedData, onCheckboxHandler }) => {
  return (
    <React.Fragment>
      <div>
        <Table className="table" responsive>
          <thead>
            <tr>
              <th>Select</th>
              <th>Client Code</th>
              <th>Client Name</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData &&
              paginatedData.map(n => {
                return (
                  <tr key={n.clientProfileId}>
                    <td>
                      <input
                        type="checkbox"
                        value={n.isActive}
                        checked={n.isActive}
                        onChange={e => onCheckboxHandler(e, n.clientProfileId)}
                      />
                    </td>
                    <td>{`${n.clientCode}`}</td>
                    <td>{`${n.clientProfileName}`}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};
