import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Table from './OrderTable';
import Pagination from '../../CustomControls/Pagination';
import Dropdown from '../../CustomControls/Dropdown';
import {
  getAllOrdersDetails,
  setOnSearchChange,
  setOnConfirmationModal,
  setAllOrderDetails,
  setApproveOrder,
  setOrderDelete,
  getAllOrdersDetailsCSV,
  getAllOrdersDetailsPDF,
  rejectOrdersDetails,
  getAllCustomerDeparmentsWithId,
  setOnChange
} from '../../../store/actions/orderDetail';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _reportprint, _requisition, _delete } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import { _clientadmin, _view } from '../../../constants/componentConstants';

import { addDaysToDate } from '../../../utils';
import SearchBar from '../../CustomControls/SearchBar';
import DatePicker from '../../CustomControls/CustomDatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import CustomConfirmation from '../../CustomControls/ConfirmationModal';
import RejectionModal from '../../CustomControls/RejectionModal';
import './style.scss';
import { getCustomersName } from '../../../store/actions/customerDetail';
import { useRef } from 'react';

const OrderDetail = props => {
  const status = [
    {
      value: 'all',
      label: 'All'
    },
    {
      value: 'Pending',
      label: 'Pending'
    },
    {
      value: 'Accepted',
      label: 'Accepted'
    },
    {
      value: 'Delivered',
      label: 'Delivered'
    },
    {
      value: 'Rejected',
      label: 'Rejected'
    },
  ];
  const [searchText, setSearchText] = useState('');
  const [searchFromDate, setSearchFromDate] = useState(addDaysToDate(new Date(), -30));
  const [searchToDate, setSearchToDate] = useState(new Date());
  const history = useHistory();
  const dispatch = useDispatch();
  const orders = useSelector(state => state.orderDetail?.orders || []);
  const order = useSelector(state => state.orderDetail || []);
  // const csvData = useSelector(state => state.orderDetail?.csvData || []);
  const customer = useSelector(state => state.customerDetail?.customerDetailsByName);
  const ui = useSelector(state => state.ui);
  const allData = useSelector(state => state);
  const permissions = ui.layout.permissions;
  const pageSize = ui.layout.pageSize;
  const currentPageNumber = order?.filterOptions?.pageNumber;

  const notificationType = orders?.isError ? 'error' : 'success';
  const showPrintOption = hasPermissionToAction(permissions, _requisition, _reportprint);
  const showDeleteOption = hasPermissionToAction(permissions, _requisition, _delete);
  const [showModal, setShowModal] = useState(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [orderID, setOrderID] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [departmentData, setDepartmentData] = useState('all');
  const [statusData, setStatusData] = useState();
  const [stateHandler, setStateHandler] = useState(false);
  // const dropRef = useRef(null);

  // selectedUser
  // const [customerData,setCustomerData ] = useState();
  const [reason, setReason] = useState('');
  useEffect(() => {
    dispatch(getAllOrdersDetails(currentPageNumber, pageSize, order?.filterOptions?.filterBy, order?.filterOptions?.sortBy));
  }, [dispatch, currentPageNumber, pageSize]);
  // useEffect(()=>{
  //   setLocalChangeOrderDetails()
  // },[order])
  async function approveHandler(id, status) {
    await dispatch(setApproveOrder(id, status));
    setTimeout(() => {
      dispatch(getAllOrdersDetails(currentPageNumber, pageSize, order?.filterOptions?.filterBy, order?.filterOptions?.sortBy));
    }, 2000);
  }

  const onPageChangeHandler = currentPage => {
    setLocalChange({
      ...order,
      data: { ...order?.filterOptions, pageNumber: currentPage }
    });
  };

  const onAddNewHandler = () => {
    history.push('/operation/requisitions/requisition');
  };

  const onEditHandler = id => {
    dispatch(setAllOrderDetails(id));
    // http://localhost:58646/customer/order-details?orderId=709250c1-5e7b-46c7-9e26-b9fdddc682d4
    history.push(`/customer/order-info/admin`);
  };

  const onRepeatHandler = id => {
    history.push(`/operation/requisitions/requisition/1/${id}`);
  };

  const onDispatchHandler = id => {
    history.push(`/operation/dispatches/dispatch/1/${id}`);
  };

  const onPrintHandler = id => {
    history.push(`/operation/requisitions/requisition-report/${id}`);
  };
  const dropDownChange = data => {
    setStateHandler(true);
    setDepartmentData("all")
    setLocalChangeOrderDetails({
      ...order,
      data: []
    });
    setSelectedUser(data.value);
    dispatch(getAllCustomerDeparmentsWithId(data.value));
    dispatch(getAllOrdersDetails(currentPageNumber, pageSize, order?.filterOptions?.filterBy, order?.filterOptions?.sortBy, data.value, "all", statusData));
    // setLocalChange({
    //   ...orders,
    //   data: { ...orders.currentClientProfile, [field]: selectedOption.value }
    // });
  };
  const dropDownChangeDepartment = data => {
    setStateHandler(false);
    setDepartmentData(data);
    dispatch(
      getAllOrdersDetails(
        currentPageNumber,
        pageSize,
        order?.filterOptions?.filterBy,
        order?.filterOptions?.sortBy,
        selectedUser,
        data,
        statusData
      )
    );
  };
  const dropDownChangeStatus = data => {
    setStatusData(data);
    dispatch(
      getAllOrdersDetails(
        currentPageNumber,
        pageSize,
        order?.filterOptions?.filterBy,
        order?.filterOptions?.sortBy,
        selectedUser,
        departmentData,
        data
      )
    );
  };
  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Order Listing', path: '/order', isCurrent: true }
  ];

  useEffect(() => {
    return () => {
      let filterListCurrent = order?.filterOptions?.filterBy || [];
      if (filterListCurrent.length > 0) {
        filterListCurrent = filterListCurrent.filter(x => x.name.toLowerCase() !== 'requisitionref');
      }
      setLocalChange({
        ...order,
        data: { ...order?.filterOptions, filterBy: filterListCurrent }
      });
    };
  }, []);

  const handleInput = e => {
    const searchVal = e.target.value ?? '';

    setSearchText(searchVal);
    let filterSearchList = order?.filterOptions?.filterBy || [];

    const objIndex = filterSearchList.findIndex(x => x.name.toLowerCase() === 'requisitionref');

    if (objIndex !== -1) {
      filterSearchList[objIndex].value = searchVal.toLowerCase();
    } else {
      filterSearchList.push({ name: 'requisitionref', value: searchVal.toLowerCase() });
    }
  };
  const handleDateInput = (field, dateVal) => {
    const setDate = dateVal ? new Date(dateVal) : null;
    if (field === 'fromdate') {
      setSearchFromDate(setDate);
    }

    if (field === 'todate') {
      setSearchToDate(setDate);
    }

    let filterSearchList = order?.filterOptions?.filterBy || [];

    const objIndex = filterSearchList.findIndex(x => x.name.toLowerCase() === field);

    if (objIndex !== -1) {
      filterSearchList[objIndex].value = setDate.toDateString();
    } else {
      filterSearchList.push({ name: field, value: setDate.toDateString() });
    }

    setLocalChange({
      ...order,
      data: { ...order?.filterOptions, filterBy: filterSearchList }
    });
  };

  const setLocalChange = currentState => {
    dispatch(setOnSearchChange(currentState));
  };
  const setLocalChangeOrderDetails = currentState => {
    dispatch(setOnChange(currentState));
  };

  const onSearchHandler = e => {
    dispatch(
      getAllOrdersDetails(
        1,
        pageSize,
        order?.filterOptions?.filterBy,
        order?.filterOptions?.sortBy,
        selectedUser,
        departmentData,
        statusData
      )
    );
  };

  const onDeleteHandler = id => {
    setShowModal(true);
    setOrderID(id);
    // dispatch(
    //   setOnConfirmationModal({
    //     data: {
    //       showConfirmationModal: true,
    //       confirmationId: id
    //     }
    //   })
    // );
  };
  const onRejectionHandler = id => {
    setShowRejectionModal(true);
    setOrderID(id);
  };
  useEffect(() => {
    dispatch(getCustomersName());
  }, [dispatch]);

  function downloadCSV() {
    dispatch(
      getAllOrdersDetailsCSV(currentPageNumber, pageSize, order?.filterOptions?.filterBy, order?.filterOptions?.sortBy, selectedUser, departmentData, statusData)
    );
  }
  function downloadPDF() {
    dispatch(
      getAllOrdersDetailsPDF(currentPageNumber, pageSize, order?.filterOptions?.filterBy, order?.filterOptions?.sortBy, selectedUser, departmentData, statusData)
    );
  }
  const onDeleteConfirmation = async () => {
    setShowModal(false);
    dispatch(setOrderDelete(orderID));
    setTimeout(() => {
      dispatch(
        getAllOrdersDetails(1, pageSize, order?.filterOptions?.filterBy, order?.filterOptions?.sortBy, selectedUser, departmentData, statusData)
      );
    }, 1500);
  };
  const onRejectConfirmation = async () => {
    setShowRejectionModal(false);
    dispatch(rejectOrdersDetails(orderID, reason));
    setTimeout(() => {
      dispatch(getAllOrdersDetails(1, pageSize, order?.filterOptions?.filterBy, order?.filterOptions?.sortBy));
    }, 1500);
  };

  const onDeleteCancel = () => {
    setShowModal(false);
    setOrderID(null);
    // dispatch(
    //   setOnConfirmationModal({
    //     data: {
    //       showConfirmationModal: false,
    //       confirmationId: ''
    //     }
    //   })
    // );
  };
  const onRejectionCancel = () => {
    setShowRejectionModal(false);
    setOrderID(null);
  };
  return (
    <React.Fragment>
      <CustomConfirmation
        show={showModal}
        onDeleteClick={onDeleteConfirmation}
        onCloseClick={onDeleteCancel}
        message="Are you sure you want to delete this record?"
        header="Deleting record"
      />
      <RejectionModal
        show={showRejectionModal}
        setReason={setReason}
        onRejectClick={onRejectConfirmation}
        onCloseClick={onRejectionCancel}
        message="Are you sure you want to reject this record?"
        header="Rejecting record"
      />
      <div className="page-content">
        {order?.showMessage && <Notification notificationType={notificationType} message={order?.message} />}
        <Container fluid>
          <Breadcrumbs title="Order Listing " paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="4">
                      <SearchBar onChange={handleInput} placeholder="Search By Order No" value={searchText} />
                    </Col>
                    <Col sm="2">
                      <div className="mb-4">
                        <DatePicker
                          label="From Date"
                          selected={searchFromDate}
                          onChange={date => handleDateInput('fromdate', date)}
                          dateFormat="dd-MMM-yyyy"
                        />
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="mb-4">
                        <DatePicker
                          label="To Date"
                          selected={searchToDate}
                          onChange={date => handleDateInput('todate', date)}
                          dateFormat="dd-MMM-yyyy"
                          minDate={searchFromDate}
                        />
                      </div>
                    </Col>
                    <Col sm="4" className="m-auto">
                      <div className="text-sm-start">
                        <Button type="button" className="btn btn-outline-primary" onClick={onSearchHandler}>
                          <FontAwesomeIcon className="standardIcon" icon={faSearch} /> Search
                        </Button>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        {hasPermissionToAction(permissions, _clientadmin, _view) ? (
                          <Dropdown
                            placeholder="Select User"
                            label="Users"
                            name="customerName"
                            options={customer}
                            isMulti={false}
                            onChange={val => dropDownChange(val)}
                            validate={{
                              required: { value: true, errorMessage: 'Please select a Customer' }
                            }}
                          />
                        ) : (
                          <Dropdown
                            placeholder="Select Customer"
                            label="Customers"
                            name="customerName"
                            options={customer}
                            isMulti={false}
                            onChange={val => dropDownChange(val)}
                            validate={{
                              required: { value: true, errorMessage: 'Please select a Customer' }
                            }}
                          />
                        )}

                        {/* <Dropdown
                          placeholder="Select Customer"
                          label="Customers"
                          name="customerName"
                          options={customer}
                          isMulti={false}
                          onChange={val => dropDownChange(val)}
                          validate={{
                            required: { value: true, errorMessage: 'Please select a Customer' }
                          }}
                        /> */}
                      </div>
                    </Col>
                    {stateHandler ? (
                      <Col md="4">
                        <div className="mb-3">
                          <Dropdown
                            placeholder="Select Department"
                            label="Departments"
                            name="departmentName"
                            options={order.departmentDetails}
                            value={order?.departmentDetails?.[0]}
                            isMulti={false}
                            onChange={val => dropDownChangeDepartment(val.value)}
                            validate={{
                              required: { value: true, errorMessage: 'Please select a Department' }
                            }}
                          />
                        </div>
                      </Col>
                    ) : (
                      <Col md="4">
                        <div className="mb-3">
                          <Dropdown
                            placeholder="Select Department"
                            label="Departments"
                            name="departmentName"
                            options={order.departmentDetails}
                            isMulti={false}
                            onChange={val => dropDownChangeDepartment(val.value)}
                            validate={{
                              required: { value: true, errorMessage: 'Please select a Department' }
                            }}
                          />
                        </div>
                      </Col>
                    )}
                    <Col md="4">
                      <div className="mb-3">
                        <Dropdown
                          placeholder="Select Status"
                          label="Status"
                          name="status"
                          options={status}
                          isMulti={false}
                          onChange={val => dropDownChangeStatus(val.value)}
                          validate={{
                            required: { value: true, errorMessage: 'Please select a Status' }
                          }}
                        />
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="mb-3">
                        <Button type="button" className="btn btn-primary btn-block app-add-button" onClick={downloadCSV}>
                          EXPORT CSV
                        </Button>
                        <Button type="button" className="btn btn-primary btn-block app-add-button" onClick={downloadPDF}>
                          EXPORT PDF
                        </Button>
                      </div>
                    </Col>

                    {/* <Col sm="3">
                      <div className="text-sm-end">
                        {showSaveOption && (
                          <Button type="button" className="btn btn-primary btn-block  app-add-button" onClick={onAddNewHandler}>
                            NEW REQUISITION
                          </Button>
                        )}
                      </div>
                    </Col> */}
                  </Row>
                  <Row className="tablerow">
                    <Col xl="12">
                      <div>
                        {order.isLoading && <LoadingSpinner />}
                        <Pagination
                          data={orders.data}
                          pageSize={pageSize}
                          onPageChange={onPageChangeHandler}
                          startingPage={currentPageNumber}
                          apiPaging={true}
                          apiDataLength={orders.totalRecords}
                        >
                          <Table
                            editHandler={onEditHandler}
                            repeatHandler={onRepeatHandler}
                            dispatchHandler={onDispatchHandler}
                            printHandler={onPrintHandler}
                            showPrint={showPrintOption}
                            deleteHandler={onDeleteHandler}
                            rejectionHandler={onRejectionHandler}
                            showDelete={showDeleteOption}
                            approveHandler={approveHandler}
                          />
                        </Pagination>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OrderDetail;
