import {
  DISTRIBUTION_ERROR,
  DISTRIBUTION_GET_ALL_DATA,
  DISTRIBUTION_IS_LOADING,
  DISTRIBUTION_ON_CHANGE_DATA,
  DISTRIBUTION_CLEAN_UP,
  DISTRIBUTION_GET_ALL_CLIENTS,
  DISTRIBUTION_ID
} from '../../../constants/index';
import { getAllDistributionCenterClients } from '../../actions/Management/distributionCenter';
import { initialDistributionState } from '../initialState';

const distributionCenterReducer = (state = initialDistributionState, action) => {
  const { payload, type } = action;
  switch (type) {
    case DISTRIBUTION_ERROR: {
      const { showMessage, errorMessage, errorList } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage,
        errorList: errorList
      };
    }
    case DISTRIBUTION_GET_ALL_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        distributionList: resultData
      };
    }
    case DISTRIBUTION_GET_ALL_CLIENTS: {
      const { resultData } = payload;
      return {
        ...state,
        distributionDetails: resultData,
        currentDistribution: {
          ...state.currentDistribution,
          dcCode: resultData.dcCode,
          name: resultData.name
        }
      };
    }
    case DISTRIBUTION_ID: {
      const { resultData } = payload;
      return {
        ...state,
        distributionId: resultData.createdId,
        currentActiveTab: 'clients'
      };
    }
    // case COMPANY_GET_DATA: {
    //   const { resultData } = payload;
    //   return {
    //     ...state,
    //     currentCompany: {
    //       ...state.currentCompany,
    //       ...resultData
    //     }
    //   };
    // }
    case DISTRIBUTION_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }
    // case COMPANY_SAVE_DATA: {
    //   const { showMessage, message, redirect } = payload;
    //   return {
    //     ...state,
    //     isError: false,
    //     redirect: redirect,
    //     showMessage: showMessage,
    //     message: message
    //   };
    // }

    case DISTRIBUTION_ON_CHANGE_DATA: {
      let { data, currentActiveTab, distributionDetails } = payload;
      let responseObject = {
        ...state,
        currentDistribution: {
          ...state.currentDistribution,
          ...data
        },
        currentActiveTab: currentActiveTab
      };
      if (distributionDetails.dcid !== '') responseObject.distributionId = distributionDetails.dcid;
      return responseObject;
    }
    case DISTRIBUTION_CLEAN_UP: {
      let { data } = payload;

      return {
        ...state,
        message: '',
        errorList: [],
        isError: false,
        showMessage: false,
        redirect: false,
        currentDistribution: {
          ...state.currentDistribution,
          ...data.currentDistribution
        },
        currentActiveTab: 'general'
      };
    }

    default:
      return state;
  }
};

export default distributionCenterReducer;
