import { COMPANY_GET_ALL_DATA, COMPANY_GET_DATA, COMPANY_ERROR, COMPANY_IS_LOADING, COMPANY_SAVE_DATA, COMPANY_ON_CHANGE_DATA, COMPANY_CLEAN_UP }  from '../../../constants/index';
import { initialCompanyState } from '../initialState';

const companyReducer = (state = initialCompanyState, action) => {
  const { payload, type } = action;
  switch (type) {
    case COMPANY_ERROR: {
      const { showMessage, errorMessage, errorList } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage,
        errorList: errorList
      };
    }
    case COMPANY_GET_ALL_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        companyList: resultData
      };
    }
    case COMPANY_GET_DATA: {
      const { resultData } = payload;
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          ...resultData
        }
      };
    }
    case COMPANY_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }
    case COMPANY_SAVE_DATA: {
      const { showMessage, message, redirect } = payload;
      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message
      };
    }

    case COMPANY_ON_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          ...data
        }
      };
    }
    case COMPANY_CLEAN_UP: {
      let { data } = payload;

      return {
        ...state,
        message: '',
        errorList: [],
        isError: false,
        showMessage: false,
        redirect: false,
        currentCompany: {
          ...state.currentCompany,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default companyReducer;




