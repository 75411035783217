import { IS_LOADING, SET_SETTINGS, UI_ERROR  } from '../../constants/index';
import Cookies from 'js-cookie';
import { apiHelper } from '../../utils/index';

const API_URL = '/general'

export const getGeneralData = () => dispatch => {
  dispatch(_getGeneralData());
};

const _getGeneralData = () =>
  apiHelper({
      url: `${API_URL}`,
      method: 'GET',
      onLoad: status => setIsLoading(status),
      onSuccess: result => setSettings(result),
      onFailure: err => setErrorThunk(err),
      accessToken: Cookies.get('accessToken'),
  });

  const setErrorThunk = (err) => ({
    type: UI_ERROR,
    payload: { showMessage : false, errorMessage :err  }
  });

  export const setIsLoading = value => ({
    type: IS_LOADING,
    payload: { value }
  });
  
  const  setSettings = setting => ({
    type: SET_SETTINGS,
    payload: { setting }
  });
  