import moment from 'moment-timezone';

export const checkAndRedirect = (isAuthenticated, redirect) => {
  if (!isAuthenticated) {
    redirect();
  }
};

export const isValidEmail = value => {
  const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegEx.test(value);
};

export const arrayToObject = (arr, keyField) =>
  arr.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});

export const filterObject = (obj, keyField, filterValue) =>
  Object.keys(obj).reduce(
    (acc, val) =>
      obj[val][keyField] === filterValue
        ? acc
        : {
            ...acc,
            [val]: obj[val]
          },
    {}
  );

export const isRequired = (value, characters) => {
  return value.length < characters;
};

export const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => {
    if (val.required && val.value.length === 0) {
      valid = false;
    }
  });
  Object.values(errors).forEach(val => val.error.length > 0 && (valid = false));
  return valid;
};

export const countErrors = errors => {
  let count = 0;
  Object.values(errors).forEach(val => val.length > 0 && (count = count + 1));
  return count;
};

export const convertNametoSequenceKey = (name = '') => name.replace(/\s/g, '').toLowerCase();

export const apiHelper = ({
  accessToken = null,
  data = null,
  method = 'GET',
  onLoad = null,
  onSuccess = () => {},
  onFailure = null,
  skipCallback = false,
  url = '',
  accessid = null
}) => ({
  type: 'API',
  payload: {
    accessToken,
    data,
    method,
    onLoad,
    onSuccess,
    onFailure,
    skipCallback,
    url,
    accessid
  }
});

export const isMobile = () => {
  let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return isMobile;
};

export const getQuerystring = queryName => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  return params.get(queryName);
};

export const groupBy = key => array =>
  array.reduce(
    (objectsByKeyValue, obj) => ({
      ...objectsByKeyValue,
      [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj)
    }),
    {}
  );

export const sortObjectKeys = objKeys => {
  return (
    Object.keys(objKeys)

      // Sort and calling a method on
      // keys on sorted fashion.
      .sort()
      .reduce(function(Obj, key) {
        // Adding the key-value pair to the
        // new object in sorted keys manner
        Obj[key] = objKeys[key];
        return Obj;
      }, {})
  );
};

export const getDateInDDMMYYYHHMMSSFormat = (date, currentTimezone) => {
  if (!date || !currentTimezone) return '';
  return moment
    .unix(date)
    .tz(currentTimezone)
    .format('DD-MMM-YYYY HH:mm:ss z');
};

export const getDateInDDMMYYYFormat = (date, currentTimezone) => {
  if (!date || !currentTimezone) return '';
  return moment
    .unix(date)
    .tz(currentTimezone)
    .format('DD-MMM-YYYY');
};

export const getDateInYYYYFormat = (date, currentTimezone) => {
  if (!date || !currentTimezone) return '';
  return moment
    .unix(date)
    .tz(currentTimezone)
    .format('YYYY');
};

export const timeOptions = () => {
  const timelist = [
    {
      label: '1 hour',
      value: 3600
    }
  ];

  for (let i = 2; i <= 24; +i++) {
    timelist.push({
      label: `${i} hours`,
      value: i * 3600
    });
  }
  return timelist;
};
export const flattenObject = obj => {
  const flattened = {};

  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });

  return flattened;
};

export const addDaysToDate = (date, days) => {
  return date.setDate(date.getDate() + days);
};

var lastday = function(y, m) {
  return new Date(y, m + 1, 0).getDate();
};

export const getLastDateOfLastMonth = () => {
  const today = new Date();
  const lastMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1;
  const year = lastMonth === 0 ? today.getFullYear() - 1 : today.getFullYear();
  const lastDate = lastday(year, lastMonth);
  var dd = new Date(year, lastMonth, lastDate);
  return dd;
};
export const getFirstDateOfLastMonth = () => {
  const today = new Date();
  const lastMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1;
  const year = lastMonth === 0 ? today.getFullYear() - 1 : today.getFullYear();
  const lastDate = lastday(year, lastMonth);
  var dd = new Date(year, lastMonth, 1);
  return dd;
};
