import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Table } from 'reactstrap';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, Container, Row } from 'reactstrap';
import { describeDispatch, saveDispatch, getProductsByDept, setOnChange, setOnCleanUp } from '../../../store/actions/Operation/dispatch';
import { AvForm } from 'availity-reactstrap-validation';
import { initialDispatchState } from '../../../store/reducers/initialState';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { _create, _dispatch, _allowbackdate } from '../../../constants/componentConstants';
import { IS_NEW, IS_DISPATCH_FROM_REQ, IS_DISPATCH_FROM_REQ_FILTER } from '../../../constants';
import { hasPermissionToAction } from '../../../utils/accessUtil';
import Dropdown from '../../CustomControls/Dropdown';
import CustomAvField from '../../CustomControls/CustomAvField';
import DatePicker from '../../CustomControls/CustomDatePicker';
import ErrorListing from '../../CustomControls/ErrorListing';
import { Text } from '@react-pdf/renderer';

const ManageDispatch = props => {
  let history = useHistory();
  const backUrl = '/operation/dispatches';
  const dispatchAction = useDispatch();
  const dispatch = useSelector(state => state.dispatch);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  let IsEdit = false;
  let { dispatchid, requisitionid, isreferred } = useParams();
  const notificationType = dispatch.isError ? 'error' : 'success';
  const [triggerValidation, setTriggerValidation] = useState(false);

  let repeatCreation = true;
  if (requisitionid && isreferred == '1') {
    repeatCreation = false;
  }

  let showSaveOption = hasPermissionToAction(permissions, _dispatch, _create);
  let allowBackdate = hasPermissionToAction(permissions, _allowbackdate, _create);

  let currentDispatch = dispatch.currentDispatch;
  console.log('-----dispatch----', dispatch);
  useEffect(() => {}, [currentDispatch]);
  const disableItemControls = dispatchid ? true : false;

  let localPath = '/operation/dispatches/dispatch';
  let heading = 'New Dispatch';

  if (dispatchid) {
    IsEdit = true;
    heading = 'Edit Dispatch';
    localPath = localPath + `/${dispatchid}`;
    showSaveOption = false;
  }

  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Dispatch Listing', path: '/operation/dispatches' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  useEffect(() => {
    if (dispatchid) {
      dispatchAction(describeDispatch(dispatchid));
    } else if (requisitionid && isreferred === '1') {
      dispatchAction(describeDispatch(IS_DISPATCH_FROM_REQ, requisitionid));
    } else {
      dispatchAction(describeDispatch(IS_NEW));
    }
  }, [dispatchAction, dispatchid, requisitionid, isreferred]);

  useEffect(() => {
    if (currentDispatch.departmentId) {
      dispatchAction(getProductsByDept(currentDispatch?.departmentId));
    }
  }, [dispatchAction]);

  //clean up
  useEffect(() => {
    return () => {
      setLocalCleanUpData({
        data: { ...dispatch.currentDispatch, ...initialDispatchState.currentDispatch }
      });
    };
  }, []);

  const setLocalCleanUpData = cleanUpData => {
    dispatchAction(setOnCleanUp(cleanUpData));
  };

  const setLocalChange = currentState => {
    dispatchAction(setOnChange(currentState));
  };

  const handleInput = e => {
    setLocalChange({
      ...dispatch,
      data: { ...dispatch.currentDispatch, [e.target.name]: e.target.value }
    });
  };

  const handleDateInput = (field, dateVal) => {
    const setDate = dateVal ? new Date(dateVal) : null;
    setLocalChange({
      ...dispatch,
      data: { ...dispatch.currentDispatch, [field]: setDate }
    });
  };

  const dropDownChange = (field, selectedOption) => {
    let changeObj = { [field]: selectedOption.value };

    setLocalChange({
      ...dispatch,
      data: { ...dispatch.currentDispatch, ...changeObj }
    });
    if (field === 'requisitionId') {
      dispatchAction(describeDispatch(IS_DISPATCH_FROM_REQ_FILTER, selectedOption.value));
    }
  };

  const handleSubmit = e => {
    if (!isValid()) {
      setTriggerValidation(true);
      return;
    }

    if (dispatch.currentDispatch) {
      dispatchAction(saveDispatch(dispatch.currentDispatch, !IsEdit, dispatchid, repeatCreation));
      setTriggerValidation(false);
    }
  };
  const isValid = () => {
    let status = true;
    if (!dispatch.currentDispatch.requisitionId) {
      status = false;
    }

    if (!dispatch.currentDispatch.locationId) {
      status = false;
    }

    if (!dispatch.currentDispatch.dispatchedDate || dispatch.currentDispatch.dispatchedDate === '') {
      status = false;
    }

    return status;
  };
  const handleInvalidSubmit = e => {
    setTriggerValidation(true);
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  const onUpdateItemHandler = addObj => {
    let currentDisItems = dispatch.currentDispatch.dispatchDetails || [];

    const objIndex = currentDisItems.findIndex(x => x.productId === addObj.productId);

    if (objIndex !== -1) {
      currentDisItems[objIndex].requestedQty = addObj.requestedQty;
    } else {
      currentDisItems.push(addObj);
    }

    setLocalChange({
      ...dispatch,
      data: { ...dispatch.currentDispatch, dispatchDetails: currentDisItems }
    });
  };

  const contentHeading = IsEdit ? `${heading} - ${currentDispatch.dispatchRef}` : heading;
  console.log('currentDispatch in ManageDispatch: ', currentDispatch);
  console.log('isLoad: ', dispatch.isLoading);
  const colSpanSplit = !currentDispatch?.refAutoGenerated ? 4 : 8;
  // return (
  //     <>
  //         <div className="page-content">
  //             <Text>LOADING: {dispatch.isLoading}</Text>
  //             <br />
  //             {JSON.stringify(currentDispatch, null, 4)}
  //             <Button color='primary' onClick={() => {
  //                 dropDownChange('requisitionId', {
  //                     value: 'fc4a2d50-5c0d-4180-9d5c-a76720c60ebb'
  //                 })
  //             }}>
  //                 PRESS ME
  //             </Button>
  //         </div>
  //     </>
  // );

  if (!currentDispatch) {
    return <LoadingSpinner />;
  } else {
    console.log('currentDispatch in else: ', currentDispatch);
  }

  return (
    <React.Fragment>
      {dispatch.redirect ? <Redirect to={backUrl} /> : ''}
      {dispatch.isLoading && <LoadingSpinner />}
      {dispatch.showMessage && <Notification notificationType={notificationType} message={dispatch.message} />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardTitle>{contentHeading} </CardTitle>
                <CardBody>
                  <AvForm className="form-horizontal" onValidSubmit={handleSubmit} onInvalidSubmit={handleInvalidSubmit}>
                    {dispatch.errorList && dispatch.errorList.length > 0 && <ErrorListing errorList={dispatch.errorList} />}
                    <Row>
                      <Col lg={12}>
                        <CardSubtitle>Requsition Information</CardSubtitle>
                        <Card>
                          <CardBody>
                            <Row>
                              <Col sm="4">
                                <div className="mb-3">
                                  <Dropdown
                                    placeholder="Select Requisition"
                                    label="Requisition"
                                    name="requisitionId"
                                    options={currentDispatch.requisitionSelectList}
                                    isMulti={false}
                                    value={currentDispatch.requisitionSelectList.find(x => x.value === currentDispatch.requisitionId)}
                                    onChange={val => dropDownChange('requisitionId', val)}
                                    isDisabled={disableItemControls}
                                    triggerValidation={triggerValidation}
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: 'Please select a requisition no'
                                      }
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <Row>
                          {!currentDispatch.refAutoGenerated && (
                            <Col md="2">
                              <div className="mb-3">
                                <CustomAvField
                                  name="dispatchRef"
                                  label="Dispatch Ref"
                                  className="form-control"
                                  placeholder="Dispatch Ref"
                                  value={currentDispatch.dispatchRef}
                                  onChange={handleInput}
                                  type="text"
                                  maxLength="10"
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'Please enter a valid dispatch no'
                                    }
                                  }}
                                />
                              </div>
                            </Col>
                          )}
                          <Col md="2">
                            <div className="mb-4">
                              <DatePicker
                                label="Dispatched Date"
                                triggerValidation={triggerValidation}
                                selected={currentDispatch.dispatchedDate ? new Date(currentDispatch.dispatchedDate) : null}
                                onChange={date => handleDateInput('dispatchedDate', date)}
                                dateFormat="dd-MMM-yyyy"
                                minDate={allowBackdate ? new Date('January 1, 1990 00:00:00') : new Date()}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Please select a valid dispatch date'
                                  }
                                }}
                              />
                            </div>
                          </Col>
                          <Col md="2">
                            <div className="mb-4">
                              <DatePicker
                                label="Expected Dispatch Date"
                                triggerValidation={triggerValidation}
                                selected={
                                  currentDispatch.expectedDeliveryDate ? new Date(currentDispatch.expectedDeliveryDate) : new Date()
                                }
                                onChange={date => handleDateInput('expectedDeliveryDate', date)}
                                dateFormat="dd-MMM-yyyy"
                                minDate={allowBackdate ? new Date('January 1, 1990 00:00:00') : new Date()}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Please select a valid expected dispatch date'
                                  }
                                }}
                              />
                            </div>
                          </Col>
                          <Col sm="2">
                            <div className="mb-3">
                              <Dropdown
                                placeholder="Select Dispatched Location"
                                label="Dispatched Location"
                                name="locationId"
                                options={currentDispatch.locationSelectList}
                                isMulti={false}
                                value={currentDispatch.locationSelectList.find(x => x.value === currentDispatch.locationId)}
                                onChange={val => dropDownChange('locationId', val)}
                                triggerValidation={triggerValidation}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Please select a dispatch location'
                                  }
                                }}
                              />
                            </div>
                          </Col>
                          <Col md="2">
                            <div className="mb-4">
                              <DatePicker
                                label="Ordered Date"
                                triggerValidation={triggerValidation}
                                selected={currentDispatch.requisitionDate ? new Date(currentDispatch.requisitionDate) : new Date()}
                                disabled
                                // onChange={date => handleDateInput('expectedDeliveryDate', date)}
                                dateFormat="dd-MMM-yyyy"
                                minDate={allowBackdate ? new Date('January 1, 1990 00:00:00') : new Date()}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Please select a valid expected dispatch date'
                                  }
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={colSpanSplit}>
                            <div className="mb-3">
                              <CustomAvField
                                name="remarks"
                                label="Remarks"
                                className="form-control"
                                placeholder="Remarks"
                                value={currentDispatch.remarks}
                                onChange={handleInput}
                                type="text"
                                maxLength="200"
                                validate={{
                                  required: { value: false, errorMessage: '' }
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <CardSubtitle>Dispatched Products</CardSubtitle>
                        <Card>
                          <CardBody>
                            <DispatchDetailTable data={dispatch.currentDispatch.dispatchDetails} editItemHandler={onUpdateItemHandler} />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      {showSaveOption && (
                        <Button type="submit" color="primary" className="btn " disabled={dispatch.isLoading}>
                          SAVE
                        </Button>
                      )}
                      <Button onClick={cancelHandler} color="secondary" className=" ">
                        CANCEL
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageDispatch;

const DispatchDetailTable = ({ data, editItemHandler }) => {
  const totalQty = data.reduce(function(accumulator, item) {
    return accumulator + parseInt(item.requestedQty);
  }, 0);

  const totalDispatchedQty = data.reduce(function(accumulator, item) {
    return accumulator + parseInt(item.dispatchedQty);
  }, 0);

  return (
    <div>
      <Table className="tablelight" responsive>
        <thead>
          <tr>
            <th className="col-sm-6">Product Name</th>
            <th className="col-sm-2 alignRight">Ordered Qty</th>
            <th className="col-sm-3 alignRight">Dispatched Qty</th>
          </tr>
        </thead>
        <tbody>
          <React.Fragment>
            {data.map(n => {
              return <UpdateTBRow key={n.productId} item={n} editItemHandler={editItemHandler} />;
            })}
            {data.length > 0 && (
              <tr className="" key={'summary'}>
                <td>
                  <span style={{ fontWeight: 'bold' }} className="col-sm-6 summaryLabel">
                    Line Count
                  </span>{' '}
                  <span className="summaryValue">{data.length}</span>
                </td>
                <td className="alignRight">
                  <span className="summaryLabel">
                    <b>Total Ordered</b>{' '}
                  </span>{' '}
                  <span className="summaryValue">{totalQty}</span>
                </td>
                <td style={{ fontWeight: 'bold', marginLeft: '15px' }} className="">
                  <span style={{ fontWeight: 'bold', marginRight: '5px' }} className="col-sm-3 summaryLabel">
                    Total Dispatched
                  </span>{' '}
                  <span className="summaryValue">{totalDispatchedQty}</span>
                </td>
              </tr>
            )}
          </React.Fragment>
        </tbody>
      </Table>
    </div>
  );
};

const UpdateTBRow = ({ item, editItemHandler }) => {
  const [dispatchQty, setDispatchQty] = useState(item.dispatchedQty);
  const onChangeHandler = e => {
    setDispatchQty(e.target.value);
    item.dispatchedQty = e.target.value;
    editItemHandler(item);
  };

  return (
    <tr key={item.productId}>
      <td>{item.productName || ''}</td>
      <td className="alignRight">{item.requestedQty || ''}</td>
      <td>
        <CustomAvField
          name={`dispatchQty_${item.productId}`}
          label=""
          className="form-control alignRight small-txtbx align-textbox"
          placeholder="Dispatch Qty"
          value={dispatchQty}
          onChange={onChangeHandler}
          step="1"
          min="0"
          max={item.requestedQty}
          type="number"
          maxLength="50"
          validate={{
            required: { value: true, errorMessage: 'Invalid dispatch qty' }
          }}
        />
      </td>
    </tr>
  );
};
