import {
  USER_GET_ALL_DATA,
  USER_GET_DATA,
  USER_ERROR,
  USER_IS_LOADING,
  USER_SAVE_DATA,
  USER_ON_CHANGE_DATA,
  USER_CHANGED_PASSWORD,
  USER_CLEAN_UP,
  USER_RESET_PASSWORD,
  USER_ALL_DETAILS
} from '../../constants/index';
import { apiHelper } from '../../utils/index';
import Cookies from 'js-cookie';
import {
  setErrorThunk,
  setLoadingStatus,
  setGetResults,
  setDescribeData,
  setSaveData,
  setOnChangeData,
  setOnCleanUpData
} from './actionHelper';

const API_URL = '/user';

export const getAllUsers = () => dispatch => {
  dispatch(_getAllUsers());
};

const _getAllUsers = () =>
  apiHelper({
    url: `${API_URL}`,
    method: 'GET',
    onLoad: status => setLoadingStatus(USER_IS_LOADING, status),
    onSuccess: result => setGetResults(USER_GET_ALL_DATA, result),
    onFailure: err => setErrorThunk(USER_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
export const deleteHandlerCall = id => dispatch => {
  dispatch(_deleteHandlerCall(id));
};

const _deleteHandlerCall = id =>
  apiHelper({
    url: `${API_URL}/${id}`,
    method: 'DELETE',
    onLoad: status => setLoadingStatus(USER_IS_LOADING, status),
    onSuccess: result => getAllUsers(),
    onFailure: err => setErrorThunk(USER_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
export const userDetails = () => dispatch => {
  dispatch(_userDetails());
};

const _userDetails = () =>
  apiHelper({
    url: `/customer/profile-details`,
    method: 'GET',
    onLoad: status => setLoadingStatus(USER_IS_LOADING, status),
    onSuccess: result => setGetResults(USER_ALL_DETAILS, result),
    onFailure: err => setErrorThunk(USER_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });
export const updateProfileDetails = data => dispatch => {
  dispatch(_updateProfileDetails(data));
};

const _updateProfileDetails = data =>
  apiHelper({
    url: `/customer/update-profile`,
    method: 'PATCH',
    data: data,
    onLoad: status => setLoadingStatus(USER_IS_LOADING, status),
    onSuccess: result => setGetResults(USER_ALL_DETAILS, result),
    onFailure: err => setErrorThunk(USER_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

export const describeUser = id => dispatch => {
  dispatch(_getUser(id));
};

const _getUser = id =>
  apiHelper({
    url: `${API_URL}/${id}`,
    method: 'GET',
    data: {
      id: id
    },
    onLoad: status => setLoadingStatus(USER_IS_LOADING, status),
    onSuccess: result => setDescribeData(USER_GET_DATA, result),
    onFailure: err => setErrorThunk(USER_ERROR, true, err),
    accessToken: Cookies.get('accessToken')
  });

export const saveUser = (saveData, isNew, id = null) => dispatch => {
  let MethodType = 'POST';
  if (!isNew) {
    MethodType = 'PUT';
  }

  let apiUrl = id ? `${API_URL}/${id}` : API_URL;

  dispatch(_saveUser(saveData, MethodType, apiUrl));
};

const _saveUser = (data, MethodType, apiUrl) =>
  apiHelper({
    url: apiUrl,
    method: MethodType,
    onSuccess: result => setSaveData(USER_SAVE_DATA, true, result),
    onFailure: err => setErrorThunk(USER_ERROR, true, err),
    onLoad: status => setLoadingStatus(USER_IS_LOADING, status),
    data,
    accessToken: Cookies.get('accessToken')
  });

export const setOnChange = retData => dispatch => {
  dispatch(setOnChangeData(USER_ON_CHANGE_DATA, retData));
};

export const setOnCleanUp = retData => dispatch => {
  dispatch(setOnCleanUpData(USER_CLEAN_UP, retData));
};

/*RESET PASSWORD */

export const resetPasswordHandler = (userid, newPassword) => dispatch => {
  let apiUrl = `${API_URL}/resetpassword`;

  const saveData = {
    userId: userid,
    newPassword: newPassword
  };

  dispatch(_resetPassword(saveData, apiUrl));
};
const _resetPassword = (data, apiUrl) =>
  apiHelper({
    url: apiUrl,
    method: 'POST',
    onSuccess: result => setSaveData(USER_RESET_PASSWORD, true, result),
    onFailure: err => setErrorThunk(USER_ERROR, true, err),
    onLoad: status => setLoadingStatus(USER_IS_LOADING, status),
    data,
    accessToken: Cookies.get('accessToken')
  });

/* CHANGE PASSWORD*/

export const changePassword = (currentPassword, newPassword, confirmPassword) => dispatch => {
  let apiUrl = `${API_URL}/changepassword`;

  const saveData = {
    currentPassword: currentPassword,
    newPassword: newPassword,
    confirmPassword: confirmPassword
  };

  dispatch(_changePassword(saveData, apiUrl));
};

const _changePassword = (data, apiUrl) =>
  apiHelper({
    url: apiUrl,
    method: 'POST',
    onSuccess: result => setSavePasswordData(result),
    onFailure: err => setErrorThunk(USER_ERROR, true, err),
    onLoad: status => setLoadingStatus(USER_IS_LOADING, status),
    data,
    accessToken: Cookies.get('accessToken')
  });

const setSavePasswordData = ({ message }) => dispatch => {
  dispatch(_setPasswordChangeSuccess(message, true, false));
  setTimeout(() => {
    dispatch(_setPasswordChangeSuccess(message, false, true));
  }, 2500);
};

const _setPasswordChangeSuccess = (message, showMessage, redirect) => ({
  type: USER_CHANGED_PASSWORD,
  payload: { showMessage, message, redirect }
});
