import {
  CUSTOMER_DETAILS_BY_ID,
  CUSTOMER_DETAIL_BY_NAME,
  CUSTOMER_DETAIL_IS_LOADING,
  CUSTOMER_DETAIL_ON_SEARCH_CHANGE_DATA,
  CUSTOMER_DETAIL_ORDER_PLACED,
  CUSTOMER_DETAIL_PRODUCTS_ERROR,
  CUSTOMER_DETAIL_PRODUCTS_SET_DATA,
  CUSTOMER_JOB_REPORT_IS_LOADING,
  CUSTOMER_JOB_REPORT_GET_RESULT,
  CUSTOMER_JOB_REPORT_ERROR
} from '../../constants';
import { initialCustomerState } from './initialState';
import Papa, { parse } from 'papaparse';

const customerReducer = (state = initialCustomerState, action) => {
  const { payload, type } = action;
  switch (type) {
    case CUSTOMER_DETAIL_PRODUCTS_ERROR: {
      const { showMessage, errorMessage, errorList } = payload;
      return {
        ...state,
        isError: showMessage ? true : false,
        showMessage: showMessage,
        message: errorMessage,
        errorList: errorList
      };
    }
    case CUSTOMER_DETAIL_PRODUCTS_SET_DATA: {
      const { resultData } = payload;

      return {
        ...state,
        productList: resultData?.data?.map(p => {
          return {
            ...p,
            label: p.productName,
            value: p.productId
          };
        }),
        totalRecords: resultData.totalRecords,
        filterOptions: {
          ...state.filterOptions,
          pageNumber: resultData.pageNumber,
          pageSize: resultData.pageSize
        }
      };
    }
    case CUSTOMER_DETAIL_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }
    case CUSTOMER_DETAILS_BY_ID: {
      const { showMessage } = payload;
      return {
        ...state,
        customerDetailsById: showMessage
      };
    }
    case CUSTOMER_DETAIL_BY_NAME: {
      const { showMessage } = payload;
      return {
        ...state,
        customerDetailsByName: showMessage.map(item => {
          return {
            label: item.customerName,
            value: item.customerId
          };
        })
      };
    }
    case CUSTOMER_JOB_REPORT_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }
    case CUSTOMER_JOB_REPORT_GET_RESULT: {
      const { resultData } = payload;
      const parsedData = Papa.parse(resultData, { header: true }).data;
      console.log(parsedData);
      return {
        ...state,
        csvDataCustomerJobReport: parsedData
      };
    }
    case CUSTOMER_JOB_REPORT_ERROR: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading: isLoading
      };
    }
    case CUSTOMER_DETAIL_ON_SEARCH_CHANGE_DATA: {
      let { data } = payload;

      return {
        ...state,
        filterOptions: {
          ...state.data,
          ...data
        }
      };
    }
    case CUSTOMER_DETAIL_ORDER_PLACED: {
      //As per require we do not redirect in steam preserve some of the data
      let { showMessage, message, redirect } = payload;

      return {
        ...state,
        isError: false,
        redirect: redirect,
        showMessage: showMessage,
        message: message
      };
    }
    default:
      return state;
  }
};

export default customerReducer;
