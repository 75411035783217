import React, { useEffect, useReducer } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row
} from "reactstrap"
import { changePassword, setOnCleanUp } from '../../../store/actions/user';
import { AvForm } from "availity-reactstrap-validation";
import { initialUserState } from '../../../store/reducers/initialState';
import LoadingSpinner from "../../CustomControls/Spinner";
import Notification from "../../CustomControls/Notification";
import Breadcrumbs from "../../CustomControls/Breadcrumb";
import CustomAvField from "../../CustomControls/CustomAvField";


const reducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE_VALUE':
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

const ChangePassword = props => {
    let history = useHistory();
    const backUrl = '/';
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);


    const initialState = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    };

    const [passwordState, localDispatch] = useReducer(reducer, initialState)

    const notificationType = user.isError ? "error" : "success";

    let heading = 'Change Password';

    const breadCrumbPaths = [
        { id: 1, title: 'Home', path: '/' },
        { id: 2, title: 'Change Password', path: '/change-password' },
    ]



    const setLocalCleanUpData = cleanUpData => {
        dispatch(setOnCleanUp(cleanUpData))
    }

    //clean up
    useEffect(() => {
        return () => {
            setLocalCleanUpData({
                data: { ...user.currentUser, ...initialUserState.currentUser }
            })
        };
    }, []);

    const handleInput = (e) => {
        localDispatch({ type: 'CHANGE_VALUE', payload: { [e.target.name]: e.target.value } })
    }
    const handleSubmit = (e) => {

        if (passwordState.currentPassword && passwordState.newPassword && passwordState.confirmPassword) {

            dispatch(changePassword(passwordState.currentPassword, passwordState.newPassword, passwordState.confirmPassword))
        }
    }

    const cancelHandler = () => {
        history.push(backUrl);
    };

    return (
        <React.Fragment>
            {user.redirect ? <Redirect to={backUrl} /> : ''}
            {user.isLoading && <LoadingSpinner />}
            {user.showMessage && <Notification notificationType={notificationType} message={user.message} />}
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={heading} paths={breadCrumbPaths} />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardTitle>Change Password</CardTitle>
                                <CardBody>
                                    <AvForm className="form-horizontal" onValidSubmit={handleSubmit} >
                                        <Row>
                                            <Col sm="4">
                                                <div className="mb-3">
                                                    <CustomAvField
                                                        name="currentPassword"
                                                        label="Current password"
                                                        className="form-control"
                                                        placeholder="Current password"
                                                        type="password"
                                                        value={passwordState.currentPassword}
                                                        onChange={handleInput}
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Please enter the current password' },
                                                        }}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <CustomAvField
                                                        name="newPassword"
                                                        label="New password"
                                                        className="form-control"
                                                        placeholder="New password"
                                                        type="password"
                                                        value={passwordState.newPassword}
                                                        onChange={handleInput}
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Please enter a new password' },
                                                            pattern: { value: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})', errorMessage: 'Password is not complex enough.' }
                                                        }}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <CustomAvField
                                                        name="confirmPassword"
                                                        label="Confirm password"
                                                        className="form-control"
                                                        placeholder="Confirm password"
                                                        type="password"
                                                        value={passwordState.confirmPassword}
                                                        onChange={handleInput}
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Please enter a confirm password' },
                                                            match: { value: 'newPassword', errorMessage: 'Passowords do not match' }
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="d-flex flex-wrap gap-2">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="btn "
                                                disabled={user.isLoading}
                                            >
                                                CHANGE PASSWORD
                                            </Button>
                                            <Button
                                                onClick={cancelHandler}
                                                color="secondary"
                                                className=" "
                                            >
                                                CANCEL
                                            </Button>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )

}

export default ChangePassword;