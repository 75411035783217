import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { getIsUserAuthenticated } from '../selectors/index';
import AppLoading from '../components/AppLoading/AppLoading';
import { getGeneralData } from '../store/actions/ui';
import { hasPermission } from '../utils/accessUtil';
import Unauth from '../hocs/unauthorized';
import UnAuth401 from '../components/Unauthorised/Unauthorised';

const CustomRoute = props => {
  const userName = Cookies.get('accessToken') || '';
  const accessid = Cookies.get('companyid') || '';
  const ui = useSelector(state => state.ui);
  const isLoading = ui.isLoading;
  const permissions = ui.layout.permissions;
  const dataFetched = ui.layout.dataFetched;

  const dispatch = useDispatch();

  useEffect(() => {
    if (accessid.length > 0 && !dataFetched) {
      dispatch(getGeneralData());
    }
  }, [dispatch, accessid, dataFetched]);

  if (!getIsUserAuthenticated() || !userName) {
    return <Redirect to="/login" />;
  }

  let status = false;

  if (isLoading) {
    return <Route {...props} component={AppLoading} />;
  }

  status = hasPermission(permissions, props.pageAccess, props.pageAction);

  if (status && !isLoading) {
    return <Route {...props} />;
  } else if (!status && !isLoading && dataFetched) {
    return <Route component={Unauth(UnAuth401)} />;
  } else {
    return <Route {...props} component={AppLoading} />;
  }
};

export default CustomRoute;
