import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { Table, Button, Card, CardBody, CardSubtitle, CardTitle, Col, Container, Row } from 'reactstrap';
import { initialGroupState } from '../../../store/reducers/initialState';
import Pagination from '../../CustomControls/Pagination';
import LoadingSpinner from '../../CustomControls/Spinner';
import Notification from '../../CustomControls/Notification';
import Breadcrumbs from '../../CustomControls/Breadcrumb';
import { getGroupPermissions, setOnGroupPermissionChange, saveGroupPermissions, setOnCleanUp } from '../../../store/actions/group';
import SearchBar from '../../CustomControls/SearchBar';
import { _groups, _create, _view, _delete, _reportprint } from '../../../constants/componentConstants';
import { hasPermissionToAction } from '../../../utils/accessUtil';

const GroupPermissions = props => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [results, setResults] = useState([]);
  const group = useSelector(state => state.group);
  const ui = useSelector(state => state.ui);
  const permissions = ui.layout.permissions;
  const pageSize = ui.layout.pageSize;
  let { groupid } = useParams();
  const notificationType = group.isError ? 'error' : 'success';
  let currentGroup = group.currentGroup;

  const showSaveOption = hasPermissionToAction(permissions, _groups, _create);

  let localPath = '/management/groups/group';
  let heading = `Group Permissions`;
  const backUrl = '/management/groups';
  const breadCrumbPaths = [
    { id: 1, title: 'Home', path: '/' },
    { id: 2, title: 'Group Listing', path: '/management/groups' },
    { id: 3, title: heading, path: localPath, isCurrent: true }
  ];

  useEffect(() => {
    if (searchText.length > 0) {
      setResults(currentGroup.permissions.filter(x => x.category.toLowerCase().includes(searchText.toLowerCase())));
    } else {
      setResults(currentGroup.permissions);
    }
  }, [searchText, setResults, currentGroup.permissions]);

  useEffect(() => {
    if (groupid) {
      dispatch(getGroupPermissions(groupid));
    }
  }, [dispatch, groupid]);

  //clean up
  useEffect(() => {
    return () => {
      setLocalCleanUpData({
        data: { ...group.currentGroup, ...initialGroupState.currentGroup }
      });
    };
  }, []);

  const setLocalCleanUpData = cleanUpData => {
    dispatch(setOnCleanUp(cleanUpData));
  };

  const onPageChangeHandler = currentPage => {};

  const onCheckboxHandler = (e, id, type) => {
    dispatch(setOnGroupPermissionChange({ data: { id: id, value: e.target.checked, type: type } }));
  };

  const handleInput = e => {
    setSearchText(e.target.value);
  };

  const handleSave = e => {
    if (group.currentGroup && groupid) {
      const listSelectedViewPermissions = group.currentGroup.permissions.filter(x => x.isView).map(res => res.viewId);

      const listSelectedCreatePermissions = group.currentGroup.permissions.filter(x => x.isCreate).map(res => res.createId);

      const listSelectedDeletePermissions = group.currentGroup.permissions.filter(x => x.isDelete).map(res => res.deleteId);

      const listSelectedPrintPermissions = group.currentGroup.permissions.filter(x => x.isPrintView).map(res => res.printViewId);

      const listSelectedPermissions = [
        ...listSelectedViewPermissions,
        ...listSelectedCreatePermissions,
        ...listSelectedDeletePermissions,
        ...listSelectedPrintPermissions
      ];

      var saveObj = {
        id: groupid,
        groupPermissions: listSelectedPermissions
      };

      dispatch(saveGroupPermissions(saveObj, groupid));
    }
  };

  return (
    <React.Fragment>
      {group.redirect ? <Redirect to={backUrl} /> : ''}
      {group.isLoading && <LoadingSpinner />}
      {group.showMessage && <Notification notificationType={notificationType} message={group.message} />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={heading} paths={breadCrumbPaths} />
          <Row>
            <Col xs="12">
              <Card>
                <CardTitle>{`${heading} - ${currentGroup.groupName}`}</CardTitle>
                <CardBody>
                  <Row className="mb-2 buttonrow">
                    <Col sm="4">
                      <SearchBar onChange={handleInput} placeholder="Search" value={searchText} />
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        {showSaveOption && (
                          <Button type="button" className="btn btn-primary btn-block " onClick={handleSave}>
                            SAVE PERMISSIONS
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <CardSubtitle className="mb-4">Select the permissions in the group</CardSubtitle>
                  <Pagination data={results} pageSize={pageSize} onPageChange={onPageChangeHandler} startingPage={1}>
                    <UserTable onCheckboxHandler={onCheckboxHandler} />
                  </Pagination>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GroupPermissions;

const UserTable = ({ paginatedData, onCheckboxHandler }) => {
  return (
    <React.Fragment>
      <div>
        <Table className="table" responsive>
          <thead>
            <tr>
              <th className="col-sm-4">Screen</th>
              <th className="col-sm-2">View</th>
              <th className="col-sm-2">Create/Update</th>
              <th className="col-sm-2">Delete</th>
              <th className="col-sm-2">View Print</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData &&
              paginatedData.map(n => {
                return (
                  <tr key={n.category}>
                    <td>{n.category.replace('Report_', 'Report - ').replace('_', ' ')}</td>
                    <td>
                      {n.isView !== null ? (
                        <input type="checkbox" value={n.isView} checked={n.isView} onChange={e => onCheckboxHandler(e, n.viewId, _view)} />
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      {n.isCreate !== null ? (
                        <input
                          type="checkbox"
                          value={n.isCreate}
                          checked={n.isCreate}
                          onChange={e => onCheckboxHandler(e, n.createId, _create)}
                        />
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      {n.isDelete !== null ? (
                        <input
                          type="checkbox"
                          value={n.isDelete}
                          checked={n.isDelete}
                          onChange={e => onCheckboxHandler(e, n.deleteId, _delete)}
                        />
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      {n.isPrintView !== null ? (
                        <input
                          type="checkbox"
                          value={n.isPrintView}
                          checked={n.isPrintView}
                          onChange={e => onCheckboxHandler(e, n.printViewId, _reportprint)}
                        />
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
};
