import { DEPARTMENT_GET_ALL_DATA, DEPARTMENT_GET_DATA, DEPARTMENT_ERROR, DEPARTMENT_IS_LOADING, DEPARTMENT_SAVE_DATA, DEPARTMENT_ON_CHANGE_DATA, DEPARTMENT_CLEAN_UP,DEPARTMENT_DELETE_DATA, DEPARTMENT_UPDATE_CONFIRMATION } from '../../../constants/index';
import { apiHelper } from '../../../utils/index';
import Cookies from 'js-cookie';
import { setErrorThunk, setLoadingStatus, setGetResults, setDescribeData, setSaveData, setOnChangeData, setOnCleanUpData,setDeleteData,setOnConfirmationData } from '../actionHelper';

const API_URL = '/department'

export const getAllDepartments = () => dispatch => {
    dispatch(_getAllDepartments());
};

const _getAllDepartments = () =>
    apiHelper({
        url: `${API_URL}`,
        method: 'GET',
        onLoad: status => setLoadingStatus(DEPARTMENT_IS_LOADING, status),
        onSuccess: result => setGetResults(DEPARTMENT_GET_ALL_DATA, result),
        onFailure: err => setErrorThunk(DEPARTMENT_ERROR, true, err),
        accessToken: Cookies.get('accessToken'),
    });

export const describeDepartment = (id) => dispatch => {
    dispatch(_getDepartment(id));
};

const _getDepartment = (id) =>
    apiHelper({
        url: `${API_URL}/${id}`,
        method: 'GET',
        data: {
            id: id
        },
        onLoad: status => setLoadingStatus(DEPARTMENT_IS_LOADING, status),
        onSuccess: result => setDescribeData(DEPARTMENT_GET_DATA, result),
        onFailure: err => setErrorThunk(DEPARTMENT_ERROR, true, err),
        accessToken: Cookies.get('accessToken'),
    });

export const saveDepartment = (saveData, isNew, id = null) => dispatch => {
    let MethodType = 'POST';
    if (!isNew) {
        MethodType = 'PUT'
    }

    let apiUrl = id ? `${API_URL}/${id}` : API_URL;

    dispatch(_saveDepartment(saveData, MethodType, apiUrl));
}

const _saveDepartment = (data, MethodType, apiUrl) =>
    apiHelper({
        url: apiUrl,
        method: MethodType,
        onSuccess: result => setSaveData(DEPARTMENT_SAVE_DATA, true, result),
        onFailure: err => setErrorThunk(DEPARTMENT_ERROR, true, err),
        onLoad: status => setLoadingStatus(DEPARTMENT_IS_LOADING, status),
        data,
        accessToken: Cookies.get('accessToken'),
    });


export const setOnChange = retData => dispatch => {
    dispatch(setOnChangeData(DEPARTMENT_ON_CHANGE_DATA, retData));
};

export const setOnCleanUp = retData => dispatch => {
    dispatch(setOnCleanUpData(DEPARTMENT_CLEAN_UP, retData));
};



// DELETE OPERATION
export const deleteDepartment = (id) => dispatch => {
    let MethodType = 'DELETE';
    let apiUrl = id ? `${API_URL}/${id}` : API_URL;

    dispatch(_deleteDepartment(MethodType, apiUrl));
    
}

export const setOnConfirmationModal = retData => dispatch => {
    dispatch(setOnConfirmationData(DEPARTMENT_UPDATE_CONFIRMATION, retData));
};


export const onDeleteSuccess = (result) => dispatch => {
    dispatch(setDeleteData(DEPARTMENT_DELETE_DATA, true, result));
    dispatch(_getAllDepartments());
};



const _deleteDepartment = (MethodType, apiUrl) =>
    apiHelper({
        url: apiUrl,
        method: MethodType,
        onSuccess: result => onDeleteSuccess(result),
        onFailure: err => setErrorThunk(DEPARTMENT_ERROR, true, err),
        onLoad: status => setLoadingStatus(DEPARTMENT_IS_LOADING, status),
        accessToken: Cookies.get('accessToken'),
    });